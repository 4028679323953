import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    Stack,
    TextField,
    PageActions,
    Labelled,
} from "@shopify/polaris";
import styled from "styled-components";

import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";
import { CollectionSelectPolaris } from "../../collections/CollectionSelectPolaris";
import { TagsSelectPolaris } from "../../tags/TagsSelectPolaris";
import { BasesSection } from "./BasesSection";
import { useCreateProduct } from "./context";
import Wysiwyg from "../../../Wysiwyg";
import { useToastContext } from "../../../shared/ToastContext";
import { genHelpText, ShortTitleComp } from "../../components/ShortTitle";
import { MAX_TITLE_CHARACTERS } from "../../../../variable";
import JoditEditor from "../../../shared/JoditEditor";

const FIELDS_REQUIRED = {
    title: "Title",
    collections: "Collections",
    tags: "Tags",
};

export function Form({ onSubmit, loading, onCancel, isEdit, isClone }) {
    // Context
    const { toggleToast, setNotify } = useToastContext();
    const {
        state: {
            fieldsValue: {
                title,
                shortTitle,
                amzTitle,
                description,
                collections,
                tags,
                ...restField
            },
            selectedBases,
            template,
        },
        updateField,
        updateState,
        clearState,
    } = useCreateProduct();

    const [errors, setErrors] = useState({});
    const [errorDimensions, setErrorDimensions] = useState({});

    // Handle action
    const validateFields = useCallback((value, id) => {
        let error = null;
        let label = FIELDS_REQUIRED[id];
        if ((!value || !value.length) && label) {
            error = `${label} is required.`;
        }

        setErrors((prev) => ({ ...prev, [id]: error }));
    }, []);

    const handleValueChange = useCallback(
        (value, id) => {
            updateField(value, id);
            validateFields(value, id);
        },
        [updateField, validateFields]
    );

    const handleCancel = useCallback(() => {
        if (onCancel) {
            onCancel();
        } else {
            clearState();
            updateState({ selectedBases: [] });
        }
    }, [updateState, clearState, onCancel]);

    const handleSubmit = useCallback(() => {
        const values = {
            title,
            collections,
            tags,
        };

        for (let [key, value] of Object.entries(values)) {
            validateFields(value, key);
        }

        const noError = Object.values(errors).every((i) => i == null);
        const noErrorD = Object.values(errorDimensions).every((i) => i == null);
        if (
            title &&
            collections.length > 0 &&
            tags.length > 0 &&
            noError &&
            noErrorD
        ) {
            const newCollections =
                collections?.length > 0
                    ? collections.map((i) => (typeof i === "object" ? i.id : i))
                    : [];
            const newTags =
                tags?.length > 0
                    ? tags.map((i) => (typeof i === "object" ? i.id : i))
                    : [];
            const newFields =
                restField.fields?.length > 0
                    ? restField.fields.map((f) => {
                          const { id, __typename, sorting, ...rest } = f;
                          return rest;
                      })
                    : [];

            const sortBases = [];
            const disabledVariants = [];
            const enabledVariants = [];
            const productBaseOnlyUseDesignForGenMockup = [];
            let designPositions = [];
            const productBases = [];
            const randomTemplate = [];

            for (let i = 0; i < selectedBases.length; i++) {
                const base = selectedBases[i];
                if (base == null) continue;
                const baseId = base.id;
                productBases.push(baseId);
                sortBases.push({ productBaseId: baseId, position: i });

                for (let j = 0; j < base.variants.length; j++) {
                    let {
                        disabled,
                        productBaseVariantId,
                        regularPrice,
                        productBaseVariant,
                    } = base.variants[j];
                    //isEdit

                    const newProductBaseVariantId =
                        productBaseVariant?.productBaseVariantId;
                    const id = productBaseVariant?.id;

                    const variant = {
                        disabled,
                        productBaseVariantId,
                        regularPrice,
                    };

                    if (isEdit) {
                        variant.id = id;
                        variant.productBaseVariantId = newProductBaseVariantId;
                    } else if (isClone) {
                        variant.productBaseVariantId = newProductBaseVariantId;
                    }

                    if (disabled) {
                        disabledVariants.push(variant);
                    } else {
                        enabledVariants.push(variant);
                    }
                }

                const currentDP = base.designPositions.map((dp) => {
                    const file = dp?.file?.length > 0 ? dp.file[0] : null;
                    const fileId = file ? file.id : null;

                    return {
                        designPositionId: dp.id,
                        fileId,
                    };
                });

                designPositions = designPositions.concat(currentDP);

                if (base.useDesign) {
                    productBaseOnlyUseDesignForGenMockup.push(baseId);
                }

                if (base.isRandomTemplate) {
                    randomTemplate.push({
                        productBaseId: base.id,
                        qty: base.templateQuantity
                            ? parseInt(base.templateQuantity)
                            : 3,
                    });
                }
            }

            const variants = [...enabledVariants, ...disabledVariants];

            const countDP = designPositions.filter((i) => i.fileId);
            if (countDP.length === 0) {
                toggleToast(true);
                setNotify({
                    msg: "Please choose at least one file for design position",
                    err: true,
                });
                return;
            }

            const input = {
                title,
                shortTitle,
                amzTitle,
                description,
                collections: newCollections,
                tags: newTags,
                productBases,
                variants,
                designPositions,
                using2dMockup: {
                    ...restField.using2dMockup,
                    productBaseOnlyUseDesignForGenMockup,
                    randomTemplate,
                },
                personalized: restField.personalized,
                fields: newFields,
            };

            if (onSubmit) {
                onSubmit(input);
            }
        }
    }, [
        title,
        shortTitle,
        amzTitle,
        collections,
        tags,
        errors,
        description,
        restField,
        selectedBases,
        onSubmit,
        toggleToast,
        setNotify,
        errorDimensions,
        isEdit,
        isClone,
        validateFields,
    ]);

    // Get data
    useEffect(() => {
        if (template != null) {
            let title = "",
                description = "",
                tags = [],
                collections = [];

            let product = {};
            let fields = template.data?.fields ? template.data.fields : [];
            if (fields?.length > 0) {
                fields = fields.map((f) => {
                    const { __typename, sorting, ...rest } = f;
                    return rest;
                });
            }

            if (template.data?.products?.length > 0) {
                product = template.data.products[0];
                title = product?.title;
                description = product?.description;
                collections = product?.collections || [];
                tags = product?.tags || [];
            }
            updateField(title, "title");
            updateField(description, "description");
            updateField(collections, "collections");
            updateField(tags, "tags");
        }
    }, [template, updateField]);

    const titleHelpText = genHelpText(title);

    return (
        <Wrapper>
            <Card sectioned>
                <div className="first-content">
                    <div className="left-content">
                        <Stack vertical spacing="loose">
                            <div>
                                <ComponentLabelPolaris label="Title" required />
                                <TextField
                                    id={"title"}
                                    placeholder="Enter product title"
                                    value={title}
                                    onChange={handleValueChange}
                                    error={errors.title}
                                    helpText={titleHelpText}
                                />
                            </div>
                            <div>
                                <ShortTitleComp
                                    id="shortTitle"
                                    placeholder="Enter facebook title"
                                    value={shortTitle}
                                    onChange={handleValueChange}
                                    multiline={4}
                                    maxLength={MAX_TITLE_CHARACTERS.fbTitle}
                                    label="FB Title"
                                />
                            </div>
                            <div>
                                <Labelled label="Description" />
                                {/* <Wysiwyg
                                    value={description}
                                    onChange={(value) =>
                                        handleValueChange(value, "description")
                                    }
                                /> */}
                                <JoditEditor
                                    value={description}
                                    onChange={(value) =>
                                        handleValueChange(value, "description")
                                    }
                                />
                            </div>
                        </Stack>
                    </div>
                    <div className="right-content">
                        <Stack vertical spacing="loose">
                            <div>
                                <ShortTitleComp
                                    id="amzTitle"
                                    placeholder="Enter amazon title"
                                    value={amzTitle}
                                    onChange={handleValueChange}
                                    multiline={4}
                                    maxLength={MAX_TITLE_CHARACTERS.amzTitle}
                                    label="Amazon Title"
                                />
                            </div>
                            <CollectionSelectPolaris
                                label="Collections"
                                required
                                allowMultiple
                                haveQuickAdd
                                value={collections}
                                errorMessage={errors.collections}
                                limit={2000}
                                onChange={(value) =>
                                    handleValueChange(value, "collections")
                                }
                            />
                            <TagsSelectPolaris
                                label="Tags"
                                required
                                allowMultiple
                                haveQuickAdd
                                value={tags}
                                errorMessage={errors.tags}
                                limit={2000}
                                onChange={(value) =>
                                    handleValueChange(value, "tags")
                                }
                            />
                        </Stack>
                    </div>
                </div>
            </Card>
            <BasesSection
                selectedBases={selectedBases}
                setErrorDimensions={setErrorDimensions}
            />
            <PageActions
                primaryAction={{
                    content: "Submit",
                    onAction: handleSubmit,
                    loading: loading,
                }}
                secondaryActions={[
                    {
                        content: "Cancel",
                        onAction: handleCancel,
                    },
                ]}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    .first-content {
        display: flex;
        column-gap: 2rem;

        .left-content {
            width: calc(60% - 1rem);
        }

        .right-content {
            width: calc(40% - 1rem);
        }
    }
`;
