import React from "react";
import { WrapperPage } from "../../../components/shared/WrapperPage";
import SuspenseComp from "../../../components/shared/SuspenseComp";
import { SkeletonPagePolaris } from "../../../components/shared/SkeletonPagePolaris";
import TiktokEditProductTemplate from "../../../components/tiktok/product-templates/TiktokEditProductTemplate"
import { useParams } from "react-router-dom";

export default function TiktokEditProductTemplatePage() {
	const { id } = useParams();

	return (
		<WrapperPage>
			<SuspenseComp fallback={<SkeletonPagePolaris />}>
				<TiktokEditProductTemplate id={id}/>
			</SuspenseComp>
		</WrapperPage>
	);
}
