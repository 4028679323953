import React from "react";
import { WrapperPage } from "../../../components/shared/WrapperPage";
import SuspenseComp from "../../../components/shared/SuspenseComp";
import { SkeletonPagePolaris } from "../../../components/shared/SkeletonPagePolaris";
import TiktokProductCrawlItems from "../../../components/tiktok/product-crawl-items/TiktokProductCrawlItems"

export default function TiktokProductCrawlItemsPage() {
	return (
		<WrapperPage>
			<SuspenseComp fallback={<SkeletonPagePolaris />}>
				<TiktokProductCrawlItems />
			</SuspenseComp>
		</WrapperPage>
	);
}
