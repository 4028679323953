import {
	Page,
	Card,
	Form,
	FormLayout,
	TextField,
	Spinner,
	Heading,
	ButtonGroup,
	Button,
	TextStyle,
} from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useState, useCallback, useMemo, useRef } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import { getParamByRole } from "../../../helper";
import { useHistory } from "react-router-dom";
import { Prompt } from "react-router-dom";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { useToastContext } from "../../shared/ToastContext";
import TiktokCategorySelector from "./TiktokCategorySelector";
import TiktokStaticAttributes from "./TiktokStaticAttributes";
import TiktokVariantsGenerator from "./TiktokVariantsGenerator";
import TiktokPackageDimensions from "./TiktokPackageDimensions";
import TiktokPackageWeight from "./TiktokPackageWeight";
import TiktokExtraImages from "./TiktokExtraImages";
import TiktokSizeChartImage from "./TiktokSizeChartImage";
import TiktokBrandSelector from "./TiktokBrandSelector";
import TiktokExcelTemplate from "./TiktokExcelTemplate";
import JoditEditor from 'jodit-react';
import TiktokExtraImageTemplate from "./TiktokExtraImageTemplate";
import TiktokSizeChartImageTemplate from "./TiktokSizeChartImageTemplate";
import { matchWeightAndDimesion } from "./utils";

const _ = require('lodash');

const CREATE_TIKTOK_PRODUCT_TEMPLATE = gql`
	mutation createTiktokProductTemplate($input: CreateTiktokProductTemplateInput!) {
		createTiktokProductTemplate(input: $input) {
			id
		}
	}
`;

const Container = styled.div`
	.short-desc {
		font-size: 13px;
		font-style: italic;
	}
	.btn-wrap {
		margin-top: 20px;
		display: flex;
		flex-direction: row-reverse;
	}

	.product-attributes {
		margin-top: 20px;
	}

	.field-error-message {
		color: red;
		font-size: 12px;
	}
`;

export default function TiktokCreateProductTemplate() {
	const history = useHistory();
	const [fieldData, setFieldData] = useState({
		title: null,
		brand: null,
		brandDetail: {},
		categoryID: null, 
		productDescription: null,
		staticAttributes: null,
		dynamicAttributes: null,
		variants: null,
		packageDimensions: null,
		packageWeight: null,
		extraImageFileIDs: null,
		sizeChartFileID: null,
		excelTemplateFileID: null,
	});
	const [fieldError, setFielError] = useState({});

	const editor = useRef(null);

	const [isPrompt, setIsPrompt] = useState(false);
	const [mutationData, setMutationData] = useState({loading: false, response: null, error: null});

	const { currentUser } = useAppContext();
	const param = getParamByRole(currentUser);

	const pagePath = `/${param}/tiktok-hub/product-templates`;
	const { toggleToast, setNotify } = useToastContext();

	const handleFieldDataChanged = useCallback((field, value) => {
		setFieldData((prevFields) => {
			if (field.includes(".")) { // Set complex nested object value
				const keys = field.split(".");
				const [parentKey, childKey] = keys;

				return {
					...prevFields,
					[parentKey]: {
						...prevFields[parentKey],
						[childKey]: value,
					},
				};
			}

			const newState = matchWeightAndDimesion({state: prevFields, key: field, value})
			return { // Set case simple key:value
				...newState,
				[field]: value,
			};
		});
		if (value) { // The value is valid so try to remove the field error.
			setFielError((prevState) => {
				const newState = { ...prevState };
				if (field in newState) {
					delete newState[field];
				}
				return newState;
			});
		}
		setIsPrompt(true);
	}, []);

	const handleFieldErrorChanged = (field, value) => {
		setFielError((prevFields) => ({
			...prevFields,
			[field]: value,
		}));
	}

	const removeFieldError = (field) => {
		setFielError((prevState) => {
			const newState = { ...prevState };
			if (field in newState) {
				delete newState[field];
			}
			return newState;
		});
	}

	const isValidKey = (obj, key) => {
		let valid = _.has(obj, key) && obj[key] != null && obj[key].length > 0;
		return valid;
	};
	const isValidNestedKey = (obj, key) => {
		const keys = Array.isArray(key) ? key : key.split('.');
		
		let currentValue = obj;
		for (let i = 0; i < keys.length; i++) {
			if (!currentValue || !Object.prototype.hasOwnProperty.call(currentValue, keys[i])) {
				return false; 
			}
			currentValue = currentValue[keys[i]]; 
		}
		return currentValue != null && currentValue.length > 0;
	};

	const [createTiktokProductTemplate] = useMutation(CREATE_TIKTOK_PRODUCT_TEMPLATE);

	const extraITRef = React.useRef(null);
	const sizeChartRef = React.useRef(null);
	const handleFormSubmit = async () => {
		let formData = fieldData;
		let hasError = false;

		if (extraITRef.current) {
			const curRef = extraITRef.current;
			const ids = curRef?.getIds ? curRef.getIds() : [];
			if (ids?.length > 0 ) {
				formData.extraImageTemplateIDs = ids;
			}
		}

		if (sizeChartRef.current) {
			const curRef = sizeChartRef.current;
			const ids = curRef?.getId ? curRef.getId() : null;
			if (ids?.length > 0) {
				formData.sizeChartImageTemplateID = ids[0];
			}
		}

		if (!isValidKey(formData, 'title')) {
			handleFieldErrorChanged('title', 'Please enter a template name');
			hasError = true;
		}
		if (!isValidKey(formData, 'categoryID')) {
			handleFieldErrorChanged('categoryID', 'Please choose a Tiktok category');
			hasError = true;
		}

		if (!isValidNestedKey(formData, 'packageDimensions.unit')) {
			handleFieldErrorChanged('packageDimensions', 'Please enter a package dimensions unit');
			hasError = true;
		}
		if (!isValidNestedKey(formData, 'packageDimensions.width')) {
			handleFieldErrorChanged('packageDimensions', 'Please enter a package dimensions width');
			hasError = true;
		}
		if (!isValidNestedKey(formData, 'packageDimensions.height')) {
			handleFieldErrorChanged('packageDimensions', 'Please enter a package dimensions height');
			hasError = true;
		}
		if (!isValidNestedKey(formData, 'packageDimensions.length')) {
			handleFieldErrorChanged('packageDimensions', 'Please enter a package dimensions length');
			hasError = true;
		}

		if (!isValidNestedKey(formData, 'packageWeight.unit')) {
			handleFieldErrorChanged('packageWeight', 'Please enter a package weight unit');
			hasError = true;
		}
		if (!isValidNestedKey(formData, 'packageWeight.value')) {
			handleFieldErrorChanged('packageWeight', 'Please enter a package weight value');
			hasError = true;
		}

		if (!isValidNestedKey(formData, 'dynamicAttributes')) {
			handleFieldErrorChanged('dynamicAttributes', 'Please create product attributes');
			hasError = true;
		}

		if (hasError) {
			return;
		}

		// Submit data
		toggleToast(true);
		try {
			setMutationData((prev) => ({...prev, loading: true}));
			const res = await createTiktokProductTemplate({
				variables: {
					input: formData
				},
			});
			if (res && res.data && res.data.createTiktokProductTemplate) {
				setNotify({
                    msg: "The new Tiktok product template has been added successfully",
                    err: false,
                });
			}
			
		} catch (ex) {
			setNotify({
				msg: "Could not create new Tiktok product template",
				err: false,
			});
		} finally {
			setIsPrompt(false);
			setMutationData((prev) => ({...prev, loading: false}));
			setTimeout(function() {
				history.push(pagePath);
			}, 3000);
		}
	}


	const initialAttrAndVariants = useMemo(() => ({
		attributes: fieldData?.dynamicAttributes || null,
		variants: fieldData?.variants || null,
	}), [fieldData]);

	const renderFieldErrorMessage = (key) => {
		let message = null;
		if (fieldError?.[key]) {
			message = fieldError?.[key];
		}

		return (message ? (
			<div className="Polaris-Labelled__Error"><div className="Polaris-InlineError"><div className="Polaris-InlineError__Icon"><span className="Polaris-Icon"><svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z" fillRule="evenodd"></path></svg></span></div>{message}</div></div>
		) : null)
	}

	const editorConfigs = useMemo(
		() => ({
			readonly: false,
			placeholder: 'Start typings...',
			minHeight: 400,
			buttons: "bold,italic,underline,strikethrough,eraser,ul,ol,cut,copy,paste,selectall,copyformat,hr,table,link,indent,outdent,left",
		}),
		[]
	);

	return (
		<Container>
			<Page
				title="New Tiktok Product Template"
				breadcrumbs={[
					{
						content: "All Tiktok Product Templates",
						url: pagePath,
					},
				]}
			>
				<React.Fragment>
					<Prompt
						when={isPrompt}
						message="You have unsaved changes, are you sure you want to leave?"
					/>
					<Container>
						<Form onSubmit={handleFormSubmit}>
							<Card sectioned>
								<FormLayout>

									<div>
										<ComponentLabelPolaris
											label="Template name"
											required
										/>
										<TextField
											value={fieldData?.title || null}
											placeholder="Enter template name"
											onChange={(val) =>
												handleFieldDataChanged(
													"title",
													val
												)
											}
											error={fieldError?.title || null}
										/>
									</div>

									<div>
										<TiktokCategorySelector
											label={"Tiktok product category"}
											defaultValue={fieldData?.categoryID ? [fieldData?.categoryID] : []}
											onSelect={(val) =>
												handleFieldDataChanged(
													"categoryID",
													val?.[0]
												)
											}
										/>
										{renderFieldErrorMessage('categoryID')}
									</div>

									<div>
										<React.Suspense
											fallback={<Spinner size="small" />}
										>
											<TiktokBrandSelector
												label={"Tiktok brand"}
												defaultValue={fieldData?.brandDetail || {}}
												onSelect={(brand) => {
													handleFieldDataChanged("brandDetail", brand)
													handleFieldDataChanged("brand", brand.id)
												}}
											/>
										</React.Suspense>
									</div>

									<div>
										<ComponentLabelPolaris
											label="Product description"
										/>
										<JoditEditor
											ref={editor}
											value={fieldData?.productDescription ?? ''}
											config={editorConfigs}
											tabIndex={1} // tabIndex of textarea
											onBlur={(newContent) => handleFieldDataChanged( "productDescription", newContent)} // preferred to use only this option to update the content for performance reasons
										/>

										<div style={{ marginTop: 10}}>
											<TextStyle variation="subdued" >
												All dynamic variables: MB_TT_PRODUCT_STORE_ID, MB_TT_ITEM_ID, MB_TT_ITEM_TITLE, MB_TT_PUSH_ID, MB_TT_PUSH_TITLE, MB_RANDOM_ID
											</TextStyle>
										</div>
									</div>
								</FormLayout>
							</Card>

							<div className="product-attributes">
								<TiktokVariantsGenerator initialData={initialAttrAndVariants || null} onDataChange={(values) => {
									handleFieldDataChanged( "dynamicAttributes", values.attributes);
									handleFieldDataChanged( "variants", values.variants);
								}} error={fieldError?.dynamicAttributes || null}/>
							</div>

							{fieldData?.categoryID ? (
								<>
									<Card sectioned>
										<Heading element="h3">Product attributes (ID: {fieldData?.categoryID}):</Heading>

										{/* Baby & Maternity > Baby Clothing & Shoes > Hoodies & Active > Unisex Hoodies & Active */}
										<TiktokStaticAttributes
											categoryID={fieldData?.categoryID}
											defaultValues={fieldData?.staticAttributes || null}
											onAttributeChange={(values) => {
												const attrs = values.filter(item => item.values.length > 0 || item.custom_values.length > 0);
												handleFieldDataChanged("staticAttributes", attrs)
											}}
										/>
									</Card>
									
								</>
							) : (null)}

							<Card sectioned>
								<Heading>Package Dimensions</Heading>
								<TiktokPackageDimensions defaultValues={fieldData?.packageDimensions || null} onChange={(value) => handleFieldDataChanged("packageDimensions", value)}/>
								{renderFieldErrorMessage('packageDimensions')}
							</Card>

							<Card sectioned>
								<Heading>Package weight</Heading>
								<TiktokPackageWeight defaultValues={fieldData?.packageWeight || null} onChange={(value) => handleFieldDataChanged("packageWeight", value)}/>
								{renderFieldErrorMessage('packageWeight')}
							</Card>

							<Card sectioned>
								<Heading>Extra Images</Heading>
								<TiktokExtraImages defaultValues={[]} onChange={(values) => {
									if (values.length > 0) {
										let imageIds = [];
										values.forEach((img) => {
											if (img && img.id) {
												imageIds.push(img.id);
											}
										});
										if (imageIds.length > 0) {
											handleFieldDataChanged("extraImageFileIDs", imageIds)
										} else {
											handleFieldDataChanged("extraImageFileIDs", null);
										}
									} else {
										handleFieldDataChanged("extraImageFileIDs", null);
									}
								}}/>
								<TiktokExtraImageTemplate ref={extraITRef}/>
							</Card>

							<Card sectioned>
								<Heading>Size chart</Heading>
								<TiktokSizeChartImage defaultValue={{}} onChange={(value) => {
									if (value && value?.[0] && value?.[0]?.id) {
										handleFieldDataChanged("sizeChartFileID", value[0].id);
									} else {
										handleFieldDataChanged("sizeChartFileID", null);
									}
								}}/>

								<TiktokSizeChartImageTemplate ref={sizeChartRef}/>
							</Card>

							{fieldData?.categoryID ? (
								<Card sectioned>
									<Heading>Excel template</Heading>
									<TiktokExcelTemplate tiktokCategoryID={fieldData?.categoryID || null} defaultValue={{}} 
										onChange={(value, errorMessage) => {
											if (value && value?.[0] && value?.[0]?.id) {
												handleFieldDataChanged("excelTemplateFileID", value[0].id);
											} else {
												handleFieldDataChanged("excelTemplateFileID", null);
											}

											if (errorMessage) {
												handleFieldErrorChanged('excelTemplateFileID', errorMessage)
											} else {
												removeFieldError('excelTemplateFileID');
											}
										}}
									/>
								</Card>
							) : null}

							<div className="btn-wrap">
								<ButtonGroup>
									<Button onClick={() => history.push(pagePath) }>
										Cancel
									</Button>

									<Button submit primary loading={mutationData.loading} disabled={!isPrompt || mutationData.loading || !_.isEmpty(fieldError)}>
										Create new template
									</Button>
								</ButtonGroup>
							</div>
						</Form>
					</Container>
				</React.Fragment>
			</Page>
		</Container>
	);
}
