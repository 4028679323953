import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useReducer,
} from "react";
import {
  arrInvalid,
  getUniqueV2,
  handleError,
  reducerFn,
  sleep,
} from "../../helper";
import { ComponentLabelPolaris } from "./ComponentLabelPolaris";
import { Autocomplete, Tag } from "@shopify/polaris";
import useTimeout from "../../hooks/useTimeout";
import { useCallbackRef } from "../../hooks/useCallbackRef";
import { TagContainer } from "./TagContainer";
import { isEqual } from "lodash";

let aId = 0;
function AutocompleteV2(
  {
    id,
    label,
    labelHidden,
    required,
    onChange: onChangeProp,
    allowMultiple,
    options: optionsProp,
    value,
    loading,
    error,
    getValueImmediate, // get value immediate when selection

    setFilter,
  },
  ref,
) {
  let mId = id ?? `mb__${++aId}`;

  const [state, setState] = useReducer(reducerFn, {
    options: [],
    selected: [],
    inputVal: "",
    selectedOptions: [],
    deselectedOptions: [],
  });

  const INPUT_ID = React.useMemo(() => "INPUT_COLLECTION_" + mId, []);
  const AUTOCOMPLETE_ID = React.useMemo(
    () => "AUTOCOMPLETE_COLLECTION_" + mId,
    [],
  );

  const COMBOBOX_SELECTOR = React.useMemo(
    () => `div[role="combobox"][aria-controls="${AUTOCOMPLETE_ID}"]`,
    [AUTOCOMPLETE_ID],
  );

  const [setDelay] = useTimeout();
  const inputChanged = React.useRef(null);
  const setFilterRef = useCallbackRef(setFilter);
  const handleInputChange = React.useCallback((search) => {
    setState({ inputVal: search });
    inputChanged.current = !!search;

    setDelay(() => {
      setFilterRef({ search });
    });
  }, []);

  const onChange = useCallbackRef(onChangeProp);
  const changedRef = React.useRef(false);
  const handleSelection = React.useCallback(
    (selected) => {
      const { options, deselectedOptions } = state;
      let mOptions = getUniqueV2("value", options, deselectedOptions);

      const selectedOptions = getSelectedOptions(selected, mOptions);
      const newState = {
        selected,
        selectedOptions,
      };

      const { label } = getFirstItem(selectedOptions);
      if (!allowMultiple) {
        newState.inputVal = label || "";
      }
      setState(newState);

      changedRef.current = true;

      if (getValueImmediate) {
        const args = getSelectedInfo(selected, selectedOptions, allowMultiple);
        onChange(...args);
      }
    },
    [allowMultiple, state.options, state.deselectedOptions, getValueImmediate],
  );

  const handleRemove = React.useCallback(
    (value) => () => {
      const { selected: ss, selectedOptions: sso } = state;
      const selected = filterItem(ss, (s) => s !== value);
      const selectedOptions = filterItem(sso, (i) => i.value !== value);

      setState({ selected, selectedOptions });
      allowMultiple && onChange(selected, selectedOptions);
    },
    [onChange, allowMultiple, state.selectedOptions, state.selected],
  );

  const handleFocus = React.useCallback(async () => {
    // Focus again when options change
    // When has search value + changed is `false`
    if (inputChanged.current != null && !changedRef.current) {
      const input = document.getElementById(INPUT_ID);
      if (input) {
        input.blur && input.blur();
        await sleep(0);
        input.focus && input.focus();
      }
    }
  }, []);

  // Effect
  useEffect(() => {
    if (!isEqual(state.options, optionsProp)) {
      setState({ options: optionsProp });
    }
  }, [JSON.stringify(optionsProp)]);

  useLayoutEffect(() => {
    const { options, deselectedOptions } = state;
    if (arrInvalid(value) || arrInvalid(options)) return;

    const dso = getUniqueV2("value", options, deselectedOptions);
    const selectedOptions = getSelectedOptions(value, dso);
    const newState = {
      selected: value,
      deselectedOptions,
    };

    if (allowMultiple) {
      newState.selectedOptions = selectedOptions;
    } else {
      const { label } = getFirstItem(selectedOptions);
      newState.inputVal = label || "";
    }

    setState(newState);
  }, [value, state.options, allowMultiple]);

  useLayoutEffect(() => {
    const container = document.querySelector(COMBOBOX_SELECTOR);
    if (container && onChange) {
      function updateValue(e) {
        let attr = container.getAttribute("aria-expanded") || "";
        const expanded = JSON.parse(attr);

        if (!expanded && !container.contains(e.target) && changedRef.current) {
          const sso = state.selectedOptions;
          const ss = state.selected;
          const args = getSelectedInfo(ss, sso, allowMultiple);
          onChange(...args);

          changedRef.current = false;
          inputChanged.current = null;
        }
      }
      document.addEventListener("click", updateValue);

      return () => {
        document.removeEventListener("click", updateValue);
      };
    }
  }, [state.selectedOptions, state.selected]);

  useImperativeHandle(ref, () => ({
    handleFocus: handleFocus,
  }));

  const textField = (
    <Autocomplete.TextField
      value={state.inputVal}
      onChange={handleInputChange}
      placeholder="Search ..."
      id={INPUT_ID}
    />
  );

  return (
    <React.Fragment>
      {labelHidden ? null : <ComponentLabelPolaris label={label} required={!!required} />}
      {error ? (
        <div>Error: {handleError(error?.toString())}</div>
      ) : (
        <>
          <Autocomplete
            options={state.options}
            selected={state.selected}
            onSelect={handleSelection}
            textField={textField}
            allowMultiple={allowMultiple}
            emptyState={<span>No items found!</span>}
            loading={loading}
            id={AUTOCOMPLETE_ID}
          />
          {allowMultiple ? (
            <TagContainer>
              {state.selectedOptions?.length > 0
                ? state.selectedOptions.map((opt) => (
                    <Tag
                      children={opt.label}
                      key={opt.value}
                      onRemove={handleRemove(opt.value)}
                    />
                  ))
                : null}
            </TagContainer>
          ) : null}
        </>
      )}
    </React.Fragment>
  );
}

function filterItem(arr, fn) {
  if (arrInvalid(arr)) return [];

  return arr.filter(fn);
}

function genOptions(
  data,
  fn = (item) => ({ value: item.id, label: item.title, data: item }),
) {
  if (arrInvalid(data)) return [];

  return data.map(fn);
}

const getSelectedOptions = (selected, deselectedOptions) => {
  const fn = (item) => {
    return (deselectedOptions || []).find(({ value }) => value === item);
  };
  return genOptions(selected, fn).filter(Boolean);
};

function getSelectedInfo(selected, selectedOptions, allowMultiple) {
  const [firstItem] = selectedOptions || [];
  const { value } = firstItem || {};
  return allowMultiple ? [selected, selectedOptions] : [value, firstItem];
}

function getFirstItem(selected) {
  if (arrInvalid(selected)) return {};
  const [f] = selected;
  const { value, label } = f || {};

  return { item: f, value, label };
}

export default forwardRef(AutocompleteV2);
