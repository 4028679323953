import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete, TextField, Spinner, Icon, Tag, Toast } from "@shopify/polaris";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import debounce from "lodash.debounce";
import { TagContainer } from "../../shared/TagContainer";

const GET_TIKTOK_BRANDS = gql`
	query getTiktokBrands($input: GetTiktokBrandFilter!) {
		getTiktokBrands(input: $input) {
			code
			data {
				brands {
					id
					name
				}
				nextPageToken
				totalCount
			}
			message
		}
	}
`;

const CREATE_TIKTOK_BRAND = gql`
	mutation createFirstStoreTiktokCustomBrand($name: String!) {
		createFirstStoreTiktokCustomBrand(name: $name) {
			code
			data {
				id
			}
			message
		}
	}
`;

const TiktokBrandSelector = ({ label, defaultValue, onSelect }) => {
	const [inputValue, setInputValue] = useState(defaultValue?.title || "");
	const [selectedBrand, setSelectedBrand] = useState(defaultValue || null);
	const [options, setOptions] = useState([]);
	const [noResults, setNoResults] = useState(false);
	const [toastMessage, setToastMessage] = useState(null);

	useEffect(() => {
		setInputValue(defaultValue?.title || "");
		setSelectedBrand(defaultValue || null);
	}, [defaultValue]);

	const { refetch: fetchBrands, loading } = useQuery(GET_TIKTOK_BRANDS, {
		skip: true,
	});

	const [createBrand] = useMutation(CREATE_TIKTOK_BRAND);

	const handleFetchBrands = async (brandName = "") => {
		setNoResults(false);
		try {
			const { data } = await fetchBrands({
				input: {
					brandName,
					pageToken: "",
				},
			});

			const brands = data?.getTiktokBrands?.data?.brands || [];
			if (brands.length === 0) {
				setNoResults(true);
			} else {
				setOptions(
					brands.map((brand) => ({
						value: brand.id,
						label: brand.name,
					}))
				);
			}
		} catch (error) {
			console.log("Failed to fetch brands", error);
		}
	};

	const debouncedFetchBrands = useCallback(
		debounce(handleFetchBrands, 1000),
		[]
	);

	const handleCreateBrand = async (name) => {
		try {
			const { data } = await createBrand({ variables: { name } });
			const newBrand = data?.createFirstStoreTiktokCustomBrand?.data;

			if (newBrand?.id) {
				const brandData = { id: newBrand.id, title: name };
				onSelect(brandData);
				setSelectedBrand(brandData);
				setInputValue("");
				setToastMessage(`Brand "${name}" created successfully!`);
			}
		} catch (error) {
			console.log("Failed to create brand", error);
			setToastMessage("Failed to create brand. Please try again.");
		}
	};

	const updateText = useCallback(
		(value) => {
			setInputValue(value);
			if (value.trim() === "") {
				setOptions([]);
				setNoResults(false);
				return;
			}
			debouncedFetchBrands(value);
		},
		[debouncedFetchBrands]
	);

	const handleSelection = useCallback(
		(selected) => {
			const selectedOption = options.find(
				(option) => option.value === selected[0]
			);
			if (selectedOption) {
				const brandData = {
					id: selectedOption.value,
					title: selectedOption.label,
				};
				onSelect(brandData);
				setSelectedBrand(brandData);
				setInputValue("");
			}
		},
		[options, onSelect]
	);

	useEffect(() => {
		handleFetchBrands("");
	}, []);

	const handleTagRemove = useCallback(() => {
		setSelectedBrand(null);
		onSelect(null);
	}, [onSelect]);

	return (
		<div>
			<Autocomplete
				options={
					noResults
						? [
								{
									value: "create-new",
									label: (
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Icon source={CirclePlusMinor} />
											<span
												style={{ marginLeft: "0.5rem" }}
											>
												Create new brand: "{inputValue}"
											</span>
										</div>
									),
								},
						  ]
						: options
				}
				selected={[]}
				onSelect={(selected) => {
					if (selected[0] === "create-new") {
						handleCreateBrand(inputValue);
					} else {
						handleSelection(selected);
					}
				}}
				textField={
					<TextField
						value={inputValue}
						onChange={updateText}
						label={label ? label : 'Tiktok brand'}
						clearButton
						onClearButtonClick={() => {
							setInputValue("");
							setOptions([]);
							setNoResults(false);
						}}
						placeholder="Type to search..."
						prefix={loading ? <Spinner size="small" /> : null}
					/>
				}
				loading={loading}
			/>

			{selectedBrand && selectedBrand.title ? (
				<TagContainer>
					<Tag onRemove={handleTagRemove}>{selectedBrand.title}</Tag>
				</TagContainer>
			) : null}

			{toastMessage && (
				<Toast
					content={toastMessage}
					onDismiss={() => setToastMessage(null)}
				/>
			)}
		</div>
	);
};

export default TiktokBrandSelector;
