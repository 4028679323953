import React, { useState, useCallback, useEffect } from "react";
import {
  Layout,
  Form,
  FormLayout,
  TextField,
  Card,
  ChoiceList,
  Button,
  ButtonGroup,
} from "@shopify/polaris";
import _ from "lodash";
import styled from "styled-components";

import history from "../../../history";
import Wysiwyg from "../../Wysiwyg";

import { CollectionTypePolaris } from "./CollectionTypePolaris";
import { ProductByCollectionPolaris } from "./ProductsByCollectionPolaris";
import { CollectionSelectPolaris } from "./CollectionSelectPolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import JoditEditor from "../../shared/JoditEditor";

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 2rem 0;
  margin: 0 auto;
  border-top: 0.1rem solid var(--p-border-subdued, #dfe3e8);
`;

const Container = styled.div`
  .list-file_wrap {
    height: 120px;
    .file-item {
      max-width: 120px;
      .file_wrap {
        height: 100%;
      }
    }
  }
`;

export const CollectionFormPolaris = ({
  onSubmit,
  loading,
  value,
  editCollection,
  currentParam,
}) => {
  const [type, setType] = useState([false]);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [parentsCollection, setParentsCollection] = useState(null);
  const [collectionVisibility, setCollectionVisibility] = useState([false]);
  const [imageFileId, setImageFileId] = useState(null);
  const [conditions, setConditions] = useState({
    anyCondition: false,
    value: [],
  });
  const [errors, setErrors] = useState({
    name: null,
  });
  const [isPopular, setIsPopular] = useState([false]);

  useEffect(() => {
    if (value) {
      let img = _.get(value, "image", null);
      setName(_.get(value, "name", ""));
      setDescription(_.get(value, "description", ""));
      setParentsCollection(_.get(value, "parent.id", null));
      setCollectionVisibility([_.get(value, "private", false)]);
      setType([_.get(value, "automated", false)]);
      setConditions((prevState) => {
        return {
          ...prevState,
          anyCondition: false,
          value: _.get(value, "conditions", []),
        };
      });
      setImageFileId(img ? [img] : []);
      setIsPopular([_.get(value, "isPopular", false)]);
    }
  }, [value]);

  const handleNameValue = (value) => {
    setErrors((prevState) => {
      let n = null;
      if (!value) {
        n = "Please enter name.";
      }
      return {
        ...prevState,
        name: n,
      };
    });
  };
  const handleNameChange = useCallback((value) => {
    setName(value);
    handleNameValue(value);
  }, []);
  const handleDescriptionChange = useCallback(
    (value) => setDescription(value),
    [],
  );
  const handleParentsCollection = useCallback(
    (value) => setParentsCollection(value),
    [],
  );
  const handleTypeChange = useCallback((value) => setType(value), []);
  const handleCollectionVisibility = useCallback((value) => {
    setCollectionVisibility(value);
  }, []);

  const handleImageChange = useCallback((value) => {
    let newImg = value.length ? [_.get(_.head(value), "id", null)] : [];
    setImageFileId(newImg);
  }, []);

  const handleConditionsChange = useCallback(({ anyCondition, conditions }) => {
    setConditions((prevState) => {
      return {
        ...prevState,
        anyCondition,
        value: conditions,
      };
    });
  }, []);

  const handleSubmit = useCallback(() => {
    handleNameValue(name);
    let count = _.size(_.filter(_.values(errors, (e) => e !== null)));
    let newConditions = conditions.value.map((c) => {
      let { __typename, ...rest } = c;
      return {
        ...rest,
      };
    });

    if (name && count === 0) {
      let input = {
        name,
        description,
        parentId: parentsCollection,
        imageFileId:
          imageFileId && imageFileId.length ? _.head(imageFileId) : null,
        automated: _.head(type),
        anyCondition: conditions.anyCondition,
        conditions: newConditions,
        private: _.head(collectionVisibility),
        isPopular: _.head(isPopular),
      };
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errors,
    name,
    description,
    parentsCollection,
    imageFileId,
    type,
    conditions,
    collectionVisibility,
    isPopular,
  ]);

  return (
    <Container>
      <Form>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <FormLayout>
                <div>
                  <ComponentLabelPolaris label="Name" required />
                  <TextField
                    // label="Name(*)"
                    value={name}
                    onChange={handleNameChange}
                    placeholder="Name"
                    error={errors.name}
                  />
                </div>
                <div>
                  <ComponentLabelPolaris label="Description (optional)" />
                  {/* <Wysiwyg
                    value={description}
                    onChange={(v) => handleDescriptionChange(v)}
                  /> */}
                  <JoditEditor
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </div>
                <CollectionSelectPolaris
                  value={parentsCollection ? [parentsCollection] : []}
                  resource={value?.parent || null}
                  onChange={handleParentsCollection}
                  label="Parents collection"
                />
              </FormLayout>
            </Card>
            <Card>
              {editCollection && value ? (
                <ProductByCollectionPolaris collectionId={value.id} />
              ) : (
                <>
                  <Card.Header title="Collection type" />
                  <Card.Section>
                    <ChoiceList
                      title="collection type"
                      titleHidden
                      choices={[
                        {
                          label: "Manual",
                          value: false,
                          helpText:
                            "Add products to this collection one by one",
                        },
                        {
                          label: "Automated",
                          value: true,
                          helpText:
                            "Existing and future products that match the conditions you set will automatically be added to this collection",
                        },
                      ]}
                      selected={type}
                      onChange={handleTypeChange}
                    />
                  </Card.Section>
                  {true === _.head(type) && (
                    <Card.Section title="Conditions">
                      <CollectionTypePolaris
                        onChange={(value) => handleConditionsChange(value)}
                      />
                    </Card.Section>
                  )}
                </>
              )}
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card>
              <Card.Header title="Collection visibility" />
              <Card.Section>
                <ChoiceList
                  title="Collection visibility"
                  titleHidden
                  choices={[
                    { label: "Public", value: false },
                    { label: "Private", value: true },
                  ]}
                  selected={collectionVisibility}
                  onChange={handleCollectionVisibility}
                />
              </Card.Section>
            </Card>
            <Card>
              <Card.Header title="Collection image" />
              <Card.Section>
                {editCollection ? (
                  <>
                    {imageFileId ? (
                      <MediaSelectorButtonPolaris
                        accept={"image/*"}
                        singleUpload={true}
                        multiple={false}
                        value={imageFileId}
                        sizeSmall
                        onChange={(value) => handleImageChange(value)}
                      />
                    ) : null}
                  </>
                ) : (
                  <MediaSelectorButtonPolaris
                    accept={"image/*"}
                    singleUpload={true}
                    multiple={false}
                    sizeSmall
                    onChange={(value) => handleImageChange(value)}
                  />
                )}
              </Card.Section>
            </Card>
            <Card title="Collection popular" sectioned>
              <ChoiceList
                selected={isPopular}
                choices={[
                  { label: "False", value: false },
                  { label: "True", value: true },
                ]}
                onChange={setIsPopular}
              />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <ButtonWrap>
              <ButtonGroup>
                <Button
                  onClick={() => history.push(`/${currentParam}/collections`)}
                >
                  Cancel
                </Button>
                <Button primary onClick={handleSubmit} loading={loading}>
                  Save
                </Button>
              </ButtonGroup>
            </ButtonWrap>
          </Layout.Section>
        </Layout>
      </Form>
    </Container>
  );
};
