import { Card, Page, SkeletonDisplayText } from "@shopify/polaris";
import SuspenseComp from "../../shared/SuspenseComp";
import ProductTemplatesFilter from "./ProductTemplatesFilter";
import ProductTemplatesTable from "./ProductTemplatesTable";
import React, { useState, useEffect, useRef, useMemo } from "react";

import { useAppContext } from "../../../context";
import { getParamByRole } from "../../../helper";
import _ from "lodash";

export default function TiktokProductTemplates() {
	const [filters, setFilters] = useState({
		limit: 20,
		offset: 0,
		search: null,
		order: "DESC",
		orderBy: "created_at",
	});
	const isMounted = useRef(true);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const safeSetFilters = (newFilters) => {
		if (!_.isEqual(filters, newFilters)) {
			if (isMounted.current) {
				setFilters(newFilters);
			}
		}
	};

	const { currentUser } = useAppContext();
	const param = getParamByRole(currentUser);

	return (
		<Page
			title="Tiktok Product Templates"
			fullWidth
			primaryAction={{
				content: "New template",
				url: `/${param}/tiktok-hub/product-templates/add`,
			}}
		>
			<Card sectioned>
				<SuspenseComp fallback={<SkeletonDisplayText />}>
					<ProductTemplatesFilter
						filters={filters}
						onFilterChange={safeSetFilters}
					/>
				</SuspenseComp>
			</Card>
			<Card>
				<ProductTemplatesTable filters={filters} />
			</Card>
		</Page>
	);
}
