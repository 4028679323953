export const Production = true;
export const LocalApi = "http://localhost:8080";
export const LiveApi = "https://api.merchbridge.com";
export const LocalApp = "http://localhost:3000";
export const LiveApp = "https://dashboard.merchbridge.com";
export const REACT_APP_SHOPIFY_API = "b80d999c14fcc1ba95d5fa7e9d6209c0";
export const API_DOMAIN = Production ? "api.merchbridge.com" : "localhost:8080";
export const PODApi ="https://api.personalbridge.com";
export const PODApp ="https://dashboard.personalbridge.com";

export const Mockup2DApp = Production ?  "https://2dmockup.onemercegroup.com" :"http://localhost:3000" ;
export const Mockup2DApi = Production ?  "https://api-2dmockup.onemercegroup.com" : "http://localhost:8080" ;
export const TIKTOK_LINK_AUTH = Production ? "https://services.tiktokshops.us/open/authorize?service_id=7317187517500524331": "https://services.tiktokshops.us/open/authorize?service_id=7347592207429715758";

export const TIKTOK_IMAGE_TEMPLATE_URL = Production ? "http://206.189.90.227:8188" : "http://localhost:8188";