import { gql } from "apollo-boost";
import React from "react";
import { arrInvalid, objectInvalid } from "../../../helper";
import { useCustomQuery } from "../../../hooks/useCustomQuery";

function useTiktokProductTemplates(filter = {}, fragment = "") {
	const filterStr = JSON.stringify(filter);
	const queryOptions = React.useMemo(() => {
		const filter = JSON.parse(filterStr);
		return {
			variables: { filter },
			query: gql`
			query getTiktokProductTemplates($filter: GetTiktokProductTemplateFilter!) {
				getTiktokProductTemplates(filter: $filter) {
					${fragment}
				}
			}
		`,
		};
	}, [filterStr, fragment]);

	return useCustomQuery({ queryOptions, path: "data.getTiktokProductTemplates" });
}

async function getTiktokProductTemplateMissing(productIds, currentIds, fragment) {
	if (arrInvalid(productIds)) return [];
	let idsMiss = [];
	if (Array.isArray(currentIds)) {
		if (arrInvalid(currentIds)) return [];
		for (let id of currentIds) {
			if (productIds.includes(id)) continue;
	
			idsMiss.push(id);
		}
	} else if (currentIds && currentIds.length > 0) {
		idsMiss.push(currentIds);
	}

	if (arrInvalid(idsMiss)) return [];

	async function getProducts(ids) {
		const { __apolloClient__: client } = window;
		if (!client) return;

		try {
			const args = [];
			const queries = [];
			const variables = {};
			for (let i = 0; i < ids.length; i++) {
				const id = ids[i];
				args.push(`$id__${i}: ID!`);
				queries.push(`
					getTiktokProductTemplate__${i}: getTiktokProductTemplate(ID: $id__${i}) {
						...tiktokProductTemplate
					}
				`);
				variables[`id__${i}`] = id;
			}

			const q = `
				query getTiktokProductTemplate(${args.join(",")}) {
					${queries.join("\n")}
				}
			`;

			const query = gql`
				fragment tiktokProductTemplate on TiktokProductTemplate {
					${fragment}
				}
			${q}
     		`;

			const res = await client.query({
					query,
					variables,
			});

			if (!objectInvalid(res?.data)) {
				let resData = Object.values(res.data);
				return resData;
			}
		} catch (err) { }
		return [];
	}

	return await getProducts(idsMiss);
}

export { useTiktokProductTemplates, getTiktokProductTemplateMissing };
