import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import _ from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InlineError, Spinner } from "@shopify/polaris";

const Container = styled.div`
	.box-flex {
		display: flex;
		flex-wrap: nowrap;
		gap: 20px;
		margin-bottom: 15px;
	}

	.box-flex-20 {
		width: 20%;
	}

	.external-message {
		margin-top: 15px;
	}
`;


const GET_TIKTOK_CATEGORIES_FROM_EXCEL_TEMPLATE = gql`
	query getCategoriesFromExcelTemplate($excelTemplateFileID: ID!) {
		getCategoriesFromExcelTemplate(excelTemplateFileID: $excelTemplateFileID) {
			label
    		id
		}
	}
`;

export default function TiktokExcelTemplate({ defaultValue, tiktokCategoryID, onChange, onError }) {
	const [excelTemplate, setExcelTemplate] = useState(defaultValue && !_.isEmpty(defaultValue) && defaultValue?.id ? [defaultValue] : []);
	const { refetch } = useQuery(GET_TIKTOK_CATEGORIES_FROM_EXCEL_TEMPLATE, { skip: true });
	const [checkingExcelCategory, setCheckingExcelCategory] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		setExcelTemplate(defaultValue && !_.isEmpty(defaultValue) && defaultValue?.id ? [defaultValue] : []);
	}, [JSON.stringify(defaultValue)]);

	const handleMediaChange = useCallback((files) => {
		setExcelTemplate(files);
	}, []);

	useEffect(() => {
		const fetchCategories = async () => {
			let errorMessage;
			if (excelTemplate && excelTemplate[0] && excelTemplate[0].id && tiktokCategoryID) {
				setCheckingExcelCategory(true);
				let foundCategory = false;

				try {
					const { data } = await refetch({ excelTemplateFileID: excelTemplate[0].id });

					if (data.getCategoriesFromExcelTemplate) {
						for (const category of data.getCategoriesFromExcelTemplate) {
							if (category && category.id && category.id == tiktokCategoryID) {
								foundCategory = true;
								break
							}
						}
					}
				} catch (ex) {

				}
				
				if (!foundCategory) {
					errorMessage = "Could not found the selected Category in this Excel file";
					setErrorMessage(errorMessage);
				} else {
					setErrorMessage(null);
				}
				setCheckingExcelCategory(false);
			} else {
				setCheckingExcelCategory(false);
				setErrorMessage(null);
			}
			if (onChange) {
				onChange(excelTemplate, errorMessage);
			}
		}
		fetchCategories();
	}, [JSON.stringify(excelTemplate)]);

	return (
		<Container>
			<div className="box-flex">
				<div className="box-flex-20">
					<ComponentLabelPolaris
						label={`Excel template`}
					/>
					<MediaSelectorButtonPolaris
						accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
						singleUpload={true}
						multiple={false}
						value={excelTemplate ?? []}
						onChange={(files) =>
							handleMediaChange(files)
						}
					/>
					<div className="external-message">
						{errorMessage && errorMessage.length > 0 ? ( <InlineError message={errorMessage} /> ) : null}
						{checkingExcelCategory ? <p><Spinner size="small" /> Checking the Excel template...</p> : null}
					</div>
				</div>
			</div>
		</Container>
	);
}
