import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { Input, notification } from "antd";
import { useMutation } from "@apollo/react-hooks";

import { useAppContext } from "../../../context";
import { UPDATE_TEAM } from "../../../graphql/mutations/team";
import { getTeamAddress, handleError } from "../../../helper";
import { TextField } from "@shopify/polaris";

function TeamAddress({ setLoading }, ref) {
  const { currentUser } = useAppContext();
  const addr = getTeamAddress(currentUser);
  const [state, setState] = useState(addr || "");

  const [updateTeam] = useMutation(UPDATE_TEAM, {
    onCompleted: () => {
      notification.success({ message: "Update address for Team success" });
    },
    onError: (err) => {
      notification.error({ message: handleError(err?.toString()) });
    },
  });

  const changed = React.useRef(false);
  const handleAddressChange = useCallback((value) => {
    setState(value);
    changed.current = true;
  }, []);

  const handleSubmit = useCallback(() => {
    if (!changed.current) return;

    updateTeam({
      variables: {
        input: {
          address: state,
          id: "",
        },
      },
    }).finally(() => {
      setLoading(false);
      changed.current = false;
    });
  }, [updateTeam, state]);

  useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit,
  }));

  return (
    <TextField
      value={state}
      onChange={handleAddressChange}
      placeholder="Enter Team's address"
    />
  );
}

export default forwardRef(TeamAddress);
