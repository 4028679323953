import { DataTable, Link, Badge, Toast, Tooltip, Icon } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { handleError, objectInvalid, getParamByRole } from "../../../helper";
import SuspenseComp from "../../shared/SuspenseComp";
import EmptyStatePolaris from "../../shared/EmptyStatePolaris";
import SkeletonPage from "../../shared/SkeletonPagePolaris";
import ElementScrollable from "../../shared/ElementScrollable";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import PushActions from './PushActions';
import { useAppContext } from "../../../context";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const GET_TIKTOK_PUSHES = gql`
	query getTiktokPushes($filter: TiktokPushFilter) {
		getTiktokPushes(filter: $filter) {
			total
			nodes {
				id
				createdAt
				title
				status
				lastPushTime
				paused
				stores {
					id
					title
				}
				dailyPushCount
				canPushToday
				pushDetailInfo {
					count
					status
				}
				createdBy {
					firstName
					lastName
				}
				processingMode
				note
				generatedExcelFiles {
					file {
						id
						url
					}
					store {
						id
						title
					}
				}
			}
		}
	}
`;

const HEADINGS = [
	"ID",
	"Title",
	"Created At",
	"Status",
	"ProcessingMode",
	"Stores",
	"Push stats",
	"Actions",
];

const STATUS_BADGE = {
	Pending: "info",
	Done: "success",
	Paused: "critical",
	Error: "critical",
	Running: "warning",
	"Reach daily limit": "warning",
};

export default function PushProductsTable(props) {
	let currentFilters = props.filters;

	const { currentUser } = useAppContext();
	const param = getParamByRole(currentUser);
	
	const [activeToast, setActiveToast] = useState(false);
	const [toastMessage, setToastMessage] = useState({});
	const [pushProducts, setPushProducts] = useState([]);
	const [aggregation, setAggregation] = useState({});
	const [collapseRow, setCollapseRow] = useState({});
	

	const handleActionCompleted = async(data) => {
		let successMessage = (data && data.type && 'success' == data.type && data.message) ? data.message : null;
		let failMessage = (data && data.type && 'fail' == data.type && data.message) ? data.message : null;
		setToastMessage({
			success_message: successMessage,
			error_message: failMessage,
		});
		setActiveToast(true);

		try {
			await refetch();
		} catch (err) {
			console.error("Error refreshing data:", err);
		}
	}

	const toggleShowStores = (id) => {
		setCollapseRow((prevState) => {
			let newState = {
				...prevState,
				[id]: prevState?.[id] ? !prevState[id] : true,
			}
			return newState;
		})
	}

	const getRows = (nodes) => {
		const res = [];
		for (let node of nodes) {
			if (objectInvalid(node)) continue;
			let {
				id,
				title,
				createdAt,
				stores,
				status,
				processingMode,
				lastPushTime,
				paused,
				dailyPushCount,
				canPushToday,
				pushDetailInfo,
				createdBy,
				note,
				generatedExcelFiles,
			} = node;

			createdAt = moment(createdAt).format("YYYY-MM-DD HH:mm:ss");
			if (lastPushTime) {
				lastPushTime = moment(lastPushTime).format("YYYY-MM-DD HH:mm:ss");
			}

			let detailUrl = `/${param}/tiktok-hub/push-products/${id}`;

			let statusColor;
			for (let [key, value] of Object.entries(STATUS_BADGE)) {
				if (status === key) {
					statusColor = value;
				}
			}

			let storeWrapperClass = ['list-stores'];
			let showViewMoreStores = false;
			if (collapseRow?.[id] && collapseRow[id]) {
				storeWrapperClass.push('show-all-stores');
			}
			if (stores && stores.length > 4) {
				storeWrapperClass.push('show-view-more');
				showViewMoreStores = true;
			}

	
			const item = [
				// Field ID
				<div className="id item">
					<Link url={detailUrl} children={id} />
				</div>,

				// Field title
				<div className="title item">
					<Link url={detailUrl} children={title} />
					{paused ? (<div><Badge children={'Paused'} status={'warning'} /></div>) : null}
				</div>,

				<div className="created-at item">
					<span>{createdAt}</span>

					{createdBy?.firstName || createdBy?.lastName ? (
						<div style={{marginTop: '10px'}}>
							<span><strong>By: </strong>{`${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`}</span>
						</div>
					) : null }
				</div>,

				<div className="status item">
					{status && status == 'Error' && note ? (
						<Tooltip content={note}>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<Badge children={status} status={statusColor} />
								<Icon source={InfoMinor} />
							</div>
						</Tooltip>
					) : (
						<Badge children={status || ''} status={statusColor} />
					)}
				</div>,

				<div className="processing-mode item">
					{processingMode && processingMode == 'EXPORT_EXCEL' ? (
						<Badge children={'Export Excel File'} status={'info'} />
					) : (
						<Badge children={'Using API'} status={'warning'} />
					)}
				</div>,
	
				// Field stores
				<div className="stores item">
					{stores && stores.length > 0 ? (
						<div className="selected-stores item">
							<div className={ storeWrapperClass.join(' ') }>
								{stores.map((store) => {
									let fileUrl;
									if (processingMode && processingMode == 'EXPORT_EXCEL' && generatedExcelFiles && generatedExcelFiles.length > 0) {
										generatedExcelFiles.forEach((excelFile) => {
											if (excelFile?.store?.id && excelFile?.file?.url && excelFile.store.id == store.id) {
												fileUrl = excelFile.file.url
											}
										});
									}
									return (
										<div key={store.id} style={{marginBottom: '10px'}}>
											<Badge key={store.id}>{store.title}</Badge>
											{fileUrl && fileUrl.length > 0 ? (
												<div>&nbsp; &#8627; <a href={fileUrl} target="_blank" title={`Excel file for store: ${store.title}`}>Excel file</a></div>
											) : null}
										</div>
										
									);
								})}
							</div>
							{showViewMoreStores ? (
								<div className="collapse-stores">
									<span onClick={ () => toggleShowStores(id) }>{ collapseRow?.[id] && collapseRow[id] ? 'View less' : `View all stores (${stores.length})` }</span>
								</div>
							) : null }
						</div>
					) : null}
				</div>,

				
				<div className="stats item">
					<div><strong>Today pushable: </strong> {canPushToday ? 'yes' : 'no' }</div>
					<div><strong>Last date pushed: </strong> {dailyPushCount}</div>
					{lastPushTime && lastPushTime.length > 0 ? (
						<div className="last-push-time item">
							<strong>Last run: </strong>
							<span>{lastPushTime ? lastPushTime : ""}</span>
						</div>
					) : null}

					{pushDetailInfo && pushDetailInfo.length > 0 ? pushDetailInfo.map((push, index) => (
						<div className="last-push-time item" key={index}>
							<strong>{push.status} items: </strong>
							<span>{push.count}</span>
						</div>
					)) : (null)}
				</div>,
	
				<div className="actions item">
					<PushActions item={node} onActionCompleted={handleActionCompleted}/>
				</div>,
			];
	
			res.push(item);
		}
		return res;
	}

	const [filters, setFilters] = useState({
		paged: 1,
		limit: 20,
		offset: 0,
	});
	const { loading, error, data, refetch } = useQuery(GET_TIKTOK_PUSHES, {
		variables: {
			filter: {
				limit: filters.limit,
				offset: filters.offset,
				search: currentFilters?.search || null,
				storeID: currentFilters?.storeID || null,
				orderBy: currentFilters?.orderBy || null,
				order: currentFilters?.order || null,
			}
		},
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		let nodes = data?.getTiktokPushes?.nodes;
		if (nodes) {
			setPushProducts(nodes);
		} else {
			setPushProducts([]);
		}

		let total = data?.getTiktokPushes?.total;

		let agg = {
			page: filters.paged,
			totalPage: Math.ceil(total / filters.limit),
			offset: filters.offset,
			limit: filters.limit,
			onChange: onPaginationChanged,
			total: total,
		};
		setAggregation(agg);
	}, [data, filters]);

	
	const rows = React.useMemo(() => getRows(pushProducts), [pushProducts, JSON.stringify(collapseRow)]);

	const onPaginationChanged = (offset, limit) => {
		let newPaged = Math.floor(offset / limit) + 1;
		setFilters((prev) => ({...(prev || {}), limit, offset, paged: newPaged }));
	}

	const ToastMessage = activeToast ? (
        <Toast
			content={ toastMessage.success_message }
			error={ toastMessage.error_message  }
			duration={5000}
			onDismiss={() => setActiveToast(false)}
			/>
		)
    : null;

	return (
		<Wrapper>
			{ToastMessage}
			<SuspenseComp fallback={<SkeletonPage />}>
				{loading ? (
					<SkeletonPage />
				) : error ? (
					<div>Error: {handleError(error?.toString())}</div>
				) : pushProducts?.length > 0 ? (
					<>
						<ElementScrollable fixedColumn>
							<DataTable
								rows={rows}
								columnContentTypes={[
									...Array.from({ length: 10 }).fill("text"),
									"numeric",
								]}
								headings={HEADINGS}
								verticalAlign="middle"
								hideScrollIndicator
								footerContent={
									<PaginationPolaris aggregation={aggregation} showTotal />
								}
							/>
						</ElementScrollable>
					</>
				) : (
					<EmptyStatePolaris />
				)}
			</SuspenseComp>
		</Wrapper>
	);
}

export const Wrapper = styled.div`
	overflow-x: auto;

	.Polaris-DataTable__Table {
		overflow-x: inherit;
	}

	.Polaris-DataTable__Cell--firstColumn {
		width: 100px;
	}

	.id {
		width: 100px;
	}
	.item {
		white-space: normal;
		word-break: break-word;
	}

	.title {
		width: 230px;
	}

	.created-at,
	.last-push-at {
		width: 150px;
	}

	.status {
		width: 125px;

		.Polaris-Icon{
			margin: 0;
		}
	}

	.processing-mode {
		width: 140px;
	}

	.stores {
		width: 320px;

		.Polaris-Badge__Content {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 320px;
		}
	}

	.stats {
		width: 150px;
	}
	.actions {
		width: 115px;
	}

	.list-stores:not(.show-all-stores) {
		position: relative;
		height: 100%;
		max-height: 200px;
		overflow: hidden;
	}

	.collapse-stores {
		cursor: pointer;
		margin-top: 10px;
    	color: #1890ff;
	}

	.list-stores.show-view-more:not(.show-all-stores)::after {
		opacity: 1;
		content: "";
		pointer-events: none;
		background-image: linear-gradient(#0000, #fff);
		transition: opacity .2s ease-in;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
`;
