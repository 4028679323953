import MainLayout from "../layout/MainLayout";
import AddCampaignProduct from "../pages/seller/AddCampaignProduct";
import AddProductPage from "../pages/seller/AddProduct";
import AddProductPageV2 from "../pages/seller/AddProductV2";
import { CloneProduct } from "../pages/seller/CloneProduct";
import { CloneProductCampaign } from "../pages/seller/CloneProductCampaign";
import { CloneProductV2 } from "../pages/seller/CloneProductV2";
import { DesignTasksPagePolaris } from "../pages/seller/DesignTasksPagePolaris";
import EditProductPage from "../pages/seller/EditProduct";
import EditProductCampaign from "../pages/seller/EditProductCampaign";
import EditProductPageV2 from "../pages/seller/EditProductV2";
import { OrdersPagePolaris } from "../pages/seller/OrdersPagePolaris";
import { ProductsPagePolaris } from "../pages/seller/ProductsPagePolaris";

export default [
  {
    path: "/fulfillment/products/add",
    exact: true,
    // component: AddProductPage,
    component: AddProductPageV2,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Fulfillment",
  },
  {
    path: "/fulfillment/products/add/:id",
    exact: true,
    // component: AddProductPage,
    component: AddProductPageV2,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Fulfillment",
  },
  {
    path: "/fulfillment/products/clone",
    exact: true,
    // component: CloneProduct,
    component: CloneProductV2,
    layout: MainLayout,
    title: "Clone Product",
    roles: ["Seller"],
    teamRole: "Fulfillment",
  },
  {
    path: "/fulfillment/products/add-campaign",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Fulfillment",
  },
  {
    path: "/fulfillment/products/add-campaign/:id",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Fulfillment",
  },
  {
    path: "/fulfillment/products/clone-campaign",
    exact: true,
    component: CloneProductCampaign,
    layout: MainLayout,
    title: "Clone Campaign Product",
    roles: ["Seller"],
    teamRole: "Fulfillment",
  },
  {
    path: "/fulfillment/products/campaign/:id",
    exact: true,
    component: EditProductCampaign,
    layout: MainLayout,
    title: "Edit Product Campaign",
    roles: ["Seller"],
    teamRole: "Fulfillment",
  },
  {
    path: "/fulfillment/products",
    exact: true,
    component: ProductsPagePolaris,
    layout: MainLayout,
    title: "Products",
    roles: ["Seller"],
    teamRole: "Fulfillment",
  },
  {
    path: "/fulfillment/products/edit/:id",
    exact: true,
    // component: EditProductPage,
    component: EditProductPageV2,
    layout: MainLayout,
    title: "Edit Product",
    roles: ["Seller"],
    teamRole: "Fulfillment",
  },
  {
    path: "/fulfillment/orders",
    exact: true,
    component: OrdersPagePolaris,
    layout: MainLayout,
    title: "Orders",
    roles: ["Seller"],
    teamRole: "Fulfillment",
  },
  {
    path: "/fulfillment/design-tasks",
    exact: true,
    component: DesignTasksPagePolaris,
    title: "All Tasks",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Fulfillment",
  },
];
