import { Card, Page, SkeletonDisplayText } from "@shopify/polaris";
import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

import SuspenseComp from "../../shared/SuspenseComp";
import ProductCrawlItemsFilter from "./ProductCrawlItemsFilter";
import ProductCrawlItemsTable from "./ProductCrawlItemsTable";
import ProductCrawlImport from "./ProductCrawImport";

export default function TiktokProductCrawlItems() {
  const [filters, setFilters] = useState({
    limit: 20,
    offset: 0,
    order: "DESC",
    orderBy: "created_at",
    originSource: null,
    search: null,
  });
  const isMounted = useRef(true);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const safeSetFilters = (newFilters) => {
    if (!_.isEqual(filters, newFilters)) {
      if (isMounted.current) {
        setFilters(newFilters);
      }
    }
  };

  const handleOpenAdd = React.useCallback(
    (val) => () => {
      setOpen(val);
    },
    [],
  );

  const compRef = React.useRef();
  const handleImportCompleted = React.useCallback(() => {
    if (
      compRef.current &&
      compRef.current.refetch &&
      typeof compRef.current.refetch === "function"
    ) {
      compRef.current.refetch();
    }
  }, []);

  return (
    <Page
      title="Product Crawl Items"
      fullWidth
      primaryAction={{
        content: "Import Product Crawl",
        onAction: handleOpenAdd(true),
      }}
    >
      <Card sectioned>
        <SuspenseComp fallback={<SkeletonDisplayText />}>
          <ProductCrawlItemsFilter
            filters={filters}
            onFilterChange={safeSetFilters}
          />
        </SuspenseComp>
      </Card>
      <Card>
        <ProductCrawlItemsTable filters={filters} ref={compRef} />
      </Card>

      <ProductCrawlImport
        open={open}
        onClose={handleOpenAdd(false)}
        onCompleted={handleImportCompleted}
      />
    </Page>
  );
}
