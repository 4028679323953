import { Card, Stack, TextField } from "@shopify/polaris";
import React, { useCallback } from "react";
import styled from "styled-components";

import { useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { GET_FILE_BY_ID } from "../../../graphql/queries";
import { reducerFn } from "../../../helper";
import useTimeout from "../../../hooks/useTimeout";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";

export default function ExtraInfoTiktok({ value, onChange }) {
  const [state, setState] = React.useReducer(reducerFn, {
    friendly: "",
    files: [],
  });

  const updateValRef = React.useRef(false);
  const [getFile] = useLazyQuery(GET_FILE_BY_ID, {
    onCompleted: (res) => {
      updateValRef.current = true;
      const file = getFileRes(res);
      if (!file) return;
      setState({ files: [file] });
    },
    onError: () => {},
  });

  const [setDelay] = useTimeout();
  const handleFileChange = useCallback(
    (id) => (newValue, originID) => {
      let mId = id ? id : originID;
      setState({ [mId]: newValue });

      setDelay(() => {
        onChange && onChange(newValue, mId);
      }, 1000);
    },
    [onChange],
  );

  React.useEffect(() => {
    if (updateValRef.current) return;
    if (value) {
      const { friendly, logo } = value || {};
      if (friendly) {
        setState({ friendly });
        getFile({
          variables: {
            id: logo,
          },
        });
      }
    }
  }, [JSON.stringify(value)]);

  return (
    <Card sectioned title="Extra Info">
      <Stack vertical>
        <TextField
          label="Friendly Name"
          id="friendly"
          placeholder="Enter your friendly name"
          value={state.friendly}
          onChange={handleFileChange()}
        />
        <Wrapper>
          <ComponentLabelPolaris label="Logo" />
          <MediaSelectorButtonPolaris
            value={state.files}
            folder={"undefined"}
            singleUpload
            onChange={handleFileChange("files")}
            accept={`image/*`}
            sizeSmall
          />
        </Wrapper>
      </Stack>
    </Card>
  );
}

function getFileRes(res) {
  const val = get(res, "fileById") || null;
  return val;
}

const Wrapper = styled.div`
  max-width: 160px;

  .list-file_wrap,
  .file_wrap {
    height: 160px;
  }
`;
