import { arrInvalid } from "../../helper";

export const getCheckAllItems = (data, isCheckAll, property = "id") => {
  if (arrInvalid(data) || !isCheckAll) return [];
  return Array.from(new Set(data.map((item) => item[property])).values());
};

export const calcuItemCheck = (itemsChecked, id, newChecked) => {
  if (newChecked) {
    itemsChecked = [...itemsChecked, id];
  } else {
    itemsChecked = itemsChecked.filter((i) => i !== id);
  }
  itemsChecked = Array.from(new Set(itemsChecked).values());
  const countItemsChecked = itemsChecked.length;

  return {
    itemsChecked,
    checkAll: countItemsChecked > 0,
  };
};
