import { Modal, Button } from "@shopify/polaris";
import React, { useEffect, useState, useCallback } from "react";

import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";


const DELETE_CRAWL_ITEM = gql`
	mutation deleteTiktokCrawlProduct($id: ID!) {
		deleteTiktokCrawlProduct(id: $id)
	}
`;


export default function DeleteCrawlItem({item, onDeleted}) {
	const [open, setOpen] = useState(false);
	const toggleShowModal = useCallback(() => setOpen((prev) => !prev), []);

	const [deleteTiktokCrawlProduct, { loading: mutationLoading }] = useMutation(DELETE_CRAWL_ITEM);

	const handleActionDelete = async (item) => {
		try {
			const response = await deleteTiktokCrawlProduct({
				variables: {
					id: item.id
				},
			});
			if (response && response?.data && response?.data?.deleteTiktokCrawlProduct) {
				let createRes = response?.data?.deleteTiktokCrawlProduct;
				
				if (onDeleted) {
					if (createRes) {
						onDeleted({type: 'success', message: 'The selected item is deleted'});
					} else {
						onDeleted({type: 'fail', message: 'Could not delete the selected item'});
					}
				}
				
			}
		} catch (ex) {
			if (onDeleted) {
				onDeleted({type: 'fail', message: ex.toString()});
			}
		}
		setOpen(false);
	}
	
	return (
		<>
			<Button
				size="small"
				type={"primary"}
				onClick={toggleShowModal}
			>
				Delete
			</Button>

			<Modal
				open={open}
				title={`Delete crawl item`}
				onClose={toggleShowModal}
				sectioned
				primaryAction={{
					content: "Delete",
					onAction: () => handleActionDelete(item),
					loading: mutationLoading,
				}}
				secondaryActions={[
					{ content: "Cancel", onAction: toggleShowModal },
				]}
			>
				<p>
					Are you sure to delete this crawl item?
				</p>
			</Modal>
		</>
	);
}
