import React, { useState, useEffect } from "react";
import { TextField, Select } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
	.box-flex {
		display: flex;
		flex-wrap: nowrap;
		gap: 20px;
		margin-bottom: 15px;
	}

	.box-flex-50 {
		width: 50%;
	}
`;

const TiktokPackageWeight = ({ defaultValues, onChange }) => {
	const [data, setData] = useState({
		unit: defaultValues?.unit ?? "POUND",
		value: defaultValues?.value ?? '',
	});

	useEffect(() => {
		setData({
			unit: defaultValues?.unit ?? "POUND",
			value: defaultValues?.value ? defaultValues?.value.toString() : '',
		});
	}, [JSON.stringify(defaultValues)]);
	
	const handleChange = (field, value) => {
		setData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	useEffect(() => {
		if (onChange) {
			onChange(data);
		}
	}, [JSON.stringify(data)]);

	return (
		<Container>
			<div className="box-flex">
				<div className="box-flex-50">
					<Select
						label="Unit"
						options={["KILOGRAM", "POUND"]}
						value={data?.unit ?? "POUND"}
						onChange={(value) => handleChange("unit", value)}
					/>
				</div>
				<div className="box-flex-50">
					<TextField
						label="Value"
						value={data?.value ?? null}
						onChange={(value) => handleChange("value", value)}
						type="number"
						step={0.1}
						min={0}
					/>
				</div>
			</div>
		</Container>
	);
};

export default TiktokPackageWeight;
