import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  // ChoiceList,
  Form,
  FormLayout,
  Loading,
  Modal,
  Page,
  Spinner,
  Tag,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { RefreshMajorMonotone } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import _, { get } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import authImage from "../../assets/images/etsy-app-oauth.png";
import CopyBox from "../../components/shared/CopyBox";
import { AppContext, useAppContext } from "../../context";
import { GET_USER_BY_ROLES } from "../../graphql/queries";
import {
  checkRole,
  handleError,
  isPME_TEAM,
  reducerFn,
  validateUrl,
} from "../../helper";
import history from "../../history";
import {
  PLATFORMS,
  TEAM_ROLE,
  // TEAM_ROLE_PARAMS,
  USER_ROLE,
} from "../../variable";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
// import { useTiktokWarehouse } from "./hooks/useTiktokWarehouse";
import useCustomPathname from "../../hooks/useCustomPathname";
import ExtraInfoTiktok from "./components/ExtraInfoTiktok";

const FulfillmentConfig = React.lazy(() =>
  import("./components/FulfillmentConfig"),
);

const ExtraInfo = React.lazy(() =>
  import("../../components/store/components/ExtraInfo"),
);

const STORE_BY_ID = gql`
  query storeById($id: ID!) {
    storeById(id: $id) {
      id
      title
      platform
      shopbasePublicDomain
      shopifyPublicDomain
      suspended
      note
      extraInfo
      owners {
        user {
          id
          firstName
          lastName
        }
        id
        store_id
        role
      }
      status
      ignoreCronFetch
      usingEtsyAPI
      alwaysCreateArtistTask
      enableFulfillmentConfig
      certificate
      fulfillmentConfigs {
        id
        fulfillmentId
        fulfillment {
          id
          name
        }
        apiKey
        apiSecret
        storeID
        mbStoreId
        description
        printifyAutoProduction
        teezilyAutoProduction
      }
    }
  }
`;

const EDIT_STORE = gql`
  mutation updateStore($input: EditStore!) {
    updateStore(input: $input) {
      id
    }
  }
`;

const RE_AUTH_STORE = gql`
  mutation reAuthenticateStore($storeId: ID!) {
    reAuthenticateStore(storeId: $storeId)
  }
`;

export const GENERATE_ETSY_OAUTH_URL = gql`
  mutation generateEtsyOAuthURL($etsyStoreName: String!) {
    generateEtsyOAuthURL(etsyStoreName: $etsyStoreName)
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
  div[role="combobox"]:focus {
    outline: none;
  }
`;

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

const ReAuthorizeWrap = styled.div`
  label {
    display: block;
    margin-bottom: 0.4rem;
  }
`;

const BtnsWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 2rem;
`;

const EtsyReAuthWrap = styled.div`
  margin-top: 20px;
`;

const ModalContainer = styled.div`
  .image {
    display: flex;
    justify-content: center;
    img {
      max-width: 200px;
      height: auto;
    }
  }
  .warning-box {
    background: rgb(233, 162, 173);
    padding: 10px;
  }
`;

export const EditStorePolaris = (props) => {
  const { id } = props.match.params;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [sbPublicDomain, setSBPublicDomain] = useState(null);
  const [errorSBPublicDomain, setErrorSBPublicDomain] = useState(null);
  const [sfPublicDomain, setSFPublicDomain] = useState(null);
  const [errorSFPublicDomain, setErrorSFPublicDomain] = useState(null);
  const [title, setTitle] = useState("");
  const [errorTitle, setErrorTitle] = useState(null);
  const [suspended, setSuspended] = useState(false);
  const [note, setNote] = useState(null);
  const [ignoreCronFetch, setIgnoreCronFetch] = useState(false);
  const [isUsingEtsyAPI, setUsingEtsyAPI] = useState(false);
  const [isAlwaysCreateArtistTask, setAlwaysCreateArtistTask] = useState(false);
  const [showEtsyOAuthModal, setShowEtsyOAuthModal] = useState(false);
  const [agreeEtsyOAuthTerms, setAgreeEtsyOAuthTerms] = useState(false);
  const [etsyOAuthUrl, setEtsyOAuthUrl] = useState(
    "OAuth URL is generating...",
  );
  const [mustSaveDataFirst, setMustSaveDataFirst] = useState(false);
  const [showNoticeSaveDataFirst, setShowNoticeSaveDataFirst] = useState(false);
  const [, forceUpdate] = React.useState({});

  const [extraInfo, setExtraInfo] = React.useReducer(reducerFn, {
    friendly: "",
    url: "",
    emailContact: "",
    facebookLink: "",
    instagramLink: "",
    youtubeLink: "",

    // Tiktok
    files: [],
  });

  const [state, setState] = React.useReducer(reducerFn, {
    // selected: [],
    // warehouses: [],
    isTiktok: false,
  });

  const { currentUser } = useContext(AppContext);
  const isPMETeam = isPME_TEAM(currentUser);
  const { isMarketplaceManager } = checkRole(currentUser);

  // Queries
  // const [requestWarehouse, warehouseState] = useTiktokWarehouse(
  //   !state.isTiktok,
  // );
  const { data, loading, error, refetch } = useQuery(STORE_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (res?.storeById) {
        const platform = res.storeById.platform;
        if (platform === PLATFORMS.Tiktok) {
          setState({ isTiktok: true });
          // requestWarehouse();
        }
      }
    },
  });

  const {
    data: roles,
    loading: loadingRoles,
    error: errorRoles,
  } = useQuery(GET_USER_BY_ROLES, {
    variables: {
      roles: [
        USER_ROLE.Admin,
        USER_ROLE.Seller,
        USER_ROLE.StoreManager,
        TEAM_ROLE.Supporter,
      ],
    },
  });

  const [
    reAuthenticateStore,
    { data: dataReAuth, loading: loadingReAuth, error: errorReAuth },
  ] = useMutation(RE_AUTH_STORE, { onError: () => {} });

  const [updateStore, { data: dataUS, loading: loadingUS, error: errorUS }] =
    useMutation(EDIT_STORE, {
      onCompleted: () => {
        const id = setTimeout(() => {
          // client.resetStore();
          handleRedirect();
        }, 2500);
        setTimeoutId(id);
      },
      onError: () => {},
    });

  const [generateEtsyOAuthUrl] = useMutation(GENERATE_ETSY_OAUTH_URL, {
    onError: () => {},
    onCompleted: () => {},
  });

  useEffect(() => {
    let newData =
      roles && roles.listUserTeamByRoles && roles.listUserTeamByRoles.length
        ? roles.listUserTeamByRoles
            .map((item) => {
              if (item && item.user) {
                return {
                  label: `${item.user.firstName} ${item.user.lastName}`,
                  value: item.user.id,
                };
              }
              return null;
            })
            .filter(Boolean)
        : [];
    setOptions(newData);
    setDeselectedOptions(newData);
  }, [roles]);

  useEffect(() => {
    const storeById = data && data.storeById;
    const title = storeById && storeById.title;
    const sbPublicDomain = storeById && storeById.shopbasePublicDomain;
    const sfPublicDomain = storeById && storeById.shopifyPublicDomain;
    const ownerId =
      storeById && storeById.owners && storeById.owners.length
        ? storeById.owners
            .filter((owner) => owner.role && owner.role === "Owner")
            .map((item) => item && item.user && item.user.id)
        : [];
    const note = storeById && storeById.note;
    const ignoreCronFetch = storeById && storeById.ignoreCronFetch;
    const isUsingEtsyAPI = storeById && storeById.usingEtsyAPI;
    const isAlwaysCreateArtistTask =
      storeById && storeById.alwaysCreateArtistTask;
    // const storeStatus = storeById && storeById.status;

    setTitle(title);
    setSelectedOptions(ownerId);
    setSBPublicDomain(sbPublicDomain);
    setSFPublicDomain(sfPublicDomain);
    setSuspended(storeById && storeById.suspended);
    setNote(note);
    setIgnoreCronFetch(ignoreCronFetch);
    setUsingEtsyAPI(isUsingEtsyAPI);
    setAlwaysCreateArtistTask(isAlwaysCreateArtistTask);
    // setStoreStatus(storeStatus);

    if (storeById?.extraInfo) {
      setExtraInfo({ ...storeById.extraInfo });
    }

    // const warehouseID = getCertificate(storeById);
    // if (warehouseID) {
    //   setState({ selected: [warehouseID] });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // useEffect(() => {
  //   if (warehouseState?.data?.length > 0) {
  //     const warehouses = warehouseState.data.map((item) => ({
  //       value: item.id,
  //       label: item.name,
  //     }));

  //     setState((p) => {
  //       const [firstItem] = warehouses || [];
  //       if (p.selected.length === 0 && firstItem?.value?.length > 0) {
  //         p.selected = [firstItem.value];
  //       }

  //       p.warehouses = warehouses;
  //       return { ...p };
  //     });
  //   }
  // }, [warehouseState.data]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  const handleInput = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex),
      );

      setOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const handleSelection = useCallback(
    (selected) => {
      setSelectedOptions(selected);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  const handleSBPublicDomainChange = useCallback((value) => {
    let sbPDomain = "";
    if (value && value.length > 0) {
      sbPDomain = value;
    }
    setSBPublicDomain(sbPDomain);
  }, []);

  const handleSFPublicDomainChange = useCallback((value) => {
    let sfPDomain = "";
    if (value && value.length > 0) {
      sfPDomain = value;
    }
    setSFPublicDomain(sfPDomain);
  }, []);

  const handleRemove = useCallback(
    (value) => {
      let newSelected = selectedOptions.filter((s) => s !== value);
      setSelectedOptions(newSelected);
    },
    [selectedOptions],
  );

  const handleNoteChange = useCallback((value) => setNote(value), []);

  const handleSubmit = useCallback(() => {
    let owners =
      selectedOptions && selectedOptions.length
        ? selectedOptions.map((item) => ({ userId: item, id: item.seller }))
        : null;
    handleTitleValue(title);
    if (title) {
      let submitData = {
        owners,
        id,
        title,
        note,
        ignoreCronFetch,
      };
      if ("shopbase" === storeById.platform) {
        if (sbPublicDomain && sbPublicDomain.length > 0) {
          // If has input url length, then check valid url.
          if (validateUrl(sbPublicDomain)) {
            submitData["shopbasePublicDomain"] = sbPublicDomain;
          } else {
            setErrorSBPublicDomain("Please enter a valid url with https://");
            return;
          }
        } else {
          // Else skip check valid url.
          submitData["shopbasePublicDomain"] = sbPublicDomain;
        }
      }

      if ("shopify" === storeById.platform) {
        if (sfPublicDomain && sfPublicDomain.length > 0) {
          // If has input url length, then check valid url.
          if (validateUrl(sfPublicDomain)) {
            submitData["shopifyPublicDomain"] = sfPublicDomain;
          } else {
            setErrorSFPublicDomain("Please enter a valid url with https://");
            return;
          }
        } else {
          // Else skip check valid url.
          submitData["shopifyPublicDomain"] = sbPublicDomain;
        }
      }

      const platform = storeById && storeById.platform;
      if (
        [PLATFORMS.Amazon, PLATFORMS.Tiktok, PLATFORMS.Ebay].includes(platform)
      ) {
        submitData["suspended"] = suspended;
      }

      if (["etsy"].includes(platform)) {
        submitData["usingEtsyAPI"] = isUsingEtsyAPI;

        if (isPMETeam) {
          submitData["extraInfo"] = extraInfo;
        }
      }

      if (state.isTiktok) {
        submitData["extraInfo"] = {
          friendly: extraInfo.friendly,
        };

        if (extraInfo?.files?.length > 0) {
          const id = get(extraInfo, "files[0].id") || null;
          submitData["extraInfo"]["logo"] = id;
        }
      }
      submitData["alwaysCreateArtistTask"] = isAlwaysCreateArtistTask;

      // if (state.isTiktok) {
      //   let warehouseID = state.selected[0];
      //   if (!warehouseID) {
      //     warehouseID = getFirstWarehouse(state.warehouses);
      //   }
      //   submitData["warehouseID"] = warehouseID;
      // }

      updateStore({
        variables: {
          input: submitData,
        },
      });
    }
    toggleActiveToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOptions,
    sbPublicDomain,
    sfPublicDomain,
    title,
    suspended,
    note,
    ignoreCronFetch,
    isUsingEtsyAPI,
    isAlwaysCreateArtistTask,
    isPMETeam,
    extraInfo,
    // state.selected,
    state.isTiktok,
    // state.warehouses,
  ]);

  const handleReAuth = useCallback(() => {
    reAuthenticateStore({
      variables: {
        storeId: id,
      },
    });
    toggleActiveToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    [],
  );

  const handleContentToast = () => {
    if (error || errorUS || errorReAuth) {
      if (
        errorUS &&
        errorUS
          .toString()
          .includes("Could not found Etsy store ID by given name")
      ) {
        return "Could not found Etsy store ID by given name";
      }
      return "Went something wrong";
    }
    if (dataUS && dataUS.updateStore) return "Update store successful.";
    if (dataReAuth && dataReAuth.reAuthenticateStore) {
      window.location.href = dataReAuth.reAuthenticateStore;
      return `You will be redirected momentarily.`;
    }
    if (showNoticeSaveDataFirst) {
      return `You must hit "Update store" first.`;
    }
  };

  const handleTitleChange = useCallback((value) => {
    setTitle(value);
    handleTitleValue(value);
    setMustSaveDataFirst(true);
  }, []);

  const handleExtraChange = useCallback((value, id) => {
    setExtraInfo((p) => ({
      ...p.extraInfo,
      [id]: value,
    }));
  }, []);

  const handleTitleValue = (value) => {
    let t = null;
    if (!value) {
      t = "Title is required.";
    }
    setErrorTitle(t);
  };

  const toastMarkup = activeToast
    ? (error ||
        errorUS ||
        errorReAuth ||
        (dataUS && dataUS.updateStore) ||
        (dataReAuth && dataReAuth.reAuthenticateStore) ||
        showNoticeSaveDataFirst) && (
        <Toast
          content={handleContentToast()}
          error={error || errorUS || errorReAuth}
          duration={2000}
          onDismiss={toggleActiveToast}
        />
      )
    : null;

  const storeById = data && data.storeById;
  const pathname = useCustomPathname();
  let currentUrl = useTargetUrl(pathname, storeById?.platform);

  const handleRedirect = useCallback(() => {
    history.push(currentUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeById]);

  // const handleWarehouseChange = useCallback((selected) => {
  //   setState({ selected });
  // }, []);

  const handleEtsyAggreeOAuthTerms = useCallback(
    async (checked) => {
      setAgreeEtsyOAuthTerms(checked);
      if (checked && etsyOAuthUrl == "OAuth URL is generating...") {
        try {
          const res = await generateEtsyOAuthUrl({
            variables: {
              etsyStoreName: title,
            },
          });
          if (res && res.data && res.data.generateEtsyOAuthURL) {
            setEtsyOAuthUrl(res.data.generateEtsyOAuthURL);
          }
        } catch {}
      }
    },
    [title],
  );

  const textField = (
    <Autocomplete.TextField
      value={inputValue}
      onChange={handleInput}
      placeholder="Choose store managers"
      label="Choose store managers(*)"
      labelHidden
    />
  );

  // enableFulfillmentConfig
  const enableFC = React.useMemo(() => {
    return !!data?.storeById?.enableFulfillmentConfig;
  }, [data?.storeById]);

  const loadingMarkup = loading && <Loading />;

  if (error) return <div>Error{handleError(error.toString())}</div>;
  if (errorRoles) return <div>Error{handleError(errorRoles.toString())}</div>;

  const isEtsy = storeById?.platform === "etsy";
  const platformReAuth = ["woocommerce", "shopify", "shopbase"];

  return (
    <Container>
      {loadingMarkup}
      {toastMarkup}
      <Page
        title="Edit Store"
        breadcrumbs={
          isMarketplaceManager ? [] : [{ content: "Stores", url: currentUrl }]
        }
      >
        <Form onSubmit={handleSubmit}>
          <Card sectioned title="General">
            <FormLayout>
              <>
                <ComponentLabelPolaris label="Title" required />
                <TextField
                  value={title}
                  onChange={handleTitleChange}
                  placeholder="Enter title"
                  error={errorTitle}
                />
              </>
              {[PLATFORMS.Amazon, PLATFORMS.Tiktok, PLATFORMS.Ebay].includes(
                storeById && storeById.platform,
              ) && (
                <Checkbox
                  checked={suspended}
                  onChange={() => setSuspended((prev) => !prev)}
                  label="Is suspended"
                />
              )}
              <>
                <ComponentLabelPolaris label="Choose store managers" />
                <Autocomplete
                  options={options}
                  onSelect={handleSelection}
                  selected={selectedOptions}
                  textField={textField}
                  emptyState={<span>No items found!</span>}
                  allowMultiple
                  loading={loadingRoles}
                />
                <TagContainer>
                  {selectedOptions && selectedOptions.length
                    ? selectedOptions.map((s) => {
                        let matchedOptions = options.find(
                          (option) => option.value === s,
                        );
                        if (matchedOptions) {
                          return (
                            <Tag
                              children={matchedOptions && matchedOptions.label}
                              onRemove={() => handleRemove(s)}
                              key={s}
                            />
                          );
                        }
                      })
                    : null}
                </TagContainer>
              </>
              <TextField
                label="Note"
                value={note}
                onChange={handleNoteChange}
                placeholder="Enter note"
                multiline={4}
              />
              {storeById && "shopbase" === storeById.platform && (
                <TextField
                  label="Shopbase public domain"
                  type="text"
                  value={sbPublicDomain}
                  onChange={handleSBPublicDomainChange}
                  placeholder="Enter public domain"
                  error={errorSBPublicDomain}
                />
              )}
              {storeById && "shopify" === storeById.platform && (
                <TextField
                  label="Shopify public domain"
                  type="text"
                  value={sfPublicDomain}
                  onChange={handleSFPublicDomainChange}
                  placeholder="Enter public domain"
                  error={errorSFPublicDomain}
                />
              )}
              <Checkbox
                label="Ignore cron fetch"
                checked={ignoreCronFetch}
                onChange={(newValue) => {
                  setIgnoreCronFetch(newValue);
                }}
              />

              <Checkbox
                label="Always create the artist task for orders from this store?"
                checked={isAlwaysCreateArtistTask}
                onChange={(newVal) => {
                  setAlwaysCreateArtistTask(newVal);
                }}
              />
              <Checkbox
                label="Would you like to use Etsy API in this store?"
                checked={isUsingEtsyAPI}
                onChange={(newVal) => {
                  setUsingEtsyAPI(newVal);
                }}
              />

              {/* {state.isTiktok && state.warehouses.length > 0 && (
                <div>
                  <ChoiceList
                    title="Warehouses"
                    choices={state.warehouses}
                    disabled={state.warehouses.length === 1}
                    selected={state.selected}
                    onChange={handleWarehouseChange}
                  />
                </div>
              )} */}
              {storeById && platformReAuth.includes(storeById.platform) && (
                <ReAuthorizeWrap>
                  <label>Re Authorize your store</label>
                  <Button
                    loading={loadingReAuth}
                    icon={!loadingReAuth ? RefreshMajorMonotone : null}
                    onClick={handleReAuth}
                  >
                    Re Authorize
                  </Button>
                </ReAuthorizeWrap>
              )}
              {storeById && "etsy" == storeById.platform && (
                <>
                  <EtsyReAuthWrap>
                    <Button
                      icon={RefreshMajorMonotone}
                      onClick={() => {
                        if (mustSaveDataFirst) {
                          setShowNoticeSaveDataFirst(true);
                          setActiveToast(true);
                        } else {
                          setShowEtsyOAuthModal(true);
                        }
                      }}
                    >
                      Authorize
                    </Button>
                  </EtsyReAuthWrap>
                  <Modal
                    open={showEtsyOAuthModal}
                    onClose={() => {
                      setShowEtsyOAuthModal(false);
                    }}
                    title="OAuth Your Etsy Account"
                  >
                    <ModalContainer>
                      <Modal.Section>
                        <TextContainer>
                          <h2>
                            Manage and fulfill your Etsy orders from all your
                            accounts at once with MerchBridge
                          </h2>
                          <div className={"image"}>
                            <img src={authImage} alt={""} />
                          </div>
                          <div className={"warning-box"}>
                            <p>
                              Warning: To protect your account from being
                              suspended by Etsy, Please:
                            </p>
                            <ul>
                              <li>
                                Read and follow the documentation carefully
                              </li>
                              <li>
                                Only use the Auth Etsy Account URL from the PC
                                or VPS of the Etsy account that you want to
                                connect
                              </li>
                            </ul>
                          </div>
                          <Checkbox
                            label="I have read and agreed with the documentation."
                            checked={agreeEtsyOAuthTerms}
                            onChange={(checked) => {
                              handleEtsyAggreeOAuthTerms(checked);
                            }}
                          />
                          {agreeEtsyOAuthTerms && (
                            <CopyBox text={etsyOAuthUrl} />
                          )}
                        </TextContainer>
                      </Modal.Section>
                    </ModalContainer>
                  </Modal>
                </>
              )}
            </FormLayout>
          </Card>

          {isPMETeam ? (
            isEtsy ? (
              <React.Suspense fallback={null}>
                <ExtraInfo values={extraInfo} onChange={handleExtraChange} />
              </React.Suspense>
            ) : state.isTiktok ? (
              <React.Suspense fallback={null}>
                <ExtraInfoTiktok
                  value={extraInfo}
                  onChange={handleExtraChange}
                />
              </React.Suspense>
            ) : null
          ) : null}
          {enableFC && (
            <React.Suspense fallback={<Spinner size="small" />}>
              <FulfillmentConfig
                value={data?.storeById?.fulfillmentConfigs}
                storeId={data?.storeById?.id}
                refetch={refetch}
                forceUpdate={forceUpdate}
              />
            </React.Suspense>
          )}

          <BtnsWrap>
            <ButtonGroup>
              <Button onClick={() => handleRedirect()}>Cancel</Button>
              <Button primary submit loading={loadingUS}>
                Update store
              </Button>
            </ButtonGroup>
          </BtnsWrap>
        </Form>
      </Page>
    </Container>
  );
};

function getFirstWarehouse(warehouses) {
  const [firstItem] = (warehouses || []).filter(
    (item) => item?.value?.length > 0,
  );

  return firstItem.value;
}

function getCertificate(data) {
  return get(data, "certificate.warehouse_id") || "";
}

function useTargetUrl(pathname, platform) {
  const { currentUser } = useAppContext();
  const { isStoreManager } = checkRole(currentUser);

  let u = `/${pathname}/stores`;
  if (!platform) {
    return u;
  }

  switch (platform) {
    case PLATFORMS.Amazon:
    case PLATFORMS.Ebay:
    case PLATFORMS.Etsy:
      if (!isStoreManager) {
        u = `/${pathname}/stores/${platform}`;
      }
      break;

    case PLATFORMS.Facebook:
      u = `/${pathname}/stores/fb-marketplace`;
      break;

    case PLATFORMS.Tiktok:
      u = `/${pathname}/stores/tiktok`;
      break;

    default:
      break;
  }

  return u;
}
