import React, { Fragment } from "react";
import { Button } from "@shopify/polaris";

import useToggle from "../../../hooks/useToggle";
import QuickPush from "./actions/QuickPush";

export const BulkAction = () => {
  // State
  const [open, toggleOpen] = useToggle(false);

  return (
    <Fragment>
      <Button children="Quick Push" onClick={toggleOpen} />
      <QuickPush onClose={toggleOpen} open={open} />
    </Fragment>
  );
};
