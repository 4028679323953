import { useMutation } from "@apollo/react-hooks";
import { Button, Modal } from "@shopify/polaris";
import React, { useReducer } from "react";

import { FETCH_TIKTOK_STORE_STARTUS } from "../../graphql/mutations";
import { handleError, reducerFn } from "../../helper";
import { useToastContext } from "../shared/ToastContext";

export default function FetchStoreStatus() {
  const [state, setState] = useReducer(reducerFn, {
    open: false,
  });

  const { setNotify, toggleToast } = useToastContext();

  const [fetchStatus, { loading }] = useMutation(FETCH_TIKTOK_STORE_STARTUS, {
    onCompleted: () => {
      setNotify({ msg: "Fetch status for Store success", err: false });
      handleOpenChange(false)();
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const handleOpenChange = React.useCallback(
    (value) => () => {
      setState({ open: value });
    },
    [],
  );

  const handleSubmit = React.useCallback(() => {
    toggleToast && toggleToast(true);
    setNotify && setNotify({ msg: "", err: false });

    fetchStatus();
  }, [toggleToast, setNotify, fetchStatus]);

  return (
    <>
      <Button children="Fetch store status" onClick={handleOpenChange(true)} />
      <Modal
        title="Update Store Status"
        sectioned
        open={state.open}
        onClose={handleOpenChange(false)}
        secondaryActions={[
          { content: "Cancel", onAction: handleOpenChange(false) },
        ]}
        primaryAction={{ content: "Submit", onAction: handleSubmit, loading }}
      >
        <p>Are you sure to fetch status for the Store?</p>
      </Modal>
    </>
  );
}
