import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Icon as LegacyIcon } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Card,
  Carousel,
  Col,
  Input,
  Modal,
  Row,
  Tag,
  notification,
} from "antd";
import CollectionsAutoComplete from "../shared/CollectionsAutoComplete";
import TagsAutoComplete from "../shared/TagsAutoComplete";
import ProductVariants from "../product/ProductVariants";
import Price from "../Price";
import Wysiwyg from "../Wysiwyg";
import _ from "lodash";
import styled from "styled-components";

import { fieldsCampaignProductBaseItem } from "../../constants";
import {
  handleDeepEqual,
  sortProductBaseVariantId,
  handleError,
  getBaseTitle,
} from "../../helper";
import { UpdatePriceForVariants } from "./UpdatePriceForVariants";
import { ColorsComponent } from "./ColorsComponent";
import {
  MAX_LENGTH,
  MAX_TITLE_CHARACTERS,
  TITLE_LENGTH_MESS,
} from "../../variable";
import { genHelpText } from "../product/components/ShortTitle";
import { DeleteBase } from "./actions";
import { AppContext } from "../../context";
import JoditEditor from "../shared/JoditEditor";

const UpdatePriceContainer = styled.div`
  .ant-collapse-borderless > .ant-collapse-item {
    border: none;
  }
`;

const TagContainer = styled.div`
  .tag-wrap {
    border-radius: 3px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
`;

class CampaignProductBaseItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      defaultFieldsValue: null,
      showBtnSave: null,
      handleBtnSave: null,
      productAttributes: null,
      variantsState: null,
      firstColor: null,
      sortVariant: {},
    };

    this.handleChangeAttrOption = this.handleChangeAttrOption.bind(this);
  }

  componentDidMount() {
    const {
      grouped,
      value: { productBase, variants },
      template,
      isEditProduct,
    } = this.props;
    let defaultFieldsValue = this.props.form.getFieldsValue(
      fieldsCampaignProductBaseItem,
    );
    if (!grouped) {
      defaultFieldsValue = JSON.parse(JSON.stringify(defaultFieldsValue));
    }
    this.setState({
      defaultFieldsValue: defaultFieldsValue,
    });

    let stateVal = {};
    let firstColor;
    if (
      productBase &&
      productBase.attributes &&
      productBase.attributes.length
    ) {
      stateVal["productAttributes"] = this.getProductBaseAttrs(
        productBase.attributes,
        this.getActivatedProductAttrs(variants),
      );
      productBase.attributes.forEach((att) => {
        if (isSlugColor(att.slug) && att.options && att.options.length) {
          firstColor = _.head(att.options);
          this.setState({
            firstColor,
          });
        }
      });
    }

    if (variants && variants.length) {
      const variantIds = [];
      variants.forEach((v) => {
        let disabledVal = true;
        if (v.productBaseVariantId) {
          variantIds.push(v.productBaseVariantId);
        }
        if (
          v.productBaseVariant &&
          v.productBaseVariant.attributes &&
          v.productBaseVariant.attributes.length
        ) {
          v.productBaseVariant.attributes.forEach((att) => {
            if (
              isSlugColor(att.slug) &&
              firstColor.toLowerCase() === att.option.toLowerCase()
            ) {
              disabledVal = false;
            }
          });
        }
        v.disabled =
          v && firstColor && !isEditProduct
            ? disabledVal
            : v.disabled
            ? v.disabled
            : false;
      });

      const variantsMissing = (productBase?.variants || [])
        .filter(({ id }) => !variantIds.includes(id))
        .map((v) => {
          return {
            productBaseVariant: v,
            productBaseVariantId: v.id,
            image: null,
            disabled: false,
            regularPrice: v.regularPrice,
            salePrice: v.salePrice,
          };
        });
      stateVal["variantsState"] = variants;
      stateVal["variantsMissing"] = variantsMissing;
    }
    if (productBase) {
      stateVal["currentProductBase"] = productBase;
    }

    this.setState(stateVal);

    if (
      template &&
      template.data &&
      template.data.products &&
      template.data.products.length
    ) {
      let setState = {};
      let templateProduct = template.data.products.find(
        (p) => p.productbaseId === productBase.id,
      );
      let variants = this.getProductVariants(productBase, templateProduct);
      this.props.form.setFieldsValue({ variants: variants });

      let productVariants = templateProduct.variants;
      let templatePBaseVars = _.get(productBase, "variants", []) || [];
      let templatePBaseAttr = _.get(productBase, "attributes", []) || [];
      let buildProductData = [];

      productVariants.forEach((val) => {
        let pBaseId = val.productBaseVariantID;
        templatePBaseVars.forEach((v) => {
          if (v.id === pBaseId) {
            let pVariant = {
              attributes: v.attributes,
            };
            val["productBaseVariant"] = pVariant;
            val["productBaseVariantId"] = v.id;
            val["image"] = null;
          }
        });
        buildProductData.push(val);
      });
      setState["variantsState"] = buildProductData;
      buildProductData = { ...buildProductData };
      let pBaseAttrs = this.getProductBaseAttrs(
        templatePBaseAttr,
        this.getActivatedProductAttrs(buildProductData),
      );
      if (pBaseAttrs) {
        setState["productAttributes"] = pBaseAttrs;
      }

      this.setState(setState);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      onChangeValue,
      grouped,
      updatePrices,
      value: { productBase, variants },
      isEditProduct,
    } = this.props;

    if (!_.isEqual(prevProps.isChangeDrag, this.props.isChangeDrag)) {
      let stateVal = {};
      let firstColor;
      if (
        productBase &&
        productBase.attributes &&
        productBase.attributes.length
      ) {
        let productAttr = this.getProductBaseAttrs(
          productBase.attributes,
          this.getActivatedProductAttrs(variants),
        );
        stateVal["productAttributes"] = productAttr;

        productBase.attributes.forEach((att) => {
          if (isSlugColor(att.slug) && att.options && att.options.length) {
            firstColor = _.head(att.options);
            this.setState({
              firstColor,
            });
          }
        });

        // Set prices
        let labels = [];
        if (productAttr) {
          Object.keys(productAttr).forEach((attribute) => {
            if (
              productAttr[attribute] &&
              "size" === productAttr[attribute].slug
            ) {
              productAttr[attribute]["options"] &&
                Object.keys(productAttr[attribute]["options"]).forEach(
                  (option) => {
                    labels.push({
                      label:
                        productAttr[attribute]["options"][option] &&
                        productAttr[attribute]["options"][option]["label"],
                      regularPrice: null,
                      salePrice: null,
                    });
                  },
                );
            }
          });
        }
        stateVal["labels"] = labels;
      }

      if (variants && variants.length) {
        variants.forEach((v) => {
          let disabledVal = true;
          if (
            v.productBaseVariant &&
            v.productBaseVariant.attributes &&
            v.productBaseVariant.attributes.length
          ) {
            v.productBaseVariant.attributes.forEach((att) => {
              if (
                isSlugColor(att.slug) &&
                firstColor.toLowerCase() === att.option.toLowerCase()
              ) {
                disabledVal = false;
              }
            });
          }
          v.disabled =
            v && firstColor && !isEditProduct
              ? disabledVal
              : v.disabled
              ? v.disabled
              : false;
        });

        stateVal["variantsState"] = variants;
      }
      if (productBase) {
        stateVal["currentProductBase"] = productBase;
      }

      this.setState(stateVal, () => {
        this.props.form.setFieldsValue({
          variants: variants,
        });
      });
    }

    let { variantsState } = this.state;
    if (prevState.handleBtnSave !== this.state.handleBtnSave) {
      this.handleShowBtnSave();
      if (grouped) {
        if (onChangeValue) {
          onChangeValue(false);
        }
      }
    }
    const productBaseVariants = this.getProductVariants(
      productBase,
      {},
      true,
      this.state.firstColor,
    );

    let newProductBaseVariants = variantsState
      ? variantsState
      : productBaseVariants;

    if (prevProps.updatePrices !== updatePrices) {
      this.handleUpdatePrice(newProductBaseVariants);
    }

    let { value, onChange, template } = this.props;
    let isTemplate = !!template;
    if (this.state.variantsState !== prevState.variantsState) {
      if (isTemplate) {
        if (onChange) {
          onChange({ ...value, variants: variantsState });
        }
      }
    }
  }

  handleShowBtnSave = () => {
    let { form, onChangeValue, grouped } = this.props;
    if (!grouped) {
      let defaultFieldsValue = this.state.defaultFieldsValue && {
        ...this.state.defaultFieldsValue,
      };

      let newFieldsValue = {
        ...form.getFieldsValue(fieldsCampaignProductBaseItem),
      };
      newFieldsValue = JSON.parse(JSON.stringify(newFieldsValue));

      if (newFieldsValue && defaultFieldsValue) {
        newFieldsValue.variants = newFieldsValue.variants.sort(
          sortProductBaseVariantId,
        );
        defaultFieldsValue.variants = defaultFieldsValue.variants.sort(
          sortProductBaseVariantId,
        );
        newFieldsValue.description = newFieldsValue.description.replace(
          /(\r\n|\n|\r)/gm,
          "",
        );
        // newFieldsValue.shortDescription = newFieldsValue.shortDescription.replace(
        //   /(\r\n|\n|\r)/gm,
        //   ""
        // );

        if (handleDeepEqual(newFieldsValue, defaultFieldsValue)) {
          this.setState({
            showBtnSave: true,
          });
          if (onChangeValue) {
            onChangeValue(true);
          }
        } else {
          this.setState({
            showBtnSave: false,
          });
          if (onChangeValue) {
            onChangeValue(false);
          }
        }
      }
    }
  };

  strToSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  };

  countActivatedVariants = (variants) => {
    let countActivated = 0;
    _.forOwn(variants, function (el) {
      if (
        el.productBaseVariant.attributes &&
        el.productBaseVariant.attributes.length > 0
      ) {
        if (!el.disabled) {
          countActivated++;
        }
      }
    });
    return countActivated;
  };

  getProductBaseAttrs = (productBaseAttrs, activateAttrs, variants) => {
    let pBaseAttrs = {};
    let disabledAll = false;
    let isEditProduct = this.props.isEditProduct;

    // This props;
    const { template } = this.props;
    let isUseTemplate = !!template;

    if (variants) {
      let countActivated = this.countActivatedVariants(variants);
      if (0 === countActivated) {
        disabledAll = true;
      }
    }

    if (productBaseAttrs && productBaseAttrs.length > 0) {
      productBaseAttrs.forEach((val) => {
        let attrOptions = {};
        let enableFirst = !variants && isSlugColor(val.slug);
        val.options &&
          val.options.forEach((opt) => {
            let disableVal = false;
            if (disabledAll) {
              disableVal = true;
            }
            if (
              activateAttrs &&
              activateAttrs[val.slug] &&
              activateAttrs[val.slug].length > 0
            ) {
              disableVal = true;
              if (_.includes(activateAttrs[val.slug], opt)) {
                disableVal = false;
              }
            } else {
              disableVal = true;
            }

            if (enableFirst && !isEditProduct && !isUseTemplate) {
              let firstValue = _.head(val.options);
              if (opt === firstValue) {
                disableVal = false;
              } else {
                disableVal = true;
              }
            }

            attrOptions[this.strToSlug(opt)] = {
              label: opt,
              disabled: disableVal,
            };
          });
        pBaseAttrs[val.slug] = {
          name: val.name,
          slug: val.slug,
          options: attrOptions,
        };
      });
    }
    return pBaseAttrs;
  };

  getProductVariants = (productBase, product = {}, enableFirst, firstColor) => {
    // This props;
    const { template } = this.props;
    let isUseTemplate = !!template;

    if (!productBase.variants) {
      return [];
    }
    let { isEditProduct } = this.props;

    return productBase.variants.map((variant, idx) => {
      let disabledVal = true;
      if (
        variant.attributes &&
        variant.attributes.length &&
        enableFirst &&
        firstColor
      ) {
        variant.attributes.forEach((att) => {
          if (
            att.slug &&
            isSlugColor(att.slug) &&
            att.option &&
            att.option.toLowerCase() === firstColor.toLowerCase()
          ) {
            disabledVal = false;
          }
        });
      }

      let currentBaseVarID = variant.id;
      let i = idx;
      let productValid;
      if (product && Object.keys(product).length) {
        productValid = product && Object.keys(product).length;
        i = _.findIndex(product && product.variants, {
          productBaseVariantID: currentBaseVarID,
        });
      }

      return {
        productBaseVariantId: variant.id,
        productBaseVariant: variant,
        image: null,
        regularPrice:
          productValid && product.variants[i]
            ? product.variants[i].regularPrice
            : variant.regularPrice,
        salePrice:
          productValid && product.variants[i]
            ? product.variants[i].salePrice
            : variant.salePrice,
        disabled:
          enableFirst && firstColor && !isEditProduct && !isUseTemplate
            ? disabledVal
            : productValid && product.variants[i]
            ? product.variants[i].disabled
            : variant && variant.disabled
            ? variant.disabled
            : false,
      };
    });
  };

  handleChangeAttrOption = (
    attrSlug,
    optionSlug,
    data,
    productBaseVariants,
  ) => {
    let state = this.state;
    let newProductAttributes = state.productAttributes;
    let disabledVal =
      newProductAttributes[attrSlug]["options"][optionSlug]["disabled"];
    newProductAttributes[attrSlug]["options"][optionSlug][
      "disabled"
    ] = !disabledVal;

    let countAttrs = Object.keys(newProductAttributes).length;

    // Get list active options of every attribute
    let attrActivatedOptions = {};
    if (countAttrs > 1) {
      _.forOwn(newProductAttributes, function (el, key) {
        let optionActivated = [];
        _.forOwn(el.options, function (v) {
          if (!v.disabled) {
            optionActivated.push(v.label);
          }
        });
        attrActivatedOptions[key] = optionActivated;
      });
    }

    let newProductBaseVariants = state.variantsState
      ? state.variantsState
      : productBaseVariants;
    let affectKeys = [];
    _.forOwn(newProductBaseVariants, function (el, key) {
      if (
        el.productBaseVariant.attributes &&
        el.productBaseVariant.attributes.length > 0
      ) {
        el.productBaseVariant.attributes.forEach((v) => {
          if (v.slug === attrSlug && v.option === data.label) {
            let pushVal = {
              key: key,
              disabled: el.disabled,
            };
            affectKeys.push(pushVal);

            let newDisabledVar = !disabledVal;
            // If product has more than 2 attributes, then check complex condition active variants.
            if (countAttrs > 1 && disabledVal) {
              // Only check case active attribute.
              if (
                el.productBaseVariant &&
                el.productBaseVariant.attributes &&
                el.productBaseVariant.attributes.length > 0
              ) {
                let countMatch = 0;
                el.productBaseVariant.attributes.forEach((at) => {
                  let atSlug = at.slug;
                  let atOption = at.option;
                  let attrOptionsActivated = attrActivatedOptions[atSlug];
                  if (!attrOptionsActivated) {
                    atSlug = switchSlugColor(atSlug);
                    attrOptionsActivated = attrActivatedOptions[atSlug];
                  }
                  if (
                    attrOptionsActivated &&
                    attrOptionsActivated.length > 0 &&
                    attrOptionsActivated.includes(atOption)
                  ) {
                    countMatch++;
                  }
                });
                if (countMatch !== countAttrs) {
                  newDisabledVar = disabledVal;
                }
              }
            }
            return (el.disabled = newDisabledVar);
          }
        });
      }
    });

    // Count product to prevent disable all variants
    let countActivated = this.countActivatedVariants(newProductBaseVariants);
    if (0 === countActivated) {
      newProductAttributes[attrSlug]["options"][optionSlug][
        "disabled"
      ] = disabledVal;
      if (affectKeys.length > 0) {
        affectKeys.forEach(function (v, k) {
          newProductBaseVariants[v.key].disabled = v.disabled;
        });
      }
      alert("You must keep at least one option for each attributes");
    }
    this.setState({
      productAttributes: newProductAttributes,
      variantsState: newProductBaseVariants,
      showBtnSave: false,
    });
    this.props.form.setFieldsValue({
      variants: newProductBaseVariants,
    });
  };

  getActivatedProductAttrs = (variants) => {
    let activatedAttrs = {};

    _.forOwn(variants, function (el) {
      if (
        !el.disabled &&
        el.productBaseVariant.attributes &&
        el.productBaseVariant.attributes.length > 0
      ) {
        el.productBaseVariant.attributes.forEach((v) => {
          if (!activatedAttrs[v.slug]) {
            activatedAttrs[v.slug] = [];
          }
          activatedAttrs[v.slug].push(v.option);
        });
      }
    });
    return activatedAttrs;
  };

  onVariableDisabledChanged = (variants) => {
    let state = this.state;
    if (
      state &&
      state.currentProductBase &&
      state.currentProductBase.attributes
    ) {
      let activatedAttrs = this.getProductBaseAttrs(
        this.state.currentProductBase.attributes,
        this.getActivatedProductAttrs(variants),
        variants,
      );
      this.setState({
        productAttributes: activatedAttrs,
      });
    }
  };

  countState = (variantsState) => {
    let counEnabled = 0;
    let counDisabled = 0;
    variantsState &&
      variantsState.length &&
      variantsState.forEach((val) => {
        if (!val.disabled) {
          counEnabled++;
        } else {
          counDisabled++;
        }
      });
    return {
      counEnabled,
      counDisabled,
    };
  };

  handleUpdatePrice = (productBaseVariants) => {
    let value = this.state.labels;
    let { variantsState } = this.state;
    let newProductBaseVariants = variantsState
      ? variantsState
      : productBaseVariants;

    _.forOwn(value, (el) => {
      let label = el && el.label;
      let newRegularPrice = el && el.regularPrice;
      let newSalePrice = el && el.salePrice;
      _.forOwn(newProductBaseVariants, (v) => {
        if (
          v.productBaseVariant &&
          v.productBaseVariant.attributes &&
          v.productBaseVariant.attributes.length
        ) {
          _.forOwn(v.productBaseVariant.attributes, (att) => {
            if (att.option === label) {
              v.regularPrice = newRegularPrice
                ? newRegularPrice
                : v.regularPrice;
              v.salePrice = newSalePrice ? newSalePrice : v.salePrice;
            }
          });
        }
      });
    });

    this.setState({
      variantsState: newProductBaseVariants,
    });

    this.props.form.setFieldsValue({
      variants: newProductBaseVariants,
    });
  };

  handleError = (err) => {
    let uniqueMessage = {};
    _.each(err, (e) => {
      _.each(_.get(e, "errors", []), (er) => {
        const msg = _.trim(er.message.toLowerCase());
        if (typeof uniqueMessage[msg] === "undefined") {
          notification.error({ message: handleError(er.message) });
        }
        uniqueMessage[msg] = true;
      });
    });
  };

  validateTitle = (_rule, value, callback) => {
    let error = null;
    if (!value) {
      error = "Product title is required.";
    } else if (value.length > MAX_LENGTH) {
      error = TITLE_LENGTH_MESS;
    }
    error ? callback(error) : callback();
  };

  render() {
    const {
      visible,
      handleBtnSave,
      variantsState,
      variantsMissing,
    } = this.state;
    const {
      value,
      onSelected,
      onDelete,
      selected,
      grouped,
      sku,
      isEditProduct,
      noAction,
      productId,
    } = this.props;

    const { getFieldDecorator } = this.props.form;
    const { productBase, title } = value;
    const images =
      productBase.images && productBase.images.length ? productBase.images : [];
    const showVariants = value.variants && value.variants.length > 0;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };

    const productAttrs = this.state.productAttributes;
    const productBaseVariants = this.getProductVariants(
      productBase,
      {},
      true,
      this.state.firstColor,
    );
    let count = this.countState(variantsState);

    let sortedVariantsEdit = [];
    if (!this.state.isDrap) {
      if (isEditProduct) {
        sortedVariantsEdit =
          value && value.variants && value.variants.length > 0
            ? value.variants.sort((a, b) => a.sorting - b.sorting)
            : [];
      } else {
        sortedVariantsEdit =
          value && value.variants && value.variants.length > 0
            ? value.variants.sort(
                (a, b) =>
                  a.productBaseVariant.sorting - b.productBaseVariant.sorting,
              )
            : [];
      }
    }

    // Facebook title
    const fbTitleValue =
      this.props.form && this.props.form.getFieldValue("shortTitle");
    const fbTitleHelp = genHelpText(fbTitleValue, MAX_TITLE_CHARACTERS.fbTitle);

    // Amazon Title
    const aTitleValue =
      this.props.form && this.props.form.getFieldValue("amzTitle");
    const aTitleHelp = genHelpText(aTitleValue, MAX_TITLE_CHARACTERS.amzTitle);

    // Origin product title
    const titleValue =
      this.props.form && this.props.form.getFieldValue("title");
    const titleHelp = genHelpText(titleValue);

    // Base Title
    const baseTitle = getBaseTitle(productBase);

    return (
      <div>
        <Card
          className={`${selected ? "selected" : "unselected"}`}
          bordered={false}
          bodyStyle={{ padding: 10 }}
          onClick={onSelected}
          title={baseTitle}
          extra={
            noAction ? null : (
              <div>
                {/* <Button
                className="danger-btn"
                onClick={onDelete}
                size="small"
                type="link"
              >
                Delete
              </Button>{" "} */}
                <DeleteBase
                  onDelete={onDelete}
                  value={value}
                  productId={productId}
                />{" "}
                <Button
                  onClick={() => {
                    this.setState({ visible: true });
                    this.handleUpdatePrice(productBaseVariants);
                  }}
                  icon={<LegacyIcon type={"edit"} />}
                  size="small"
                  type="link"
                >
                  Edit
                </Button>
              </div>
            )
          }
        >
          <Row gutter={16}>
            <Col span={6}>
              <Carousel>
                {images.map((file, index) => (
                  <div key={index}>
                    <img
                      style={{ width: "100%", maxWidth: "300px" }}
                      src={file.thumbnailUrl ? file.thumbnailUrl : file.url}
                      alt={""}
                    />
                  </div>
                ))}
              </Carousel>
              <div>{productBase.baseCostDescription}</div>
            </Col>
            <Col span={18}>
              {!grouped && <div className={"title"}>{title}</div>}
              {productAttrs &&
                Object.keys(productAttrs).map((attribute) => (
                  <div key={`attribute-${attribute}`}>
                    <div className={"attribute-title"}>
                      {productAttrs[attribute] && productAttrs[attribute].name}
                    </div>
                    <div style={{ marginBottom: 12 }}>
                      {productAttrs[attribute] &&
                        productAttrs[attribute]["options"] &&
                        Object.keys(productAttrs[attribute]["options"]).map(
                          (option) => {
                            let enableAttributes = isSlugColor(
                              productAttrs[attribute].slug,
                            );
                            // ("color" === productAttrs[attribute].slug || "colors" === productAttrs[attribute].slug);

                            let currentOption;
                            if (enableAttributes) {
                              currentOption =
                                productAttrs[attribute]["options"] &&
                                productAttrs[attribute]["options"][option];
                            }

                            let optionSlug =
                              currentOption &&
                              this.strToSlug(currentOption["label"]);

                            let optionDisabled =
                              currentOption && currentOption["disabled"];

                            let optionLabel =
                              currentOption && currentOption["label"];

                            if (optionLabel) {
                              let hasFlag =
                                optionLabel.match("/") &&
                                optionLabel.split("/");
                              if (hasFlag && hasFlag.length) {
                                let value = hasFlag.filter((v) => v.trim());
                                if (1 === value.length) {
                                  optionLabel = value;
                                }
                              }
                            }

                            return (
                              <TagContainer
                                style={{ display: "inline-block" }}
                                key={`option-${option}`}
                              >
                                <Tag
                                  key={`option-${option}`}
                                  className={enableAttributes ? `tag-wrap` : ""}
                                  style={{
                                    color:
                                      enableAttributes && optionDisabled
                                        ? "#1890ff"
                                        : "",

                                    cursor: "pointer",
                                    marginTop: 8,
                                    padding: "3px 8px",
                                  }}
                                  color={
                                    productAttrs[attribute]["options"][option][
                                      "disabled"
                                    ]
                                      ? ""
                                      : "#1890ff"
                                  }
                                  onClick={() => {
                                    this.handleChangeAttrOption(
                                      attribute,
                                      option,
                                      productAttrs[attribute]["options"][
                                        option
                                      ],
                                      productBaseVariants,
                                    );
                                    this.setState({
                                      handleBtnSave: !handleBtnSave,
                                    });
                                  }}
                                >
                                  {enableAttributes && (
                                    <ColorsComponent optionSlug={optionSlug} />
                                  )}
                                  {enableAttributes
                                    ? optionLabel
                                    : productAttrs[attribute]["options"] &&
                                      productAttrs[attribute]["options"][
                                        option
                                      ] &&
                                      productAttrs[attribute]["options"][
                                        option
                                      ]["label"]}
                                </Tag>
                              </TagContainer>
                            );
                          },
                        )}
                    </div>
                  </div>
                ))}
              <div className={"variant-total mt-4"}>
                {count && count.counDisabled >= 0 && (
                  <div>
                    Total enabled: <span>{count.counEnabled}</span>
                  </div>
                )}
                {count && count.counDisabled >= 0 && (
                  <div>
                    Total disabled: <span>{count.counDisabled}</span>
                  </div>
                )}
              </div>
              {productAttrs &&
                Object.keys(productAttrs).forEach((attribute) => {
                  if (
                    productAttrs[attribute] &&
                    "size" === productAttrs[attribute].slug
                  ) {
                    let labels = [];
                    productAttrs[attribute]["options"] &&
                      Object.keys(productAttrs[attribute]["options"]).forEach(
                        (option) => {
                          labels.push({
                            label:
                              productAttrs[attribute]["options"][option] &&
                              productAttrs[attribute]["options"][option][
                                "label"
                              ],
                            regularPrice: null,
                            salePrice: null,
                          });
                        },
                      );

                    return (
                      <UpdatePriceContainer key={`attribute-${attribute}`}>
                        <UpdatePriceForVariants
                          borderNone
                          key={`labels-${attribute}`}
                          labels={
                            this.state.labels ? this.state.labels : labels
                          }
                          onChange={(value) => this.setState({ labels: value })}
                          onSubmit={() =>
                            this.handleUpdatePrice(productBaseVariants)
                          }
                        />
                      </UpdatePriceContainer>
                    );
                  }
                })}
            </Col>
          </Row>
        </Card>
        <Modal
          title={productBase.title}
          footer={
            <Button
              onClick={() => {
                // Sort by drap
                let { sortVariant } = this.state;
                let newVariant =
                  sortVariant &&
                  sortVariant.enabledValues &&
                  sortVariant.disabledValues
                    ? [
                        ...sortVariant.enabledValues,
                        ...sortVariant.disabledValues,
                      ]
                    : [];
                let currentVariant = this.props.form.getFieldValue("variants");

                this.setState(
                  (prevState) => ({
                    variantsState:
                      newVariant.length > 0
                        ? newVariant
                        : prevState.variantsState,
                  }),
                  () => {
                    this.props.form.setFieldsValue({
                      variants:
                        newVariant.length > 0 ? newVariant : currentVariant,
                    });
                  },
                );

                this.props.form.validateFields((err, values) => {
                  values["variants"] = newVariant.length
                    ? newVariant
                    : values.variants;
                  if (!err) {
                    this.setState({ visible: false }, () => {
                      if (this.props.onChange) {
                        this.props.onChange(values);
                      }
                    });
                  } else {
                    this.handleError(err);
                  }
                });
              }}
              className={"btn-green"}
            >
              Save Changes
            </Button>
          }
          onCancel={() => {
            this.setState({ visible: false });
          }}
          visible={visible}
          width={980}
        >
          <Form
            {...layout}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div style={{ display: `${grouped ? "none" : "block"}` }}>
              <Form.Item label={"Title"} help={titleHelp}>
                {getFieldDecorator("title", {
                  initialValue: value.title,
                  rules: !grouped
                    ? [
                        {
                          required: true,
                          validator: this.validateTitle,
                        },
                      ]
                    : [],
                })(
                  <Input
                    placeholder={`${productBase.title} product title`}
                    onChange={() => {
                      this.setState({
                        handleBtnSave: !handleBtnSave,
                      });
                    }}
                  />,
                )}
              </Form.Item>
              <Form.Item label="Facebook Title" help={fbTitleHelp}>
                {getFieldDecorator("shortTitle", {
                  initialValue: value.shortTitle,
                })(
                  <Input
                    placeholder="Enter facebook title"
                    maxLength={MAX_TITLE_CHARACTERS.fbTitle}
                    onChange={() => {
                      this.setState({
                        handleBtnSave: !handleBtnSave,
                      });
                    }}
                  />,
                )}
              </Form.Item>
              <Form.Item label="Amazon Title" help={aTitleHelp}>
                {getFieldDecorator("amzTitle", {
                  initialValue: value.amzTitle,
                })(
                  <Input
                    placeholder="Enter amazon title"
                    maxLength={MAX_TITLE_CHARACTERS.amzTitle}
                    onChange={() => {
                      this.setState({
                        handleBtnSave: !handleBtnSave,
                      });
                    }}
                  />,
                )}
              </Form.Item>
              <Form.Item label={"Description"}>
                {getFieldDecorator("description", {
                  initialValue: value.description,
                })(
                  // <Wysiwyg
                  //   onChange={() => {
                  //     this.setState({
                  //       handleBtnSave: !handleBtnSave,
                  //     });
                  //   }}
                  // />,
                  <JoditEditor
                  onChange={() => {
                    this.setState({
                      handleBtnSave: !handleBtnSave,
                    });
                  }}
                />,
                )}
              </Form.Item>
              {/* <Form.Item label={"Short Description"}>
                {getFieldDecorator("shortDescription", {
                  initialValue: value.shortDescription,
                })(
                  <Wysiwyg
                    onChange={() => {
                      this.setState({
                        handleBtnSave: !handleBtnSave,
                      });
                    }}
                  />
                )}
              </Form.Item> */}
              <Form.Item label={"Design Resources"}>
                {getFieldDecorator("psd", {
                  initialValue: value.psd,
                })(<Input.TextArea rows={4} />)}
              </Form.Item>
              <Form.Item label={"Collections"}>
                {getFieldDecorator("collections", {
                  initialValue: value.collections,
                  rules: !grouped
                    ? [
                        {
                          required: true,
                          message: "Collections is required",
                        },
                      ]
                    : [],
                })(
                  <CollectionsAutoComplete
                    onChange={() => {
                      this.setState({
                        handleBtnSave: !handleBtnSave,
                      });
                    }}
                  />,
                )}
              </Form.Item>
              <Form.Item label={"Tags"}>
                {getFieldDecorator("tags", {
                  initialValue: value.tags,
                  rules: !grouped
                    ? [
                        {
                          required: true,
                          message: "Tags is required",
                        },
                      ]
                    : [],
                })(
                  <TagsAutoComplete
                    onChange={() => {
                      this.setState({
                        handleBtnSave: !handleBtnSave,
                      });
                    }}
                  />,
                )}
              </Form.Item>
            </div>
            <Form.Item title={"Variants"} hidden={!showVariants}>
              {getFieldDecorator("variants", {
                initialValue: sortedVariantsEdit, //value.variants
              })(
                <ProductVariants
                  isEditProduct={isEditProduct}
                  variantsMissing={variantsMissing}
                  sku={sku}
                  onChange={() => {
                    this.setState({
                      handleBtnSave: !handleBtnSave,
                    });
                  }}
                  isCampaign
                  onVariableDisabledChanged={this.onVariableDisabledChanged}
                  sortVariant={({ enabledValues, disabledValues, isDrap }) => {
                    this.setState({
                      sortVariant: {
                        enabledValues,
                        disabledValues,
                      },
                      showBtnSave: false,
                      isDrap: isDrap,
                    });
                  }}
                />,
              )}
            </Form.Item>
            <Card title={"Pricing"} hidden={showVariants}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={"Regular Price"}>
                    {getFieldDecorator("regularPrice", {
                      initialValue: value ? value.regularPrice : null,
                    })(
                      <Price
                        onChange={() => {
                          this.setState({
                            handleBtnSave: !handleBtnSave,
                          });
                        }}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={"Sale Price"}>
                    {getFieldDecorator("salePrice", {
                      initialValue: value ? value.salePrice : null,
                    })(
                      <Price
                        onChange={() => {
                          this.setState({
                            handleBtnSave: !handleBtnSave,
                          });
                        }}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </Modal>
      </div>
    );
  }
}

function isSlugColor(slug) {
  if (!slug || slug.length === 0) return false;
  const pt = /color(s)*/i;

  return pt.test(slug);
}

function switchSlugColor(slug) {
  let tmp = slug;
  if (!isSlugColor) return slug;
  if (slug === "color") {
    tmp = "colors";
  } else {
    tmp = "color";
  }
  return tmp;
}

CampaignProductBaseItem.propTypes = {
  value: PropTypes.any,
  selected: PropTypes.bool,
  onSelected: PropTypes.func,
  onDelete: PropTypes.func,
  grouped: PropTypes.bool,
  onChange: PropTypes.func,
  sku: PropTypes.string,
};

export default Form.create({ name: "item-form" })(CampaignProductBaseItem);
