import { Icon as LegacyIcon } from "@ant-design/compatible";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Tag,
} from "antd";
import _ from "lodash";
import React from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import { GET_PRODUCT_BY_TITLE } from "../../../graphql/queries";
import { getUnique, handleError, objectInvalid, sleep } from "../../../helper";
import history from "../../../history";
import {
  AMZ_MESSAGE_ERROR,
  MAX_LENGTH,
  MAX_TITLE_CHARACTERS,
  TITLE_LENGTH_MESS,
  UPLOAD_FOLDER,
  WHICH_FIELD,
} from "../../../variable";
import CustomizeButton from "../../customize/CustomizeButton";
import Price from "../../Price";
import { ColorsComponent } from "../../seller/ColorsComponent";
import PushProduct from "../../seller/PushProduct";
import { UpdatePriceForVariants } from "../../seller/UpdatePriceForVariants";
import CollectionsAutoComplete from "../../shared/CollectionsAutoComplete";
import PageTitle from "../../shared/PageTitle";
import TagsAutoComplete from "../../shared/TagsAutoComplete";
import MediaSelectorButton from "../../supplier/MediaSelectorButton";
import Wysiwyg from "../../Wysiwyg";
import ProductPrintFiles from "../ProductPrintFiles";
import ProductVariants from "../ProductVariants";
import { validateDimensions } from "../_utils";
import { genHelpText } from "./ShortTitle";
import JoditEditor from "../../shared/JoditEditor";
import ProductBaseSelect from "../../base/product-base-select";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

function ProductForm({
  // productBases,
  productBase,
  currentParam,
  loading,
  value,
  onSubmit,
}) {
  // Context
  const { store } = useAppContext();
  const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
    isPrompt: null,
    productAttributes: null,
    variantsState: null,
    firstColor: null,
    labels: null,
    isDrap: undefined,
    productVariantsVisible: false,
    sortVariant: {},
    currentProductBase: productBase,
    disabled: true,
    regularPrice: null,
    maxUpdate: null,
    mainImgId: null,
    isChanged: false,
    designPositions: [],
    mockup: [],
    showPreview: false,
  });

  React.useEffect(() => {
    if (value?.productAttrs != null) {
      setState({ productAttributes: value.productAttrs });
    }
  }, [value?.productAttrs]);

  React.useEffect(() => {
    if (value?.personalized != null) {
      setState({ showPreview: !!value.personalized });
    }
  }, [value?.personalized]);

  const [form] = Form.useForm();
  const designPositionRef = React.useRef(null);

  const titleHelp = React.useMemo(() => {
    if (!form) return;
    const t = form.getFieldValue("title");
    return genHelpText(t);
  }, [form]);

  const aTitleHelp = React.useMemo(() => {
    if (!form) return;
    const t = form.getFieldValue("amzTitle");
    return genHelpText(t, MAX_TITLE_CHARACTERS.amzTitle);
  }, [form]);

  const fbTitleHelp = React.useMemo(() => {
    if (!form) return;
    const t = form.getFieldValue("shortTitle");
    return genHelpText(t, MAX_TITLE_CHARACTERS.fbTitle);
  }, [form]);

  // Actions
  const validateTitle = React.useCallback((_rule, value, callback) => {
    let error = null;
    if (!value) {
      error = "Product title is required.";
    } else if (value.length > MAX_LENGTH) {
      error = TITLE_LENGTH_MESS;
    }
    error ? callback(error) : callback();
  }, []);

  const countActivatedVariants = React.useCallback((variants) => {
    let countActivated = 0;
    _.forOwn(variants, function (el, key) {
      if (
        el.productBaseVariant.attributes &&
        el.productBaseVariant.attributes.length > 0
      ) {
        if (!el.disabled) {
          countActivated++;
        }
      }
    });
    return countActivated;
  }, []);

  const handleChangeAttrOption = React.useCallback(
    (attrSlug, optionSlug, data, productBaseVariants) => {
      let newProductAttributes = state.productAttributes;
      let disabledVal =
        newProductAttributes[attrSlug]["options"][optionSlug]["disabled"];
      newProductAttributes[attrSlug]["options"][optionSlug]["disabled"] =
        !disabledVal;
      let countAttrs = Object.keys(newProductAttributes).length;

      // Get list active options of every attribute
      let attrActivatedOptions = {};
      if (countAttrs > 1) {
        _.forOwn(newProductAttributes, function (el, key) {
          let optionActivated = [];
          _.forOwn(el.options, function (v) {
            if (!v.disabled) {
              optionActivated.push(v.label);
            }
          });
          attrActivatedOptions[key] = optionActivated;
        });
      }

      let newProductBaseVariants = state.variantsState
        ? state.variantsState
        : productBaseVariants;
      let affectKeys = [];
      _.forOwn(newProductBaseVariants, function (el, key) {
        if (
          el.productBaseVariant.attributes &&
          el.productBaseVariant.attributes.length > 0
        ) {
          el.productBaseVariant.attributes.forEach((v) => {
            if (v.slug === attrSlug && v.option === data.label) {
              let pushVal = {
                key: key,
                disabled: el.disabled,
              };
              affectKeys.push(pushVal);

              let newDisabledVar = !disabledVal;
              // If product has more than 2 attributes, then check complex condition active variants.
              if (countAttrs > 1 && disabledVal) {
                // Only check case active attribute.
                if (
                  el.productBaseVariant &&
                  el.productBaseVariant.attributes &&
                  el.productBaseVariant.attributes.length > 0
                ) {
                  let countMatch = 0;
                  el.productBaseVariant.attributes.forEach((at) => {
                    let atSlug = at.slug;
                    let atOption = at.option;
                    let attrOptionsActivated = attrActivatedOptions[atSlug];
                    if (
                      attrOptionsActivated.length > 0 &&
                      attrOptionsActivated.includes(atOption)
                    ) {
                      countMatch++;
                    }
                  });
                  if (countMatch !== countAttrs) {
                    newDisabledVar = disabledVal;
                  }
                }
              }
              return (el.disabled = newDisabledVar);
            }
          });
        }
      });

      // Count product to prevent disable all variants
      let countActivated = countActivatedVariants(newProductBaseVariants);
      if (0 === countActivated) {
        newProductAttributes[attrSlug]["options"][optionSlug]["disabled"] =
          disabledVal;
        if (affectKeys.length > 0) {
          affectKeys.forEach(function (v, k) {
            newProductBaseVariants[v.key].disabled = v.disabled;
          });
        }
        alert("You must keep at least one option for each attributes");
      }
      setState({
        productAttributes: newProductAttributes,
        variantsState: newProductBaseVariants,
        isPrompt: false,
      });

      if (form) {
        form.setFieldsValue({
          variants: newProductBaseVariants,
        });
      }
    },
    [state.productAttributes, state.variantsState, countActivatedVariants],
  );

  const countState = React.useCallback((variantsState) => {
    let counEnabled = 0;
    let counDisabled = 0;
    variantsState &&
      variantsState.length &&
      variantsState.forEach((val) => {
        if (!val.disabled) {
          counEnabled++;
        } else {
          counDisabled++;
        }
      });
    return {
      counEnabled,
      counDisabled,
    };
  }, []);

  const getProductVariants = React.useCallback(
    (productBase, product = {}, enableFirst, firstColor) => {
      if (!productBase.variants) {
        return [];
      }

      return productBase.variants.map((variant, idx) => {
        let disabledVal = true;
        if (
          variant.attributes &&
          variant.attributes.length &&
          enableFirst &&
          firstColor
        ) {
          variant.attributes.forEach((att) => {
            if (
              att.slug &&
              "color" === att.slug &&
              att.option &&
              att.option.toLowerCase() === firstColor.toLowerCase()
            ) {
              disabledVal = false;
            }
          });
        }
        let currentBaseVarID = variant.id;
        let i = idx;
        if (Object.keys(product).length) {
          i = _.findIndex(product && product.variants, {
            productBaseVariantID: currentBaseVarID,
          });
        }
        return {
          productBaseVariantId: variant.id,
          productBaseVariant: variant,
          image: null,
          regularPrice: Object.keys(product).length
            ? product.variants[i].regularPrice
            : variant.regularPrice,
          salePrice: Object.keys(product).length
            ? product.variants[i].salePrice
            : variant.salePrice,
          disabled:
            enableFirst && firstColor
              ? disabledVal
              : Object.keys(product).length
              ? product.variants[i].disabled
              : variant && variant.disabled
              ? variant.disabled
              : false,
        };
      });
    },
    [],
  );

  const handleUpdatePrice = React.useCallback(
    (productBaseVariants) => {
      let value = state.labels;
      let newProductBaseVariants = state.variantsState
        ? state.variantsState
        : productBaseVariants;

      _.forOwn(value, (el) => {
        let label = el && el.label;
        let newRegularPrice = el && el.regularPrice;
        let newSalePrice = el && el.salePrice;
        _.forOwn(newProductBaseVariants, (v) => {
          if (
            v.productBaseVariant &&
            v.productBaseVariant.attributes &&
            v.productBaseVariant.attributes.length
          ) {
            _.forOwn(v.productBaseVariant.attributes, (att) => {
              if (att.option === label) {
                v.regularPrice = newRegularPrice
                  ? newRegularPrice
                  : v.regularPrice;
                v.salePrice = newSalePrice ? newSalePrice : v.salePrice;
              }
            });
          }
        });
      });

      setState({
        variantsState: newProductBaseVariants,
      });

      if (!state.isDrap) {
        newProductBaseVariants =
          newProductBaseVariants &&
          newProductBaseVariants.sort(
            (a, b) =>
              a.productBaseVariant.sorting - b.productBaseVariant.sorting,
          );
      }

      if (form) {
        form.setFieldsValue({
          variants: newProductBaseVariants,
        });
      }
    },
    [state.labels, state.variantsState, state.isDrap],
  );

  const showProductVariants = React.useCallback(() => {
    setState({
      productVariantsVisible: true,
    });
  }, []);

  const handleCloseVariants = React.useCallback(async () => {
    const sortVariant = state.sortVariant;
    let newVariant =
      sortVariant && sortVariant.enabledValues && sortVariant.disabledValues
        ? [...sortVariant.enabledValues, ...sortVariant.disabledValues]
        : [];

    let currenVariant = form ? form.getFieldValue("variants") : "";
    setState({
      productVariantsVisible: false,
      variantsState: newVariant.length > 0 ? newVariant : state.variantsState,
    });

    await sleep(250);

    if (form) {
      form.setFieldsValue({
        variants: newVariant.length > 0 ? newVariant : currenVariant,
      });
    }
  }, [state.sortVariant, state.variantsState]);

  const getProductBaseAttrs = React.useCallback(
    (productBaseAttrs, activateAttrs, variants) => {
      let pBaseAttrs = {};

      let disabledAll = false;
      if (variants) {
        let countActivated = countActivatedVariants(variants);
        if (0 === countActivated) {
          disabledAll = true;
        }
      }

      if (productBaseAttrs && productBaseAttrs.length > 0) {
        productBaseAttrs.forEach((val) => {
          let attrOptions = {};
          let enableFirst = !activateAttrs && !variants && "color" === val.slug;
          val.options.forEach((opt) => {
            let disableVal = false;
            if (disabledAll) {
              disableVal = true;
            }
            if (
              activateAttrs &&
              activateAttrs[val.slug] &&
              activateAttrs[val.slug].length > 0
            ) {
              disableVal = true;
              if (_.includes(activateAttrs[val.slug], opt)) {
                disableVal = false;
              }
            }
            if (enableFirst) {
              let firstValue = _.head(val.options);
              if (opt === firstValue) {
                disableVal = false;
              } else {
                disableVal = true;
              }
            }
            attrOptions[strToSlug(opt)] = {
              label: opt,
              disabled: disableVal,
            };
          });
          pBaseAttrs[val.slug] = {
            name: val.name,
            slug: val.slug,
            options: attrOptions,
          };
        });
      }
      return pBaseAttrs;
    },
    [],
  );

  const getActivatedProductAttrs = React.useCallback((variants) => {
    let activatedAttrs = {};

    _.forOwn(variants, function (el, key) {
      if (
        !el.disabled &&
        el.productBaseVariant &&
        el.productBaseVariant.attributes &&
        el.productBaseVariant.attributes.length > 0
      ) {
        el.productBaseVariant.attributes.forEach((v) => {
          if (!activatedAttrs[v.slug]) {
            activatedAttrs[v.slug] = [];
          }
          activatedAttrs[v.slug].push(v.option);
        });
      }
    });
    return activatedAttrs;
  }, []);

  const onVariableDisabledChanged = React.useCallback(
    (variants) => {
      if (state.currentProductBase && state.currentProductBase.attributes) {
        let activatedAttrs = getProductBaseAttrs(
          state.currentProductBase.attributes,
          getActivatedProductAttrs(variants),
          variants,
        );
        setState({
          productAttributes: activatedAttrs,
        });
      }
      setState({
        isPrompt: false,
      });
    },
    [state.currentProductBase, getProductBaseAttrs, getActivatedProductAttrs],
  );

  const showNotification = React.useCallback((err) => {
    let uniqueMessage = {};
    _.each(err, (e) => {
      _.each(_.get(e, "errors", []), (er) => {
        const msg = _.trim(er ? er.toLowerCase() : null);
        if (typeof uniqueMessage[msg] === "undefined") {
          notification.error({ message: handleError(er) });
        }
        uniqueMessage[msg] = true;
      });
    });
  }, []);

  const onFinish = React.useCallback(
    (values) => {
      values = JSON.parse(JSON.stringify(values));
      ["collections", "tags"].forEach((name) => {
        if (values[name] && values[name].length) {
          values[name] = values[name].map((v) => v.id);
        }
      });

      values.mockup = values.mockup.map((m) => {
        return {
          fileId: m.id,
          productBaseId: values.productBaseId,
        };
      });

      // Unique fileId
      values.mockup = getUnique(values.mockup, "fileId");

      if (values.designPositions && values.designPositions.length) {
        values.designPositions = values.designPositions
          .map((v) => {
            if (undefined === v.fileId) {
              v["fileId"] = v.file && v.file.id ? v.file.id : null;
            }
            if ("" === v.fileId) {
              delete v["fileId"];
            }
            const designPositionId = _.get(v, "designPosition.id", null);
            if (designPositionId == null) return null;
            return {
              fileId: v.fileId,
              designPositionId,
            };
          })
          .filter(Boolean);
      }

      let psdFileIds = [];
      if (values.psdFiles && values.psdFiles.length > 0) {
        let inputPsdFiles = values.psdFiles;
        inputPsdFiles.forEach((v) => {
          if (v && v.id && v.id.length > 0) {
            psdFileIds.push(v.id);
          }
        });
      }
      if (psdFileIds.length > 0) {
        values.psdFiles = psdFileIds;
      }

      if (!values.variants) {
        if (state.variantsState) {
          values["variants"] = state.variantsState;
        }
      }

      if (values.variants && values.variants.length) {
        values.variants = values.variants.map((v) => {
          [
            "__typename",
            "productBaseVariant",
            "sku",
            "sorting",
            "productBaseVariantID",
          ].forEach((name) => {
            if (typeof v[name] !== "undefined") {
              delete v[name];
            }
          });
          if (v.image) {
            v.image = v.image.id;
          }
          if (v.amazonImages?.length > 0) {
            v.amazonImages = v.amazonImages
              .map((i) => (i ? (i.file ? i.file.id : i.id) : null))
              .filter(Boolean);
          }
          return v;
        });
      }

      // Sort base
      values["sortBases"] = [
        { productBaseId: values.productBaseId, position: 0 },
      ];

      values.productBases = [values.productBaseId];
      delete values.productBaseId;

      if (values.pushToStores) {
        values.pushToStores = values.pushToStores.map((s) => s.id);
      }

      if (state.isChanged && store) {
        values.pushToStores = store && store.id !== "all" ? [store] : null;
      }

      if (values?.fields?.length > 0) {
        values.fields = values.fields.map(({ id, ...item }) => item);
      }

      onSubmit(values);
    },
    [onSubmit, state.variantsState, state.isChanged, store],
  );

  const handleValueChange = React.useCallback(() => {
    setState({ isPrompt: false });
  }, []);

  const productAttrs = state.productAttributes;
  // const findPB = productBases.find((pb) => pb.id === productBase.id);
  const showVariants =
    productBase && productBase.variants && productBase.variants.length;

  const productBaseVariants = getProductVariants(
    productBase,
    {},
    true,
    state.firstColor,
  );

  let count = countState(
    state.variantsState ? state.variantsState : productBaseVariants,
  );

  const max = state.maxUpdate ? state.maxUpdate : productBase.regularPrice;

  const fulfillmentId = React.useMemo(() => {
    return _.get(productBase, 'fulfillment.fulfillmentId') || ''
  }, [JSON.stringify(productBase)])

  return (
    <React.Fragment>
      <Prompt
        when={false === state.isPrompt}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Container>
        <Form
          {...layout}
          form={form}
          scrollToFirstError
          onFinishFailed={({ _values, errorFields }) =>
            showNotification(errorFields)
          }
          onChange={() => {
            setState({ isPrompt: false });
          }}
          initialValues={{
            title: value?.title || undefined,
            shortTitle: value?.shortTitle || undefined,
            amzTitle: value?.amzTitle || undefined,
            description: value?.description || productBase.defaultContent,
            productBaseId: value?.productBaseId || null,
            collections:
              value?.collections?.length > 0 ? value.collections : [],
            tags: value?.tags?.length > 0 ? value.tags : [],
            variants: value?.variants?.length > 0 ? value.variants : [],
            regularPrice: value ? value.regularPrice : null,
            salePrice: value ? value.salePrice : null,
            personalized: value ? value.personalized : false,
            fields: value?.fields?.length > 0 ? value.fields : [],
            designPositions:
              value?.designPositions?.length > 0
                ? value.designPositions.map(({ file, ...item }) => item)
                : [],
            mockup: [],
            pushToStores: store && store.id !== "all" ? [store] : null,
            updateProductStore: false,
            psd: null,
            psdFiles: null,
            hasSetIgnoredIsFalse: true,
          }}
          onFinish={async (values) => {
            // Validate file design
            const dpRef = designPositionRef.current;
            if (!objectInvalid(dpRef)) {
              const dimensions = dpRef?.state?.dimensions || {};
              const error = validateDimensions({
                dimensions,
              });

              if (error != null) {
                notification.error({ message: error.message });
                return;
              }
            }

            setState({ isPrompt: true });
            handleUpdatePrice(productBaseVariants);

            values.variants = form.getFieldValue("variants");
            let { mainImgId } = state;

            const curMockups = values.mockup || [];
            const [firstImg] = curMockups;
            if (!mainImgId) {
              if (firstImg != null) {
                mainImgId = firstImg.id;
              }
            }
            values["mainImageId"] = mainImgId;

            // Check amazon title
            const prevAmzTitle = _.get(value, "amzTitle");
            const productID = _.get(value, "id", undefined);
            const { amzTitle } = values || {};
            if (amzTitle && amzTitle !== prevAmzTitle) {
              const { __apolloClient__: client } = window;
              if (!client) return;

              const { data } = await client.query({
                query: GET_PRODUCT_BY_TITLE,
                variables: {
                  title: amzTitle,
                  whichField: WHICH_FIELD.AmzTitle,
                  productID,
                },
              });
              if (data?.getProductsByTitle?.total > 0) {
                notification.error({
                  message: AMZ_MESSAGE_ERROR,
                  duration: 3000,
                });
                setState({
                  isPrompt: false,
                });
                return;
              }
            }

            onFinish(values);
          }}
        >
          <PageTitle subTitle="Products" title={"Clone Product"} />
          <Row gutter={16}>
            <Col span={18}>
              <Card>
                <Form.Item
                  name="title"
                  label="Title"
                  help={titleHelp || undefined}
                  rules={[
                    {
                      required: true,
                      validator: validateTitle,
                    },
                  ]}
                >
                  <Input placeholder="Product title" />
                </Form.Item>
                <Form.Item
                  name="shortTitle"
                  label="Facebook Title"
                  help={fbTitleHelp || undefined}
                >
                  <Input
                    maxLength={MAX_TITLE_CHARACTERS.fbTitle}
                    placeholder="Enter facebook title"
                  />
                </Form.Item>
                <Form.Item
                  name="amzTitle"
                  label="Amazon Title"
                  help={aTitleHelp || undefined}
                >
                  <Input
                    maxLength={MAX_TITLE_CHARACTERS.amzTitle}
                    placeholder="Enter amazon title"
                  />
                </Form.Item>
                <Form.Item name="description" label="Description">
                  {/* <Wysiwyg onChange={handleValueChange} /> */}
                  <JoditEditor onChange={handleValueChange} />
                </Form.Item>
              </Card>

              <Card title="Organization">
                <Form.Item
                  name="collections"
                  label="Collections"
                  rules={[
                    { required: true, message: "Collections is required" },
                  ]}
                >
                  <CollectionsAutoComplete onChange={handleValueChange} />
                </Form.Item>
                <Form.Item
                  name="tags"
                  label="Tags"
                  rules={[{ required: true, message: "Tags is required" }]}
                >
                  <TagsAutoComplete onChange={handleValueChange} />
                </Form.Item>
              </Card>

              <Card>
                {/* <Form.Item
                  name="productBaseId"
                  label="Product Base"
                  rules={[
                    { required: true, message: "Product base is required" },
                  ]}
                >
                  <Select disabled={!!value}>
                    {undefined === findPB && (
                      <Select.Option
                        value={productBase.id}
                        key={productBase.id}
                        data={productBase}
                      >
                        {productBase.title}
                      </Select.Option>
                    )}

                    {productBases.map((base, index) => (
                      <Select.Option value={base.id} key={index} data={base}>
                        {base.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item> */}
                <Form.Item
                  name="productBaseId"
                  label="Product Base"
                  rules={[
                    { required: true, message: "Product base is required" },
                  ]}
                >
                  <ProductBaseSelect
                    filter={{ fulfillmentId }}
                    productBase={productBase}
                    disabled={!!value}
                  />
                </Form.Item>
              </Card>

              <Card title="Product Attributes" hidden={!showVariants}>
                {productAttrs &&
                  Object.keys(productAttrs).map((attribute, index) => (
                    <div key={`attribute-${attribute}`}>
                      <div className={"attribute-title"}>
                        {productAttrs[attribute] &&
                          productAttrs[attribute].name}
                        :
                      </div>
                      <div style={{ marginBottom: 12 }}>
                        {productAttrs[attribute] &&
                          productAttrs[attribute]["options"] &&
                          Object.keys(productAttrs[attribute]["options"]).map(
                            (option) => {
                              let enableAttributes =
                                "color" === productAttrs[attribute].slug;

                              let currentOption;
                              if (enableAttributes) {
                                currentOption =
                                  productAttrs[attribute]["options"] &&
                                  productAttrs[attribute]["options"][option];
                              }

                              let optionSlug =
                                currentOption &&
                                strToSlug(currentOption["label"]);

                              let optionDisabled =
                                currentOption && currentOption["disabled"];

                              let optionLabel =
                                currentOption && currentOption["label"];

                              if (optionLabel) {
                                let hasFlag =
                                  optionLabel.match("/") &&
                                  optionLabel.split("/");
                                if (hasFlag && hasFlag.length) {
                                  let value = hasFlag.filter((v) => v.trim());
                                  if (1 === value.length) {
                                    optionLabel = value;
                                  }
                                }
                              }

                              return (
                                <div
                                  style={{ display: "inline-block" }}
                                  key={`option-${option}`}
                                >
                                  <Tag
                                    key={`option-${option}`}
                                    className={
                                      enableAttributes ? `tag-wrap` : ""
                                    }
                                    style={{
                                      color:
                                        enableAttributes && optionDisabled
                                          ? "#1890ff"
                                          : "",

                                      cursor: "pointer",
                                      marginTop: 8,
                                      padding: "3px 8px",
                                    }}
                                    color={
                                      productAttrs[attribute]["options"][
                                        option
                                      ]["disabled"]
                                        ? ""
                                        : "#1890ff"
                                    }
                                    onClick={() => {
                                      handleChangeAttrOption(
                                        attribute,
                                        option,
                                        productAttrs[attribute]["options"][
                                          option
                                        ],
                                        productBaseVariants,
                                      );
                                    }}
                                  >
                                    {enableAttributes && (
                                      <ColorsComponent
                                        optionSlug={optionSlug}
                                      />
                                    )}
                                    {enableAttributes
                                      ? optionLabel
                                      : productAttrs[attribute]["options"] &&
                                        productAttrs[attribute]["options"][
                                          option
                                        ] &&
                                        productAttrs[attribute]["options"][
                                          option
                                        ]["label"]}
                                  </Tag>
                                </div>
                              );
                            },
                          )}
                      </div>
                    </div>
                  ))}
                <div className={"variant-total mt-6"}>
                  {count && count.counDisabled >= 0 && (
                    <div>
                      Total enabled: <span>{count.counEnabled}</span>
                    </div>
                  )}
                  {count && count.counDisabled >= 0 && (
                    <div>
                      Total disabled: <span>{count.counDisabled}</span>
                    </div>
                  )}
                </div>
                {productAttrs &&
                  Object.keys(productAttrs).forEach((attribute) => {
                    if (
                      productAttrs[attribute] &&
                      "size" === productAttrs[attribute].slug
                    ) {
                      let labels = [];
                      productAttrs[attribute]["options"] &&
                        Object.keys(productAttrs[attribute]["options"]).forEach(
                          (option) => {
                            labels.push({
                              label:
                                productAttrs[attribute]["options"][option] &&
                                productAttrs[attribute]["options"][option][
                                  "label"
                                ],
                              regularPrice: null,
                              salePrice: null,
                            });
                          },
                        );

                      return (
                        <UpdatePriceForVariants
                          key={`labels-${attribute}`}
                          labels={state.labels ? state.labels : labels}
                          onChange={(value) => setState({ labels: value })}
                          onSubmit={() =>
                            handleUpdatePrice(productBaseVariants)
                          }
                        />
                      );
                    }
                  })}

                <Button
                  onClick={() => {
                    showProductVariants();
                    handleUpdatePrice(productBaseVariants);
                  }}
                >
                  View All Variants
                </Button>

                <Modal
                  title="Product Variants"
                  visible={state.productVariantsVisible}
                  onOk={handleCloseVariants}
                  onCancel={handleCloseVariants}
                  width={980}
                  className="modal-variants-wrap"
                >
                  <Form.Item name={"variants"}>
                    <ProductVariants
                      productAttrs={JSON.stringify(productAttrs || {})}
                      onChange={handleValueChange}
                      onVariableDisabledChanged={onVariableDisabledChanged}
                      sortVariant={({
                        enabledValues,
                        disabledValues,
                        isDrap,
                      }) => {
                        setState({
                          sortVariant: {
                            enabledValues,
                            disabledValues,
                          },
                          isPrompt: false,
                          isDrap: isDrap,
                        });
                      }}
                    />
                  </Form.Item>
                </Modal>
              </Card>

              <Card title={"Pricing"} hidden={showVariants}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name={"regularPrice"} label={"Regular Price"}>
                      <Price
                        onChange={(value) => {
                          setState({ regularPrice: value, isPrompt: false });
                        }}
                        onPressEnter={() => setState({ disabled: false })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name={"salePrice"} label={"Sale Price"}>
                      <Price
                        disabled={state.disabled}
                        max={state.regularPrice || max}
                        onChange={handleValueChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col span={6}>
              <Card style={{ marginBottom: 15 }} title={"Push Product"}>
                <Form.Item name={"pushToStores"}>
                  <PushProduct
                    store={store && store.id !== "all" ? store : null}
                    checked={store && store.id !== "all" ? !!store : false}
                    onChange={() => {
                      setState({ isChanged: true, isPrompt: false });
                    }}
                  />
                </Form.Item>
              </Card>
              <Card title={"Print Files"}>
                <Form.Item name={"designPositions"}>
                  <ProductPrintFiles
                    uploadFolder="undefined"
                    isProductForm
                    ref={designPositionRef}
                    onChange={(value) => {
                      setState({
                        designPositions: value,
                        isPrompt: false,
                      });
                    }}
                  />
                </Form.Item>
              </Card>

              <Card>
                <Form.Item
                  label="Mockup"
                  name={"mockup"}
                  rules={[
                    {
                      required: true,
                      message: "Please select at least one image",
                    },
                  ]}
                >
                  <MediaSelectorButton
                    prefix="mockups/"
                    folder={UPLOAD_FOLDER.MOCKUPS}
                    multiple={true}
                    listType={"picture-card"}
                    buttonType="primary"
                    accept={"image/*"}
                    onChange={handleValueChange}
                    isMockup
                    fileList={state.mockup || []}
                    hasDefaultMockup
                    onChangeMainImg={(value) =>
                      setState({
                        mainImgId: value,
                        isPrompt: false,
                      })
                    }
                  />
                </Form.Item>
              </Card>
              <Card>
                <Form.Item label="Design Resource Files" name={"psdFiles"}>
                  <MediaSelectorButton
                    prefix={UPLOAD_FOLDER.PSD_RESOURCE + "/"}
                    folder={UPLOAD_FOLDER.PSD_RESOURCE}
                    multiple={true}
                    listType={"picture-card"}
                    buttonType="primary"
                    accept={"image/*"}
                    value={null}
                    onChange={handleValueChange}
                  />
                </Form.Item>
              </Card>

              <Card>
                <Form.Item label="Design Resource Urls" name="psd">
                  <Input.TextArea rows={4} />
                </Form.Item>
              </Card>
            </Col>

            <Col span={24}>
              <Card title={"Personalized"}>
                <Form.Item name={"personalized"} valuePropName="checked">
                  <Checkbox
                    onChange={(e) => {
                      setState({
                        showPreview: e.target.checked,
                      });
                    }}
                  >
                    This is a personalized product
                  </Checkbox>
                </Form.Item>
                <div>
                  <Form.Item
                    name={"fields"}
                    rules={[
                      {
                        required: state.showPreview,
                        message: "Personalized is required.",
                      },
                    ]}
                  >
                    <CustomizeButton
                      showPreview={state.showPreview}
                      onChange={handleValueChange}
                    />
                  </Form.Item>
                </div>
              </Card>
              <Form.Item style={{ marginTop: 20, textAlign: "right" }}>
                <Button
                  onClick={() => {
                    if (null === state.isPrompt ? false : !state.isPrompt) {
                      Modal.confirm({
                        title: "Cancel all unsaved changes?",
                        icon: <ExclamationCircleOutlined />,
                        content: "If Ok, you’ll delete any edits you made.",
                        onOk() {
                          history.push(`/${currentParam}/products`);
                        },
                        cancelText: "Continue",
                      });
                    } else {
                      history.push(`/${currentParam}/products`);
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="ml-4"
                  type="primary"
                  icon={<LegacyIcon type={"save"} />}
                  loading={loading}
                  htmlType={"submit"}
                  disabled={null === state.isPrompt ? true : state.isPrompt}
                >
                  {"Publish Product"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  );
}

/**
 * Utils
 */
const strToSlug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

const Container = styled.div`
  .ant-card {
    margin-bottom: 20px;
  }
  .tag-wrap {
    border-radius: 3px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .product-fake-wrap {
    .ant-tabs-nav-wrap {
      padding-left: 0;
    }
    .ant-tabs-tab {
      padding-left: 0;
      max-width: 180px;
      white-space: break-spaces;
      text-align: left;
      padding-right: 12px;
    }
    .ant-tabs-ink-bar {
      height: auto;
    }
    .ant-tabs-bar {
      height: 400px;
    }
    .ant-tabs-content {
      height: 400px;
      overflow-y: scroll;
    }
    .product-fake-item {
      label {
        display: inline-block;
      }
      &.files {
        width: 65%;
      }
      & + .product-fake-item {
        margin-top: 1rem;
      }
    }
  }
`;

export { ProductForm };
