import { DataTable, Link, Button, Toast } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useAppContext } from "../../../context";
import { handleError, objectInvalid, getParamByRole, checkRole } from "../../../helper";
import SuspenseComp from "../../shared/SuspenseComp";
import EmptyStatePolaris from "../../shared/EmptyStatePolaris";
import SkeletonPage from "../../shared/SkeletonPagePolaris";
import ElementScrollable from "../../shared/ElementScrollable";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import ProductTemplateActions from './ProductTemplateActions';

import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const GET_TIKTOK_PRODUCT_TEMPLATES = gql`
	query getTiktokProductTemplates($filter: GetTiktokProductTemplateFilter!) {
		getTiktokProductTemplates(filter: $filter) {
			total
			nodes {
				id
				createdAt
				title
				createdBy {
					id
					firstName
					lastName
				}
			}
		}
	}
`;

const HEADINGS = [
	"ID",
	"Title",
	"Created At",
	"Author",
	"Actions",
];

export default function ProductTemplatesTable(props) {
	let currentFilters = props.filters;

	const { currentUser } = useAppContext();
	const param = getParamByRole(currentUser);
	
	const [activeToast, setActiveToast] = useState(false);
	const [toastMessage, setToastMessage] = useState({});
	const [pushProducts, setPushProducts] = useState([]);
	const [aggregation, setAggregation] = useState({});
	

	const handleActionCompleted = async(data) => {
		let successMessage = (data && data.type && 'success' == data.type && data.message) ? data.message : null;
		let failMessage = (data && data.type && 'fail' == data.type && data.message) ? data.message : null;
		setToastMessage({
			success_message: successMessage,
			error_message: failMessage,
		});
		setActiveToast(true);

		try {
			await refetch();
		} catch (err) {
			console.error("Error refreshing data:", err);
		}
	}

	const getRows = (nodes) => {
		const res = [];
		for (let node of nodes) {
			if (objectInvalid(node)) continue;
			let {
				id,
				title,
				createdAt,
				createdBy,
			} = node;

			createdAt = moment(createdAt).format("YYYY-MM-DD HH:mm:ss");
			
			let detailUrl = `/${param}/tiktok-hub/product-templates/${id}`;

			const { isStoreManager } = checkRole(currentUser);
			let disableActions = (isStoreManager && currentUser?.id && createdBy?.id && currentUser.id !== createdBy.id) ? true : false;
			const item = [
				// Field ID
				<div className="id item">
					<Link url={detailUrl} children={id} />
				</div>,

				// Field title
				<div className="title item">
					<Link url={detailUrl} children={title} />
				</div>,

				<div className="created-at item">
					<span>{createdAt}</span>
				</div>,

				<div className="author item">
					<span>{`${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`}</span>
				</div>,

				<div className="actions item">
					<ProductTemplateActions disableAction={disableActions} item={node} onActionCompleted={handleActionCompleted}/>
				</div>,
			];
	
			res.push(item);
		}
		return res;
	}

	const [paged, setPaged] = useState(1);
	const [perPage, setPerPage] = useState(currentFilters?.limit || 20);
	const { loading, error, data, refetch } = useQuery(GET_TIKTOK_PRODUCT_TEMPLATES, {
		variables: {
			filter: {
				limit: perPage,
				offset: currentFilters?.offset || 0,
				search: currentFilters?.search || null,
				order: currentFilters?.order || 'DESC',
				orderBy: currentFilters?.orderBy || 'created_at',
			}
		},
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		let nodes = data?.getTiktokProductTemplates?.nodes;
		if (nodes) {
			setPushProducts(nodes);
		} else {
			setPushProducts([]);
		}

		let total = data?.getTiktokProductTemplates?.total;
		let totalPages = 1;
		if (total) {
			totalPages = Math.ceil(total / perPage);
		}

		let agg = {
			page: paged,
			totalPage: totalPages ?? 1,
			offset: currentFilters?.offset ?? 0,
			limit: perPage,
			onChange: onPaginationChanged,
			total: total,
		};
		
		setAggregation(agg);
	}, [data]);

	
	const rows = React.useMemo(() => getRows(pushProducts), [pushProducts]);

	const onPaginationChanged = (offset, limit) => {
		let newPaged = Math.floor(offset / limit) + 1;
		setPaged(newPaged);
		setPerPage(limit);
	}

	const ToastMessage = activeToast ? (
        <Toast
			content={ toastMessage.success_message }
			error={ toastMessage.error_message  }
			duration={5000}
			onDismiss={() => setActiveToast(false)}
			/>
		)
    : null;

	return (
		<Wrapper>
			{ToastMessage}
			<SuspenseComp fallback={<SkeletonPage />}>
				{loading ? (
					<SkeletonPage />
				) : error ? (
					<div>Error: {handleError(error?.toString())}</div>
				) : pushProducts?.length > 0 ? (
					<>
						<ElementScrollable fixedColumn>
							<DataTable
								rows={rows}
								columnContentTypes={[
									...Array.from({ length: 10 }).fill("text"),
									"numeric",
								]}
								headings={HEADINGS}
								verticalAlign="middle"
								hideScrollIndicator
								footerContent={
									<PaginationPolaris aggregation={aggregation} showTotal />
								}
							/>
						</ElementScrollable>
					</>
				) : (
					<EmptyStatePolaris />
				)}
			</SuspenseComp>
		</Wrapper>
	);
}

export const Wrapper = styled.div`
	overflow-x: auto;

	.Polaris-DataTable__Table {
		overflow-x: inherit;
	}

	.Polaris-DataTable__Cell--firstColumn {
		width: 100px;
	}

	.id {
		width: 100px;
	}
	.item {
		white-space: normal;
		word-break: break-word;
	}

	.title {
		width: 230px;
	}

	.author,
	.created-at{
		width: 150px;
	}

	.Polaris-DataTable__Cell:last-child {
		text-align: right;
	}
`;
