import React from "react";
import styled from "styled-components";

function TagContainer({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;

  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

export { TagContainer };
