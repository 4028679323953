import React, { useState, useEffect } from "react";
import { TextField, FormLayout, Tag, Autocomplete } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { TagContainer } from "../../shared/TagContainer";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import styled from "styled-components";
import _ from "lodash";

const GET_TIKTOK_ATTRIBUTES = gql`
	query getFirstStoreTiktokAttributesByCategory($categoryID: String!) {
		getFirstStoreTiktokAttributesByCategory(categoryID: $categoryID) {
			id
			name
			type
			isCustomizable
			isMultipleSelection
			isRequried
			values {
				id
				name
			}
		}
	}
`;

const Container = styled.div`
	.box-flex {
		display: flex;
		flex-wrap: nowrap;
		gap: 20px;
		margin-bottom: 15px;
	}

	.box-flex-50 {
		width: 50%;
	}
`;

const TiktokStaticAttributes = ({
	categoryID,
	defaultValues = [],
	onAttributeChange,
}) => {
	const { loading, error, data } = useQuery(GET_TIKTOK_ATTRIBUTES, {
		variables: { categoryID },
	});

	const [selectedValues, setSelectedValues] = useState({});
	const [customValues, setCustomValues] = useState({});
	const [customTempValues, setCustomTempValues] = useState({});
	const [searchQuery, setSearchQuery] = useState("");

	// Initialize selected values and custom tags with defaultValues (if provided)
	useEffect(() => {
		if (!defaultValues || _.isEmpty(defaultValues)) {
			return;
		}
		const initializeValues = () => {
			const selectedVals = {};
			const customValuesVals = {};

			defaultValues.forEach((defaultValue) => {
				selectedVals[defaultValue.id] =
					defaultValue.values?.map((val) => val.id) || [];
				customValuesVals[defaultValue.id] =
					defaultValue.custom_values || [];
			});

			setSelectedValues(selectedVals);
			setCustomValues(customValuesVals);
		};

		if (defaultValues.length > 0) {
			initializeValues();
		}
	}, [defaultValues]);

	// Handle loading and error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	const attributes = data.getFirstStoreTiktokAttributesByCategory;

	// Handle autocomplete selection change
	const handleAutocompleteChange = (id, selected) => {
		setSelectedValues((prev) => {
			const newValues = { ...prev, [id]: selected };
			// Notify parent about the change
			if (onAttributeChange)
				onAttributeChange(getUpdatedAttributes(newValues, customValues));
			return newValues;
		});
	};

	// Handle search query input change
	const handleSearchQueryChange = (query) => {
		setSearchQuery(query);
	};

	// Filter attributes with type 'PRODUCT_PROPERTY'
	const productAttributes = attributes
		.filter((attribute) => attribute.type === "PRODUCT_PROPERTY")
		.sort((a, b) => {
			if (a.isRequried && !b.isRequried) return -1;
			if (!a.isRequried && b.isRequried) return 1;
			if (a.isCustomizable && !b.isCustomizable) return -1;
			if (!a.isCustomizable && b.isCustomizable) return 1;
			return 0;
		});

	// Filter the values based on the search query
	const filterOptions = (options) => {
		if (!searchQuery) return options;
		return options.filter((option) =>
			option.label.toLowerCase().includes(searchQuery.toLowerCase())
		);
	};

	// Function to structure the updated attributes with both selected and custom values
	const getUpdatedAttributes = (selectedValues, customValues) => {
		return productAttributes.map((attribute) => {
			const customValueList = customValues[attribute.id] || [];
			const selectedOptionIds = selectedValues[attribute.id] || [];

			return {
				id: attribute.id,
				name: attribute.name,
				type: attribute.type,
				values: attribute.values
					.filter((value) => selectedOptionIds.includes(value.id))
					.map((value) => ({
						id: value.id,
						name: value.name,
					})),
				isRequried: attribute.isRequried,
				custom_values: customValueList,
				isCustomizable: attribute.isCustomizable,
				isMultipleSelection: attribute.isMultipleSelection,
			};
		});
	};

	// Add custom value to the list when Enter is pressed
	const handleAddCustomValue = (attributeId, value) => {
		setCustomTempValues((prev) => ({
			...prev,
			[attributeId]: value,
		}));

		if (value.includes(',')) { // If detect ',' then add this value
			let cleanValue = value.replaceAll(",", "");
			if (cleanValue.length == 0) {
				return;
			}

			setCustomTempValues((prev) => ({
				...prev,
				[attributeId]: null,
			}));

			setCustomValues((prev) => {
				let currentCustomValues = prev[attributeId] || [];
				
				currentCustomValues.push(cleanValue);
				return {
					...prev,
					[attributeId]: currentCustomValues,
				}
			});

			if (onAttributeChange) {
				onAttributeChange(getUpdatedAttributes(selectedValues, customValues));
			}
		}
	};

	return (
		<FormLayout>
			<Container>
				{productAttributes.map((attribute) => {
					const hasValues = attribute.values && attribute.values.length > 0;

					return (
						<div key={attribute.id}>
							<div className="box-flex">
								<div className="box-flex-50">
									<ComponentLabelPolaris
										label={attribute?.name}
										required={attribute?.isRequried ?? false}
									/>
									<Autocomplete
										options={
											hasValues
												? filterOptions(
														attribute.values.map(
															(value) => ({
																label: value.name,
																value: value.id,
															})
														)
												  )
												: []
										}
										selected={selectedValues[attribute.id] || []}
										onSelect={(selected) =>
											handleAutocompleteChange(
												attribute.id,
												selected
											)
										}
										allowMultiple={attribute.isMultipleSelection}
										textField={
											<TextField
												value={searchQuery}
												onChange={handleSearchQueryChange}
												placeholder="Search options..."
											/>
										}
									/>
									{selectedValues[attribute.id]?.length > 0 && (
										<TagContainer>
											{selectedValues[attribute.id].map((valueId) => {
												const value = attribute.values.find(
													(val) => val.id === valueId
												);
												return (
													<Tag
														key={valueId}
														onRemove={() =>
															handleAutocompleteChange(
																attribute.id,
																selectedValues[
																	attribute.id
																].filter(
																	(id) =>
																		id !==
																		valueId
																)
															)
														}
													>
														{value.name}
													</Tag>
												);
											})}
										</TagContainer>
									)}
								</div>

								<div className="box-flex-50">
									{attribute.isCustomizable && (
										<>
											<TextField
												label={"Custom values"}
												value={customTempValues[attribute.id] || ""}
												onChange={(value) => handleAddCustomValue(attribute.id, value)}
												placeholder="Enter custom values (comma to confirm)"
											/>
											<TagContainer>
												{(customValues[attribute.id] || []).map((tag, index) => (
													<Tag
														key={index}
														onRemove={() => {
															setCustomValues((prev) => {
																const updatedTags = prev[attribute.id]?.filter(
																	(t) => t !== tag
																) || [];

																const updatedState = {
																	...prev,
																	[attribute.id]: updatedTags,
																};

																if (onAttributeChange) {
																	onAttributeChange(
																		getUpdatedAttributes(
																			selectedValues,
																			updatedState
																		)
																	);
																}
																return updatedState;
															});
														}}
													>
														{tag}
													</Tag>
												))}
											</TagContainer>
										</>
									)}
								</div>
							</div>
						</div>
					);
				})}
			</Container>
		</FormLayout>
	);
};

export default TiktokStaticAttributes;