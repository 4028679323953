import React, { createContext, useContext, useMemo } from "react";
import { get } from "lodash";
import { useQuery } from "@apollo/react-hooks";

import { GET_TIKTOK_CRAWL_PRODUCT_PUSHED_TO_STORE } from "../../../graphql/queries";

const ProductCrawlContext = createContext(null);

export function ProductCrawlProvider({ children, data: source }) {
  const ids = getProductCrawlIds(source);

  const { data } = useQuery(GET_TIKTOK_CRAWL_PRODUCT_PUSHED_TO_STORE, {
    variables: {
      productIDs: ids,
    },
  });

  const bag = useMemo(
    () => getProductCrawlPushes(data),
    [JSON.stringify(getProductCrawlPushes(data))],
  );

  return (
    <ProductCrawlContext.Provider value={bag}>
      {children}
    </ProductCrawlContext.Provider>
  );
}

export function useProductCrawlCtx() {
  return useContext(ProductCrawlContext);
}

function getProductCrawlPushes(data) {
  return get(data, "getTiktokCrawlProductPushedToStore");
}

function getProductCrawlIds(data) {
  const nodes = get(data, "getTiktokCrawlProducts.nodes") || [];
  return Array.from(new Set(nodes.map((i) => get(i, "id")).filter(Boolean)));
}
