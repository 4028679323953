import { Mutation, Query } from "@apollo/react-components";
import { Button, notification } from "antd";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { Component } from "react";

import { ProductTemplatesPolaris } from "../../components/product/templates/ProductTemplatesPolaris";
import AddProductFormV2 from "../../components/seller/AddProductFormV2";
import ProductbaseSelectV2 from "../../components/seller/ProductBaseSelectV2";
import { getProductBaseVariants } from "../../components/seller/utils";
import PageTitle from "../../components/shared/PageTitle";
import { AppContext } from "../../context";
import { productBaseFragment } from "../../fragments/index";
import { getParam, handleError } from "../../helper";
import history from "../../history";

export const ADD_PRODUCT_MUTATION = gql`
  mutation createProduct($input: NewProduct!) {
    createProduct(input: $input) {
      id
      title
      sku
      description
      designStatus
      personalized
      status
      sku
      productBases {
        id
        title
      }
      images {
        id
        productBaseId
        file {
          id
          url
          thumbnailUrl
        }
      }
      tags {
        id
        name
      }
      collections {
        id
        name
      }
      fields {
        id
        title
        name
        type
        extraFee
        configure
        sorting
      }
    }
  }
`;

const TEMPLATE_BY_ID = gql`
  query getTemplateByID($id: ID!) {
    getTemplateByID(id: $id) {
      id
      name
      slug
      grouping
      defaultMockupIds
      data {
        personalized
        productBases {
          ...ProductBasse
        }
        ProductBaseIds
        fields {
          name
          title
          type
          extraFee
          configure
          sorting
        }
        products {
          title
          description
          productbaseId
          shortDescription
          tagIds
          tags {
            id
            name
          }
          variants {
            salePrice
            regularPrice
            productBaseVariantID
            disabled
          }
          collectionIds
          collections {
            id
            name
          }
        }
      }
    }
  }
  ${productBaseFragment}
`;

class AddProductPageV2 extends Component {
  static contextType = AppContext;
  state = {
    selectedProductBase: null,
    step: 1,
    loading: false,
    fulfillmentId: null,
    template: null,
  };

  componentDidUpdate(_, prevState) {
    let { loading } = this.state;
    let { handleLoading } = this.props;
    if (loading !== prevState.loading) {
      if (handleLoading) {
        handleLoading(loading);
      }
    }
  }

  preLoadDesignPositionByBase = async (base) => {
    if (!base) return base;
    const { __apolloClient__: client } = window || {};
    if (!client) return base;
    const { id } = base;

    const values = await getProductBaseVariants([id]);
    let newBase = { ...base };
    if (values && values[id]) {
      newBase = { ...newBase, ...values[id] };
    }
    return newBase;
  };

  render() {
    let { handleBtnSave, clickSave, match } = this.props;
    let { step } = this.state;
    const { id } = match.params;

    const { currentUser } = this.context;
    let currentParam = getParam(currentUser);

    return (
      <Query
        query={TEMPLATE_BY_ID}
        variables={{ id: id }}
        onCompleted={(res) => {
          if (id && res) {
            this.setState({
              template: res.getTemplateByID,
              selectedProductBase: _.head(
                res.getTemplateByID.data.productBases,
              ),
              step: 2,
            });
          }
        }}
        skip={!id}
      >
        {() => {
          return (
            <>
              {step === 1 && (
                <div>
                  <PageTitle
                    title={"Add Product"}
                    subTitle={"Products"}
                    link={`/${currentParam}/products`}
                    action={
                      <Button
                        type="primary"
                        onClick={async () => {
                          if (this.state.step === 1) {
                            if (!this.state.selectedProductBase) {
                              notification.error({
                                message: "Please select product base!",
                              });
                              return;
                            }

                            const newBase =
                              await this.preLoadDesignPositionByBase(
                                this.state.selectedProductBase,
                              );
                            this.setState({
                              step: 2,
                              selectedProductBase: newBase,
                            });
                          }
                        }}
                      >
                        Continue
                      </Button>
                    }
                  />
                  <ProductbaseSelectV2
                    onFulfillmentChange={(v) => {
                      this.setState({ fulfillmentId: v });
                    }}
                    fulfillmentId={this.state.fulfillmentId}
                    selectedProductBases={this.state.selectedProductBase}
                    onChange={(selected) => {
                      this.setState({
                        selectedProductBase: selected ? selected[0] : null,
                      });
                    }}
                    onViewTemplates={() => {
                      this.setState({
                        step: 3,
                      });
                    }}
                    multiple={false}
                  />
                </div>
              )}
              {step === 2 && (
                <React.Fragment>
                  <Mutation
                    onError={(e) => {
                      notification.error({
                        message: handleError(e.toString()),
                      });
                      this.setState({ loading: false });
                    }}
                    onCompleted={(res) => {
                      const product = res.createProduct;
                      notification.success({
                        message: "Product has been saved!",
                      });
                      history.push(
                        `/${currentParam}/products/edit/${product.id}`,
                      );
                    }}
                    mutation={ADD_PRODUCT_MUTATION}
                  >
                    {(createProduct, { client }) => (
                      <AddProductFormV2
                        apolloClient={client}
                        loading={this.state.loading}
                        currentParam={currentParam}
                        onSubmit={(values) => {
                          this.setState({ loading: true }, () => {
                            createProduct({
                              variables: {
                                input: values,
                              },
                            });
                          });
                        }}
                        fulfillmentId={this.state.fulfillmentId}
                        productBase={this.state.selectedProductBase}
                        template={this.state.template}
                        onViewTemplates={() => {
                          this.setState({
                            step: 3,
                          });
                        }}
                        onChangeProductBase={(p) =>
                          this.setState({ selectedProductBase: p })
                        }
                        clickSave={clickSave}
                        handleBtnSave={(value, aggregations) => {
                          aggregations = {
                            ...aggregations,
                            step: this.state.step,
                            link: "/seller/products",
                          };
                          if (handleBtnSave) {
                            handleBtnSave(value, aggregations);
                          }
                        }}
                      />
                    )}
                  </Mutation>
                </React.Fragment>
              )}
              {step === 3 && (
                <ProductTemplatesPolaris
                  onChangeStep={() =>
                    this.setState({
                      step: 1,
                    })
                  }
                  handleUseTemplate={(template) => {
                    this.setState({
                      selectedProductBase: _.head(template.data.productBases),
                      step: 2,
                      template: template,
                    });
                  }}
                />
              )}
            </>
          );
        }}
      </Query>
    );
  }
}

export default AddProductPageV2;
