import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React from "react";

import { DISABLED_AUTO_MAP_ORDER_LATER } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export default function DisabledAutoMap({ open, onClose, value, refetch }) {
  const { id } = value;

  // Context
  const { toggleToast, setNotify } = useToastContext();

  const timeoutRef = React.useRef(null);
  const [disabledAutoMap, { loading }] = useMutation(
    DISABLED_AUTO_MAP_ORDER_LATER,
    {
      onCompleted: () => {
        setNotify({ msg: "Disabled auto map Order later success", err: false });
        if (refetch) {
          timeoutRef.current && clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(refetch, 1500);
        }
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );

  const handleSubmit = React.useCallback(() => {
    if (!id) return;

    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });
    disabledAutoMap({
      variables: {
        orderIDs: [id],
      },
    }).finally(() => {
      onClose && onClose();
    });
  }, [id, disabledAutoMap, setNotify, toggleToast]);

  return (
    <Modal
      title="Disabled auto map Order later"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <p>Are you sure to disabled auto map for the Order later?</p>
    </Modal>
  );
}
