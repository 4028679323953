import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import _ from "lodash";

const Container = styled.div`
	.box-flex {
		display: flex;
		flex-wrap: nowrap;
		gap: 20px;
		margin-bottom: 15px;
	}

	.box-flex-20 {
		width: 20%;
	}
`;

export default function TiktokSizeChartImage({ defaultValue, onChange }) {
	const [sizeChart, setSizeChart] = useState(defaultValue && !_.isEmpty(defaultValue) && defaultValue?.id ? [defaultValue] : []);

	useEffect(() => {
		setSizeChart(defaultValue && !_.isEmpty(defaultValue) && defaultValue?.id ? [defaultValue] : []);
	}, [JSON.stringify(defaultValue)]);

	const handleMediaChange = (files) => {
		setSizeChart(files);
	};

	useEffect(() => {
		if (onChange) {
			onChange(sizeChart);
		}
	}, [JSON.stringify(sizeChart)]);

	return (
		<Container>
			<div className="box-flex">
				<div className="box-flex-20">
					<ComponentLabelPolaris
						label={`Size chart image`}
					/>
					<MediaSelectorButtonPolaris
						accept={"image/*"}
						singleUpload={true}
						multiple={false}
						value={sizeChart ?? []}
						onChange={(files) =>
							handleMediaChange(files)
						}
					/>
				</div>
			</div>
		</Container>
	);
}
