import { gql } from "apollo-boost";
import React from "react";
import { arrInvalid, objectInvalid, reducerFn } from "../../../helper";
import { useCallbackRef } from "../../../hooks/useCallbackRef";
import { useCustomQuery } from "../../../hooks/useCustomQuery";

function useStores(filter = {}, fragment = "", skip) {
  const filterStr = JSON.stringify(filter);
  const queryOptions = React.useMemo(() => {
    const filter = JSON.parse(filterStr);

    return {
      variables: { filter },
      skip,
      query: gql`
        query stores($filter: StoreFilter) {
          stores(filter: $filter) {
            ${fragment}
          }
        }
      `,
    };
  }, [filterStr, fragment, skip]);

  return useCustomQuery({
    queryOptions,
    path: "data.stores",
  });
}

async function getStoreMissing(storeIds, currentIds, fragment) {
  if (arrInvalid(storeIds) || arrInvalid(currentIds)) return [];

  let idsMiss = [];
  for (let id of currentIds) {
    if (!storeIds.includes(id)) {
      idsMiss.push(id);
    }
  }
  if (arrInvalid(idsMiss)) return [];

  async function getStore(ids) {
    const { __apolloClient__: client } = window;
    if (!client) return;

    try {
      const args = [];
      const queries = [];
      const variables = {};
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        args.push(`$id__${i}: ID!`);
        queries.push(`
          storeById__${i}: storeById(id: $id__${i}) {
            ...store
          }
        `);
        variables[`id__${i}`] = id;
      }

      const q = `
        query storeById(${args.join(",")}) {
          ${queries.join("\n")}
        }
    `;

      const query = gql`
        fragment store on Store {
          ${fragment}
        }
       ${q}
      `;

      const res = await client.query({
        query: query,
        variables: variables,
      });

      if (!objectInvalid(res?.data)) {
        return Object.values(res.data);
      }
    } catch (err) {}
    return [];
  }

  return await getStore(idsMiss);
}

function useStoresFilter(value, skip, fragment = "id title", filterProp) {
  const [state, setState] = React.useReducer(reducerFn, {
    options: [],
    selectedOptions: [],
  });

  const [filter, setFilter] = React.useReducer(reducerFn, {
    limit: 20,
    offset: 0,
    search: "",
    ...(filterProp ? filterProp : {}),
  });

  const { data, loading, error } = useStores(
    React.useMemo(() => filter, Object.values(filter)),
    `nodes{${fragment}}`,
    skip,
  );

  React.useEffect(() => {
    if (objectInvalid(data)) return;

    const options = getOptions(data.nodes || []);
    setState({ options });
  }, [data]);

  const optionsStr = JSON.stringify(state.options);
  React.useEffect(() => {
    let options = JSON.parse(optionsStr);
    if (arrInvalid(options) || arrInvalid(value)) return;

    (async function () {
      const ids = options.map(({ value }) => value);
      const optionsOther = await getStoreMissing(ids, value, fragment);
      const optionsOtherFormat = getOptions(optionsOther);
      options = [...options, ...optionsOtherFormat];
      const selectedOptions = options.filter(({ value: val }) =>
        value.includes(val),
      );

      setState({ options, selectedOptions });
    })();
  }, [optionsStr, value, fragment]);

  return [
    React.useMemo(
      () => ({
        loading,
        error,
        options: state.options,
        selectedOptions: state.selectedOptions,
      }),
      [loading, error, state.options, state.selectedOptions],
    ),
    useCallbackRef(setFilter),
  ];
}

function getOptions(arr) {
  if (arrInvalid(arr)) return [];

  return arr.map((item) => {
    const { id, title } = item || {};
    return {
      value: id,
      label: title,
      data: item,
    };
  });
}

export { useStores, getStoreMissing, useStoresFilter };
