import { ActionList, Button, Popover, Modal, TextContainer } from "@shopify/polaris";
import { getParamByRole } from "../../../helper";
import React, { useState, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useAppContext } from "../../../context";
import { useHistory } from "react-router-dom";
const _ = require('lodash');


const DELETE_SELECTED_PUSHES = gql`
	mutation deleteSelectedTiktokPushes($ids: [ID!]) {
		deleteSelectedTiktokPushes(ids: $ids)
	}
`;
const PAUSE_SELECTED_PUSHES = gql`
	mutation pauseSelectedTiktokPushes($ids: [ID!]) {
		pauseSelectedTiktokPushes(ids: $ids)
	}
`;

const RESUME_SELECTED_PUSHES = gql`
	mutation resumeSelectedTiktokPushes($ids: [ID!]) {
		resumeSelectedTiktokPushes(ids: $ids)
	}
`;

export default function PushActions({item, onActionCompleted}) {
	const history = useHistory();
	const [open, setOpen] = useState(false);
	const [actionInModal, setActionInModal] = useState({});
	const [modalActionLoading, setModalActionLoading] = useState(false);
	const [activeRow, setActiveRow] = useState(null);

	const { currentUser } = useAppContext();
	const param = getParamByRole(currentUser);

	const handleAction = (action, item) => {
		console.log(`Action: ${action}`, item);
		switch(action) {
			case 'edit':
				let itemDetailUrl = `/${param}/tiktok-hub/push-products/${item?.id}`;
				history.push(itemDetailUrl);
				break
			case 'pause':
			case 'resume':
			case 'delete':
				setOpen(true);
				setActionInModal({
					action,
					item
				});
				break;
		}
		setActiveRow(null); // Close the popover
	};

	const renderActions = useCallback((item) => {
		let actions = [{
			content: 'Edit push',
			onAction: () => handleAction('edit', item),
		}];
		if (item.status != 'Done' && !item.paused) {
			actions.push({
				content: 'Pause push',
				onAction: () => handleAction('pause', item),
			});
		}
		if (item.status != 'Done' && item.paused) {
			actions.push({
				content: 'Resume push',
				onAction: () => handleAction('resume', item),
			});
		}
		actions.push({
			content: 'Delete push',
			onAction: () => handleAction('delete', item),
		});

		return (
			<Popover
				active={activeRow === item.id}
				activator={
					<Button disclosure onClick={() => {
						setActiveRow(prevActiveRow => {
							let newActiveRow = (prevActiveRow === item.id ? null : item.id);
							return newActiveRow;
						});
					}}>Actions</Button>
				}
				onClose={() => setActiveRow(null)}
			>
				<ActionList items={actions} />
			</Popover>
		)
	}, [activeRow]);

	const handleModalChange = useCallback(() => setOpen((open) => !open), []);

	const [deleteSelectedPushes, {}] = useMutation(DELETE_SELECTED_PUSHES);
	const [pauseSelectedPushes, {}] = useMutation(PAUSE_SELECTED_PUSHES);
	const [resumeSelectedPushes, {}] = useMutation(RESUME_SELECTED_PUSHES);
	let validActions = {
		delete: deleteSelectedPushes,
		pause: pauseSelectedPushes,
		resume: resumeSelectedPushes,
	}

	const modalActionLabels = {
		pause: {
			heading: 'Pause this push: #' + (item?.id || ''),
			label: 'Pause this push',
			confirmText: 'Are you sure to pause the selected push?',
		},
		resume: {
			heading: 'Resume this push: #' + (item?.id || ''),
			label: 'Resume this push',
			confirmText: 'Are you sure to resume the selected push?',
		},
		delete: {
			heading: 'Delete this push: #' + (item?.id || ''),
			label: 'Delete this push',
			confirmText: 'Are you sure to delete the selected push?',
		},
	}

	return (
		<>
			{renderActions(item)}

			<Modal
				title={modalActionLabels?.[actionInModal?.action]?.heading}
				open={open}
				onClose={handleModalChange}
				primaryAction={{
					content: modalActionLabels?.[actionInModal?.action]?.label,
					loading: modalActionLoading,
					onAction: () => {
						setModalActionLoading(true);

						let selectedItem = actionInModal?.item
						if (selectedItem) {
							switch (actionInModal?.action) {
								case 'pause':
								case 'resume':
								case 'delete':
									validActions[actionInModal.action]({
										variables: { ids: [selectedItem.id] },
									})
									.then((res) => {
										setModalActionLoading(false);
										if (res && onActionCompleted) {
											onActionCompleted({
												type: 'success',
												message: _.startCase(actionInModal?.action) + ' processed successfully',
											});
										}
									}).catch((err) => {
										setModalActionLoading(false);
										if (onActionCompleted) {
											onActionCompleted({
												type: 'fail',
												message: _.startCase(actionInModal?.action) + ' failed to process',
											});
										}
									}).finally(() => {
										setOpen(false);
									});
									break;
							}
						}
					}
				}}
				secondaryActions={[
					{
						content: "Cancel",
						onAction: handleModalChange,
					},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<div dangerouslySetInnerHTML={{ __html: modalActionLabels?.[actionInModal.action]?.confirmText }} />
					</TextContainer>
				</Modal.Section>
			</Modal>
		</>
	);
}