import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Card, Checkbox, Heading, Stack } from "@shopify/polaris";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import noImg from "../../../../assets/images/unnamed.jpeg";
import useToggle from "../../../../hooks/useToggle";
import { AttributesByBase } from "../../../product/print-file/Create/BasesSection";
import { useCreateIdea } from "../context/createIdea";
import { SelectProducts } from "./SelectProducts";
import { ViewVariants } from "./ViewVariants";
import { FIELDS_REQUIRED } from "./Layout";
import { checkStatusTask, mapSubmission } from "../utils";
import { PrintFile } from "./PrintFile";
import { useAppContext } from "../../../../context";
import { ReviewTaskInner } from "./actions/ReviewTaskInner";
import { checkRole } from "../../../../helper";
import { ProductTitle } from "./ProductTitle";
import { ProductTaxonomy } from "./ProductTaxonomy";
import { ProductDescription } from "./ProductDescription";
import { ProductHeader } from "./ProductHeader";
import { RejectReason } from "./RejectReason";
import { BaseTitle } from "./BaseTitle";
import { ProductAdditionalInfo } from "./ProductAdditionalInfo";
import { ProductPersonalized } from "./ProductPersonalized";
import { FBTitle } from "./ProductFBTitle";
import { AMZTitle } from "./ProductAmzTitle";
import { ProductDesignResources } from "./ProductDesignResources";
import { getProductBaseVariants } from "../../../seller/utils";

const GET_BASE_BY_ID = gql`
  query productBaseByID($id: ID!) {
    productBaseByID(id: $id) {
      id
      title
      images {
        id
        name
        url
        thumbnailUrl
      }
      attributes {
        name
        slug
        options
      }
      variants {
        id
        sorting
        attributes {
          name
          slug
          option
        }
      }
      designPositions {
        id
        name
        description
        artworkGuidelines {
          id
          file {
            id
            url
            mimeType
          }
        }
      }
      mockupTemplates {
        id
        originTemplateID
        isDefault
      }
    }
  }
`;

export const ProductItem = forwardRef(function ProductItem(
  {
    item,
    index,
    setErrorRoot,
    handleActiveKeyChange,
    status,
    assignee,
    taskId,
    taskValue,
    onCloseParent,
    isClone,
    activeKey,
  },
  ref,
) {
  // Props
  const {
    title,
    description,
    collectionIds,
    tagIds,
    bases,
    isCampaign,
    isPriority,
    shortTitle,
    amzTitle,
  } = item || {};
  const base = bases?.length > 0 ? bases[0] : null;

  // Context
  const {
    duplicateProduct,
    removeProduct,
    updateProduct,
    isUpdate,
    updateBase,
    setChanged,
    forceUpdate,
    toggleForceUpdate,
  } = useCreateIdea();

  const { currentUser } = useAppContext();
  const {
    isSeller,
    isStoreManager,
    isDesignLeader,
    isDesigner,
    isIdea,
    isIdeaLeader,
  } = checkRole(currentUser);
  const isAdminSeller = isSeller || isStoreManager;

  const isRoleIdea = isIdea || isIdeaLeader;
  const isTeamLeader = isAdminSeller || isDesignLeader || isIdeaLeader;
  const isCreator = isAdminSeller || isStoreManager || isRoleIdea;

  // State
  const [errors, setErrors] = useState({});
  const updateTimeout = useRef(null);
  const updateBaseRef = useRef(null);
  const updateBaseRef2 = useRef(null);

  // Actions
  const validateFields = useCallback(
    (value, id) => {
      let error = null;
      let label = FIELDS_REQUIRED[id];

      if (label) {
        if ((!value || !value.length) && label) {
          error = `${label} is required.`;
        }

        setErrors((prev) => ({ ...prev, [id]: error }));
        setErrorRoot((prev) => ({
          ...prev,
          [index]: { ...prev[index], [id]: error },
        }));
      }
    },
    [setErrorRoot, index],
  );

  useEffect(() => {
    if (item != null) {
      const fieldsRequired = {};
      fieldsRequired.title = item.title;
      fieldsRequired.collectionIds = item.collectionIds;
      fieldsRequired.tagIds = item.tagIds;

      for (let [key, value] of Object.entries(fieldsRequired)) {
        validateFields(value, key);
      }
    }
  }, [item, validateFields]);

  const handleSubmit = useCallback(() => {
    const fieldsRequired = {};
    fieldsRequired.title = item.title;
    fieldsRequired.collectionIds = item.collectionIds;
    fieldsRequired.tagIds = item.tagIds;

    for (let [key, value] of Object.entries(fieldsRequired)) {
      validateFields(value, key);
    }
  }, [item, validateFields]);

  useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit,
  }));

  const handleUpdate = useCallback(
    (newValue) => {
      setChanged && setChanged(true);
      updateProduct(index, newValue);
    },
    [updateProduct, index, setChanged],
  );

  const handleChange = useCallback(
    (value, id) => {
      handleUpdate({ [id]: value });
    },
    [handleUpdate],
  );

  const handleIsPersonalizedChange = useCallback(
    (value) => handleUpdate({ isPersonalized: value }),
    [handleUpdate],
  );

  const handleFieldsChange = useCallback(
    (value) => handleUpdate({ fields: value }),
    [handleUpdate],
  );

  const handleCollectionChange = useCallback(
    (value) => {
      handleUpdate({ collectionIds: value });
    },
    [handleUpdate],
  );
  const handleTagChange = useCallback(
    (value) => {
      handleUpdate({ tagIds: value });
    },
    [handleUpdate],
  );

  const handleDuplicate = useCallback(() => {
    const length = duplicateProduct(index);
    const key = Math.max(0, length - 1);
    handleActiveKeyChange(`product-${key}`);
  }, [index, duplicateProduct, handleActiveKeyChange]);

  const handleDelete = useCallback(() => {
    removeProduct(index);
    handleActiveKeyChange(`product-${Math.max(0, index - 1)}`);
  }, [index, removeProduct, handleActiveKeyChange]);

  const handleDeleteBase = useCallback(
    (baseId) => {
      const indexBase = [...bases].findIndex((b) => b.id === baseId);
      if (indexBase !== -1) {
        bases.splice(indexBase, 1);
      }
      setChanged && setChanged(true);
      updateProduct(index, { bases });

      if (updateTimeout.current) clearTimeout(updateTimeout.current);
      updateTimeout.current = setTimeout(() => {
        toggleForceUpdate(true);
      }, 100);
    },
    [bases, index, updateProduct, toggleForceUpdate, setChanged],
  );

  const handleAttributesChange = useCallback(
    (value) => {
      if (value == null) return;
      updateProduct(index, { bases: value.selectedBases });

      if (updateTimeout.current) clearTimeout(updateTimeout.current);
      updateTimeout.current = setTimeout(() => {
        updateBaseRef2.current && updateBaseRef2.current.update();
      }, 500);
    },
    [index, updateProduct],
  );

  const handleUpdateBase = useCallback(
    (base, first) => {
      if (!base) return;
      const baseId = base.id;

      let curBase = [...bases].find((i) => i.id === baseId);
      if (curBase != null) {
        // First = get value from request baseById
        let newDP = mapSubmission(
          curBase.baseConfigId,
          curBase.taskBaseSubmissions,
          base.designPositions,
          first,
        );

        const dpsGen = (newDP || []).map((d) => ({
          ...d,
          file: d.generateMockupFile,
        }));

        curBase = {
          ...curBase,
          variants: base.variants,
          attributes: base.attributes,
          title: base.title,
          images: base.images,
          mockupTemplates: base.mockupTemplates,
          designPositions: newDP || base.designPositions,
          designPositionsForGen: dpsGen,
          customMockups: base.customMockups,
        };
      }

      if (!first) {
        setChanged && setChanged(true);
      }
      updateBase(index, curBase);

      if (updateTimeout.current) clearTimeout(updateTimeout.current);
      updateTimeout.current = setTimeout(() => {
        if (isCampaign) {
          toggleForceUpdate(true);
          return;
        }

        updateBaseRef.current && updateBaseRef.current.update();
      }, 500);
    },
    [updateBase, bases, index, toggleForceUpdate, isCampaign, setChanged], // updateProduct
  );

  // Markup
  const {
    isDoing,
    isPending,
    isDenied,
    isDraft,
    isDeclined,
    isDone,
    isRejected,
  } = checkStatusTask(status);
  const { isNeedReview: isNeedReviewItem } = checkStatusTask(item?.status);
  const hideBtnRemove = (isAdminSeller || isDesignLeader) && isNeedReviewItem;

  // const statusPendingOrEmpty =
  const statusChangeField =
    isPending || status == null || isDraft || isClone || isDeclined;
  const noDoingOrPending = statusChangeField || isDoing;

  const canUpdate = (isAdminSeller || isRoleIdea) && statusChangeField;
  const itemStatusNull = item?.status == null;

  // Show fields such as title, description, collections, tags
  const showFields = isCreator && !isNeedReviewItem;
  const titleMarkup = showFields && (
    <ProductTitle
      canEdit={statusChangeField || itemStatusNull}
      value={title}
      onChange={handleChange}
      error={errors["title"]}
    />
  );

  const fbTitleMarkup = showFields && (
    <FBTitle
      value={shortTitle}
      onChange={handleChange}
      canEdit={statusChangeField || itemStatusNull}
    />
  );

  const amzTitleMarkup = showFields && (
    <AMZTitle
      value={amzTitle}
      onChange={handleChange}
      canEdit={statusChangeField || itemStatusNull}
    />
  );

  const descriptionMarkup = showFields && (
    <ProductDescription
      value={description}
      onChange={(value) => handleChange(value, "description")}
      canEdit={statusChangeField || itemStatusNull}
    />
  );

  const taxonomyMarkup = showFields && (
    <ProductTaxonomy
      canEdit={statusChangeField || itemStatusNull}
      collectionIds={collectionIds}
      tagIds={tagIds}
      handleCollectionChange={handleCollectionChange}
      handleTagChange={handleTagChange}
      errors={errors}
    />
  );

  // Props
  const baseProps = {
    updateBaseRef2,
    updateBaseRef,
    handleUpdateBase,
    handleAttributesChange,
    isDoing,
    isUpdate,
    setChanged,
    taskId,
    isTeamLeader,
    item,
    isCreator,
  };

  const commonProps = {
    index,
    updateProduct,
    setChanged,
  };

  const assigned = assignee != null;
  const showPrintFile = !isDenied && assigned;
  // !isDenied && !isPending && assigned && !isAdminSeller;

  // hide print file when:
  //   - design leader: assigned && status pending
  //   - designer: status pending
  const hidePFDLeader =
    (isDesigner && assigned && isPending) || (isDesigner && isPending);

  const infoMarkup = [
    titleMarkup,
    fbTitleMarkup,
    amzTitleMarkup,
    descriptionMarkup,
    taxonomyMarkup,
    <div className="section-verticals">
      <div className="content">
        <Heading>
          <span style={{ margin: "0.5rem" }}>Product Base:</span>
        </Heading>
        <BaseInProduct
          base={base}
          bases={bases}
          isAdminSeller={isAdminSeller}
          isRoleIdea={isRoleIdea}
          canUpdate={canUpdate}
          hideBtnRemove={hideBtnRemove}
          hidePFDLeader={hidePFDLeader}
          {...baseProps}
          handleChange={handleChange}
          forceUpdate={forceUpdate}
          toggleForceUpdate={toggleForceUpdate}
        />
      </div>
    </div>,
  ]
    .filter(Boolean)
    .map((render, index) => {
      return <span key={`item-${index}`}>{render}</span>;
    });

  const canShow = isCreator && !noDoingOrPending && !itemStatusNull;
  const { isRejected: itemRejected } = checkStatusTask(item.status);
  const labelProductOrCampaign = isCampaign ? "campaign" : "product";

  return (
    <Wrapper>
      {isCreator && (statusChangeField || itemStatusNull) && (
        <ProductHeader
          haveActions={statusChangeField}
          handleDelete={handleDelete}
          handleDuplicate={handleDuplicate}
        />
      )}
      <Card.Section>
        <Stack vertical spacing="loose">
          {canShow && !isCampaign && (
            <div className="info-wrap">
              <div className="content">{infoMarkup}</div>
              <div className="content">
                {showPrintFile && !isCampaign && (
                  <PrintFile
                    base={base}
                    handleUpdateBase={handleUpdateBase}
                    item={item}
                    isAdminSeller={isAdminSeller}
                    hideBtnRemove={hideBtnRemove}
                    hideTitle
                    taskId={taskId}
                    isTeamLeader={isTeamLeader}
                    handleChange={({ clone, isCustom }) => {
                      handleChange(clone, "mainMockups");
                      handleChange(isCustom, "isMainCustomMockup");
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {!canShow && !isCampaign && titleMarkup}
          {!canShow && !isCampaign && fbTitleMarkup}
          {!canShow && !isCampaign && amzTitleMarkup}
          {!canShow && !isCampaign && descriptionMarkup}
          {isCampaign &&
            (isAdminSeller || isRoleIdea) &&
            (titleMarkup ||
            descriptionMarkup ||
            taxonomyMarkup ||
            fbTitleMarkup ||
            amzTitleMarkup ? (
              <Stack distribution="fillEvenly">
                <div>
                  <Stack vertical>
                    {titleMarkup}
                    {fbTitleMarkup}
                    {amzTitleMarkup}
                    {descriptionMarkup}
                  </Stack>
                </div>
                {taxonomyMarkup}
              </Stack>
            ) : null)}
          {isCampaign ? (
            <div>
              <div
                className={`bases-header-wrap have-print-file ${
                  canShow ? "has-attr" : ""
                }`}
              >
                <div className={`base-info${canShow ? "-wrap" : ""}`}>
                  <Heading children="Product bases:" />
                </div>
                {!statusChangeField && !isDoing && !itemStatusNull && (
                  <Heading children="Print file" />
                )}
              </div>
              <div className="product-base-wrap">
                {bases?.length > 0
                  ? [...bases].map((base, index, arr) => (
                      <BaseItem
                        base={{ ...base }}
                        key={`base-${index}`}
                        handleDelete={handleDeleteBase}
                        disabled={arr.length === 1}
                        bases={arr}
                        forceUpdate={forceUpdate}
                        toggleForceUpdate={toggleForceUpdate}
                        {...baseProps}
                        isAdminSeller={isAdminSeller}
                        isRoleIdea={isRoleIdea}
                        showPrintFile={showPrintFile}
                        item={item}
                        status={status}
                        canUpdate={canUpdate}
                        itemStatusNull={itemStatusNull}
                        hideBtnRemove={hideBtnRemove}
                        hidePFDLeader={hidePFDLeader}
                        handleChange={handleChange}
                        noDoingOrPending={noDoingOrPending}
                      />
                    ))
                  : null}
              </div>
              {(canUpdate || itemStatusNull) && (
                <AddMoreProducts
                  item={item}
                  index={index}
                  updateProduct={updateProduct}
                />
              )}
            </div>
          ) : (isAdminSeller || isRoleIdea) &&
            (noDoingOrPending || itemStatusNull) ? (
            <div className="section-vertical">
              <div className="content">
                <Heading children="Organization" />
                {taxonomyMarkup}
              </div>
              <div className="content">
                <Heading>
                  <span style={{ margin: "0.5rem" }}>Product Base:</span>
                  <BaseTitle base={base} />
                </Heading>
                <BaseInProduct
                  base={base}
                  bases={bases}
                  isAdminSeller={isAdminSeller}
                  isRoleIdea={isRoleIdea}
                  canUpdate={canUpdate}
                  itemStatusNull={itemStatusNull}
                  hideBtnRemove={hideBtnRemove}
                  hidePFDLeader={hidePFDLeader}
                  handleChange={handleChange}
                  forceUpdate={forceUpdate}
                  toggleForceUpdate={toggleForceUpdate}
                  {...baseProps}
                />
              </div>
            </div>
          ) : !isAdminSeller && !isRoleIdea ? (
            <div>
              <Heading children="Product base:" />
              <BaseInProduct
                isAdminSeller={isAdminSeller}
                isRoleIdea={isRoleIdea}
                base={base}
                handleUpdateBase={handleUpdateBase}
                isUpdate={isUpdate}
                hideBtnRemove={hideBtnRemove}
                hidePFDLeader={hidePFDLeader}
                setChanged={setChanged}
                taskId={taskId}
                isTeamLeader={isTeamLeader}
                item={item}
                handleAttributesChange={handleAttributesChange}
                isCreator={isCreator}
                handleChange={handleChange}
                forceUpdate={forceUpdate}
                toggleForceUpdate={toggleForceUpdate}
              />
            </div>
          ) : null}
          {(isAdminSeller || isRoleIdea) && !isNeedReviewItem && (
            <Stack vertical>
              <Checkbox
                label={`Prioritize this ${labelProductOrCampaign}`}
                checked={isPriority}
                id="isPriority"
                onChange={handleChange}
              />
              <ProductPersonalized
                item={item}
                canUpdate={canUpdate}
                handleFieldsChange={handleFieldsChange}
                handleIsPersonalizedChange={handleIsPersonalizedChange}
              />
            </Stack>
          )}
          <ProductDesignResources
            canUpdate={canUpdate}
            item={item}
            {...commonProps}
            designerCanView={isDone || isDoing || isRejected}
            taskDone={isDone}
          />
          <ProductAdditionalInfo
            canUpdate={canUpdate}
            item={item}
            {...commonProps}
          />
          {itemRejected && !isClone && <RejectReason bases={bases} />}
          {(isAdminSeller || isDesignLeader || isIdeaLeader) && !isClone && (
            <ReviewTaskInner
              item={item}
              taskId={taskId}
              isTeamLeader={isTeamLeader}
              taskValue={taskValue}
              onCloseParent={onCloseParent}
              activeKey={activeKey}
            />
          )}
        </Stack>
      </Card.Section>
    </Wrapper>
  );
});

function BaseInProduct({
  base,
  handleAttributesChange,
  bases,
  updateBaseRef,
  handleUpdateBase,
  updateBaseRef2,
  isAdminSeller,
  isRoleIdea,
  isUpdate,
  canUpdate,
  hideBtnRemove,
  itemStatusNull,
  hidePFDLeader,
  setChanged,
  taskId,
  isTeamLeader,
  item,
  isCreator,
  handleChange,
  forceUpdate,
  toggleForceUpdate,
}) {
  const id = base?.productBaseId;
  const { isNeedReview } = checkStatusTask(item?.status);

  // State
  const updateRef = useRef(false);
  const baseRef = useRef(base);
  baseRef.current = base;

  // Query
  const { data } = useQuery(GET_BASE_BY_ID, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (isUpdate && data?.productBaseByID != null && !updateRef.current) {
      const newBase = {
        ...baseRef.current,
        ...data.productBaseByID,
      };

      const variants = newBase.variants;
      const activeProductBaseVariantIds = newBase.activeProductBaseVariantIds;

      const newAttributes = {};
      const newVariants = (variants || []).map((item) => {
        const clone = Object.assign({}, item);

        const attributes = clone.attributes || [];
        for (let i = 0; i < attributes.length; i++) {
          const attr = attributes[i];
          const { slug, option, name } = attr || {};
          newAttributes[slug] =
            newAttributes[slug]?.options != null
              ? {
                  ...newAttributes[slug],
                  options: [...newAttributes[slug].options, option],
                }
              : { slug, name, options: [option] };
        }
        if (activeProductBaseVariantIds?.length > 0) {
          clone.disabled = !activeProductBaseVariantIds.includes(clone.id);
        }
        clone.productBaseVariant = { attributes: clone.attributes };
        clone.productBaseVariantId = clone.id;
        return clone;
      });

      newBase.variants = newVariants;
      newBase.attributes = Object.values(newAttributes);
      baseRef.current = newBase;

      handleUpdateBase({ ...newBase }, true);
      updateRef.current = true;
    }
  }, [data, handleUpdateBase, isUpdate]);

  const onUpdateBase = useCallback(
    (base) => {
      const newBase = {
        ...baseRef.current,
        ...base,
      };

      handleUpdateBase({ ...newBase });
    },
    [handleUpdateBase],
  );

  // return isAdminSeller || isRoleIdea ? (
  return (isAdminSeller || isRoleIdea) && !isNeedReview ? (
    <>
      <AttributesByBase
        base={base}
        updateState={handleAttributesChange}
        selectedBases={bases}
        ref={updateBaseRef}
        disabledAll={!canUpdate && !itemStatusNull}
        setChanged={setChanged}
        forceUpdate={forceUpdate}
        toggleForceUpdate={toggleForceUpdate}
      />
      {canUpdate && (
        <ViewAllVariants
          base={base}
          updateBase={onUpdateBase}
          updateBaseRef2={updateBaseRef2}
        />
      )}
    </>
  ) : (
    <BaseInner
      base={base}
      hideBtnRemove={hideBtnRemove}
      // hidePFDLeader={isNeedReview || hidePFDLeader}
      hidePFDLeader={hidePFDLeader}
      taskId={taskId}
      isTeamLeader={isTeamLeader}
      item={item}
      handleUpdateBase={handleUpdateBase}
      isCreator={isCreator}
      handleChange={handleChange}
    />
  );
}

function BaseInner({
  base,
  isCampaign,
  handleUpdateBase,
  item,
  status,
  hideBtnRemove,
  attrMarkup,
  hidePFDLeader = false,
  taskId,
  isTeamLeader,
  isCreator,
  handleChange,
}) {
  const images = base?.images;
  const image = images?.length > 0 ? images[0] : null;

  const url = image
    ? image.thumbnailUrl
      ? image.thumbnailUrl
      : image.url
      ? image.url
      : null
    : null;

  const hasAttr = attrMarkup ? "has-attr" : "";
  const baseTitle = <BaseTitle base={base} />;
  const baseInfo = (
    <div className="base-info">
      <img src={url || noImg} className="base-image" alt="" />
      {baseTitle}
    </div>
  );

  return isCampaign ? (
    <div className={`base-wrap have-print-file ${hasAttr}`}>
      {hasAttr ? (
        <div className="base-info-wrap">
          {baseInfo}
          {attrMarkup}
        </div>
      ) : (
        baseInfo
      )}
      <div className="design-position">
        {!hidePFDLeader && (
          <PrintFile
            base={base}
            item={item}
            handleUpdateBase={handleUpdateBase}
            status={status}
            hideBtnRemove={hideBtnRemove}
            hideTitle
            taskId={taskId}
            isTeamLeader={isTeamLeader}
            handleChange={({ clone, isCustom }) => {
              handleChange(clone, "mainMockups");
              handleChange(isCustom, "isMainCustomMockup");
            }}
          />
        )}
      </div>
    </div>
  ) : !isCreator ? (
    <div className={`base-wrap have-print-file`}>
      {baseInfo}
      <div className="design-position">
        {!hidePFDLeader && (
          <PrintFile
            base={base}
            item={item}
            handleUpdateBase={handleUpdateBase}
            status={status}
            hideBtnRemove={hideBtnRemove}
            hideTitle
            taskId={taskId}
            isTeamLeader={isTeamLeader}
            handleChange={({ clone, isCustom }) => {
              handleChange(clone, "mainMockups");
              handleChange(isCustom, "isMainCustomMockup");
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <div className="base-wrap">
      <img src={url || noImg} className="base-image" alt="" />
      {baseTitle}
    </div>
  );
}

// View all variants
function ViewAllVariants({ base, updateBase, updateBaseRef2 }) {
  // State
  const [open, toggleOpen] = useToggle(false);

  return (
    <Fragment>
      <Button children="View all variants" onClick={toggleOpen} size="slim" />
      <ViewVariants
        open={open}
        onClose={toggleOpen}
        base={base}
        updateBase={updateBase}
        ref={updateBaseRef2}
      />
    </Fragment>
  );
}

// Add more products in case: campaign
function AddMoreProducts({ item, updateProduct, index }) {
  // Props
  const selectedProductBases = item.bases;
  const fulfillmentId = item.fulfillmentId;
  const isCampaign = item.isCampaign;

  // State
  const [open, toggleOpen] = useToggle(false);
  const [state, setState] = useState({
    fulfillmentId,
    selectedProductBases,
  });

  // Get data
  useEffect(() => {
    setState((prev) => ({ ...prev, fulfillmentId, selectedProductBases }));
  }, [fulfillmentId, selectedProductBases]);

  // Actions
  const handleFulfillmentChange = useCallback((fulfillmentId) => {
    setState((prev) => ({ ...prev, fulfillmentId }));
  }, []);

  const handleChange = useCallback((selectedProductBases) => {
    setState((prev) => ({ ...prev, selectedProductBases }));
  }, []);

  const handleContinue = useCallback(async () => {
    // const selectedProductBases = state.selectedProductBases;
    // const fulfillmentId = state.fulfillmentId;
    // updateProduct(index, { bases: selectedProductBases, fulfillmentId });

    const baseIds = (state.selectedProductBases || [])
      .map((item) => item?.id)
      .filter(Boolean);

    const values = await getProductBaseVariants(baseIds);
    const newBaseSelected = (state.selectedProductBases || []).map((base) => {
      const { id } = base;
      if (values || values[id]) {
        base = {
          ...base,
          ...values[id],
        };
      }

      return base;
    });

    updateProduct(index, { bases: newBaseSelected, fulfillmentId });
  }, [state, updateProduct, index]);

  return (
    <div className="add-more">
      <Button children="Add More Products" onClick={toggleOpen} />
      <SelectProducts
        isCampaign={isCampaign}
        open={open}
        onClose={toggleOpen}
        handleContinue={handleContinue}
        handleFulfillmentChange={handleFulfillmentChange}
        handleChange={handleChange}
        fulfillmentId={state.fulfillmentId}
        selectedProductBases={state.selectedProductBases}
      />
    </div>
  );
}

function BaseItem({
  base,
  handleDelete,
  disabled,
  bases,
  handleAttributesChange,
  handleUpdateBase,
  updateBaseRef2,
  updateBaseRef,
  forceUpdate,
  toggleForceUpdate,
  isAdminSeller,
  isRoleIdea,
  isUpdate,
  showPrintFile,
  item,
  status,
  canUpdate,
  hideBtnRemove,
  noDoingOrPending,
  itemStatusNull,
  hidePFDLeader,
  setChanged,
  taskId,
  isTeamLeader,
  isCreator,
  handleChange,
}) {
  const baseId = base?.id;
  const images = base?.images;
  const image = images?.length > 0 ? images[0] : null;

  const url = image
    ? image.thumbnailUrl
      ? image.thumbnailUrl
      : image.url
      ? image.url
      : null
    : null;

  // State
  const [open, toggleOpen] = useToggle(false);
  const updateRef = useRef(false);
  const baseRef = useRef(base);

  // Query
  const { data } = useQuery(GET_BASE_BY_ID, {
    variables: {
      id: baseId,
    },
    skip: !baseId,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (isUpdate && data?.productBaseByID != null && !updateRef.current) {
      const newBase = {
        ...baseRef.current,
        ...data.productBaseByID,
      };

      const variants = newBase.variants;
      const activeProductBaseVariantIds = newBase.activeProductBaseVariantIds;

      const newAttributes = {};
      const newVariants = (variants || []).map((item) => {
        const attributes = item.attributes || [];
        for (let i = 0; i < attributes.length; i++) {
          const attr = attributes[i];
          const { slug, option, name } = attr || {};
          newAttributes[slug] =
            newAttributes[slug]?.options != null
              ? {
                  ...newAttributes[slug],
                  options: [...newAttributes[slug].options, option],
                }
              : { slug, name, options: [option] };
        }
        if (activeProductBaseVariantIds?.length > 0) {
          item.disabled = !activeProductBaseVariantIds.includes(item.id);
        }
        item.productBaseVariant = { attributes: item.attributes };
        item.productBaseVariantId = item.id;
        return item;
      });

      newBase.variants = newVariants;
      newBase.attributes = Object.values(newAttributes);
      baseRef.current = newBase;

      handleUpdateBase({ ...newBase }, true);
      updateRef.current = true;
    }
  }, [isUpdate, data, handleUpdateBase]);

  // Actions
  const onUpdateBase = useCallback(
    (base) => {
      const newBase = {
        ...baseRef.current,
        ...base,
      };

      handleUpdateBase({ ...newBase });
    },
    [handleUpdateBase],
  );

  const onDelete = useCallback(() => {
    handleDelete(baseId);
  }, [handleDelete, baseId]);

  const btnRemove = (
    <Button
      children="Delete"
      destructive
      plain
      onClick={onDelete}
      size="slim"
      disabled={!canUpdate}
    />
  );

  const btnEdit = (
    <Button
      children="Edit"
      size="slim"
      plain
      onClick={toggleOpen}
      disabled={!canUpdate}
    />
  );

  //  Status item
  const { isNeedReview } = checkStatusTask(item?.status);

  const attrMarkup = (isAdminSeller || isRoleIdea) && !isNeedReview && (
    <AttributesByBase
      base={base}
      updateState={handleAttributesChange}
      selectedBases={bases}
      ref={updateBaseRef}
      forceUpdate={forceUpdate}
      toggleForceUpdate={toggleForceUpdate}
      disabledAll={!canUpdate && !itemStatusNull}
      setChanged={setChanged}
    />
  );

  return (isAdminSeller || isRoleIdea) &&
    (noDoingOrPending || itemStatusNull) ? (
    <div className="base-content">
      <Stack>
        <Stack.Item fill>
          <BaseTitle base={base} />
        </Stack.Item>
        <div className="btn-wrap">
          {btnEdit}
          {!disabled && btnRemove}
        </div>
      </Stack>
      <div className="base-wrap">
        <img src={url || noImg} className="base-image" alt="" />
        <AttributesByBase
          base={base}
          updateState={handleAttributesChange}
          selectedBases={bases}
          ref={updateBaseRef}
          forceUpdate={forceUpdate}
          toggleForceUpdate={toggleForceUpdate}
          disabledAll={!canUpdate && !itemStatusNull}
          setChanged={setChanged}
        />
      </div>
      <ViewVariants
        open={open}
        onClose={toggleOpen}
        base={base}
        updateBase={onUpdateBase}
        ref={updateBaseRef2}
      />
    </div>
  ) : (
    <BaseInner
      base={base}
      isCampaign={showPrintFile}
      handleUpdateBase={handleUpdateBase}
      item={item}
      status={status}
      hideBtnRemove={hideBtnRemove}
      attrMarkup={attrMarkup}
      hidePFDLeader={hidePFDLeader}
      taskId={taskId}
      isTeamLeader={isTeamLeader}
      isCreator={isCreator}
      handleChange={handleChange}
    />
  );
}

const Wrapper = styled.div`
  .add-more {
    margin-top: 1.6rem;
  }
  .section-vertical {
    display: flex;
    flex-direction: row;
    gap: 1rem 2rem;
    flex-wrap: wrap;

    .content {
      width: calc(50% - 1rem);
    }

    .left {
      width: calc(40% - 1rem);
    }

    .right {
      width: calc(60% - 1rem);
    }
  }

  .additional-info {
    margin-top: 1.6rem;
    padding-top: 1.6rem;
    border-top: 1px solid #c4cdd5;
  }

  .Polaris-Heading {
    margin-bottom: 1rem;
  }

  .product-base-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .base-content {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.6rem 1.6rem;
    border: 0.2rem solid #dfe3e8;
    border-radius: 5px;
    flex: 1 1 60%;

    .actions-wrap {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
    }
  }

  .base-image {
    width: 16rem;
    height: 16rem;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: var(
      --p-card-shadow,
      0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15)
    );
  }

  .base-wrap,
  .bases-header-wrap {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 0.5rem;
    flex-wrap: wrap;

    &.have-print-file {
      + .have-print-file {
        margin-top: 1.6rem;
        padding-top: 1.6rem;
        border-top: 1px solid #d9d9d9;
      }

      &.has-attr {
        .base-info-wrap {
          width: calc(50% - 1rem);

          .attributes-wrap {
            margin-top: 1.6rem;
          }

          .base-info {
            width: 100%;
          }
        }
      }

      .base-info {
        width: calc(50% - 1rem);
        display: flex;
        flex-direction: row;
        column-gap: 2rem;
        margin-top: 0.5rem;
      }

      .design-position {
        flex: 1 1;
      }
    }
  }

  .attributes-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;

    .option-wrap {
      border-radius: 3px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
  }

  .info-wrap {
    display: flex;
    flex-direction: row;
    column-gap: 1.6rem;

    > .content {
      width: calc(50% - 1rem);
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;
    }
  }
`;
