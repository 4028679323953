import React, { useState, useEffect, useCallback } from "react";
import { DataTable, Badge, Link, Popover, ActionList, Button, Toast, Modal,    TextContainer } from "@shopify/polaris";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { useHistory } from "react-router-dom";
import { handleError } from "../../../helper";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

const Container = styled.div`
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .note-wrap {
    width: calc(20rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
`;

const STATUS_BADGE = {
	Pending: "info",
	Paid: "success",
	Overdue: "critical",
	Frozen: "critical",
	'Awaiting Approval': "warning",
};

const MARK_INVOICE_AS_PAID = gql`
	mutation adminMarkInvoiceAsPaid($id: ID!) {
		adminMarkInvoiceAsPaid(id: $id) {
			id
			name
			createdAt
   			paymentDueDate
			totalPrice
			status
			orderIDs
			summary
			team {
				id
				name
			}
			summary
			rootUser {
				firstName
				lastName
				email
			}
			paymentMethod
		}
	}
`;
const FROZEN_TEAM_VIA_INVOICE = gql`
	mutation adminFrozenATeamViaInvoice($id: ID!) {
		adminFrozenATeamViaInvoice(id: $id) {
			id
			name
			createdAt
   			paymentDueDate
			totalPrice
			status
			orderIDs
			summary
			team {
				id
				name
			}
			summary
			rootUser {
				firstName
				lastName
				email
			}
			paymentMethod
		}
	}
`;
const DELETE_INVOICE = gql`
	mutation adminDeleteInvoice($id: ID!) {
		adminDeleteInvoice(id: $id)
	}
`;

export const DataTableInvoicesPolaris = ({
	data,
	filter,
	setFilter,
	onActionCompleted
}) => {
	const history = useHistory();
	// State
	const [rows, setRows] = useState([]);
	const [columnContentTypes, setColumnContentTypes] = useState([]);
	const [headings, setHeadings] = useState([]);
	const [activeRow, setActiveRow] = useState(null);
	const [open, setOpen] = useState(false);
	const [activeToast, setActiveToast] = useState(false);
	const [actionInModal, setActionInModal] = useState({});
	const [modalActionResult, setModalActionResult] = useState(null);
	const [modalActionLoading, setmodalActionLoading] = useState(false);

	const renderActions = useCallback((invoice) => {
		let actions = [{
			content: 'View Details',
			onAction: () => handleAction('view', invoice),
		}];
		if (invoice.status != 'Paid') {
			actions.push({
				content: 'Mark as Paid',
				onAction: () => handleAction('mark_as_paid', invoice),
			});
			if (invoice.status != 'Frozen') {
				actions.push({
					content: 'Frozen Team',
					onAction: () => handleAction('frozen_team', invoice),
				});
			}
		}
		if (invoice.status == 'Pending') {
			actions.push({
				content: 'Delete',
				onAction: () => handleAction('delete', invoice),
			});
		}

		return (<Popover
			active={activeRow === invoice.id}
			activator={
				<Button disclosure onClick={() => {
					setActiveRow(activeRow === invoice.id ? null : invoice.id);
					console.log('clicked to button');
				}}>Actions</Button>
			}
			onClose={() => setActiveRow(null)}
		>
			<ActionList items={actions} />
		</Popover>)
	}, [activeRow, history]);

	const modalActionLabels = {
		mark_as_paid: {
			label: 'Mark invoice as paid',
			confirmText: `Are you sure to mark this selected invoice to Paid?`,
		},
		frozen_team: {
			label: 'Frozen the team',
			confirmText: 'Are you sure to frozen the selected team?',
		},
		delete: {
			label: 'Delete invoice',
			confirmText: 'Are you sure to delete the selected invoice?',
		},
	}

	const handleAction = (action, invoice) => {
		console.log(`Action: ${action}`, invoice);
		switch(action) {
			case 'view':
				let invoiceDetailUrl = `/admin/billings/invoice/${invoice?.id}`;
				history.push(invoiceDetailUrl);
				break
			case 'mark_as_paid':
			case 'frozen_team':
			case 'delete':
				setOpen(true);
				setActionInModal({
					action,
					invoice
				});
				break;
		}
		setActiveRow(null); // Close the popover
	};

	// Get data
	useEffect(() => {
		// Column type
		const colType = [];
		for (let i = 0; i < 8; i++) {
			colType.push("text");
		}

		// Heading
		const headings = [
			"ID",
			"Name",
			"Team",
			"Created At",
			"Status",
			"Total",
			"Actions",
		];

		setColumnContentTypes(colType);
		setHeadings(headings);

		let newRows =
			data?.invoices?.nodes?.length > 0
				? data?.invoices.nodes.map((item) => {
					const status = item?.status;

					let statusColor;
					for (let [key, value] of Object.entries(STATUS_BADGE)) {
						if (status === key) {
							statusColor = value;
						}
					}

					// Created
					const createdAt = moment(item?.createdAt).format(
						"YYYY-MM-DD HH:mm:ss"
					);

					let invoiceDetailUrl = `/admin/billings/invoice/${item?.id}`;

					return [
						<div className="invoice-id">
							<Link url={invoiceDetailUrl} children={item?.id} />
						</div>,
						<div className="invoice-name">
							<span>{item?.name}</span>
						</div>,
						<div className="invoice-team">
							<span>{item?.team?.name} ({item?.team?.id})</span>
						</div>,
						<div className="invoice-created_at">
							<span>{createdAt}</span>
						</div>,
						<div className="invoice-status">
							<Badge children={status} status={statusColor} />
						</div>,
						<div className="invoice-total">
							<span>${item?.totalPrice?.toFixed(2)}</span>
						</div>,
						<div className="invoice-action">
							{renderActions(item)}
						</div>,
					];
				})
				: [];
		setRows(newRows);
	}, [data, activeRow]);

	let { limit, offset } = filter;
	const total = get(data, "invoices.total", 0);
	const totalPage = Math.ceil(total / limit);
	const page = offset / limit + 1;
	const aggregation = {
		page,
		totalPage,
		offset,
		limit,
		onChange: setFilter,
		total,
	};

	
	const handleModalChange = useCallback(() => setOpen((open) => !open), []);
	const toggleActive = useCallback(() => { setActiveToast((activeToast) => !activeToast); }, []);

	const toastDuration = 3000;
	const toastMarkup = activeToast ? (modalActionResult?.error || modalActionResult?.data )&& (
		<Toast
			content={ modalActionResult?.error ? handleError(modalActionResult.error.toString()) : modalActionResult?.data && "The invoice has been successfully updated!" }
			error={modalActionResult?.error}
			duration={toastDuration}
			onDismiss={toggleActive}
		/>
	) : null;

	const emitEventComplete = () => {
		setTimeout(function() {
			onActionCompleted();
		}, toastDuration);
	}

	const [markAsPaid, {}] = useMutation(
		MARK_INVOICE_AS_PAID,
		{
			onCompleted: (res) => {
				if (res && res.adminMarkInvoiceAsPaid) {
					setModalActionResult({data: res.adminMarkInvoiceAsPaid})
				}
				handleModalChange();
				setActiveToast(true);
				emitEventComplete();
			},
			onError: (err) => {
				if (err) {
					setModalActionResult({error: err})
				}
				handleModalChange();
				setActiveToast(true);
			}
		}
	);
	const [frozenTeamViaInvoice, {}] = useMutation(
		FROZEN_TEAM_VIA_INVOICE,
		{
			onCompleted: (res) => {
				if (res && res.adminFrozenATeamViaInvoice) {
					setModalActionResult({data: res.adminFrozenATeamViaInvoice})
				}
				handleModalChange();
				setActiveToast(true);
				emitEventComplete();
			},
			onError: (err) => {
				if (err) {
					setModalActionResult({error: err})
				}
				handleModalChange();
				setActiveToast(true);
			}
		}
	);
	const [deleteInvoice, {}] = useMutation(
		DELETE_INVOICE,
		{
			onCompleted: (res) => {
				if (res && res.adminDeleteInvoice) {
					setModalActionResult({data: res.adminDeleteInvoice})
				}
				handleModalChange();
				setActiveToast(true);
				emitEventComplete();
			},
			onError: (err) => {
				if (err) {
					setModalActionResult({error: err})
				}
				handleModalChange();
				setActiveToast(true);
			}
		}
	);

	let validActions = {
		mark_as_paid: markAsPaid,
		frozen_team: frozenTeamViaInvoice,
		delete: deleteInvoice,
	}

	return (
		<Container>
			<DataTable
				columnContentTypes={columnContentTypes}
				headings={headings}
				rows={rows}
				verticalAlign="middle"
				hideScrollIndicator
				footerContent={
					<PaginationPolaris aggregation={aggregation} showTotal />
				}
			/>

			<Modal
				title={modalActionLabels?.[actionInModal?.action]?.label}
				open={open}
				onClose={handleModalChange}
				primaryAction={{
					content: modalActionLabels?.[actionInModal?.action]?.label,
					loading: modalActionLoading,
					onAction: () => {
						setmodalActionLoading(true);

						let invoice = actionInModal?.invoice
						if (invoice) {
							switch (actionInModal?.action) {
								case 'mark_as_paid':
								case 'frozen_team':
								case 'delete':
									validActions[actionInModal.action]({
										variables: { id: invoice.id },
									})
									.then((res) => {
										setmodalActionLoading(false);
										
									}).catch((err) => {
										setmodalActionLoading(false);
										console.log('Error: ', err)
									});
									break;
							}
						}
						
					}
				}}
				secondaryActions={[
					{
						content: "Cancel",
						onAction: handleModalChange,
					},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<div dangerouslySetInnerHTML={{ __html: modalActionLabels?.[actionInModal.action]?.confirmText }} />
					</TextContainer>
				</Modal.Section>
			</Modal>
			{toastMarkup}
		</Container>
	);
};
