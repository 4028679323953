import { useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import React, { useMemo, useReducer, useRef } from "react";

import { GET_TIKTOK_IMAGE_TEMPLATES } from "../../../graphql/queries";
import { reducerFn } from "../../../helper";
import AutocompleteV2 from "../../shared/AutocompleteV2";

export default function ImageTemplateSelect({ onChange, value, label, allowMultiple }) {
  const [filter, setFilter] = useReducer(reducerFn, {
    limit: 20,
    offset: 0,
    search: "",
  });

  const { data, loading, error } = useQuery(GET_TIKTOK_IMAGE_TEMPLATES, {
    variables: {
      filter,
    },
  });

  const acRef = useRef(null);
  const options = useMemo(() => {
    const options = getOptions(data);
    if (options.length > 0) {
      acRef.current?.handleFocus && acRef.current.handleFocus();
    }

    return options;
  }, [JSON.stringify(data)]);

  return (
    <div style={{ marginBottom: "1rem" }}>
      <AutocompleteV2
        label={label || "Image Templates"}
        options={options}
        value={value}
        allowMultiple={allowMultiple}
        error={error}
        loading={loading}
        setFilter={setFilter}
        onChange={onChange}
        ref={acRef}
      />
    </div>
  );
}

function getOptions(res) {
  const nodes = get(res, "getTiktokImageTemplates.nodes") || [];
  const arr = [];
  for (let item of nodes) {
    if (!item || !item.id) continue;
    arr.push({ value: item.id, label: item.title || "" });
  }

  return arr;
}
