import React, { useCallback, useEffect } from "react";
import { Modal } from "@shopify/polaris";

import ProductBaseSelect from "../../../seller/ProductBaseSelect";
import { elementContains } from "../../../../helper";
import ProductbaseSelectV2 from "../../../seller/ProductBaseSelectV2";

export function SelectProducts({
  isCampaign,
  open,
  onClose,
  handleContinue,
  handleChange,
  handleFulfillmentChange,
  fulfillmentId,
  selectedProductBases,
}) {
  // Mounted
  useEffect(() => {
    const domI = document.querySelector("#select-product-modal");
    const modalCls = ".Polaris-Modal-Dialog__Modal";
    const domParents = document.querySelectorAll(modalCls);

    for (const domP of domParents) {
      if (elementContains(domP, domI)) {
        domP.setAttribute(
          "style",
          "max-width: calc(100% - 2.5rem); max-height: calc(100vh - 2.5rem); height: 100vh",
        );
      }
    }
  });

  // Actions
  const onContinue = useCallback(() => {
    onClose();
    handleContinue();
  }, [onClose, handleContinue]);

  // Markup
  const titleMarkup = isCampaign ? "s" : "";

  return (
    <Modal
      title={`Select Product base${titleMarkup}`}
      open={open}
      onClose={onClose}
      large
      primaryAction={{ content: "Continue", onAction: onContinue }}
    >
      <div id="select-product-modal">
        {/* <ProductBaseSelect
          onFulfillmentChange={handleFulfillmentChange}
          fulfillmentId={fulfillmentId}
          value={selectedProductBases}
          onChange={handleChange}
          multiple={isCampaign}
          noHeader
        /> */}
        <ProductbaseSelectV2
          onFulfillmentChange={handleFulfillmentChange}
          fulfillmentId={fulfillmentId}
          value={selectedProductBases}
          onChange={handleChange}
          multiple={isCampaign}
          noHeader
        />
      </div>
    </Modal>
  );
}
