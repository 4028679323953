import React, { useState, useCallback } from "react";
import { Page, Loading, Card, Modal, TextContainer, Toast, FormLayout, TextField, Stack, RadioButton } from "@shopify/polaris";
import styled from "styled-components";
import { InvoiceDetailsPolaris } from "./InvoiceDetailsPolaris";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { handleError } from "../../../helper";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";

export const INVOICE_BY_ID = gql`
	query invoiceByID($id: ID!) {
		invoiceByID(
			id: $id
		) {
			id
			name
			createdAt
   			paymentDueDate
			totalPrice
			status
			orderIDs
			summary
			team {
				id
				name
				address
			}
			summary
			rootUser {
				firstName
				lastName
				email
				address
			}
			paymentMethod
		}
	}
`;

const SELLER_REQUEST_APPROVAL = gql`
	mutation sellerRequestApproval($id: ID!, $input: RequestApprovalInput!) {
		sellerRequestApproval(id: $id, input: $input) {
			id
			name
			createdAt
   			paymentDueDate
			totalPrice
			status
			orderIDs
			summary
			team {
				id
				name
			}
			summary
			rootUser {
				firstName
				lastName
				email
			}
			paymentMethod
		}
	}
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const InvoiceDetailsPagePolaris = (props) => {
	const [open, setOpen] = useState(false);
	const [activeToast, setActiveToast] = useState(false);
	const [paymentReference, setPaymentReference] = useState("");
	const [paymentProof, setPaymentProof] = useState(null);

	const [fieldError, setFieldError] = useState({paymentReference: null, paymentProof: null});
	
	const { id } = props.match.params;
	const { data, loading } = useQuery(INVOICE_BY_ID, {
		variables: { id },
		fetchPolicy: "network-only",
	});
	let invoice = (data && data.invoiceByID && data.invoiceByID.id.length) ? data.invoiceByID : null;

	const [paymentMethod, setPaymentMethod] = useState(invoice?.paymentMethod || 'bank-transfer');
	const handlePaymentMethodChange = useCallback((newValue) => {
		setPaymentMethod(newValue)
	}, [paymentMethod]);

	const [requestApproval, requestApprovalRes] = useMutation(
		SELLER_REQUEST_APPROVAL,
		{
			onCompleted: () => {
				if (requestApprovalRes && requestApprovalRes.data && requestApprovalRes.data.sellerRequestApproval) {
					invoice = requestApprovalRes.data.sellerRequestApproval;
				}
				handleModalChange();
				setActiveToast(true);
			},
			onError: () => {
				handleModalChange();
				setActiveToast(true);
			}
		}
	);

	const toggleActive = useCallback(() => { setActiveToast((activeToast) => !activeToast); }, []);

	const toastMarkup = activeToast ? (requestApprovalRes.error || (requestApprovalRes.data && requestApprovalRes.data.sellerRequestApproval)) && (
		<Toast
			content={requestApprovalRes.error ? handleError(requestApprovalRes.error.toString()) : requestApprovalRes.data && requestApprovalRes.data.sellerRequestApproval && "Your request has been successfully submitted!"}
			error={requestApprovalRes.error}
			duration={10000}
			onDismiss={toggleActive}
		/>
	) : null;

	const loadingMarkup = loading && <Loading />;

	let otherProps = {};
	if (invoice && invoice.status == 'Pending') {
		otherProps = {
			primaryAction: {
				content: "Request Approval",
				onAction: () => handleAction('requestApproval', invoice),
			},
		}
	}

	const handleAction = (action, invoice) => {
		switch (action) {
			case 'requestApproval':
				handleModalChange();
				break;
		}
	};
	const handleModalChange = useCallback(() => setOpen((open) => !open), []);

	const handlePaymentReference = useCallback((value) => {
		setPaymentReference(value);
		if ( value && value.length > 0 ) {
			setFieldError((prev) => ({ ...prev, paymentReference: null }) );
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePaymentProof = useCallback((value) => {
		setPaymentProof(value);
		if ( value && value.length > 0 ) {
			setFieldError((prev) => ({ ...prev, paymentProof: null }) );
		}
	}, []);

	return (
		<Container>
			<Page
				title={`Invoice Details`}
				breadcrumbs={[
					{ content: "All Invoices", url: "/seller/billings/invoices" },
				]}
				{...otherProps}
			>
				<Card sectioned>
					{loadingMarkup}
					{loading ? (
						<SkeletonPagePolaris />
					) : invoice ? (
						<InvoiceDetailsPolaris invoice={invoice} />
					) : (
						<EmptyStatePolaris />
					)}
				</Card>

				<Modal
					title={"Request Approval"}
					open={open}
					onClose={handleModalChange}
					primaryAction={{
						content: "Request Approval",
						loading: loading,
						onAction: () => {
							if (paymentProof.length == 0) {
								setFieldError((prev) => ({ ...prev, paymentProof: 'You must upload a proof of payment' }) );
							}
							if (paymentReference.length == 0) {
								setFieldError((prev) => ({ ...prev, paymentReference: 'You must enter the payment reference id' }) );
							}

							if (paymentProof.length == 0 || paymentReference.length == 0) {
								return;
							}

							requestApproval({
								variables: { 
									id: invoice.id,
									input: {
										referenceID: paymentReference,
										proofOfPayment: paymentProof?.[0]?.id,
										paymentMethod
									}
								},
							})
							.then((res) => {

							}).catch((err) => {
								console.log('Error: ', err)
							});
						}
					}}
					secondaryActions={[
						{
							content: "Cancel",
							onAction: handleModalChange,
						},
					]}
				>
					<Modal.Section>
						<FormLayout>
							<div>
								<label><strong>Which payment method did you use?</strong></label>
								<TextContainer>
									<Stack>
										<RadioButton
											label="Bank Transfer"
											checked={paymentMethod == 'bank-transfer'}
											value="bank-transfer"
											name="payment-method"
											onChange={() => handlePaymentMethodChange("bank-transfer")}
										/>
										{/* <RadioButton
											label="Paypal"
											checked={paymentMethod == 'paypal'}
											value="paypal"
											name="payment-method"
											onChange={() => handlePaymentMethodChange("paypal")}
										/>
										<RadioButton
											label="Pingpong"
											checked={paymentMethod == 'pingpong'}
											value="pingpong"
											name="payment-method"
											onChange={() => handlePaymentMethodChange("pingpong")}
										/>
										<RadioButton
											label="Payoneer"
											checked={paymentMethod == 'payoneer'}
											value="payoneer"
											name="payment-method"
											onChange={() => handlePaymentMethodChange("payoneer")}
										/> */}
									</Stack>
								</TextContainer>
							</div>
							<div>
								<ComponentLabelPolaris label="Your payment reference" required />
								<TextField
									value={paymentReference}
									onChange={handlePaymentReference}
									error={fieldError.paymentReference}
								/>
							</div>
							<div style={{maxWidth: '200px'}}>
								<ComponentLabelPolaris label="Upload proof of payment" required/>
								<MediaSelectorButtonPolaris
									onChange={handlePaymentProof}
								/>
								{fieldError.paymentProof && (<p style={{color: 'red'}}>{fieldError.paymentProof}</p>)}
							</div>
						</FormLayout>
						<br/>
						<TextContainer>
							<div><strong>Notes:</strong></div>
							<div>1. To request approval for your invoice, please ensure you provide a valid payment reference and upload proof of payment.</div>
							<div>2. Incomplete submissions will not be processed.</div>
						</TextContainer>
					</Modal.Section>
				</Modal>
				{toastMarkup}
			</Page>
		</Container>
	);
};
