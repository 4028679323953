import React from "react";
import { useAppContext } from "../context";
import get from "lodash/get";
import { getParamByRole, getParamByUserRole } from "../helper";

export default function useCustomPathname() {
  const { currentUser } = useAppContext();

  const teamUser = get(currentUser, "teamUser");
  let param = getParamByUserRole(currentUser);
  if (teamUser != null) {
    param = getParamByRole(currentUser);
  }

  return param;
}
