import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Form,
  Input,
  notification,
  Select,
  Spin,
} from "antd";
import { gql } from "apollo-boost";
import get from "lodash/get";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import {
  CREATE_TRACKING_CONFIG,
  UPDATE_MANUAL_ORDER_CREATION_NOTICE,
  UPDATE_TRACKING_CONFIG,
  UPDATE_USER_HAS_PERMISSION_ACCEPT_ACCESS_IN_TEAM,
} from "../../graphql/mutations";
import {
  GET_MANUAL_ORDER_CREATION_NOTICE,
  GET_TRACKING_CONFIG,
  GET_USER_HAS_PERMISSION_ACCEPT_ACCESS_IN_TEAM,
} from "../../graphql/queries";
import { handleError, sleep } from "../../helper";
import CopyBox from "../shared/CopyBox";
import BackgroundImage from "./components/BackgroundImage";
import { Enable2FA } from "./components/Enable2FA";
import VerifyShippingCost from "./components/VerifyShippingCost";
import { ViewOriginFile } from "./components/ViewOriginFile";
import { WarningEmail } from "./components/WarningEmail";
import SetOnHoldTime from "./components/SetOnHoldTime";
import TeamAddress from "./components/TeamAddress";

const HelpWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  .copy-box {
    padding: 0.2rem 1rem;
    border-radius: var(--p-border-radius-base, 3px);
    margin-top: 0.5rem;
    border-color: rgb(183, 193, 202);
    gap: 0.5rem;
  }
`;

export function Settings() {
  const controlRef = useRef({ tracking: null });
  const [loading, setLoading] = useState([]);

  // Context
  const { currentUser } = useAppContext();
  const isRootUser = get(currentUser, "isRootUser", false);

  // Actions
  const handleSubmit = useCallback(async () => {
    // if (controlRef.current.tracking != null) {
    //   controlRef.current.tracking.onSubmit();
    // }

    const keys = Object.keys(controlRef.current);
    let flag = 0;
    for (let key of keys) {
      if (key === "enable2fa") continue;
      const el = controlRef.current[key];
      if (el.onSubmit && typeof el.onSubmit === "function") {
        el.onSubmit();
        flag++;
      }
    }
    if (flag > 1) {
      await sleep(1000);
    }

    if (controlRef.current.enable2fa) {
      const el = controlRef.current.enable2fa;
      if (el.onSubmit && typeof el.onSubmit === "function") {
        el.onSubmit();
      }
    }

    // if (controlRef.current.backgroundImage) {
    //   const el = controlRef.current.backgroundImage;
    //   if (el.onSubmit && typeof el.onSubmit === "function") {
    //     el.onSubmit();
    //   }
    // }
    // if (controlRef.current.verifyShippingCost) {
    //   const el = controlRef.current.verifyShippingCost;
    //   if (el.onSubmit && typeof el.onSubmit === "function") {
    //     el.onSubmit();
    //   }
    // }
    // if (controlRef.current.setOnHoldTime) {
    //   const el = controlRef.current.setOnHoldTime;
    //   if (el.onSubmit && typeof el.onSubmit === "function") {
    //     el.onSubmit();
    //   }
    // }
  }, []);

  const handleLoading = useCallback(
    (index) => (value) => {
      setLoading((prev) => {
        prev[index] = value;
        return [...prev];
      });
    },
    [],
  );

  return (
    <Card>
      <Collapse defaultActiveKey={["tracking"]}>
        <Collapse.Panel key="tracking" header="Tracking">
          <Tracking
            ref={(el) => (controlRef.current.tracking = el)}
            setLoading={handleLoading(0)}
          />
        </Collapse.Panel>
        {/* <Collapse.Panel key="manageUser" header="Advanced manage users">
          <ManageUser
            ref={(el) => (controlRef.current.managUser = el)}
            setLoading={handleLoading(1)}
          />
        </Collapse.Panel> */}
        <Collapse.Panel key="emailForward" header="Email notification">
          <EmailForward
            ref={(el) => (controlRef.current.emailForward = el)}
            setLoading={handleLoading(2)}
          />
        </Collapse.Panel>
        <Collapse.Panel
          key="warningEmail"
          header="Warning about addresses that cannot be shipped"
        >
          <WarningEmail
            ref={(el) => (controlRef.current.warningEmail = el)}
            setLoading={handleLoading(4)}
          />
        </Collapse.Panel>
        <Collapse.Panel key="viewOriginal" header="View Original File">
          <ViewOriginFile
            ref={(el) => (controlRef.current.viewOriginFile = el)}
            setLoading={handleLoading(5)}
          />
        </Collapse.Panel>
        {isRootUser ? (
          <>
            <Collapse.Panel
              key="enable2fa"
              header="Two-Factor Authentication (2FA)"
            >
              <Enable2FA
                ref={(el) => (controlRef.current.enable2fa = el)}
                setLoading={handleLoading(3)}
              />
            </Collapse.Panel>
            <Collapse.Panel
              key="backgroundImage"
              header={
                <div>
                  <span>Config Background Image</span>
                  <span
                    style={{ color: "rgba(0, 0, 0, 0.45)", display: "block" }}
                  >
                    Set a default background color for all image files.
                  </span>
                </div>
              }
            >
              <BackgroundImage
                ref={(el) => (controlRef.current.backgroundImage = el)}
                setLoading={handleLoading(6)}
              />
            </Collapse.Panel>
            <Collapse.Panel
              key="verifyShippingCost"
              header={"Check Tiktok label shipping cost"}
            >
              <VerifyShippingCost
                ref={(el) => (controlRef.current.verifyShippingCost = el)}
                setLoading={handleLoading(7)}
              />
            </Collapse.Panel>
            <Collapse.Panel key="setOnHoldTime" header={"Set On Hold Time"}>
              <SetOnHoldTime
                ref={(el) => (controlRef.current.setOnHoldTime = el)}
                setLoading={handleLoading(7)}
              />
            </Collapse.Panel>
            <Collapse.Panel key="teamAddress" header={"Set Address for Team"}>
              <TeamAddress
                ref={(el) => (controlRef.current.teamAddress = el)}
                setLoading={handleLoading(8)}
              />
            </Collapse.Panel>
          </>
        ) : null}
      </Collapse>

      <div className="actions mt-8">
        <Button
          type="primary"
          onClick={handleSubmit}
          children="Submit"
          loading={loading.some((i) => !!i)}
        />
      </div>
    </Card>
  );
}

const Tracking = React.forwardRef(function Tracking({ setLoading }, ref) {
  // Context
  const { currentUser } = useAppContext();
  const teamId = get(currentUser, "teamUser.team.id");

  // State
  const changed = React.useRef(false);
  const [state, setState] = useReducer((p, state) => ({ ...p, ...state }), {
    isEnable: false,
    isEdit: false,
  });

  // Query
  const { data, loading, error } = useQuery(GET_TRACKING_CONFIG, {
    variables: {
      teamId,
    },
    skip: !teamId,
  });

  // Mutation
  const [create] = useMutation(CREATE_TRACKING_CONFIG);
  const [update] = useMutation(UPDATE_TRACKING_CONFIG);

  const [form] = Form.useForm();
  const options = useMemo(
    () => Array.from({ length: 8 }).map((_, i) => i + ""),
    [],
  );

  useEffect(() => {
    if (data?.getTrackingConfig != null) {
      const {
        teamId,
        __typename,
        timeSubmitTrackingDelay,
        timeSubmitTrackingToStoreDelay,
        ...fields
      } = data.getTrackingConfig;

      const newTime =
        timeSubmitTrackingToStoreDelay === -1
          ? "nothing"
          : (timeSubmitTrackingToStoreDelay || "nothing") + "";

      form.setFieldsValue({
        ...fields,
        timeSubmitTrackingDelay: (timeSubmitTrackingDelay || 1) + "",
        timeSubmitTrackingToStoreDelay: newTime,
      });
      setState({ isEdit: true, isEnable: fields.isEnable });
    }
  }, [data, form]);

  // Actions
  const handleValuesChange = useCallback((value) => {
    changed.current = true;
    const { isEnable } = value;
    if (isEnable != null) {
      setState({ isEnable });
    }
  }, []);

  const handleFinish = useCallback(
    (values) => {
      if (!changed.current) return;
      if (!teamId) return;
      setLoading(true);
      const {
        timeSubmitTrackingDelay,
        timeSubmitTrackingToStoreDelay,
        isEnable,
        trackingmoreApi,
      } = values;
      const fn = state.isEdit ? update : create;
      const label = state.isEdit ? "Update" : "Create";

      const newTime =
        timeSubmitTrackingToStoreDelay !== "nothing"
          ? parseInt(timeSubmitTrackingToStoreDelay, 10)
          : -1;

      fn({
        variables: {
          input: {
            teamId,
            isEnable,
            trackingmoreApi: isEnable ? trackingmoreApi : undefined,
            timeSubmitTrackingDelay: isEnable
              ? parseInt(timeSubmitTrackingDelay || 0)
              : undefined,
            timeSubmitTrackingToStoreDelay: isEnable ? undefined : newTime,
          },
        },
      })
        .then(() => {
          notification.success({
            message: `${label} tracking config success.`,
          });
        })
        .catch((e) => {
          notification.error({ message: handleError(e?.toString()) });
        })
        .finally(() => {
          setLoading(false);
          changed.current = false;
        });
    },
    [state.isEdit, teamId, setLoading, update, create],
  );

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: form.submit,
    }),
    [form],
  );

  return (
    <Form
      form={form}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      onValuesChange={handleValuesChange}
      onFinish={handleFinish}
      initialValues={{
        timeSubmitTrackingDelay: "1",
        timeSubmitTrackingToStoreDelay: "nothing",
        isEnable: false,
      }}
    >
      {loading ? (
        <Spin />
      ) : error ? (
        <div>Error: {handleError(error.toString())}</div>
      ) : (
        <>
          <Form.Item name="isEnable" valuePropName="checked">
            <Checkbox children="Using TrackingMore service" />
          </Form.Item>
          {state.isEnable ? (
            <>
              <Form.Item
                label="Submit tracking delay"
                name="timeSubmitTrackingDelay"
                help="Set the delay duration to N days before submitting the shipment to TrackingMore."
              >
                <Select placeholder="Choose time submit tracking delay">
                  {options.map((el, i) => (
                    <Select.Option key={`option-${i}`} value={`${el}`}>
                      {el} {el < 2 ? "day" : "days"}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Trackingmore API"
                name="trackingmoreApi"
                help={
                  <HelpWrapper>
                    You must set a webhook url to
                    <CopyBox text="https://api.merchbridge.com/tracking-more/wh-zk1XSw6mG1" />{" "}
                    in your{" "}
                    <a href="https://admin.trackingmore.com/developer/webhooks">
                      TrackingMore account
                    </a>
                  </HelpWrapper>
                }
              >
                <Input placeholder="Enter Trackingmore API" />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              label="Time Submit Tracking To Store Delay"
              name="timeSubmitTrackingToStoreDelay"
              help="Set the delay duration to N days before submitting the shipment to store: Ebay, Shopify, WooCommerce, Shopbase."
            >
              <Select placeholder="Choose time submit tracking to Store delay">
                {[...options, "nothing"].map((el, i) => (
                  <Select.Option key={`option-${i}`} value={`${el}`}>
                    {el === "nothing"
                      ? "Do nothing"
                      : `${el} ${el < 2 ? "day" : "days"}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </>
      )}
    </Form>
  );
});

export const LIST_TEAM = gql`
  query teamMembers($filter: TeamUserFilter) {
    teamMembers(filter: $filter) {
      hits {
        id
        user {
          id
          firstName
          lastName
          email
          teamUser {
            id
            role
          }
        }
      }
    }
  }
`;

const ManageUser = React.forwardRef(function ({ setLoading }, ref) {
  const [filter, setFilter] = React.useState({
    limit: 20,
    offset: 0,
    status: true,
    search: "",
  });
  const [value, setValue] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const changed = React.useRef(false);

  const { data } = useQuery(GET_USER_HAS_PERMISSION_ACCEPT_ACCESS_IN_TEAM);
  const {
    data: dataT,
    loading,
    error,
  } = useQuery(LIST_TEAM, {
    variables: {
      filter,
    },
  });

  const [update] = useMutation(
    UPDATE_USER_HAS_PERMISSION_ACCEPT_ACCESS_IN_TEAM,
    {
      onCompleted: () => {
        notification.success({
          message: "Update user has permission success.",
        });
      },
      onError: (err) => {
        notification.error({ message: handleError(err?.toString()) });
      },
    },
  );

  React.useEffect(() => {
    const nodes = data?.getUserHasPermissionAcceptAccessInTeam || [];
    if (nodes?.length > 0) {
      const ids = nodes.map(({ id }) => id);
      setValue(ids);
    }
  }, [data]);

  React.useEffect(() => {
    const nodes = dataT?.teamMembers?.hits || [];
    const nodesVal = data?.getUserHasPermissionAcceptAccessInTeam || [];

    const opts = formatUser(nodes);
    const values = formatUser(nodesVal);

    const mapVal = new Map(
      [...values, ...opts].map((item) => [item.value, item]),
    );
    const merged = Array.from(mapVal.values());
    setOptions(merged);
  }, [dataT, data]);

  const onSubmit = React.useCallback(() => {
    if (!changed.current) return;
    if (!value || value.length === 0) return;
    const ids = value.filter(Boolean);

    setLoading(true);
    update({ variables: { userIds: ids?.length > 0 ? ids : [] } }).finally(
      () => {
        setLoading(false);
        changed.current = false;
      },
    );
  }, [value]);

  React.useImperativeHandle(ref, () => ({
    onSubmit,
  }));
  const handleChange = React.useCallback((val) => {
    setValue(val);
    changed.current = true;
  }, []);

  if (error) return <div>Error: {error.toString()}</div>;
  return (
    <Select
      showSearch
      onSearch={(search) => setFilter((p) => ({ ...p, search }))}
      options={options}
      onChange={handleChange}
      filterOption={false}
      value={value}
      mode="multiple"
      style={{ width: "100%" }}
      loading={loading}
    />
  );
});

export const labelStyle = {
  color: "rgba(0, 0, 0, 0.85)",
  fontSize: 14,
  fontWeight: 500,
  marginTop: -5,
  display: "block",
  marginBottom: 5,
};
const EmailForward = React.forwardRef(function ({ setLoading }, ref) {
  const [filter, setFilter] = React.useState({
    limit: 20,
    offset: 0,
    status: true,
    search: "",
    role: "Admin",
  });
  const [value, setValue] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const changed = React.useRef(false);

  const { data } = useQuery(GET_MANUAL_ORDER_CREATION_NOTICE);
  const {
    data: dataT,
    loading,
    error,
  } = useQuery(LIST_TEAM, {
    variables: {
      filter,
    },
  });

  const [update] = useMutation(UPDATE_MANUAL_ORDER_CREATION_NOTICE, {
    onCompleted: () => {
      notification.success({
        message: "Update email forward success.",
      });
    },
    onError: (err) => {
      notification.error({ message: handleError(err?.toString()) });
    },
  });

  React.useEffect(() => {
    const nodes = data?.getManualOrderCreationNotice || [];
    if (nodes?.length > 0) {
      const ids = nodes.map(({ id }) => id);
      setValue(ids);
    }
  }, [data]);

  React.useEffect(() => {
    const nodes = dataT?.teamMembers?.hits || [];
    const nodesVal = data?.getManualOrderCreationNotice || [];

    const opts = formatUser(nodes);
    const values = formatUser(nodesVal);

    const mapVal = new Map(
      [...values, ...opts].map((item) => [item.value, item]),
    );
    const merged = Array.from(mapVal.values());
    setOptions(merged);
  }, [dataT, data]);

  const onSubmit = React.useCallback(() => {
    if (!changed.current) return;
    const ids = (value || []).filter(Boolean);

    setLoading(true);
    update({ variables: { userIds: ids?.length > 0 ? ids : [] } }).finally(
      () => {
        setLoading(false);
        changed.current = false;
      },
    );
  }, [value]);

  React.useImperativeHandle(ref, () => ({
    onSubmit,
  }));
  const handleChange = React.useCallback((val) => {
    setValue(val);
    changed.current = true;
  }, []);

  if (error) return <div>Error: {error.toString()}</div>;
  return (
    <>
      <label style={labelStyle}>
        Add email lists to get notifications whenever a new manual order is
        created.
      </label>
      <Select
        showSearch
        onSearch={(search) => setFilter((p) => ({ ...p, search }))}
        options={options}
        onChange={handleChange}
        filterOption={false}
        value={value}
        mode="multiple"
        style={{ width: "100%" }}
        loading={loading}
      />
    </>
  );
});

export function formatUser(arr) {
  if (!arr || arr.length === 0) return arr;
  return arr.map((item) => {
    let user = item?.user != null ? item.user : item;
    const { id, firstName: f, lastName: l, email } = user || {};
    const label = `${f} ${l} (${email})`;

    return {
      value: id,
      label,
    };
  });
}
