import React from "react";
import { Badge, Stack } from "@shopify/polaris";
import styled from "styled-components";

import { useProductCrawlCtx } from "./context";
import { arrInvalid } from "../../../helper";

function PushedToStore({ node }) {
  const { id } = node || {};

  const ctx = useProductCrawlCtx() || {};
  const stores = ctx[id];
  if (arrInvalid(stores)) {
    return null;
  }

  return (
    <Stack vertical spacing="tight">
      {uniqueStores(stores).map((s, index) => (
        <div key={`${id}-store-${index}`} title={s?.title || ""}>
          <Badge children={s?.title || ""} />
        </div>
      ))}
    </Stack>
  );
}

const uniqueStores = (stores) => {
  if (arrInvalid(stores)) return [];

  return Array.from(
    new Map(
      stores.map((i) => (i.id ? [i.id, i] : null)).filter(Boolean),
    ).values(),
  );
};

const Wapper = styled.div``;

export default PushedToStore;
