import { gql } from "apollo-boost";

import { StoreFragment } from "../fragments";

export const GET_STORES = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      total
      nodes {
        ...StoreFragment
      }
    }
  }
  ${StoreFragment}
`;

export const GET_OWNER_STORE_BY_IDS = gql`
  query getOwnerStoreByIDs2($filter: GetOwnerStoreByIDsFilter!) {
    getOwnerStoreByIDs2(filter: $filter) {
      storeID
      storeOwners {
        createdAt
        deletedAt
        totalSale
        totalBaseCost
        totalRevenue
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_TIKTOK_SALES_WAREHOUSES = gql`
  query getTiktokSalesWarehouses {
    getTiktokSalesWarehouses {
      id
      name
    }
  }
`;
