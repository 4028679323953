import React, { useState, useCallback, useEffect, useRef } from "react";
import { Filters } from "@shopify/polaris";
import { isEmpty } from "../../../helper";
import { FilterListRadioPolaris } from "../../filters/FilterListRadioPolaris";
import { FilterHasSearchPolaris } from "../../filters/FilterHasSearchPolaris";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const ALL_ORDER_OPTIONS = [
	{ value: "DESC", label: "DESC" },
	{ value: "ASC", label: "ASC" },
];

const ALL_ORDER_BY_OPTIONS = [
	{ value: "title", label: "Title" },
	{ value: "created_at", label: "Created At" },
];

export default function ProductTemplatesFilter({ onFilterChange }) {
	const [filterOrdering, setFilterOrdering] = useState("DESC");
	const [filterOrderBy, setFilterOrderBy] = useState("created_at");
	const [queryValue, setQueryValue] = useState("");


	const typingTimeoutRef = useRef(null);

	useEffect(() => {
		if (typingTimeoutRef.current) {
			clearTimeout(typingTimeoutRef.current);
		}
		typingTimeoutRef.current = setTimeout(() => {
			if (onFilterChange) {
				onFilterChange({
					search: queryValue ? queryValue.trim() : queryValue,
					orderBy: filterOrderBy,
					order: filterOrdering,
				});
			}
		}, 300);
	}, [queryValue, filterOrderBy, filterOrdering]);

	// Handle actions
	const handleQueryValueChange = useCallback(
		(value) => setQueryValue(value),
		[]
	);
	const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);

	const handleFilterClearAll = useCallback(() => {
		handleQueryValueRemove();
		handleRemoveFiltering("ordering");
		handleRemoveFiltering("order_by");
	}, [handleQueryValueRemove]);

	const handleRemoveFiltering = (key) => {
		switch (key) {
			case "ordering":
				setFilterOrdering("");
				break;
			case "order_by":
				setFilterOrderBy("");
				break;
			case "keyword":
				handleQueryValueRemove();
				break;
		}
	};

	const filters = [
		{
			key: "ordering",
			label: "Order",
			filter: (
				<FilterListRadioPolaris
					options={ALL_ORDER_OPTIONS}
					value={filterOrdering}
					onChange={({ value, label }) => setFilterOrdering(value)}
				/>
			),
		},
		{
			key: "order_by",
			label: "Order by",
			filter: (
				<FilterListRadioPolaris
					options={ALL_ORDER_BY_OPTIONS}
					value={filterOrderBy}
					onChange={({ value, label }) => setFilterOrderBy(value)}
				/>
			),
		},
	];
	const appliedFilters = [];

	if (!isEmpty(filterOrdering)) {
		const key = "ordering";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, filterOrdering),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	if (!isEmpty(filterOrderBy)) {
		const key = "order_by";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, filterOrderBy),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	if (!isEmpty(queryValue)) {
		const key = "keyword";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, queryValue),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	return (
		<Filters
			queryPlaceholder={`Search product template`}
			queryValue={queryValue}
			onQueryChange={handleQueryValueChange}
			onQueryClear={handleQueryValueRemove}
			onClearAll={handleFilterClearAll}
			filters={filters}
			appliedFilters={appliedFilters}
		/>
	);
	function disambiguateLabel(key, value) {
		switch (key) {
			case "keyword":
				return `Keyword: ${value}`;
			case "origin_source":
				return `Origin source: ${value}`;
			case "ordering":
				return `Order: ${value}`;
			case "order_by":
				if (ALL_ORDER_BY_OPTIONS) {
					let orderBy = ALL_ORDER_BY_OPTIONS.find(
						(item) => item.value === value
					);
					if (orderBy && orderBy.label) {
						return `Order by: ${orderBy.label}`;
					}
				}
				return `Order by: ${value}`;
			default:
				return value;
		}
	}
}
