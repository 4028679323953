import React, { useState, useEffect } from "react";
import { TextField, Select } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
	.box-flex {
		display: flex;
		flex-wrap: nowrap;
		gap: 20px;
		margin-bottom: 15px;
	}

	.box-flex-25 {
		width: 25%;
	}
`;

const TiktokPackageDimensions = ({ defaultValues, onChange }) => {
	const [data, setData] = useState({
		unit: defaultValues?.unit ?? "INCH",
		width: defaultValues?.width ?? '',
		height: defaultValues?.height ?? '',
		length: defaultValues?.length ?? '',
	});

	useEffect(() => {
		setData({
			unit: defaultValues?.unit ?? "INCH",
			width: defaultValues?.width ? defaultValues?.width.toString() : '',
			height: defaultValues?.height ? defaultValues?.height.toString() : '',
			length: defaultValues?.length ? defaultValues?.length.toString() : '',
		});
	}, [JSON.stringify(defaultValues)]);

	const handleChange = (field, value) => {
		let fieldVal = value;
		if ('unit' != field) {
			fieldVal = parseInt(value);
		}
		setData((prevData) => ({
			...prevData,
			[field]: fieldVal,
		}));
	};

	useEffect(() => {
		if (onChange) {
			onChange(data);
		}
	}, [JSON.stringify(data)]);

	return (
		<Container>
			<div className="box-flex">
				<div className="box-flex-25">
					<Select
						label="Unit"
						options={["CENTIMETER", "INCH"]}
						value={data?.unit ?? "INCH"}
						onChange={(value) => handleChange("unit", value)}
					/>
				</div>
				<div className="box-flex-25">
					<TextField
						label="Width"
						value={data?.width ?? null}
						onChange={(value) => handleChange("width", value)}
						type="number"
						min={0}
						step={1}
					/>
				</div>
				<div className="box-flex-25">
					<TextField
						label="Height"
						value={data?.height ?? null}
						onChange={(value) => handleChange("height", value)}
						type="number"
						min={0}
						step={1}
					/>
				</div>
				<div className="box-flex-25">
					<TextField
						label="Length"
						value={data?.length ?? null}
						onChange={(value) => handleChange("length", value)}
						type="number"
						min={0}
						step={1}
					/>
				</div>
			</div>
		</Container>
	);
};

export default TiktokPackageDimensions;
