import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import { get, isEqual } from "lodash";
import { Select } from "antd";

import { reducerFn, userInTeam } from "../../helper";
import useTimeout from "../../hooks/useTimeout";
import { useAppContext } from "../../context";
import { getProductBaseVariants } from "../seller/utils";

const PRODUCT_BASE_FOR_SELLER = gql`
  query productBasesForSeller($filter: ProductBaseFilter) {
    productBasesForSeller(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

function ProductBaseSelect(
  { filter: filterProps, value, disabled, productBase, onChange },
  _ref,
) {
  const { currentUser } = useAppContext();
  const hasTeam = userInTeam(currentUser);

  const [filter, setFilter] = useReducer(reducerFn, {
    limit: filterProps?.limit || 200,
    offset: filterProps?.offset || 0,
    search: "",
    ...(filterProps ? filterProps : {}),
  });

  const { data, loading } = useQuery(PRODUCT_BASE_FOR_SELLER, {
    variables: {
      filter,
    },
    skip: !hasTeam || !!disabled,
  });

  useEffect(() => {
    if (filterProps && !isEqual(filter, filterProps)) {
      setFilter({ ...filterProps });
    }
  }, [JSON.stringify(filterProps)]);

  const options = mergeBases(productBase, getBases(data));

  const handleClear = useCallback(() => {
    setFilter({ search: "" });
  }, []);

  const [delay] = useTimeout();
  const handleSearch = useCallback((value) => {
    delay(() => {
      setFilter({ search: value });
    });
  }, []);

  const handleSelect = useCallback(
    async (value) => {
      let base = null;
      if (value && productBase?.id !== value) {
        const newBase = await getProductBaseVariants([value]);
        base = newBase[value];
      }
      onChange(base);
    },
    [productBase],
  );

  return (
    <Select
      showSearch
      allowClear
      disabled={disabled}
      defaultValue={value}
      options={options}
      filterOption={false}
      onClear={handleClear}
      onSearch={handleSearch}
      onSelect={handleSelect}
      loading={loading}
    />
  );
}

function getBases(data) {
  const nodes = get(data, "productBasesForSeller.nodes") || [];

  const res = [];
  for (let node of nodes) {
    if (!node || !node.id) continue;

    res.push({ value: node.id, label: node.title });
  }

  return res;
}

function mergeBases(curBase, bases) {
  const res = [];
  if (curBase?.id) {
    res.push({ value: curBase.id, label: curBase.title });
  }

  return Array.from(
    new Map(
      [...res, ...bases]
        .map((item) => (item?.value ? [item.value, item] : null))
        .filter(Boolean),
    ).values(),
  );
}

export default memo(forwardRef(ProductBaseSelect));
