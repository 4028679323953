import { Form, Icon as LegacyIcon } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { capitalize, get, isEqual } from "lodash";
import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";
import {
  COLOR_CODES,
  CUSTOM_FULFILLMENT,
  fieldsProductBase,
} from "../../../constants";
import { KPI } from "../../../constants/task";
import {
  GET_PRINTIFY_PRODUCT_PRINT_PROVIDERS,
  GET_PRINTIFY_PRODUCT_PRINT_PROVIDER_VARIANTS,
} from "../../../graphql/queries";
import { handleError, NumberToFixed, toSlug } from "../../../helper";
import history from "../../../history";
import ProductBaseVariants from "../../../pages/admin/ProductBaseVariants";
import {
  CUSTOMCAT_SHIPPING_RATE,
  GEARMENT_SHIPPING_RATE,
  SCALABLEPRESS_DESIGN_TYPE,
} from "../../../variable";
import CarrierPricing from "../../base/CarrierPricing";
import { BaseTimeLine } from "../../base/components/BaseTimeline";
import { ModalVariantsMissing } from "../../base/components/ModalVariantsMissing";
import ProductBaseAttributes from "../../base/ProductBaseAttributes";
import ProductBaseDesignPositions from "../../base/ProductBaseDesignPositions";
// import { filterNewVariants } from "../../base/ProductBaseForm";
import ProductBaseFulfillment from "../../base/ProductBaseFulfillment";
import SupplierPricing from "../../base/SupplierPricing";
import {
  checkFulfillment,
  DESIGN_POSITION_DEFAULT,
  fetchProductDetailPrintway,
  fetchProductForDreamship,
  fetchProductForMerchize,
  fetchProductForTeezily,
  fetchProductsForGearment,
  fetchProductVariantsCustomcat,
  fetchProductVariantsDreamship,
  fetchProductVariantsPrintify,
  fetchVariantsForCustomcat,
  filterNewVariants,
  filterNewVariantsForMerchize,
  formatVariantsForCustomcat,
  formatVariantsForDreamship,
  genDesignPositionForPrintway,
  genDPDreamship,
  genDPScalablepress,
  getAttributesForMerchize,
  getDesignType,
  mapMerchizeSku,
  updateAttributes,
  updateDesignPositions,
} from "../../base/utils";
import Price from "../../Price";
import { CategoryAutocomplete } from "../../shared/CategoryAutocomplete";
import MediaSelectorButton from "../../supplier/MediaSelectorButton";
import Wysiwyg from "../../Wysiwyg";
import JoditEditor from "../../shared/JoditEditor";

const Container = styled.div`
  .ant-legacy-form-item {
    margin-bottom: 0;
    margin-top: 10px;
  }
`;

let showNotifyOnMove = false;

function getUniqueBy(arr = [], key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

const colorPattern = /color(s)*/gi;

export const ADDITIONAL_PRICE_TYPE = "SameItem";

class ProductBaseForm extends Component {
  state = {
    fieldsValue: {},
    handleBtnSave: null,
    showBtnSave: null,
    colorCodes: [],
    productPrintID: null,
    productProviders: [], // Printify
    isPrintify: false,
    loadingProductProviders: false,
    openDPCollapse: true,
    teamMembers: [],
    loadingFFProduct: false,
    productsGearment: [],
    openVM: false,
    loadingVM: false,
    variantsMissing: [],
  };
  submitButtonRef = React.createRef();

  disableSubmitButton = (active) => {
    if (!active) {
      showNotifyOnMove = true;
    }
    this.submitButtonRef.current.disabled = active;
  };

  componentDidMount() {
    const { form, fulfillments, value } = this.props;
    if (this.submitButtonRef) {
      this.submitButtonRef.current.disabled = true;
    }
    showNotifyOnMove = false;

    if (value) {
      let suppliers = getIds(value.suppliers);
      let carriers = getIds(value.carriers);
      const hasVariant = value.hasVariant;
      this.handleColorCodesUpdate(value.variants);

      // hasAdditionalShipping
      const { hasAdditionalShipping } = value;
      form.setFieldsValue({
        suppliers,
        carriers,
        hasVariant,
        variants: value.variants,
        hasAdditionalShipping,
        fulfillment: value.fulfillment,
      });

      const productProviders = [];
      if (value.printifyPrintProviderName && value.printifyPrintProviderID) {
        productProviders.push({
          id: value.printifyPrintProviderID,
          title: value.printifyPrintProviderName,
        });
      }

      const ffID = get(value, "fulfillment.fulfillmentId");
      const ffType = checkFulfillment(fulfillments, ffID);

      this.setState({
        defaultValue: JSON.parse(JSON.stringify(value)),
        scalablePressDesignType: value.scalablePressDesignType,
        scalableOriginID: value.scalableOriginID,
        scalablePressType: value.scalablePressType,
        productProviders,
        ...ffType,
      });
      return;
    }
    let ff = null;
    if (fulfillments?.length > 0) {
      ff = fulfillments.find(({ slug }) => slug === CUSTOM_FULFILLMENT);
      if (ff) {
        ff.fulfillmentId = ff.id;
      }
    }

    form.setFieldsValue({
      hasVariant: true,
      fulfillment: ff,
    });

    const ffType = checkFulfillment(fulfillments, ff?.fulfillmentId);
    this.setState({
      ...ffType,
      fieldsValue: JSON.parse(
        JSON.stringify(form.getFieldsValue(fieldsProductBase)),
      ),
    });
  }

  componentDidUpdate(prevProps) {
    let { clickSave, onSubmit, form, value, fulfillments } = this.props;
    if (clickSave !== prevProps.clickSave) {
      form.validateFields((err, values) => {
        if (!err) {
          if (values.fulfillment && values.fulfillment.__typename) {
            delete values.fulfillment.__typename;
          }
          if (values.fulfillment && values.fulfillment.data) {
            delete values.fulfillment.data;
          }
          if (values.attributes) {
            values.attributes = values.attributes.map(
              (attribute) => (delete attribute.__typename, attribute),
            );
          }
          if (values.variants) {
            values.variants = values.variants.map(
              (variant) => (
                delete variant.__typename,
                delete variant.sorting,
                (variant.attributes = variant.attributes.map(
                  (a) => (delete a.__typename, a),
                )),
                variant
              ),
            );
          }

          if (values.designPositions && values.designPositions.length) {
            values.designPositions = values.designPositions.map((dp) => {
              delete dp.imageUrl;
              dp.productBaseVariants =
                dp.productBaseVariants &&
                dp.productBaseVariants.map(
                  (pb) => (
                    delete pb.__typename,
                    pb.attributes &&
                      pb.attributes.map((at) => (delete at.__typename, at)),
                    pb
                  ),
                );
              if (dp.extraFee) {
                dp.extraFee = parseFloat(dp.extraFee);
              }
              return dp;
            });
          }
          if (values.images && values.images.length) {
            values.images = values.images.map((img) => img.id);
          }
          if (onSubmit) {
            onSubmit(values);
          }
        }
      });
    }
    if (!isEqual(value, prevProps.value)) {
      let suppliers = getIds(value.suppliers);
      let carriers = getIds(value.carriers);
      const hasVariant = value.hasVariant;
      this.handleColorCodesUpdate(value.variants);

      // hasAdditionalShipping
      const { hasAdditionalShipping } = value;
      form.setFieldsValue({
        suppliers,
        carriers,
        hasVariant,
        variants: value.variants,
        hasAdditionalShipping,
        fulfillment: value.fulfillment,
      });

      const productProviders = [];
      if (value.printifyPrintProviderName && value.printifyPrintProviderID) {
        productProviders.push({
          id: value.printifyPrintProviderID,
          title: value.printifyPrintProviderName,
        });
      }

      const ffID = get(value, "fulfillment.fulfillmentId");
      const ffType = checkFulfillment(fulfillments, ffID);

      this.setState({
        defaultValue: JSON.parse(JSON.stringify(value)),
        scalablePressDesignType: value.scalablePressDesignType,
        scalableOriginID: value.scalableOriginID,
        scalablePressType: value.scalablePressType,
        productProviders,
        ...ffType,
      });
    }
  }

  generateVariants = (arrays) => {
    if (arrays.length === 0) {
      return [];
    }
    let results = [[]];
    arrays.forEach((attribute) => {
      let tmp = [];
      results.forEach((resultItem) => {
        attribute.options.forEach((option) => {
          tmp.push(
            resultItem.concat([
              {
                name: attribute.name,
                slug: attribute.slug,
                option,
              },
            ]),
          );
        });
      });
      results = tmp;
    });

    return results;
  };

  handleUpdateVariantSupplierPricing = (selected, variants) => {
    variants = variants.map((variant) => {
      if (selected && selected.length === 0) {
        variant.supplierPricing = [];
      } else {
        if (!variant.supplierPricing) {
          variant.supplierPricing = [];
        }
        variant.supplierPricing = variant.supplierPricing.filter((v) =>
          selected.includes(v.userId),
        );
        for (let i = 0; i < selected.length; i++) {
          const val = Array.isArray(selected[i]) ? selected[i][0] : selected[i];
          if (!variant.supplierPricing.find((v) => v.userId === val)) {
            variant.supplierPricing.push({
              userId: val,
              price: null,
            });
          }
        }
      }
      return variant;
    });
    return variants;
  };

  handleUpdateVariantCarrierPricing(selected, variants) {
    const hasAdditionalShipping = this.props.form.getFieldValue(
      "hasAdditionalShipping",
    );

    variants = variants.map((variant) => {
      if (selected && selected.length === 0) {
        variant.carrierPricing = [];
      } else {
        if (!variant.carrierPricing) {
          variant.carrierPricing = [];
        }
        variant.carrierPricing = variant.carrierPricing.filter((v) =>
          selected.includes(v.carrierId),
        );
        for (let i = 0; i < ((selected && selected.length) || 0); i++) {
          if (
            !variant.carrierPricing.find((cp) => cp.carrierId === selected[i])
          ) {
            const newItem = {
              carrierId: selected[i],
              price: null,
              default: false,
            };

            //hasAdditionalShipping
            if (hasAdditionalShipping) {
              newItem.usingAdditionalPrice = true;
              newItem.additionalPriceType = ADDITIONAL_PRICE_TYPE;
              newItem.additionalPrice = null;
            }

            variant.carrierPricing.push(newItem);
          }
        }
      }
      return variant;
    });
    return variants;
  }

  handleUpdateOnSupplierChange(selected = []) {
    const { form } = this.props;
    let supplierPricing = form.getFieldValue("supplierPricing");
    let variants = form.getFieldValue("variants");
    if (!supplierPricing || selected.length === 0) {
      supplierPricing = [];
    }
    supplierPricing = supplierPricing.filter((sp) =>
      selected.includes(sp.userId),
    );
    for (let i = 0; i < selected.length; i++) {
      const val = Array.isArray(selected[i]) ? selected[i][0] : selected[i];
      if (!supplierPricing.find((sp) => sp.userId === val)) {
        supplierPricing.push({
          userId: val,
          price: null,
        });
      }
    }
    //handle variants supplierPricing
    if (variants && variants.length) {
      variants = this.handleUpdateVariantSupplierPricing(selected, variants);
    }

    form.setFieldsValue({
      supplierPricing,
      variants,
    });
  }

  handleChangeDesignPosition(attributes) {
    const { form } = this.props;
    const designPositions = form.getFieldValue("designPositions");
    if (
      !attributes ||
      attributes.length === 0 ||
      !designPositions ||
      designPositions.length === 0
    )
      return;

    const newDS = designPositions.map((dp) => {
      const { productBaseVariants } = dp || {};
      const newVariants = (productBaseVariants || []).filter((v) => {
        const { attributes: attrs } = v || {};
        let flag = [];
        for (let i = 0; i < attrs.length; i++) {
          const { slug, option } = attrs[i];
          const cur = (attributes || []).find((item) => item.slug === slug);
          if (cur?.options?.length > 0) {
            flag[i] = cur.options.includes(option);
          }
        }

        return flag.every(Boolean);
      });

      return {
        ...dp,
        productBaseVariants: newVariants,
      };
    });

    form.setFieldsValue({ designPositions: newDS });
  }

  handleOnAttributeUpdate(attributes, fulfillment) {
    const { form } = this.props;
    const oldVariants = form.getFieldValue("variants");
    let validAttributes = [];
    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      if (attribute.name && attribute.slug && attribute.options.length) {
        validAttributes.push(attribute);
      }
    }
    let variants = getCombineVariants(validAttributes, oldVariants);
    const { isCustom } = this.state;
    if (!fulfillment || isCustom) {
      const supplierIds = form.getFieldValue("suppliers");
      variants = this.handleUpdateVariantSupplierPricing(
        supplierIds ? [supplierIds] : [],
        variants,
      );
      variants = this.handleUpdateVariantCarrierPricing(
        form.getFieldValue("carriers"),
        variants,
      );
    }

    this.handleColorCodesUpdate(variants);
    this.handleChangeDesignPosition(attributes);
    form.setFieldsValue({
      variants,
    });
  }

  handleColorCodesUpdate(variants = []) {
    const { form } = this.props;
    const oldColorCodes = form.getFieldValue("colorCodes");
    const colorCodes = variants.reduce((acc, item) => {
      const attributes = item.attributes;
      const hasColor =
        attributes?.length > 0
          ? attributes
              .filter((att) => att?.name?.match(colorPattern))
              .map((att) => ({ name: att.option, value: "", patternImage: [] }))
          : [];

      let result = getUniqueBy([...acc, ...hasColor], "name");

      function findItem(item, target) {
        return item.name?.toLowerCase() === target.name?.toLowerCase();
      }

      result = result.map((item) => {
        const matched = (oldColorCodes || []).find((i) => findItem(i, item));
        const mapValueByName = COLOR_CODES.find((i) => findItem(i, item));

        if (matched != null) {
          return {
            ...item,
            value: matched.value,
            patternImage: matched.patternImage,
          };
        } else if (mapValueByName != null && !item.value) {
          return {
            ...item,
            value: mapValueByName.value,
          };
        }
        return item;
      });
      return result;
    }, []);

    form.setFieldsValue({
      colorCodes,
    });

    this.setState({ colorCodes });
  }

  handleUpdateOnCarrierChange(selected) {
    const { getFieldValue, setFieldsValue } = this.props.form;
    let carrierPricing = getFieldValue("carrierPricing");
    let variants = getFieldValue("variants");
    const hasAdditionalShipping = getFieldValue("hasAdditionalShipping");

    if (!carrierPricing || selected.length === 0) {
      carrierPricing = [];
    }
    if (carrierPricing && carrierPricing.length) {
      carrierPricing = carrierPricing.filter((c) =>
        selected.includes(c.carrierId),
      );
    }
    for (let i = 0; i < (selected && selected.length) || 0; i++) {
      if (!carrierPricing.find((c) => c.carrierId === selected[i])) {
        const newItem = {
          carrierId: selected[i],
          price: null,
          default: false,
        };

        //hasAdditionalShipping
        if (hasAdditionalShipping) {
          newItem.usingAdditionalPrice = true;
          newItem.additionalPriceType = ADDITIONAL_PRICE_TYPE;
          newItem.additionalPrice = null;
        }
        carrierPricing.push(newItem);
      }
    }
    // handle variants carrier pricing
    if (variants && variants.length) {
      variants = this.handleUpdateVariantCarrierPricing(selected, variants);
    }
    let defaultSelectIndex = 0;
    for (let i = 0; i < carrierPricing.length; i++) {
      if (carrierPricing[i].default) {
        defaultSelectIndex = i;
      }
    }
    if (carrierPricing.length) {
      carrierPricing[defaultSelectIndex].default = true;
    }
    setFieldsValue({
      carrierPricing: carrierPricing,
      variants,
    });
  }

  // Get product information for Printify fulfillment.
  getProductIntoPrintify = async (productID) => {
    const { __apolloClient__: client } = window;
    if (!productID || !client) return;

    this.setState({ loadingProductProviders: true });
    try {
      const { data } = await client.query({
        query: GET_PRINTIFY_PRODUCT_PRINT_PROVIDERS,
        variables: {
          productID,
        },
      });

      if (data?.getPrintifyProductPrintProviders?.length === 0) {
        notification.error({
          message: "Product print providers counld'nt found.",
        });
        return;
      }

      const nodes = data.getPrintifyProductPrintProviders.map(
        ({ id, title }) => ({ id, title }),
      );
      this.setState({ productProviders: nodes, productPrintID: productID });
    } catch (err) {
      notification.error({ message: err?.toString() });
    } finally {
      this.setState({ loadingProductProviders: false });
    }
  };

  getProductVariantsIntoPrintify = async (printProviderID, record) => {
    const { __apolloClient__: client } = window;
    const { productPrintID } = this.state;
    if (!productPrintID || !printProviderID || !client) return;

    try {
      const { data } = await client.query({
        query: GET_PRINTIFY_PRODUCT_PRINT_PROVIDER_VARIANTS,
        variables: {
          productID: productPrintID,
          printProviderID: printProviderID + "",
        },
      });

      const variants = get(
        data,
        "getPrintifyProductPrintProviderVariants.variants",
        [],
      );
      if (variants.length === 0) {
        notification.error({
          message: "Product variants print providers counld'nt found.",
        });
        return;
      }

      const { variants: newVariants, attributes } =
        formatVariantsForPrintify(variants);
      const newDS = genDesignPositionForPrintify(variants, newVariants);

      const { getFieldValue, setFieldsValue } = this.props.form;
      const fulfillment = getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = newVariants;
        fulfillment.data.attributes = attributes;
      }

      setFieldsValue({
        variants: newVariants,
        designPositions: newDS,
        attributes,
        fulfillment,
      });

      const { children } = record;
      this.setState({
        printifyPrintProviderName: children?.trim(),
      });
    } catch (err) {
      notification.error({ message: err?.toString() });
    }
  };

  handleChangeFulfillment = (v, designType) => {
    const { fulfillments } = this.props;
    const fulfillmentId = get(v, "fulfillmentId");
    const ffType = checkFulfillment(fulfillments, fulfillmentId);
    const { isDreamship, isScalablepress } = ffType;

    let dps = [DESIGN_POSITION_DEFAULT];
    if (isDreamship) {
      dps = genDPDreamship(v);
    } else if (isScalablepress) {
      dps = genDPScalablepress(designType);
    }
    this.props.form.setFieldsValue({ designPositions: dps });
  };

  toggleOpen = () => {
    this.setState(({ openDPCollapse }) => ({
      openDPCollapse: !openDPCollapse,
    }));
  };

  onChange = () => {
    this.disableSubmitButton(false);
  };

  getProductDetailPrintway = async (productCode) => {
    try {
      const { form, isEditProductBase } = this.props;
      const res =
        (await fetchProductDetailPrintway(productCode, isEditProductBase)) ||
        {};

      const name = res?.name || "";
      const description = res?.description || "";
      const variants = res?.variants || [];
      const attributes = res?.attributes || [];
      const dataSku = res?.dataSku || {};
      const designPositions = genDesignPositionForPrintway(dataSku, variants);

      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      form.setFieldsValue({
        variants,
        designPositions,
        attributes,
        fulfillment,
        title: name,
        defaultContent: description,
      });
    } catch (err) {
      notification.error({ message: err?.toString() });
    }
  };

  getProductInfoCustomcat = async (id) => {
    try {
      this.setState({ loadingFFProduct: true });
      const { form } = this.props;
      const {
        title,
        description,
        attributes = [],
        variants = [],
        designPositions = [],
      } = (await fetchVariantsForCustomcat(id)) || {};
      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      form.setFieldsValue({
        variants,
        designPositions,
        attributes,
        fulfillment,
        title,
        defaultContent: description,
      });

      this.setState({ loadingFFProduct: false });
    } catch (err) {
      notification.error({ message: err?.toString() });
    }
  };

  getProductInfoGearment = async () => {
    try {
      this.setState({ loadingFFProduct: true });
      const productsGearment = await fetchProductsForGearment();
      this.setState({ loadingFFProduct: false, productsGearment });
    } catch (err) {
      notification.error({ message: err?.toString() });
    }
  };

  matchProductGearment = (id) => {
    if (!id) return;
    const { productsGearment } = this.state;
    if (productsGearment && typeof productsGearment === "object") {
      const { title, variants, attributes, colorCodes } =
        productsGearment[id] || {};

      const { form } = this.props;
      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      form.setFieldsValue({
        variants,
        attributes,
        fulfillment,
        title,
        colorCodes,
      });
    }
  };

  getProductInfoDreamship = async (id) => {
    try {
      this.setState({ loadingFFProduct: true });
      const {
        title,
        description,
        attributes,
        variants,
        designPositions,
        colorCodes,
      } = await fetchProductForDreamship(id);

      const { form } = this.props;
      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      form.setFieldsValue({
        variants,
        defaultContent: description,
        attributes,
        fulfillment,
        title,
        colorCodes,
        designPositions,
      });

      this.setState({ loadingFFProduct: false });
    } catch (err) {
      notification.error("error", err.toString());
    }
  };

  getProductInfoTeezily = async (id) => {
    try {
      this.setState({ loadingFFProduct: true });
      const {
        title,
        variants = [],
        attributes = [],
        designPositions = [],
      } = await fetchProductForTeezily(id);

      const { form } = this.props;
      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      form.setFieldsValue({
        title,
        variants,
        attributes,
        fulfillment,
        designPositions,
      });
    } catch (err) {
      notification.error({ message: err?.toString() });
    } finally {
      this.setState({ loadingFFProduct: false });
    }
  };

  getProductInfoMerchize = async (id, skus) => {
    try {
      this.setState({ loadingFFProduct: true });
      const res = (await fetchProductForMerchize(id)) || {};
      const variants = res.variants || [];
      const attributes = res.attributes || [];
      const { title, description } = res || {};

      const { form } = this.props;
      const fulfillment = form.getFieldValue("fulfillment");
      if (fulfillment?.data) {
        fulfillment.data.variants = variants;
        fulfillment.data.attributes = attributes;
      }

      const newVar = mapMerchizeSku(variants, skus);
      form.setFieldsValue({
        variants: newVar,
        defaultContent: description,
        attributes,
        fulfillment,
        title,
      });

      this.setState({ loadingFFProduct: false });
    } catch (err) {
      notification.error({ message: err?.toString() });
    }
  };

  fetchVariantsMissingForPrintify = async () => {
    const { value, form } = this.props;
    const { printifyPrintProviderID, printifyBlueprintID } = value || {};
    try {
      const variants = await fetchProductVariantsPrintify(
        printifyBlueprintID,
        printifyPrintProviderID,
      );
      const newVariants = filterNewVariants(
        variants,
        form.getFieldValue("variants"),
      );

      const { variants: newVar } = formatVariantsForPrintify(newVariants);

      this.setState({ variantsMissing: newVar, tmpVariants: newVariants });
    } catch (err) {
      notification.error({ message: handleError(err.toString()) });
    }
  };

  fetchVariantsMissingForDreamship = async () => {
    const { value, form } = this.props;
    const baseID = get(value, "id", null);
    if (baseID == null) return;
    try {
      const variants = await fetchProductVariantsDreamship(baseID);
      const newVariants = filterNewVariants(
        variants,
        form.getFieldValue("variants"),
      );

      // Case attributes duplicate
      const count = (newVariants || []).reduce((acc, cur) => {
        const { attributes } = cur;
        const key = (attributes || [])
          .map(({ slug, option }) => `${slug}_${option}`)
          .join("_");
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});

      if (Object.values(count).some((i) => i > 1)) {
        this.setState({
          tmpError:
            "The current Dreamship product catalog differs from the current product base in terms of attributes.",
        });
        return;
      }
      const { variants: newVar } = formatVariantsForDreamship(newVariants);

      this.setState({ variantsMissing: newVar, tmpVariants: newVariants });
    } catch (err) {}
  };

  fetchVariantsMissingForCustomcat = async () => {
    const { value, form } = this.props;
    const baseID = get(value, "id", null);
    if (baseID == null) return;

    try {
      const variants = await fetchProductVariantsCustomcat(baseID);
      const newVariants = filterNewVariants(
        variants,
        form.getFieldValue("variants"),
      );

      // Case attributes duplicate
      const count = (newVariants || []).reduce((acc, cur) => {
        const { attributes } = cur;
        const key = (attributes || [])
          .map(({ slug, option }) => `${slug}_${option}`)
          .join("_");
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});

      if (Object.values(count).some((i) => i > 1)) {
        this.setState({
          tmpError:
            "The current Customcat product catalog differs from the current product base in terms of attributes.",
        });
        return;
      }
      const { variants: newVar } = formatVariantsForCustomcat(newVariants);
      this.setState({ variantsMissing: newVar, tmpVariants: newVariants });
    } catch (err) {}
  };

  fetchVariantsMissingForMerchize = async () => {
    const { merchizeSku, fulfillmentSkus } = this.state;

    if (!merchizeSku || !fulfillmentSkus) return;
    try {
      const { form } = this.props;
      const res = (await fetchProductForMerchize(merchizeSku)) || {};
      const variants = res.variants || [];
      const newVariants = filterNewVariantsForMerchize(
        variants,
        form.getFieldValue("variants"),
        fulfillmentSkus,
      );
      this.setState({ variantsMissing: newVariants, tmpVariants: newVariants });
    } catch (err) {}
  };

  fetchVariantsMissing = async () => {
    const { isPrintify, isDreamship, isCustomcat, isMerchize } = this.state;
    this.setState({ loadingVM: true, openVM: true });
    if (isPrintify) {
      await this.fetchVariantsMissingForPrintify();
    } else if (isDreamship) {
      await this.fetchVariantsMissingForDreamship();
    } else if (isCustomcat) {
      await this.fetchVariantsMissingForCustomcat();
    } else if (isMerchize) {
      await this.fetchVariantsMissingForMerchize();
    }
    this.setState({ loadingVM: false });
  };

  handleVariantsMissingForPrintify = (newVariants) => {
    const { form } = this.props;
    if (!newVariants || newVariants.lengh === 0) return {};
    const { variants: newVar, attributes } =
      formatVariantsForPrintify(newVariants);

    const newDS = genDesignPositionForPrintify(newVariants, newVar);

    const values = form.getFieldsValue([
      "variants",
      "designPositions",
      "attributes",
      "fulfillment",
    ]);
    const mergeAttributes = updateAttributes(values.attributes, attributes);
    const mergeDS = updateDesignPositions(values.designPositions, newDS);
    const mergeVariants = [...values.variants, ...newVar];
    if (values.fulfillment?.data) {
      values.fulfillment.data.variants = mergeVariants;
      values.fulfillment.data.attributes = mergeAttributes;
    }

    return {
      variants: mergeVariants,
      designPositions: mergeDS,
      attributes: mergeAttributes,
      fulfillment: values.fulfillment,
    };
  };

  handleVariantsMissingForMerchize = (newVariants) => {
    if (!newVariants || newVariants.length === 0) return {};

    let attributes = getAttributesForMerchize(newVariants);
    const { form } = this.props;
    const values = form.getFieldsValue([
      "variants",
      "attributes",
      "fulfillment",
    ]);

    const mergeVariants = [...values.variants, ...newVariants];
    const mergeAttributes = updateAttributes(values.attributes, attributes);
    if (values.fulfillment?.data) {
      values.fulfillment.data.variants = mergeVariants;
      values.fulfillment.data.attributes = mergeAttributes;
    }

    return {
      variants: mergeVariants,
      attributes: mergeAttributes,
      fulfillment: values.fulfillment,
    };
  };

  handleVariantsMissingForDreamship = (newVariants) => {
    if (!newVariants || newVariants.length === 0) return {};
    const { isDreamship, isCustomcat } = this.state;
    let newVar = [];
    let attributes = [];

    if (isDreamship) {
      ({ variants: newVar, attributes } =
        formatVariantsForDreamship(newVariants));
    } else if (isCustomcat) {
      ({ variants: newVar, attributes } =
        formatVariantsForCustomcat(newVariants));
    }

    const { form } = this.props;
    const values = form.getFieldsValue([
      "variants",
      "attributes",
      "fulfillment",
    ]);

    const mergeVariants = [...values.variants, ...newVar];
    const mergeAttributes = updateAttributes(values.attributes, attributes);
    if (values.fulfillment?.data) {
      values.fulfillment.data.variants = mergeVariants;
      values.fulfillment.data.attributes = mergeAttributes;
    }

    return {
      variants: mergeVariants,
      attributes: mergeAttributes,
      fulfillment: values.fulfillment,
    };
  };

  handleVariantsMissing = (selected) => {
    const { tmpVariants } = this.state;
    if (
      !selected ||
      selected.length === 0 ||
      !tmpVariants ||
      tmpVariants.length === 0
    )
      return;

    this.disableSubmitButton(false);
    this.setState({ tmpError: null });

    const { isPrintify, isDreamship, isCustomcat, isMerchize } = this.state;
    const { form } = this.props;
    const ids = selected.map(
      ({ fulfillmentProductId }) => fulfillmentProductId,
    );
    const filteredVariants = tmpVariants.filter(
      ({ id, fulfillmentProductId }) => {
        let val = id;
        if (isMerchize) {
          val = fulfillmentProductId;
        }

        return ids.includes(val);
      },
    );

    let newFields = {};
    if (isPrintify) {
      newFields = this.handleVariantsMissingForPrintify(filteredVariants);
    } else if (isDreamship || isCustomcat) {
      newFields = this.handleVariantsMissingForDreamship(filteredVariants);
    } else if (isMerchize) {
      newFields = this.handleVariantsMissingForMerchize(filteredVariants);
    }

    form.setFieldsValue(newFields);
  };

  render() {
    const {
      carriers,
      suppliers,
      loading,
      onSubmit,
      value,
      fulfillments,
      teamMembers,
    } = this.props;

    const { getFieldDecorator, getFieldValue } = this.props.form;
    let { handleBtnSave, showBtnSave, colorCodes, openDPCollapse } = this.state;
    const basicFields = [
      {
        name: "title",
        label: "Title",
        component: (
          <Input
            onChange={(e) => {
              if (!value) {
                this.props.form.setFieldsValue({
                  slug: toSlug(e.target.value),
                });
              }
            }}
            placeholder={"Product base title"}
          />
        ),
        rules: [
          {
            required: true,
            message: "Title is required!",
          },
        ],
      },
      {
        name: "niceName",
        label: "Nice Name",
        component: <Input placeholder={"Nice name"} />,
        rules: [
          {
            required: true,
            message: "Nice name is required!",
          },
        ],
      },
      {
        name: "slug",
        label: "Slug",
        component: <Input placeholder={"Product base slug"} />,
        rules: [
          {
            required: true,
            message: "Slug is required!",
          },
        ],
      },
      {
        name: "baseCostDescription",
        label: "Base cost description",
        rules: [],
        component: <Input placeholder={"You cost $29 - $40"} />,
      },
      {
        name: "defaultContent",
        label: "Default content",
        rules: [],
        // component: <Wysiwyg useDebounce={true} />,
        component: <JoditEditor />,
      },
      // {
      //   name: "defaultShortDescription",
      //   label: "Default short description",
      //   rules: [],
      //   component: <Wysiwyg useDebounce={true} />,
      // },
      // {
      //   name: "details",
      //   label: "Product details",
      //   rules: [],
      //   component: <Wysiwyg useDebounce={true} />,
      // },
    ];
    const selectedCarrierIds = getFieldValue("carriers");
    const selectedSupplierIds = getFieldValue("suppliers");
    const selectedCarriers = mapItemsByIds(carriers, selectedCarrierIds);
    const selectedSuppliers = mapItemsByIds(
      suppliers,
      selectedSupplierIds
        ? Array.isArray(selectedSupplierIds)
          ? selectedSupplierIds
          : [selectedSupplierIds]
        : [],
    );
    const fulfillment = getFieldValue("fulfillment");
    const checkHasFF = isFulfillment(fulfillment);

    const hasVariant = getFieldValue("hasVariant");
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    if (value && value.variants && value.variants.length) {
      value.variants = value.variants.map((v) => {
        if (v.carrierPricing && v.carrierPricing.length) {
          let defaultIndex = getDefaultIndex(v.carrierPricing);
          // for (let i = 0; i < v.carrierPricing.length; i++) {
          //   if (v.carrierPricing[i].default) {
          //     defaultIndex = i;
          //   }
          // }
          v.carrierPricing[defaultIndex].default = true;
        }
        return v;
      });
    }
    if (value && value.carrierPricing && value.carrierPricing.length) {
      let defaultIndex = getDefaultIndex(value.carrierPricing);
      // for (let i = 0; i < value.carrierPricing.length; i++) {
      //   if (value.carrierPricing[i].default) {
      //     defaultIndex = i;
      //   }
      // }
      value.carrierPricing[defaultIndex].default = true;
    }

    const isSingleShipment = getFieldValue("isSingleShipment");
    const isDigitalProduct = getFieldValue("isDigitalProduct");
    const customcatShipping = value && value.customcatShiping;

    const {
      isDreamship,
      isCustomcat,
      isGearment,
      isScalablepress,
      isPrintify,
      isCustom,
      isMerchize,
      isPrintway,
    } = this.state;
    const showShipping = isCustomcat || isGearment;

    const selectedShippingRate = getFieldValue("shippingRate");

    let DEFAULT_SHIPPING_RATE = [];
    if (customcatShipping && customcatShipping.shippingRate) {
      DEFAULT_SHIPPING_RATE = CUSTOMCAT_SHIPPING_RATE.filter(
        (i) => i.value !== customcatShipping.shippingRate,
      );
    } else {
      DEFAULT_SHIPPING_RATE = CUSTOMCAT_SHIPPING_RATE;
    }

    let currentShippingRate = null;
    if (isCustomcat) {
      currentShippingRate = DEFAULT_SHIPPING_RATE.find(
        (i) => i.value === selectedShippingRate,
      );
    }

    let currentShippCost = null;
    if (
      !DEFAULT_SHIPPING_RATE.map((i) => i.value).includes(selectedShippingRate)
    ) {
      currentShippCost = customcatShipping;
    } else {
      currentShippCost =
        currentShippingRate && currentShippingRate.shippingCost;
    }

    if (isDreamship && !customcatShipping) {
      currentShippCost = GEARMENT_SHIPPING_RATE.shippingCost;
    }

    // Check Scalabel press
    let isFFScalabelPress = isScalablepress;
    let designTypeSP = SCALABLEPRESS_DESIGN_TYPE;
    let designTypeSPArr = [];
    if (designTypeSP) {
      for (let [key, value] of Object.entries(designTypeSP)) {
        designTypeSPArr.push({ key, value });
      }
    }

    /// Get Scalablepress design type.
    let { scalablePressDesignType } = this.state;
    let scalablePressDT = scalablePressDesignType;

    // Printify
    const { productProviders, loadingProductProviders } = this.state;

    return (
      <React.Fragment>
        <Prompt
          when={showNotifyOnMove}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Container>
          <Form
            {...layout}
            onSubmit={(e) => {
              this.setState({ isPrompt: true });
              e.preventDefault();
              this.props.form.validateFields((err, values) => {
                if (!err) {
                  // Check every variant's regularPrice have value greater than 0
                  const { variants } = values;
                  const invalidPrice = (variants || []).some(
                    ({ regularPrice }) =>
                      regularPrice == null || regularPrice < 1,
                  );
                  if (invalidPrice) {
                    notification.error({
                      message: "You must enter regular price!",
                    });
                    return;
                  }

                  // Check every variant's fulfillmentProductId
                  const {
                    isCustomcat,
                    isDreamship,
                    isGearment,
                    isScalablepress,
                    isPrintify,
                    isTeezily,
                  } = this.state;
                  const isFFSpecial = [
                    isCustomcat,
                    isDreamship,
                    isGearment,
                    isScalablepress,
                    isPrintify,
                    isTeezily,
                  ].some(Boolean);

                  if (isFFSpecial) {
                    const invalidPrice = (variants || []).some(
                      ({ fulfillmentProductId }) =>
                        fulfillmentProductId == null ||
                        fulfillmentProductId == "",
                    );

                    if (invalidPrice) {
                      notification.error({
                        message: "You must enter fulfillment product id!",
                      });
                      return;
                    }
                  }

                  // Fulfillment
                  if (values.fulfillment && values.fulfillment.__typename) {
                    delete values.fulfillment.__typename;
                  }

                  if (
                    values.fulfillment &&
                    values.fulfillment.data !== undefined
                  ) {
                    delete values.fulfillment.data;
                  }

                  if (values?.fulfillment != null) {
                    let newFF = {};
                    if (Object.keys(values.fulfillment).length > 0) {
                      const { fulfillmentId, productId, presetId } =
                        values.fulfillment;
                      newFF = { fulfillmentId, productId, presetId };
                    } else {
                      newFF = null;
                    }
                    values.fulfillment = newFF;
                  }

                  if (values.attributes) {
                    values.attributes = values.attributes.map(
                      (attribute) => (delete attribute.__typename, attribute),
                    );
                  }

                  // Color codes
                  let colorCodes = filterColorCode(values.colorCodes);
                  values.colorCodes = colorCodes;

                  if (values.variants) {
                    values.variants = values.variants.map((variant) => {
                      delete variant.__typename;
                      delete variant.sorting;

                      variant.attributes = variant.attributes.map((a) => {
                        delete a.__typename;

                        const option = a.option;

                        // Find colorCodes by option
                        const matched = colorCodes.find(
                          (i) => i.name === option,
                        );
                        if (matched != null) {
                          variant.colorCodes = matched;
                        }

                        return a;
                      });

                      return variant;
                    });
                  }

                  // Scalabel Press
                  let { scalablePressType, scalableOriginID } = this.state;
                  let errorSP = {};
                  let canSubmit = true;

                  // Check Scalabel press
                  const { isScalablepress: isFFScalabelPress } = this.state;

                  if (isFFScalabelPress) {
                    values["scalablePressType"] = scalablePressType;
                    values["scalableOriginID"] = scalableOriginID;
                  }

                  if (values.designPositions && values.designPositions.length) {
                    values.designPositions = values.designPositions.map(
                      (dp, index) => {
                        delete dp.imageUrl;
                        dp.artworkGuidelines =
                          dp.artworkGuidelines &&
                          dp.artworkGuidelines
                            .map((ag) => {
                              let { fileId, description } = ag;
                              if (fileId) {
                                return {
                                  fileId,
                                  description,
                                };
                              }
                            })
                            .filter(Boolean);
                        dp.productBaseVariants =
                          dp.productBaseVariants &&
                          dp.productBaseVariants.map(
                            (pb) => (
                              delete pb.__typename,
                              pb.attributes &&
                                pb.attributes.map(
                                  (at) => (delete at.__typename, at),
                                ),
                              pb
                            ),
                          );
                        let {
                          width,
                          height,
                          horizontal,
                          top,
                          bottom,
                          ...rest
                        } = dp;
                        dp = rest;
                        if (
                          ["dtg", "poster"].includes(
                            values.scalablePressDesignType,
                          )
                        ) {
                          if (!width && !height) {
                            errorSP[index] = {
                              ...errorSP[index],
                              dimensions:
                                "For design position, width or height is required.",
                            };
                          }
                        }

                        if (["dtg"].includes(values.scalablePressDesignType)) {
                          if (!top && !bottom) {
                            errorSP[index] = {
                              ...errorSP[index],
                              position:
                                "For design position, top or bottom is required.",
                            };
                          }
                        }

                        if (
                          isFFScalabelPress &&
                          ["dtg", "poster"].includes(
                            values.scalablePressDesignType,
                          )
                        ) {
                          dp["scalablePressCustomize"] = {
                            dimensions: {
                              ...(width ? { width } : {}),
                              ...(height ? { height } : {}),
                            },
                            position: {
                              horizontal: horizontal ? horizontal : "",
                              offset: {
                                ...(top ? { top } : {}),
                                ...(bottom ? { bottom } : {}),
                              },
                            },
                          };
                        }
                        return dp;
                      },
                    );
                  }

                  if (isFFScalabelPress) {
                    if (errorSP && Object.keys(errorSP).length) {
                      Object.keys(errorSP).forEach((key, index) => {
                        if (index === 0) {
                          let err = errorSP[key];
                          for (let [, value] of Object.entries(err)) {
                            notification.error({
                              message: value,
                            });
                          }
                        }
                      });
                      canSubmit = false;
                    } else {
                      canSubmit = true;
                    }
                  }

                  if (values.images && values.images.length) {
                    values.images = values.images.map((img) => img.id);
                  }

                  let {
                    isSingleShipment,
                    usFirstFee,
                    usAdditionalFee,
                    canadaFirstFee,
                    canadaAdditionalFee,
                    internationalFirstFee,
                    internationalAdditionalFee,
                    shippingRate,
                    ...rest
                  } = values;
                  values = rest;

                  if (showShipping) {
                    values["customcatShipping"] = {
                      isSingleShipment,
                      usFirstFee,
                      usAdditionalFee,
                      canadaFirstFee,
                      canadaAdditionalFee,
                      internationalFirstFee,
                      internationalAdditionalFee,
                      shippingRate,
                    };
                  }

                  const { printifyPrintProviderName, printifyBlueprintID } =
                    this.state;
                  if (isPrintify && !value) {
                    values.printifyPrintProviderName =
                      printifyPrintProviderName;
                    values.printifyBlueprintID = printifyBlueprintID;
                  }

                  if (!!value) {
                    delete values.printifyPrintProviderID;
                  }

                  if (values.fulfillment?.fulfillmentInfo) {
                    delete values.fulfillment.fulfillmentInfo;
                  }

                  let hasOptionType = false;
                  if (values.variants?.length > 0) {
                    values.variants = values.variants.map((v) => {
                      const { fulfillmentProductId, attributes } = v || {};
                      v.disabledFPI = undefined; // Reset value
                      if (!hasOptionType) {
                        hasOptionType = attributes.some(({ name, slug }) => {
                          const pt = /^type$/i;
                          return pt.test(name) || pt.test(slug);
                        });
                      }
                      if (typeof fulfillmentProductId === "number") {
                        return {
                          ...v,
                          fulfillmentProductId: fulfillmentProductId + "",
                        };
                      }
                      return v;
                    });
                  }

                  if (values.categories?.length > 0) {
                    values.categories = (getIds(values.categories) || [])[0];
                  }

                  if (values.suppliers && !Array.isArray(values.suppliers)) {
                    values.suppliers = [values.suppliers];
                  }

                  if (values.requiredDesign == null) {
                    values.requiredDesign = false;
                  }

                  if (isCustom) {
                    if (!values.suppliers || values.suppliers.length === 0) {
                      notification.error({
                        message: "You must enter suppliers.",
                      });
                      return;
                    }
                  }
                  if (canSubmit && !hasOptionType) {
                    if (onSubmit) {
                      onSubmit(values);
                      showNotifyOnMove = false;
                    }
                  }
                }
              });
            }}
            onChange={this.onChange}
          >
            <Row gutter={16}>
              <Col span={16}>
                <Card>
                  {basicFields.map((field, index) => (
                    <Form.Item key={index} label={field.label}>
                      {getFieldDecorator(field.name, {
                        initialValue: value
                          ? value[field.name]
                          : field.value
                          ? field.value
                          : null,
                        ...(![
                          "defaultContent",
                          "defaultShortDescription",
                          "details",
                        ].includes(field.name)
                          ? [
                              {
                                trigger: "onBlur",
                                valuePropName: "defaultValue",
                              },
                            ]
                          : []),

                        rules: field.rules,
                      })(field.component)}
                    </Form.Item>
                  ))}
                  <Form.Item label={"Categories"}>
                    {getFieldDecorator("categories", {
                      // initialValue: getIds(value ? value.categories : []),
                      initialValue: value ? value.categories || [] : [],
                      rules: [
                        {
                          required: true,
                          message: "Category is required!",
                        },
                      ],
                    })(
                      // <CategorySelect
                      //   multiple={false}
                      //   placeholder={"Categories"}
                      //   data={
                      //     this.props.categories ? this.props.categories : []
                      //   }
                      // />
                      <CategoryAutocomplete
                        data={
                          this.props.categories ? this.props.categories : []
                        }
                        placeholder="Categories"
                        multiple={false}
                        quickAdd
                        onChange={() => {
                          this.disableSubmitButton(false);
                        }}
                      />,
                    )}
                  </Form.Item>
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Form.Item label={"Image"}>
                    {getFieldDecorator("images", {
                      initialValue: getImages(value ? value.images : []),
                    })(
                      <MediaSelectorButton
                        accept={"image/*"}
                        listType={"picture"}
                        multiple={true}
                        onChange={() => {
                          this.setState({
                            handleBtnSave: !handleBtnSave,
                          });
                        }}
                      />,
                    )}
                  </Form.Item>
                </Card>
                <Card title={"Fulfillment"} style={{ marginTop: 20 }}>
                  <Row>
                    <Col span={24}>
                      {getFieldDecorator("fulfillment", {
                        initialValue: value ? value.fulfillment : null,
                      })(
                        <ProductBaseFulfillment
                          fulfillments={fulfillments}
                          isPrintify={isPrintify && !!value}
                          loadingFFProduct={this.state.loadingFFProduct}
                          getOriginId={(fulfillmentData, originId) => {
                            const fulfillmentSkus = get(
                              fulfillmentData,
                              "fulfillmentSkus",
                            );
                            this.setState({
                              fulfillmentSkus,
                              merchizeSku: originId,
                            });
                          }}
                          onChange={async (v) => {
                            this.disableSubmitButton(false);
                            const fulfillmentId = get(v, "fulfillmentId");
                            const ffType = checkFulfillment(
                              fulfillments,
                              fulfillmentId,
                            );

                            this.setState({
                              productProviders: [],
                              isPrintify: false,
                              tmpError: null,
                              tmpVariants: [],
                              ...ffType,
                            });
                            if (hasVariant && v && v.data) {
                              if (v.data.isChange) {
                                const originId = get(v, "data.originId", null);
                                if (ffType.isPrintify) {
                                  this.setState({
                                    isPrintify: true,
                                    printifyBlueprintID:
                                      Number.parseInt(originId),
                                  });
                                  await this.getProductIntoPrintify(originId);
                                  return;
                                }

                                if (ffType.isPrintway) {
                                  await this.getProductDetailPrintway(originId);
                                  return;
                                }

                                if (ffType.isCustomcat) {
                                  await this.getProductInfoCustomcat(originId);
                                  return;
                                }

                                if (ffType.isGearment) {
                                  this.matchProductGearment(originId);
                                  return;
                                }

                                if (ffType.isDreamship) {
                                  this.getProductInfoDreamship(originId);
                                  return;
                                }

                                if (ffType.isMerchize) {
                                  const skus = get(v, "data.fulfillmentSkus");
                                  this.getProductInfoMerchize(originId, skus);
                                  return;
                                }

                                if (ffType.isTeezily) {
                                  this.getProductInfoTeezily(originId);
                                  return;
                                }

                                this.props.form.setFieldsValue({
                                  attributes: v.data.attributes,
                                });

                                this.handleColorCodesUpdate(v.data.variants);

                                const isEditBase = this.props.isEditProductBase;
                                const newVariants = getVariants(
                                  v.data.variants,
                                  isEditBase,
                                );
                                this.props.form.setFieldsValue({
                                  // variants: v.data.variants,
                                  variants: newVariants,
                                });
                                // Scalabelpress
                                let type = v.data.type;
                                const designType = getDesignType(type);
                                this.props.form.setFieldsValue({
                                  scalablePressDesignType: designType,
                                });
                                this.setState(
                                  {
                                    scalablePressType: v.data.type,
                                    scalableOriginID: v.data.originId,
                                    scalablePressDesignType: designType,
                                  },
                                  () => {
                                    this.handleChangeFulfillment(v, designType);
                                  },
                                );
                              }
                            } else if (
                              hasVariant &&
                              ffType.isGearment &&
                              this.state.productsGearment?.length === 0
                            ) {
                              await this.getProductInfoGearment();
                            } else {
                              this.handleColorCodesUpdate([]);

                              this.props.form.setFieldsValue({
                                variants: [],
                                attributes: [],
                                fulfillment: v,
                                defaultContent: "",
                                designPositions: [DESIGN_POSITION_DEFAULT],
                              });
                            }
                          }}
                        />,
                      )}
                      {
                        // (!fulfillment ||
                        //   (fulfillment && !fulfillment.fulfillmentId) ||
                        //   isCustom)
                        isCustom && (
                          <Form.Item label={"Suppliers"}>
                            {getFieldDecorator("suppliers", {
                              initialValue:
                                value && value.suppliers
                                  ? value.suppliers.map((s) => s.id)
                                  : [],
                              rules: [
                                {
                                  message: "Supplier is required",
                                  required: true,
                                },
                              ],
                            })(
                              <Select
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(selected) => {
                                  this.handleUpdateOnSupplierChange(
                                    selected ? [selected] : [],
                                  );
                                  this.disableSubmitButton(false);
                                }}
                                // mode={"multiple"}
                              >
                                {(isCustom ? teamMembers : suppliers).map(
                                  (supplier, index) => (
                                    <Select.Option
                                      value={supplier.id}
                                      key={index}
                                    >
                                      {`${supplier.firstName} ${supplier.lastName}`}
                                    </Select.Option>
                                  ),
                                )}
                              </Select>,
                            )}
                          </Form.Item>
                        )
                      }
                      {/* TODO: Scalablepress */}
                      {isFFScalabelPress && (
                        <Form.Item label="Design type">
                          {getFieldDecorator("scalablePressDesignType", {
                            initialValue:
                              value && value.scalablePressDesignType,
                            rules: [
                              {
                                message: "Design type is required.",
                                required: true,
                              },
                            ],
                          })(
                            <Select
                              onChange={(newVlaue) => {
                                this.setState({
                                  scalablePressDesignType: newVlaue,
                                });
                              }}
                            >
                              {designTypeSPArr.map((st, index) => (
                                <Select.Option value={st.key} key={index}>
                                  {st.value}
                                </Select.Option>
                              ))}
                            </Select>,
                          )}
                        </Form.Item>
                      )}
                      {!!this.state.isPrintify ? (
                        <Form.Item label="Product Print Providers">
                          {getFieldDecorator("printifyPrintProviderID", {
                            initialValue: value?.printifyPrintProviderID,
                            rules: [
                              {
                                message: "Product Print Provider is required",
                                required: true,
                              },
                            ],
                          })(
                            loadingProductProviders ? (
                              <Spin />
                            ) : (
                              <Select
                                onChange={this.getProductVariantsIntoPrintify}
                                disabled={!!value}
                                showSearch
                                filterOption={(value, option) => {
                                  const opt = (
                                    get(option, "data.title") || ""
                                  ).toLowerCase();
                                  const pt = new RegExp(value, "i");
                                  return pt.test(opt);
                                }}
                              >
                                {productProviders.map((p) => (
                                  <Select.Option
                                    value={p.id}
                                    key={`print-provider-${p.id}`}
                                    data={p}
                                  >
                                    {p.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            ),
                          )}
                        </Form.Item>
                      ) : null}
                      {this.props.isEditProductBase &&
                        (isPrintify ||
                          isDreamship ||
                          isCustomcat ||
                          isMerchize) && (
                          // isPrintify && (
                          <Form.Item>
                            <Button
                              children="Fetch variants missing"
                              onClick={this.fetchVariantsMissing}
                            />
                          </Form.Item>
                        )}
                    </Col>
                    {!hasVariant &&
                    (!fulfillment ||
                      (fulfillment && !fulfillment.fulfillmentId)) ? (
                      <Col span={24}>
                        {selectedSuppliers.length > 0 && (
                          <Card
                            style={{ marginTop: 20 }}
                            title={"Suppliers pricing"}
                          >
                            {getFieldDecorator("supplierPricing", {
                              initialValue:
                                value && value.supplierPricing
                                  ? value.supplierPricing
                                  : [],
                            })(
                              <SupplierPricing
                                suppliers={selectedSuppliers}
                                onChange={() => {
                                  this.setState({
                                    handleBtnSave: !handleBtnSave,
                                  });
                                }}
                              />,
                            )}
                          </Card>
                        )}
                      </Col>
                    ) : null}
                  </Row>
                </Card>
                {isCustom && (
                  <Card title={"Carriers"} style={{ marginTop: 20 }}>
                    <Row>
                      <Col span={24}>
                        {getFieldDecorator("carriers", {
                          initialValue:
                            value && value.carriers
                              ? value.carriers.map((c) => c.id)
                              : [],
                        })(
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(selected) => {
                              this.handleUpdateOnCarrierChange(selected);
                              this.disableSubmitButton(false);
                              /* this.setState({
																handleBtnSave: !handleBtnSave,
															});*/
                            }}
                            mode={"multiple"}
                          >
                            {carriers.map((carrier, index) => (
                              <Select.Option value={carrier.id} key={index}>
                                {carrier.name}
                              </Select.Option>
                            ))}
                          </Select>,
                        )}
                      </Col>
                      <Col span={24}>
                        {!hasVariant && selectedCarriers.length > 0 && (
                          <Card
                            title={"Carrier pricing"}
                            style={{ marginTop: 20 }}
                          >
                            {getFieldDecorator("carrierPricing", {
                              initialValue:
                                value && value.carrierPricing
                                  ? value.carrierPricing
                                  : [],
                            })(
                              <CarrierPricing
                                carriers={selectedCarriers}
                                onChange={() => {
                                  this.disableSubmitButton(false);
                                  /*this.setState({
																		handleBtnSave: !handleBtnSave,
																	});*/
                                }}
                              />,
                            )}
                          </Card>
                        )}
                      </Col>
                    </Row>
                  </Card>
                )}
                <Card
                  title={"Design Task Default KPI"}
                  style={{ marginTop: 20 }}
                >
                  <Row>
                    <Col span={24}>
                      {getFieldDecorator("kpi", {
                        initialValue: value && value.kpi ? value.kpi : 0.2,
                      })(
                        <Select
                          onChange={() => {
                            this.disableSubmitButton(false);
                            /* this.setState({
															handleBtnSave: !handleBtnSave,
														});*/
                          }}
                        >
                          <Select.Option value={null} key={"default-kpi"}>
                            Select Default KPI
                          </Select.Option>
                          {KPI.map((kpi, idx) => (
                            <Select.Option value={kpi} key={idx}>
                              {kpi}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </Col>
                  </Row>
                </Card>
                <Card
                  hidden={!showShipping}
                  style={{ marginTop: 20 }}
                  title={"Shipping"}
                >
                  {isCustomcat && (
                    <Form.Item label={"Customcat shipping rate"}>
                      {getFieldDecorator("shippingRate", {
                        initialValue: customcatShipping
                          ? customcatShipping.shippingRate
                          : null,
                      })(<CustomShippingRate />)}
                    </Form.Item>
                  )}

                  <Form.Item style={{ marginTop: 0 }}>
                    {getFieldDecorator("isSingleShipment", {
                      initialValue: customcatShipping
                        ? customcatShipping.isSingleShipment
                        : false,
                      valuePropName: "checked",
                    })(
                      <Checkbox
                        onChange={(e) => {
                          let checked = e.target.checked;
                          if (checked) {
                            this.props.form.setFieldsValue({
                              shippingRate: null,
                            });
                          }
                        }}
                      >
                        Is single shipment?
                      </Checkbox>,
                    )}
                  </Form.Item>
                  <Form.Item label="US First product fee">
                    {getFieldDecorator("usFirstFee", {
                      initialValue: currentShippCost
                        ? currentShippCost.usFirstFee
                        : null,
                      rules: [
                        {
                          required: showShipping,
                          message: "US First product fee is required",
                        },
                      ],
                    })(
                      <Price
                        onChange={() => {
                          this.disableSubmitButton(false);
                          /*this.setState({
														handleBtnSave: !handleBtnSave,
													});*/
                        }}
                      />,
                    )}
                  </Form.Item>
                  {!isSingleShipment && (
                    <Form.Item label="US Additional products fee">
                      {getFieldDecorator("usAdditionalFee", {
                        initialValue: currentShippCost
                          ? currentShippCost.usAdditionalFee
                          : null,
                      })(
                        <Price
                          onChange={() => {
                            this.disableSubmitButton(false);
                            /* this.setState({
															handleBtnSave: !handleBtnSave,
														});*/
                          }}
                        />,
                      )}
                    </Form.Item>
                  )}
                  <Form.Item label="Canada First product fee">
                    {getFieldDecorator("canadaFirstFee", {
                      initialValue: currentShippCost
                        ? currentShippCost.canadaFirstFee
                        : null,
                      rules: [
                        {
                          required: showShipping,
                          message: "Canada First product fee is required",
                        },
                      ],
                    })(
                      <Price
                        onChange={() => {
                          this.disableSubmitButton(false);
                          /*this.setState({
														handleBtnSave: !handleBtnSave,
													});*/
                        }}
                      />,
                    )}
                  </Form.Item>
                  {!isSingleShipment && (
                    <Form.Item label="Canada Additional products fee">
                      {getFieldDecorator("canadaAdditionalFee", {
                        initialValue: currentShippCost
                          ? currentShippCost.canadaAdditionalFee
                          : null,
                      })(
                        <Price
                          onChange={() => {
                            this.disableSubmitButton(false);
                            /*this.setState({
															handleBtnSave: !handleBtnSave,
														});*/
                          }}
                        />,
                      )}
                    </Form.Item>
                  )}
                  <Form.Item label="International First product fee">
                    {getFieldDecorator("internationalFirstFee", {
                      initialValue: currentShippCost
                        ? currentShippCost.internationalFirstFee
                        : null,
                      rules: [
                        {
                          required: showShipping,
                          message:
                            "International First product fee is required",
                        },
                      ],
                    })(
                      <Price
                        onChange={() => {
                          this.disableSubmitButton(false);
                          /*this.setState({
														handleBtnSave: !handleBtnSave,
													});*/
                        }}
                      />,
                    )}
                  </Form.Item>
                  {!isSingleShipment && (
                    <Form.Item label="International Additional product fee">
                      {getFieldDecorator("internationalAdditionalFee", {
                        initialValue: currentShippCost
                          ? currentShippCost.internationalAdditionalFee
                          : null,
                      })(
                        <Price
                          onChange={() => {
                            this.disableSubmitButton(false);
                            /*this.setState({
															handleBtnSave: !handleBtnSave,
														});*/
                          }}
                        />,
                      )}
                    </Form.Item>
                  )}
                </Card>
                <Card
                  hidden={hasVariant}
                  title={"Pricing"}
                  style={{ marginTop: 20 }}
                >
                  <div>
                    <Form.Item label="Regular Price">
                      {getFieldDecorator("regularPrice", {
                        initialValue: value ? value.regularPrice : null,
                        rules: !hasVariant
                          ? [
                              {
                                required: true,
                                message: "Regular price is required",
                              },
                            ]
                          : [],
                      })(
                        <Price
                          onChange={() => {
                            this.disableSubmitButton(false);
                            /*this.setState({
															handleBtnSave: !handleBtnSave,
														});*/
                          }}
                        />,
                      )}
                    </Form.Item>
                    <Form.Item label="Sale Price">
                      {getFieldDecorator("salePrice", {
                        initialValue: value ? value.salePrice : null,
                      })(
                        <Price
                          onChange={() => {
                            this.disableSubmitButton(false);
                            /*this.setState({
															handleBtnSave: !handleBtnSave,
														});*/
                          }}
                        />,
                      )}
                    </Form.Item>

                    <Form.Item label="Seller Price">
                      {getFieldDecorator("sellerPrice", {
                        initialValue: value ? value.sellerPrice : null,
                      })(
                        <Price
                          onChange={() => {
                            this.disableSubmitButton(false);
                            /*this.setState({
															handleBtnSave: !handleBtnSave,
														});*/
                          }}
                        />,
                      )}
                    </Form.Item>
                  </div>
                </Card>
                <Card
                  style={{ marginTop: "20px" }}
                  bodyStyle={{ padding: "10px 24px" }}
                  hidden={
                    !isCustom || Object.keys(fulfillment || {}).length > 0
                  }
                >
                  <Form.Item>
                    {getFieldDecorator("passOnHold", {
                      initialValue: value ? value.passOnHold : false,
                      valuePropName: "checked",
                    })(
                      <Checkbox>
                        Auto pass on hold for orders of this product base
                      </Checkbox>,
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("isDigitalProduct", {
                      initialValue: value ? value.isDigitalProduct : false,
                      valuePropName: "checked",
                    })(
                      <Checkbox>
                        Is digital products? (Often used to map with Etsy's
                        digital products)
                      </Checkbox>,
                    )}
                  </Form.Item>
                  {!!isDigitalProduct ? (
                    <Form.Item>
                      {getFieldDecorator("requiredDesign", {
                        initialValue: value ? !!value.requiredDesign : true,
                        valuePropName: "checked",
                      })(
                        <Checkbox
                          children="Using design task Workflow(digital product base also creates design task like normal product base) "
                          onChange={() => {
                            this.disableSubmitButton(false);
                          }}
                        />,
                      )}
                    </Form.Item>
                  ) : null}
                </Card>
              </Col>
            </Row>
            <Card
              title={
                <div>
                  <h3>Variants</h3>
                  <p className={"sub-card-title"}>
                    {getFieldDecorator("hasVariant", {
                      initialValue: value ? value.hasVariant : true,
                      valuePropName: "checked",
                    })(
                      <span>
                        {" "}
                        This product base has multiple options, like different
                        sizes or colors
                      </span>,
                      // <Checkbox
                      //   onChange={(e) => {
                      //     let selected = this.props.form.getFieldValue(
                      //       "suppliers",
                      //     );
                      //     selected = selected ? [selected] : [];
                      //     this.handleUpdateOnSupplierChange(selected);
                      //     this.handleUpdateOnCarrierChange(
                      //       this.props.form.getFieldValue("carriers"),
                      //     );
                      //     if (e.target.checked) {
                      //       const attributes = this.props.form.getFieldValue(
                      //         "attributes",
                      //       );
                      //       this.handleOnAttributeUpdate(
                      //         attributes ? attributes : [],
                      //         fulfillment,
                      //       );
                      //       if (fulfillment && fulfillment.data) {
                      //         this.props.form.setFieldsValue({
                      //           variants: fulfillment.data.variants,
                      //           attributes: fulfillment.data.attributes,
                      //         });
                      //       } else {
                      //         this.props.form.setFieldsValue({
                      //           variants: [],
                      //           attributes: [],
                      //         });
                      //       }
                      //     }
                      //     if (this.props.value) {
                      //       this.setState({
                      //         handleBtnSave: !handleBtnSave,
                      //       });
                      //     }
                      //   }}
                      // >
                      //   This product base has multiple options, like different
                      //   sizes or colors
                      // </Checkbox>,
                    )}
                  </p>
                </div>
              }
              style={{ marginTop: 20, width: "100%" }}
            >
              {hasVariant && (
                <div>
                  <h3>OPTIONS</h3>
                  {getFieldDecorator("attributes", {
                    initialValue:
                      (fulfillment &&
                        fulfillment.data &&
                        fulfillment.data.attributes) ||
                      (value && value.attributes && value.attributes) ||
                      [],
                  })(
                    <ProductBaseAttributes
                      disableSubmitButton={this.disableSubmitButton}
                      hasProduct={!!this.props.hasProduct}
                      onChange={(attributes) => {
                        this.disableSubmitButton(false);
                        this.handleOnAttributeUpdate(attributes, fulfillment);
                      }}
                    />,
                  )}
                </div>
              )}
            </Card>

            {hasVariant && (
              <Form.Item style={{ marginTop: 20 }}>
                {getFieldDecorator("variants", {
                  initialValue:
                    (fulfillment &&
                      fulfillment.data &&
                      fulfillment.data.variants) ||
                    (value && value.variants) ||
                    [],
                })(
                  <ProductBaseVariants
                    isCustom={isCustom}
                    isCustomcat={isCustomcat}
                    isMerchize={isMerchize}
                    isPrintway={isPrintway}
                    hasFulfillment={!!checkHasFF} // !!fulfillment
                    hasProduct={!!this.props.hasProduct}
                    carriers={selectedCarriers}
                    suppliers={selectedSuppliers}
                    isEditProductBase={this.props.isEditProductBase}
                    form={this.props.form}
                    hasAdditionalShipping={value?.hasAdditionalShipping}
                    onChange={() => {
                      this.disableSubmitButton(false);
                      /*this.setState({
												handleBtnSave: !handleBtnSave,
											});*/
                    }}
                  />,
                )}
              </Form.Item>
            )}
            <Card
              title={
                <span onClick={this.toggleOpen} style={{ cursor: "pointer" }}>
                  <LegacyIcon
                    type={openDPCollapse ? "caretDown" : "caretRight"}
                  />
                  <span style={{ marginLeft: 5 }}>Design positions</span>
                </span>
              }
              style={{ marginTop: 20, width: "100%" }}
              hidden={
                !!isDigitalProduct &&
                !!!this.props.form.getFieldValue("requiredDesign")
              }
            >
              {getFieldDecorator("designPositions", {
                initialValue:
                  value && value.designPositions
                    ? value.designPositions
                    : [DESIGN_POSITION_DEFAULT],
              })(
                <ProductBaseDesignPositions
                  hasFulfillment={!isCustom}
                  disableSubmitButton={this.disableSubmitButton}
                  variants={this.props.form.getFieldValue("variants")}
                  isDreamship={isDreamship}
                  isFFScalabelPress={isFFScalabelPress}
                  scalablePressDT={scalablePressDT}
                  openDPCollapse={openDPCollapse}
                  isEdit={this.props.isEditProductBase}
                  onChange={() => {
                    this.disableSubmitButton(false);
                  }}
                />,
              )}
            </Card>
            {/* {hasVariant && (
              <Form.Item style={{ marginTop: 20 }}>
                {getFieldDecorator("colorCodes", {
                  initialValue:
                    value?.colorCodes?.length > 0
                      ? value.colorCodes.map((node) => {
                          let patternImage = [];
                          if (node.patternImageId) {
                            patternImage = [node.patternImage];
                          }
                          return {
                            ...node,
                            patternImage,
                          };
                        })
                      : colorCodes?.length > 0
                      ? colorCodes
                      : [],
                })(
                  <ColorCodes
                    onChange={() => {
                      this.disableSubmitButton(false);
                    }}
                  />
                )}
              </Form.Item>
            )} */}
            <Form.Item style={{ marginTop: 20, textAlign: "right" }}>
              <Button
                onClick={() => {
                  if (null === showBtnSave ? false : !showBtnSave) {
                    Modal.confirm({
                      title: "Cancel all unsaved changes?",
                      icon: <ExclamationCircleOutlined />,
                      content: "If Ok, you'll delete any edits you made.",
                      onOk() {
                        history.push("/admin/product-bases");
                      },
                      cancelText: "Continue",
                    });
                  } else {
                    history.push("/admin/product-bases");
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                ref={this.submitButtonRef}
                className="ml-4"
                type="primary"
                icon={<LegacyIcon type={"save"} />}
                loading={loading}
                htmlType={"submit"}
                //disabled={null === showBtnSave ? true : showBtnSave}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
          <BaseTimeLine value={value} />
        </Container>
        <ModalVariantsMissing
          open={this.state.openVM}
          loading={this.state.loadingVM}
          error={this.state.tmpError}
          data={this.state.variantsMissing}
          onClose={() => {
            this.setState({ openVM: false, tmpError: null });
          }}
          handleVariantsMissing={this.handleVariantsMissing}
        />
      </React.Fragment>
    );
  }
}

const CustomShippingRate = React.forwardRef(function CustomShippingRate() {
  const shippingRate = [
    {
      title: "Default",
      value: null,
    },
    {
      title: "Supper Lightweight",
      value: "Supper Lightweight",
    },
    {
      title: "LightWeight",
      value: "LightWeight",
    },

    {
      title: "HeavyWeight",
      value: "HeavyWeight",
    },
    {
      title: "LightWeight DrinkWare",
      value: "LightWeight DrinkWare",
    },
    {
      title: "HeavyWeight DrinkWare",
      value: "HeavyWeight DrinkWare",
    },
  ];

  return (
    <Select>
      {shippingRate.map((op) => (
        <Select.Option value={op.value} key={op.value}>
          {op.title}
        </Select.Option>
      ))}
    </Select>
  );
});

// ======= Utils =========
export function formatVariantsForPrintify(data) {
  if (!data || data.length === 0) return [];

  const mergeAttributes = new Map();
  const variants = data.map((item, index) => {
    const { id, options } = item || {};
    const attributes = Object.entries(options || {}).reduce((acc, opt) => {
      const [key, value] = opt;
      if (mergeAttributes.has(key)) {
        const cur = mergeAttributes.get(key);
        mergeAttributes.set(key, cur.add(value));
      } else {
        mergeAttributes.set(key, new Set([value]));
      }
      const record = {
        name: capitalize(key),
        slug: key,
        option: value,
      };
      acc.push(record);
      return acc;
    }, []);

    return {
      regularPrice: 0,
      salePrice: 0,
      sellerPrice: 0,
      sorting: index,
      attributes,
      fulfillmentProductId: id,
      disabledFPI: true, // FPI = fulfillmentProductId
    };
  });

  const attributes = Array.from(mergeAttributes.entries()).map(
    ([key, value]) => {
      const name = capitalize(key);
      const options = Array.from(value.values());
      return {
        name,
        slug: key,
        options,
      };
    },
  );

  const unique = (variants || []).reduce((acc, cur) => {
    const key = (cur.attributes || []).map((i) => i.option).join("__");
    acc[key] = cur;
    return acc;
  }, {});

  const uniqueArr = Object.values(unique);

  const newVars = [];
  for (let i = 0; i < uniqueArr.length; i++) {
    newVars.push({ ...uniqueArr[i], sorting: i });
  }

  return { variants: newVars, attributes };
}

export function genDesignPositionForPrintify(data, variants) {
  if (!data || data.length === 0) return [];
  const placeholders = data.reduce((acc, item) => {
    const { placeholders, id } = item || {};
    if (Array.isArray(placeholders) && placeholders.length > 0) {
      acc.push(...placeholders.map((i) => ({ ...i, id })));
    }

    return acc;
  }, []);

  const placeholdersSet = new Map();
  for (let item of placeholders) {
    const { height, position, width, id } = item || {};
    const merge = `${position}-${width}-${height}`;

    if (placeholdersSet.has(merge)) {
      placeholdersSet.set(merge, [...placeholdersSet.get(merge), id]);
    } else {
      placeholdersSet.set(merge, [id]);
    }
  }
  const designPosition = Array.from(placeholdersSet.entries()).map(
    ([key, value]) => {
      const [pos, w, h] = key.split(/\-/);
      const description = `${w}x${h}px|PNG|300DPI`;

      const productBaseVariants = (value || [])
        .map((id) => {
          const tmp = (variants || []).find(
            ({ fulfillmentProductId }) => fulfillmentProductId === id,
          );
          if (!tmp) return null;

          const { attributes } = tmp;
          return {
            attributes,
            id: undefined,
          };
        })
        .filter(Boolean);

      const unique = (productBaseVariants || []).reduce((acc, cur) => {
        const key = (cur.attributes || []).map((i) => i.option).join("__");
        acc[key] = cur;
        return acc;
      }, {});

      return {
        name: pos,
        image: null,
        description,
        productBaseVariants: Object.values(unique),
      };
    },
  );

  return designPosition;
}

function getIds(data) {
  if (!data || data.length === 0) return [];
  return data.map(({ id }) => id);
}

function getVariants(variants, isEdit) {
  if (!variants || variants.length === 0) return [];
  return variants.map((v) => {
    const { regularPrice, sellerPrice } = v;
    v.disabledFPI = true; // For [Customcat, Dreamship, Printity, Merchize, Scalable Press, Gearment] can't change fulfillment_product_id
    if (isEdit || regularPrice !== 0 || sellerPrice === 0) return v;

    const newRP = Number.parseFloat(NumberToFixed(sellerPrice / 0.35, 2));
    return {
      ...v,
      regularPrice: newRP,
    };
  });
}

function getCombineVariants(attributes, variants) {
  if (!attributes.length) {
    return [];
  }
  let res = [];

  const n = attributes.length;
  let indices = [];
  // fill zero
  for (let i = 0; i < n; i++) {
    indices[i] = 0;
  }
  while (true) {
    let options = [];
    let attrs = [];
    for (let i = 0; i < n; i++) {
      const option = attributes[i].options[indices[i]];
      options.push(option);
      attrs.push({
        name: attributes[i].name,
        slug: attributes[i].slug,
        option: option,
      });
    }
    res.push({
      __slug: options.join("-"),
      attributes: attrs,
      regularPrice: null,
      salePrice: null,
      sellerPrice: null,
      fulfillmentProductId: null,
    });
    let next = n - 1;
    while (next >= 0 && indices[next] + 1 >= attributes[next].options.length) {
      next--;
    }
    if (next < 0) {
      break;
    }
    indices[next]++;
    for (let i = next + 1; i < n; i++) {
      indices[i] = 0;
    }
  }

  let mapVariantBySlug = {};
  if (!variants) {
    variants = [];
  }
  for (let i = 0; i < variants.length; i++) {
    const v = variants[i];
    if (v.__slug) {
      mapVariantBySlug[v.__slug] = v;
    } else {
      let options = [];
      for (let j = 0; j < v.attributes.length; j++) {
        const attr = v.attributes[j];
        options.push(attr.option);
      }
      const slug = options.join("-");
      mapVariantBySlug[slug] = v;
    }
  }
  // handle take exist value from prev variants
  for (let i = 0; i < res.length; i++) {
    const v = res[i];
    const prevVariant = mapVariantBySlug[v.__slug];
    if (prevVariant) {
      // res[i] = prevVariant;
      res[i] = { ...prevVariant, attributes: v.attributes }; // Update attributes
    }
    if (typeof res[i].__slug !== "undefined") {
      delete res[i].__slug;
    }
  }
  return res;
}

function isFulfillment(fulfillment) {
  if (!fulfillment || typeof fulfillment !== "object") return false;
  return Object.keys(fulfillment).length > 0;
}

function mapItemsByIds(items, ids) {
  if (!items || items.length === 0 || !ids || ids.length === 0) return [];
  const res = [];
  const map = {};
  for (let item of items) {
    map[item.id] = item;
  }

  for (let id of ids) {
    res.push(map[id]);
  }
  return res;
}

function getImages(data) {
  if (!data || data.length === 0) return [];
  return data.map(({ name, url, thumbnailUrl, id }) => ({
    thumbnailUrl,
    id,
    name,
    url,
  }));
}

function filterColorCode(data) {
  if (!data || data.length === 0) return [];
  return data
    .map(({ patternImage, name, value = null }) => {
      let { id } = (patternImage || []).find(({ id }) => id) || {};
      return {
        name,
        value,
        patternImageId: id,
      };
    })
    .filter(Boolean);
}

function getDefaultIndex(data) {
  if (!data || data.length === 0) return data;
  let index = 0;

  for (let i = 0; i < data.length; i++) {
    if (data[i].default) {
      index = i;
    }
  }

  return index;
}
export default Form.create({ name: "form" })(ProductBaseForm);
