import React from "react";
import styled from "styled-components";
import { elementContains, throttle } from "../../helper";

export default function ElementScrollable({ children, fixedColumn }) {
  const elRef = React.useRef(null);
  React.useEffect(() => {
    const handler = () => {
      throttle(() => {
        if (elRef.current) {
          onElementChange(elRef.current, fixedColumn);
        }
      });
    };

    if (elRef.current) {
      elRef.current.addEventListener("scroll", handler, {
        capture: false,
        passive: true,
      });
    }
    return () => {
      if (elRef.current) {
        elRef.current.removeEventListener("scroll", handler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedColumn]);

  return <Wrapper ref={elRef}>{children}</Wrapper>;
}

const fixedActionCol = "fixed-column";
const onElementChange = (wrapperRef, fixedColumn) => {
  if (!wrapperRef) return;
  let { scrollLeft, scrollWidth, offsetWidth } = wrapperRef;
  let addClass = offsetWidth + scrollLeft < scrollWidth;

  let domChilds = wrapperRef.querySelectorAll(".actions-wrap");
  let domParents = wrapperRef.querySelectorAll(".Polaris-DataTable__Cell");

  for (let domParent of domParents) {
    for (let domChild of domChilds) {
      if (elementContains(domParent, domChild)) {
        if (fixedColumn) {
          if (addClass) {
            domParent.classList.add(fixedActionCol);
          } else {
            domParent.classList.remove(fixedActionCol);
          }
        } else {
          domParent.classList.remove(fixedActionCol);
        }
      }
    }
  }
};

const Wrapper = styled.div`
  overflow-x: auto;
  
  .Polaris-DataTable {
    .Polaris-DataTable__ScrollContainer {
      overflow-x: initial;
    }
  }

  .Polaris-DataTable__Cell {
    &.${fixedActionCol} {
      position: sticky;
      right: 0;
      z-index: 10;
      background-color: #fff;

      &:after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
        position: absolute;
        top: 0px;
        bottom: -1px;
        width: 30px;
        transition: box-shadow 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
        content: "";
        pointer-events: none;
        left: 0px;
        transform: translateX(-100%);
      }
    }
  }

  .Polaris-DataTable__TableRow {
    &:hover {
      .Polaris-DataTable__Cell {
        &.${fixedActionCol} {
          background-color: var(--p-surface-hovered, #f9fafb);
        }
      }
    }
  }
`;
