import { Heading, Stack } from "@shopify/polaris";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
} from "react";
import styled from "styled-components";

import { arrInvalid, reducerFn } from "../../../helper";
import ImageTemplateSelect from "../../tiktok-hub/image-template/image-template-select";

function TiktokExtraImageTemplate({ value }, ref) {
  const [state, setState] = useReducer(reducerFn, {
    item1: [],
    item2: [],
    item3: [],
    item4: [],
    item5: [],
  });

  useEffect(() => {
    const newState = getImageTemplates(value);
    if (Object.keys(newState).length === 0) return;

    setState({ ...newState });
  }, [JSON.stringify(value)]);

  const handleValuesChange = useCallback(
    (key) => (value) => {
      setState({ [key]: value ? [value] : [] });
    },
    [],
  );

  useImperativeHandle(ref, () => ({
    getIds: () => getIds(state),
  }));

  return (
    <Wrapper>
      <Heading element="h3" children="Extra Image template" />
      <Stack vertical>
        <div className="item">
          {newImageTemplateSelect(state, "1", handleValuesChange)}
          {newImageTemplateSelect(state, "2", handleValuesChange)}
        </div>
        <div className="item">
          {newImageTemplateSelect(state, "3", handleValuesChange)}
          {newImageTemplateSelect(state, "4", handleValuesChange)}
        </div>
        <Stack distribution="fill">
          {newImageTemplateSelect(state, "5", handleValuesChange)}
        </Stack>
      </Stack>
    </Wrapper>
  );
}

function newImageTemplateSelect(state, key, onChange) {
  return (
    <ImageTemplateSelect
      label={`Extra image template ${key}`}
      value={state[`item${key}`]}
      onChange={onChange(`item${key}`)}
    />
  );
}

function getIds(state) {
  const res = [];
  for (let values of Object.values(state)) {
    if (arrInvalid(values)) continue;
    res.push(...values);
  }

  return res;
}

function getImageTemplates(values) {
  if (arrInvalid(values)) return {};

  const res = {};

  let j = 1;
  for (let i = 0; i < 5; i++) {
    if (!values[i]) continue;
    res[`item${j}`] = [values[i]];
    j++;
  }

  return res;
}

const Wrapper = styled.div`
  .item {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    justify-content: space-between;

    > * {
      width: calc(50% - 0.8rem);
    }
  }
`;

export default forwardRef(TiktokExtraImageTemplate);
