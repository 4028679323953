import React, { useState, useCallback, useEffect, useRef } from "react";
import { Filters } from "@shopify/polaris";
import { isEmpty } from "../../../helper";
import { FilterListRadioPolaris } from "../../filters/FilterListRadioPolaris";
import { FilterHasSearchPolaris } from "../../filters/FilterHasSearchPolaris";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const ALL_ORDER_OPTIONS = [
	{ value: "DESC", label: "DESC" },
	{ value: "ASC", label: "ASC" },
];

const ALL_ORDER_BY_OPTIONS = [
	{ value: "title", label: "Title" },
	{ value: "created_at", label: "Created At" },
];

const LIST_TIKTOK_STORES = gql`
	query stores($filter: StoreFilter) {
		stores(filter: $filter) {
			total
			nodes {
				id
				title
			}
		}
	}
`;

const LabelContainer = styled.div`
	display: flex;
	flex-direction: column;
	word-break: break-all;
	.domain {
		font-size: 0.9em;
		padding-right: 5px;
		color: var(--p-interactive, #006fbb);
	}
`;

export default function PushProductsFilter({ onFilterChange }) {
	const [filterOrdering, setFilterOrdering] = useState("DESC");
	const [filterOrderBy, setFilterOrderBy] = useState("created_at");
	const [queryValue, setQueryValue] = useState("");

	const [stores, setStores] = useState([]);
	const [store, setStore] = useState({
		value: null,
		label: null,
		search: null,
	});

	const typingTimeoutRef = useRef(null);

	const {
		data: dataStore,
		loading: loadingStore,
		error: errorStore,
	} = useQuery(LIST_TIKTOK_STORES, {
		variables: {
			filter: {
				limit: store.search ? 1000 : 20,
				offset: 0,
				search: store.search,
				platform: "tiktok"
			},
		},
		fetchPolicy: "cache-and-network",
	});

	useEffect(() => {
		if (dataStore) {
			let newData =
				dataStore.stores?.nodes?.length > 0
					? dataStore.stores.nodes.map((i) => {
							let subTitle = i.domain;
							return {
								value: i.id,
								// label: i.title,
								label: (
									<LabelContainer>
										<span className="title">{i.title}</span>
										<span className="domain">
											{subTitle}
										</span>
									</LabelContainer>
								),
							};
					  })
					: [];
			setStores(newData);
		}
	}, [dataStore]);

	useEffect(() => {
		if (typingTimeoutRef.current) {
			clearTimeout(typingTimeoutRef.current);
		}
		typingTimeoutRef.current = setTimeout(() => {
			if (onFilterChange) {
				onFilterChange({
					search: queryValue ? queryValue.trim() : queryValue,
					storeID: store?.value || null,
					orderBy: filterOrderBy,
					order: filterOrdering,
				});
			}
		}, 300);
	}, [queryValue, filterOrderBy, filterOrdering, store]);

	// Handle actions
	const handleQueryValueChange = useCallback(
		(value) => setQueryValue(value),
		[]
	);
	const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);

	const handleFilterClearAll = useCallback(() => {
		handleQueryValueRemove();
		handleRemoveFiltering("origin_source");
		handleRemoveFiltering("ordering");
		handleRemoveFiltering("order_by");
	}, [handleQueryValueRemove]);

	const handleRemoveFiltering = (key) => {
		switch (key) {
			case "store_id":
				setStore({
					value: null,
					label: null,
					search: null,
				});
				break;
			case "ordering":
				setFilterOrdering("");
				break;
			case "order_by":
				setFilterOrderBy("");
				break;
			case "keyword":
				handleQueryValueRemove();
				break;
		}
	};

	const filters = [
		{
			key: "store",
			label: "Stores",
			filter: (
				<FilterHasSearchPolaris
					options={stores}
					value={store}
					loading={loadingStore}
					error={errorStore}
					onChangeSearch={(search) =>
						setStore((prevState) => ({ ...prevState, search }))
					}
					onChange={({ value, label }) =>
						setStore((prevState) => ({
							...prevState,
							value,
							label,
						}))
					}
				/>
			),
		},
		{
			key: "ordering",
			label: "Order",
			filter: (
				<FilterListRadioPolaris
					options={ALL_ORDER_OPTIONS}
					value={filterOrdering}
					onChange={({ value, label }) => setFilterOrdering(value)}
				/>
			),
		},
		{
			key: "order_by",
			label: "Order by",
			filter: (
				<FilterListRadioPolaris
					options={ALL_ORDER_BY_OPTIONS}
					value={filterOrderBy}
					onChange={({ value, label }) => setFilterOrderBy(value)}
				/>
			),
		},
	];
	const appliedFilters = [];

	if (!isEmpty(filterOrdering)) {
		const key = "ordering";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, filterOrdering),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	if (!isEmpty(filterOrderBy)) {
		const key = "order_by";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, filterOrderBy),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	if (!isEmpty(queryValue)) {
		const key = "keyword";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, queryValue),
			onRemove: () => handleRemoveFiltering(key),
		});
	}
	if (!isEmpty(store.label)) {
		const key = "store_id";
		appliedFilters.push({
			key,
			label: disambiguateLabel(key, store.label),
			onRemove: () => handleRemoveFiltering(key),
		});
	}

	return (
		<Filters
			queryPlaceholder={`Search crawl item`}
			queryValue={queryValue}
			onQueryChange={handleQueryValueChange}
			onQueryClear={handleQueryValueRemove}
			onClearAll={handleFilterClearAll}
			filters={filters}
			appliedFilters={appliedFilters}
		/>
	);
	function disambiguateLabel(key, value) {
		switch (key) {
			case "store_id":
				if (value) {
					return `Store: ${value}`;
				}
				break;
			case "keyword":
				return `Keyword: ${value}`;
			case "origin_source":
				return `Origin source: ${value}`;
			case "ordering":
				return `Order: ${value}`;
			case "order_by":
				if (ALL_ORDER_BY_OPTIONS) {
					let orderBy = ALL_ORDER_BY_OPTIONS.find(
						(item) => item.value === value
					);
					if (orderBy && orderBy.label) {
						return `Order by: ${orderBy.label}`;
					}
				}
				return `Order by: ${value}`;
			default:
				return value;
		}
	}
}
