import React from "react";
import { WrapperPage } from "../../../components/shared/WrapperPage";
import SuspenseComp from "../../../components/shared/SuspenseComp";
import { SkeletonPagePolaris } from "../../../components/shared/SkeletonPagePolaris";
import TiktokCreatePush from "../../../components/tiktok/push-products/TiktokCreatePush"

export default function TiktokCreatePushPage() {
	return (
		<WrapperPage>
			<SuspenseComp fallback={<SkeletonPagePolaris />}>
				<TiktokCreatePush />
			</SuspenseComp>
		</WrapperPage>
	);
}
