import React from "react";
import { useParams } from "react-router-dom";

import { SkeletonPagePolaris } from "../../../components/shared/SkeletonPagePolaris";
import SuspenseComp from "../../../components/shared/SuspenseComp";
import { WrapperPage } from "../../../components/shared/WrapperPage";
import TiktokCloneProductTemplate from "../../../components/tiktok/product-templates/TiktokCloneProductTemplate";

export default function TiktokCloneProductTemplatePage() {
  const { id } = useParams();

  return (
    <WrapperPage>
      <SuspenseComp fallback={<SkeletonPagePolaris />}>
        <TiktokCloneProductTemplate id={id} />
      </SuspenseComp>
    </WrapperPage>
  );
}
