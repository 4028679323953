import React, { useState, useCallback } from "react";
import { Thumbnail } from "@shopify/polaris";
import styled from "styled-components";

import { ViewModalImage } from "../../product/ViewModalImage";

const Container = styled.div`
	.Polaris-Thumbnail {
		width: 20rem;
		margin: 1rem 0;
		.Polaris-Thumbnail__Image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		:after {
			box-shadow: none;
		}
	}
`;

export default function CrawlItemImage({ imageUrl, galleryImageUrls }) {
	const [open, setOpen] = useState(false);

	const toggleShowModal = useCallback(() => setOpen((prev) => !prev), []);

	let images = [];
	images.push({
		file: {
			url: imageUrl,
			thumbnailUrl: imageUrl,
		}
	})

	galleryImageUrls.filter((imgUrl) => imgUrl && imageUrl !== imgUrl).map((imgUrl, index) => {
		images.push({
			file: {
				url: imgUrl,
				thumbnailUrl: imgUrl,
			}
		})
	});

	return (
		<Container>
			<div onClick={toggleShowModal} style={{ cursor: "pointer" }}>
				<Thumbnail size="large" alt="" source={imageUrl} />
			</div>
			<ViewModalImage
				open={open}
				toggleShowModal={toggleShowModal}
				item={{images}}
			/>
		</Container>
	);
}
