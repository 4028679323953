import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";

const Container = styled.div`
	.box-flex {
		display: flex;
		flex-wrap: nowrap;
		gap: 20px;
		margin-bottom: 15px;
	}

	.box-flex-20 {
		width: 20%;
	}
`;

export default function TiktokExtraImages({ defaultValues, onChange }) {
	const [values, setValues] = useState(defaultValues ?? []);
	useEffect(() => {
		setValues(defaultValues ?? []);
	}, [JSON.stringify(defaultValues)]);

	const handleMediaChange = (index, files) => {
		setValues((prev) => {
			const updatedValues = [...prev];
			if (!updatedValues[index]) {
				updatedValues[index] = null;
			}
			if (files && files.length > 0) {
				updatedValues[index] = files[0];
			} else {
				updatedValues[index] = null;
			}
			return updatedValues;
		});
	};

	useEffect(() => {
		if (onChange) {
			let cleanValues = [];
			values.forEach(item => {
				if (item && item?.id) {
					cleanValues.push(item);
				}
			});
			onChange(cleanValues);
		}
	}, [JSON.stringify(values)]);

	return (
		<Container>
			<div className="box-flex">
				{Array.from({ length: 5 }, (_, index) => (
					<div className="box-flex-20" key={index}>
						<ComponentLabelPolaris
							label={`Extra image ${index + 1}`}
						/>

						<MediaSelectorButtonPolaris
							accept={"image/*"}
							singleUpload={true}
							multiple={false}
							value={values[index] ? [values[index]] : []}
							onChange={(files) =>
								handleMediaChange(index, files)
							}
						/>
					</div>
				))}
			</div>
		</Container>
	);
}
