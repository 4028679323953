import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import JoditEditorLib from "jodit-react";
import { isEqual } from "lodash";

import { reducerFn } from "../../helper";

export default function JoditEditor({ value, onChange, disabled }) {
  const [state, setState] = useReducer(reducerFn, {
    value: "",
  });

  useEffect(() => {
    if (!isEqual(value, state.value)) {
      setState({ value });
    }
  }, [value, state.value]);

  const editorConfigs = useMemo(
    () => ({
      readonly: false,
      placeholder: "Start typings...",
      minHeight: 400,
      buttons:
        "bold,italic,underline,strikethrough,eraser,ul,ol,cut,copy,paste,selectall,copyformat,hr,table,link,indent,outdent,left",
    }),
    [],
  );

  const handleBlur = useCallback((value) => {
    onChange && onChange(value);
  }, [onChange]);

  return (
    <JoditEditorLib
      // ref={editor}
      value={state.value ?? ""}
      config={editorConfigs}
      tabIndex={1} // tabIndex of textarea
      onBlur={handleBlur}
      disabled={disabled}
    />
  );
}
