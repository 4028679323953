import { Clone as CloneProductFromPrintFile } from "../components/product/print-file/Clone";
import { Create as CreateProductFromPrintFile } from "../components/product/print-file/Create";
import { Edit as EditProductFromPrintFile } from "../components/product/print-file/Edit";
import { TemplatesPolaris } from "../components/product/templates/TemplatesPolaris";
import MainLayout from "../layout/MainLayout";
import AddCampaignProduct from "../pages/seller/AddCampaignProduct";
import AddProductPage from "../pages/seller/AddProduct";
import EditProductPage from "../pages/seller/EditProduct";
import EditProductCampaign from "../pages/seller/EditProductCampaign";
import { OrdersPagePolaris } from "../pages/seller/OrdersPagePolaris";
import { ProductFromPrintFilePage } from "../pages/seller/ProductFromPrintFilePage";
import { ProductsPagePolaris } from "../pages/seller/ProductsPagePolaris";
import { StatisticPagePolaris } from "../pages/seller/StatisticPagePolaris";
import { Reports } from "../components/tasks/report/Reports";
import { DesignTasksPagePolaris } from "../pages/seller/DesignTasksPagePolaris";
import { IdeaTaskPagePolaris } from "../pages/seller/IdeaTaskPagePolaris";
import { ProductsRMPagePolaris } from "../pages/seller/ProductsRMPagePolaris";
import { MediaPagePolaris } from "../pages/design-leader/MediaPagePolaris";
import { TeamMembersPagePolaris } from "../pages/seller/TeamMembersPagePolaris";
import { AddMemberPolaris } from "../components/members/AddMemberPolaris";
import PersonalizedPage from "../pages/seller/PersonalizedPage";
import { DocumentationsPage } from "../components/documentations";
import { EditMemberPolaris } from "../components/members/EditMemberPolaris";
import { MappingOrderPagePolaris } from "../pages/seller/MappingOrdersPagePolaris";
import { CloneProduct } from "../pages/seller/CloneProduct";
import { CloneProductCampaign } from "../pages/seller/CloneProductCampaign";
import OnlineDesignTeamPage from "../pages/seller/OnlineDesignTeamPage";
import { CloneProductV2 } from "../pages/seller/CloneProductV2";
import AddProductPageV2 from "../pages/seller/AddProductV2";
import EditProductPageV2 from "../pages/seller/EditProductV2";

const routes = [
  {
    path: "/design-leader/products",
    exact: true,
    component: ProductsPagePolaris,
    layout: MainLayout,
    title: "Products",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/products/edit/:id",
    exact: true,
    // component: EditProductPage,
    component: EditProductPageV2,
    layout: MainLayout,
    title: "Edit Product",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/products/add",
    exact: true,
    // component: AddProductPage,
    component: AddProductPageV2,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/products/add/:id",
    exact: true,
    // component: AddProductPage,
    component: AddProductPageV2,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/products/clone",
    exact: true,
    // component: CloneProduct,
    component: CloneProductV2,
    layout: MainLayout,
    title: "Clone Product",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/products/add-campaign",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/products/add-campaign/:id",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/products/clone-campaign",
    exact: true,
    component: CloneProductCampaign,
    layout: MainLayout,
    title: "Clone Campaign Product",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/products/campaign/:id",
    exact: true,
    component: EditProductCampaign,
    layout: MainLayout,
    title: "Edit Product Campaign",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/templates",
    exact: true,
    component: TemplatesPolaris,
    layout: MainLayout,
    title: "Templates",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },

  {
    path: "/design-leader/product-from-print-file",
    exact: true,
    component: ProductFromPrintFilePage,
    title: "Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
    private: true,
  },
  {
    path: "/design-leader/product-from-print-file/create",
    exact: true,
    component: CreateProductFromPrintFile,
    title: "Create Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
    private: true,
  },
  {
    path: "/design-leader/product-from-print-file/edit/:id",
    exact: true,
    component: EditProductFromPrintFile,
    title: "Edit Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
    private: true,
  },
  {
    path: "/design-leader/product-from-print-file/clone/:id",
    exact: true,
    component: CloneProductFromPrintFile,
    title: "Clone Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
    private: true,
  },
  {
    path: "/design-leader/orders",
    exact: true,
    // component: SellerOrders,
    component: OrdersPagePolaris,
    layout: MainLayout,
    title: "Orders",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/design-tasks",
    exact: true,
    component: DesignTasksPagePolaris,
    title: "All Tasks",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
    accessTask: true,
  },
  {
    path: "/design-leader/design-tasks/ideas",
    exact: true,
    component: IdeaTaskPagePolaris,
    title: "Idea Task page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
    accessTask: true,
  },
  {
    path: "/design-leader/design-tasks/report",
    exact: true,
    component: Reports,
    title: "Reports task tracking",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/design-tasks/statistic",
    exact: true,
    component: StatisticPagePolaris,
    title: "Statistic page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/re-mockups-tasks",
    exact: true,
    component: ProductsRMPagePolaris,
    title: "Product recreate mockup",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
    private: true,
  },
  {
    path: "/design-leader/media",
    exact: true,
    component: MediaPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/team-members",
    exact: true,
    component: TeamMembersPagePolaris,
    layout: MainLayout,
    title: "Members",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/add-team-member",
    exact: true,
    component: AddMemberPolaris,
    layout: MainLayout,
    title: "Add New Member",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/online-design-teams",
    exact: true,
    component: OnlineDesignTeamPage,
    layout: MainLayout,
    title: "Design Online Team",
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/edit-team-member/:id",
    exact: true,
    component: EditMemberPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/personalized/artworks",
    exact: true,
    component: PersonalizedPage,
    title: "Artworks",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/personalized/cliparts",
    exact: true,
    component: PersonalizedPage,
    title: "Cliparts",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/personalized/fonts",
    exact: true,
    component: PersonalizedPage,
    title: "Fonts",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/documentations",
    exact: true,
    component: DocumentationsPage,
    title: "Documentations Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
  {
    path: "/design-leader/mapping",
    exact: true,
    component: MappingOrderPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Design Leader",
  },
];

export default routes;
