import { useQuery } from "@apollo/react-hooks";
import { Button, ButtonGroup, Card, Loading, Page } from "@shopify/polaris";
import isEqual from "lodash/isEqual";
import React from "react";

import { EmptyStatePolaris } from "../../components/shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../components/shared/SkeletonPagePolaris";
import { WrapperPage } from "../../components/shared/WrapperPage";
import { SelectRowsProvider } from "../../components/store/SelectRowsProvider";
import { StoresFilterPolaris } from "../../components/store/StoresFilterPolaris";
import { useAppContext } from "../../context";
import { getParamByRole } from "../../helper";
import { PLATFORMS } from "../../variable";
import { ETSY_STORE_QUERY as TIKTOK_STORE_QUERY } from "./Etsy";
import FetchStoreStatus from "../../components/tiktok/FetchStoreStatus";

const TiktokTable = React.lazy(() =>
  import("../../components/tiktok/TiktokTable"),
);

export default function TiktokPage() {
  const [filter, setFilter] = React.useState({
    limit: 20,
    offset: 0,
    platformSelected: [PLATFORMS.Tiktok],
    status: null,
    search: null,
  });

  // context
  const { currentUser } = useAppContext();
  const param = getParamByRole(currentUser);

  // Query
  const { data, loading, error, refetch } = useQuery(TIKTOK_STORE_QUERY, {
    variables: {
      filter,
    },
    fetchPolicy: "no-cache",
  });

  const handleFilterChange = React.useCallback(({ search, status }) => {
    setFilter((prev) => {
      if (!isEqual(prev.search, search) || !isEqual(prev.status, status)) {
        return { ...prev, search, status, offset: 0 };
      }

      return prev;
    });
  }, []);

  const handlePaginationChange = React.useCallback((offset, limit) => {
    setFilter((prev) => ({ ...prev, limit, offset }));
  }, []);

  const loadingMarkup = loading && <Loading />;

  return (
    <WrapperPage>
      {loadingMarkup}
      <Page
        fullWidth
        breadcrumbs={[{ content: "Sales Channels", url: `/${param}/stores` }]}
        title="All Tiktok Accounts"
        // primaryAction={{
        //   content: "Add Tiktok Account",
        //   url: `/${param}/stores/tiktok/add`,
        // }}
        primaryAction={
          <ButtonGroup>
            <Button
              children="Add Tiktok Account"
              url={`/${param}/stores/tiktok/add`}
              primary
            />
            <FetchStoreStatus />
          </ButtonGroup>
        }
      >
        <Card sectioned>
          <StoresFilterPolaris filter={filter} onChange={handleFilterChange} />
        </Card>
        <Card>
          {loading ? (
            <SkeletonPagePolaris />
          ) : error ? (
            <div>Error: {error?.toString()}</div>
          ) : data?.stores?.nodes?.length > 0 ? (
            <React.Suspense fallback={<SkeletonPagePolaris />}>
              <SelectRowsProvider
                data={data.stores.nodes || []}
                refetch={refetch}
                isChildNode
              >
                <TiktokTable
                  data={data}
                  currentParam={param}
                  filter={filter}
                  handlePaginationChange={handlePaginationChange}
                  refetch={refetch}
                />
              </SelectRowsProvider>
            </React.Suspense>
          ) : (
            <EmptyStatePolaris />
          )}
        </Card>
      </Page>
    </WrapperPage>
  );
}
