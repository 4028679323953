import { Labelled } from "@shopify/polaris";
import React from "react";
import Wysiwyg from "../../../Wysiwyg";
import JoditEditor from "../../../shared/JoditEditor";

const label = "Description";
export function ProductDescription({ value, onChange, canEdit }) {
    return (
        <div>
            <Labelled label={label} />
            {/* <Wysiwyg value={value} onChange={onChange} disabled={!canEdit} /> */}
            <JoditEditor value={value} onChange={onChange} disabled={!canEdit} />
        </div>
    );
}
