import { Icon as LegacyIcon } from "@ant-design/compatible";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
} from "antd";
import { gql } from "apollo-boost";
import React from "react";
import { Prompt } from "react-router-dom";
import { GET_PRODUCT_BY_TITLE } from "../../../graphql/queries";
import { getUnique, handleError, objectInvalid } from "../../../helper";
import history from "../../../history";
import {
  AMZ_MESSAGE_ERROR,
  MAX_LENGTH,
  MAX_TITLE_CHARACTERS,
  TITLE_LENGTH_MESS,
  UPLOAD_FOLDER,
  WHICH_FIELD,
} from "../../../variable";
import CustomizeButton from "../../customize/CustomizeButton";
import CampaignProductBaseItems from "../../seller/CampaignProductBaseItems";
import PushProduct from "../../seller/PushProduct";
import CollectionsAutoComplete from "../../shared/CollectionsAutoComplete";
import PageTitle from "../../shared/PageTitle";
import TagsAutoComplete from "../../shared/TagsAutoComplete";
import MediaSelectorButton from "../../supplier/MediaSelectorButton";
import Wysiwyg from "../../Wysiwyg";
import ProductPrintFiles from "../ProductPrintFiles";
import { validateDimensions } from "../_utils";
import { genHelpText } from "./ShortTitle";
import JoditEditor from "../../shared/JoditEditor";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

function ProductCampaignForm({ currentParam, productBases, value }) {
  const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
    isPrompt: null,
    selected: 0,
    updatePrices: false,
    mainImgId: {},
    loading: false,
  });

  const [form] = Form.useForm();
  const designPositionRefs = React.useRef({});

  const productBasesStr = JSON.stringify(productBases || {});
  React.useEffect(() => {
    const productBases = JSON.parse(productBasesStr);
    if (
      !productBases ||
      !Array.isArray(productBases) ||
      productBases.length === 0
    )
      return;

    const fieldValue = {
      products: productBases.map((p) => {
        let title = `${p.title} Product Title`;
        let shortTitle = p.shortTitle;
        let amzTitle = p.amzTitle;
        let collections = [];
        let tags = [];

        const item = {
          productBase: p,
          title,
          shortTitle,
          amzTitle,
          description: p.defaultContent,
          collections: collections ? collections : [],
          tags: tags ? tags : [],
          variants: p.variants.map((variant, index) => {
            let v = {
              productBaseVariant: variant,
              productBaseVariantId: variant.id,
              image: null,
              regularPrice: variant.regularPrice,
              salePrice: variant.salePrice,
              disabled: variant.disabled,
            };
            return v;
          }),
        };

        return item;
      }),
    };

    for (let i = 0; i < productBases.length; i++) {
      fieldValue[`designPositions__${i}`] = productBases[i].designPositions.map(
        (dp) => {
          return {
            designPosition: dp,
            designPositionId: dp.id,
            file: null,
          };
        },
      );
      fieldValue[`mockup__${i}`] = [];
    }

    if (form) {
      form.setFieldsValue(fieldValue);
    }

    let newSelected = productBases[0].id;
    setState({ selected: newSelected });
  }, [productBasesStr, form]);

  // Actions
  const handleValueChange = React.useCallback(() => {
    setState({ isPrompt: false });
  }, []);

  const validateTitle = React.useCallback((_rule, value, callback) => {
    let error = null;
    if (!value) {
      error = "Product title is required.";
    } else if (value.length > MAX_LENGTH) {
      error = TITLE_LENGTH_MESS;
    }
    error ? callback(error) : callback();
  }, []);

  const handleFinish = React.useCallback(
    async (value) => {
      // Validate file design
      if (!objectInvalid(designPositionRefs.current)) {
        let result;
        for (let item of Object.values(designPositionRefs.current)) {
          const dimensions = item?.state?.dimensions || {};
          const error = validateDimensions({
            dimensions,
          });
          if (error != null) {
            result = error;
            break;
          }
        }

        if (result != null) {
          notification.error({ message: result.message });
          return;
        }
      }

      const { __apolloClient__: client } = window;
      if (!client) return;
      setState({ isPrompt: null, loading: true });

      const values = JSON.parse(JSON.stringify(value));
      values["mainImageId"] = state.mainImgId;

      const mutation = gql`
        mutation createProduct($input: NewProduct!) {
          createProduct(input: $input) {
            id
            title
            sku
            description
            designStatus
            personalized
            status
            productBases {
              id
              title
            }
            images {
              id
              productBaseId
              file {
                id
                url
                thumbnailUrl
              }
            }
            tags {
              id
              name
            }
            collections {
              id
              name
            }
            fields {
              id
              title
              name
              type
              extraFee
              configure
              sorting
            }
          }
        }
      `;

      let variants = [];
      let designPositions = [];
      let mockup = [];
      let sortBases = [];
      let disabledVariants = [];
      let enabledVariants = [];

      for (let i = 0; i < values.products.length; i++) {
        const product = values.products[i];
        let pBaseId = product && product.productBase && product.productBase.id;
        sortBases.push({ productBaseId: pBaseId, position: i });

        for (let j = 0; j < product.variants.length; j++) {
          let v = product.variants[j];
          if (v.disabled) {
            disabledVariants.push(v);
          } else {
            enabledVariants.push(v);
          }

          delete v.productBaseVariant;
          delete v.__typename;
          if (v.image) {
            v.image = v.image.id;
            v.amazonImages =
              v.amazonImages?.length > 0
                ? v.amazonImages.map((i) => i.id)
                : undefined;
          }
          if (v.__typename) {
            delete v.__typename;
          }
          if (v.productBaseVariantID) {
            delete v.productBaseVariantID;
          }
        }
        for (let j = 0; j < values[`designPositions__${i}`].length; j++) {
          const dp = values[`designPositions__${i}`][j];
          ["designPosition", "__typename", "file"].forEach((name) => {
            if (typeof dp[name] !== "undefined") {
              delete dp[name];
            }
          });
          designPositions.push(dp);
        }

        const images = values[`mockup__${i}`];
        const productBaseId = product.productBase.id;

        if (images && images.length) {
          for (let j = 0; j < images.length; j++) {
            const fileId = images[j].id;
            mockup.push({
              productBaseId,
              fileId,
            });
          }
        }
      }

      variants = [...enabledVariants, ...disabledVariants];

      let psdFileIds = [];
      if (values.psdFiles && values.psdFiles.length > 0) {
        let inputPsdFiles = values.psdFiles;
        inputPsdFiles.forEach((v) => {
          if (v && v.id && v.id.length > 0) {
            psdFileIds.push(v.id);
          }
        });
      }

      let mainImageId = values.mainImageId;
      let imgIds = [];
      if (mainImageId) {
        for (let [, value] of Object.entries(mainImageId)) {
          if (value) {
            imgIds.push(value);
          }
        }
      }

      if (mockup && mockup.length === 0) {
        notification.error({ message: "Please select at least one mockup" });
        setState({ loading: false });
        return;
      }

      // Unique mockup
      mockup = getUnique(mockup, "fileId");
      const data = {
        tags: values.tags.map((t) => t.id),
        collections: values.collections.map((c) => c.id),
        mockup,
        title: values.title,
        shortTitle: values.shortTitle,
        amzTitle: values.amzTitle,
        description: values.description,
        personalized: values.personalized,
        fields:
          values.fields?.length > 0
            ? values.fields.map(({ id, ...item }) => item)
            : [],
        variants: variants,
        productBases: productBases.map((p) => p.id),
        designPositions: designPositions,
        pushToStores: values.pushToStores
          ? values.pushToStores.map((s) => s.id)
          : [],
        psd: values.psd,
        ...(psdFileIds.length > 0 ? { psdFiles: psdFileIds } : null),
        mainImageId: imgIds && imgIds.length ? imgIds[imgIds.length - 1] : null,
        sortBases,
      };

      // Check amazon title
      if (values.amzTitle) {
        const { data: dataAmzTitle } = await client.query({
          query: GET_PRODUCT_BY_TITLE,
          variables: {
            title: values.amzTitle,
            whichField: WHICH_FIELD.AmzTitle,
          },
        });

        if (dataAmzTitle?.getProductsByTitle?.total > 0) {
          notification.error({
            message: AMZ_MESSAGE_ERROR,
            duration: 3000,
          });
          setState({
            loading: false,
          });
          return;
        }
      }

      client
        .mutate({
          mutation: mutation,
          variables: {
            input: data,
          },
        })
        .then(() => {
          notification.success({ message: `Product has been saved.` });
          history.push(`/${currentParam}/products`);
        })
        .catch((e) => {
          notification.error({ message: handleError(e.toString()) });
        })
        .finally(() => {
          setState({ loading: false });
        });
    },
    [productBases, currentParam, state.mainImgId, designPositionRefs],
  );

  const handleFinishFailed = React.useCallback((err) => {
    const { errorFields } = err || {};
    let uniqueMessage = {};
    if (Array.isArray(errorFields) && errorFields?.length > 0) {
      for (let e of errorFields) {
        const { errors } = e || {};
        if (Array.isArray(errors) && errors.length > 0) {
          for (const e2 of errors) {
            const msg = (e2.toLowerCase() || "").trim();
            if (typeof uniqueMessage[msg] === "undefined") {
              notification.error({ message: handleError(e2) });
            }
            uniqueMessage[msg] = true;
          }
        }
      }
    }
  }, []);

  const titleHelp = React.useMemo(() => {
    if (!form) return;
    const t = form.getFieldValue("title");
    return genHelpText(t);
  }, [form]);

  const aTitleHelp = React.useMemo(() => {
    if (!form) return;
    const t = form.getFieldValue("amzTitle");
    return genHelpText(t, MAX_TITLE_CHARACTERS.amzTitle);
  }, [form]);

  const fbTitleHelp = React.useMemo(() => {
    if (!form) return;
    const t = form.getFieldValue("shortTitle");
    return genHelpText(t, MAX_TITLE_CHARACTERS.fbTitle);
  }, [form]);

  const productsValue = React.useMemo(() => {
    if (!form) return;
    return form.getFieldValue("products");
  }, [form?.getFieldValue("products")]);

  const personalized = React.useMemo(() => {
    if (!form) return false;
    return form.getFieldValue("personalized");
  }, [form?.getFieldValue("personalized")]);

  return (
    <React.Fragment>
      <Prompt
        when={false === state.isPrompt}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Form
        {...layout}
        form={form}
        scrollToFirstError
        onFinish={handleFinish}
        onChange={() => {
          setState({ isPrompt: false });
        }}
        onFinishFailed={handleFinishFailed}
        initialValues={{
          title: value?.title || undefined,
          shortTitle: value?.shortTitle || undefined,
          amzTitle: value?.amzTitle || undefined,
          description: value?.description || undefined,
          collections: value?.collections?.length > 0 ? value.collections : [],
          tags: value?.tags?.length > 0 ? value.tags : [],
          personalized: value ? value?.personalized : false,
          fields: value?.fields?.length > 0 ? value.fields : [],
          pushToStores: [],
          psdFiles: [],
          products: [],
        }}
      >
        <PageTitle
          subTitle={"Products"}
          title={"Clone Campaign"}
          link={`/${currentParam}/products`}
        />
        <Row gutter={16}>
          <Col span={17}>
            <Card title="Product Configuration">
              <Form.Item
                name="title"
                label={"Title"}
                help={titleHelp || undefined}
                rules={[
                  {
                    required: true,
                    validator: validateTitle,
                  },
                ]}
              >
                <Input placeholder="Product title" />
              </Form.Item>
              <Form.Item
                name="shortTitle"
                label="Facebook Title"
                help={fbTitleHelp}
              >
                <Input
                  placeholder="Enter facebook title"
                  maxLength={MAX_TITLE_CHARACTERS.fbTitle}
                />
              </Form.Item>
              <Form.Item name="amzTitle" label="Amazon Title" help={aTitleHelp}>
                <Input
                  placeholder="Enter amazon title"
                  maxLength={MAX_TITLE_CHARACTERS.amzTitle}
                />
              </Form.Item>
              <Form.Item name="description" label={"Description"}>
                {/* <Wysiwyg onChange={handleValueChange} /> */}
                <JoditEditor onChange={handleValueChange} />
              </Form.Item>
              <Form.Item
                name="collections"
                label={"Collections"}
                rules={[
                  {
                    required: true,
                    message: "Collections is required",
                  },
                ]}
              >
                <CollectionsAutoComplete onChange={handleValueChange} />
              </Form.Item>
              <Form.Item
                label={"Tags"}
                name="tags"
                rules={[
                  {
                    required: true,
                    message: "Tags is required",
                  },
                ]}
              >
                <TagsAutoComplete onChange={handleValueChange} />
              </Form.Item>
              <Form.Item name="products">
                <CampaignProductBaseItems
                  selectedIndex={state.selected}
                  updatePrices={state.updatePrices}
                  onSelected={(pbaseId) => {
                    setState({ selected: pbaseId });
                  }}
                  onDelete={(index) => {
                    if (productsValue[index]) {
                      productBases = productBases.filter(
                        (p) => p.id !== productsValue[index].productBase.id,
                      );
                      if (form) {
                        form.setFieldsValue({
                          products: productsValue.filter((_, i) => i !== index),
                        });
                      }
                    }
                  }}
                  onChangeValue={(v) => {
                    setState({
                      onChangeValue: v,
                      isPrompt: false,
                    });
                  }}
                />
              </Form.Item>
            </Card>
            <Card style={{ marginTop: 20 }} title={"Personalized"}>
              <Form.Item name="personalized" valuePropName="checked">
                <Checkbox>This is a personalized product</Checkbox>
              </Form.Item>
              <Form.Item
                name="fields"
                rules={[
                  {
                    required: !!personalized,
                    message: "Personalized is required",
                  },
                ]}
              >
                <CustomizeButton
                  showPreview={!!personalized}
                  onChange={handleValueChange}
                />
              </Form.Item>
            </Card>
          </Col>
          <Col span={7}>
            <Card
              style={{ marginBottom: 15 }}
              title={"Push Product"}
              name="pushToStores"
            >
              <PushProduct onChange={handleValueChange} />
            </Card>
            {(productBases || []).map((p, index) => {
              const pBaseId = p && p.id;
              const currentBase =
                productsValue && productsValue.length > 0
                  ? productsValue.find(
                      (i) =>
                        i &&
                        i.productBase &&
                        i.productBase.id === state.selected,
                    )
                  : null;
              const defaultMockupFiles = state[`mockup__${index}`];
              return (
                <Card
                  style={{ marginBottom: 20 }}
                  hidden={pBaseId !== state.selected}
                  key={index}
                  title={
                    currentBase ? currentBase.title : `${p.title} Product Title`
                  }
                >
                  <Card style={{ marginBottom: 15 }} title={"Print Files"}>
                    <Form.Item name={`designPositions__${index}`}>
                      <ProductPrintFiles
                        uploadFolder={"undefined"}
                        onChange={handleValueChange}
                        ref={(ref) => {
                          designPositionRefs.current[index] = ref;
                          return ref;
                        }}
                      />
                    </Form.Item>
                  </Card>
                  <Card key={`mockup-${index}`} title={"Mockup"}>
                    <Form.Item name={`mockup__${index}`}>
                      <MediaSelectorButton
                        isMockup
                        prefix="mockups/"
                        folder={"undefined"}
                        multiple={true}
                        listType={"picture-card"}
                        buttonType="primary"
                        fileList={defaultMockupFiles || []}
                        hasDefaultMockup
                        onChange={handleValueChange}
                        onChangeMainImg={(value) =>
                          setState({
                            isPrompt: false,
                            mainImgId: {
                              ...state.mainImgId,
                              [`mockup__${index}`]: value,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </Card>
                </Card>
              );
            })}
            <Card title={"Design Resource Files"} style={{ marginTop: 15 }}>
              <Form.Item name="psdFiles">
                <MediaSelectorButton
                  prefix={UPLOAD_FOLDER.PSD_RESOURCE + "/"}
                  folder={UPLOAD_FOLDER.PSD_RESOURCE}
                  multiple={true}
                  listType={"picture-card"}
                  buttonType="primary"
                  accept={"image/*"}
                  onChange={handleValueChange}
                />
              </Form.Item>
            </Card>
            <Card title={"Design Resources Urls"} style={{ marginTop: 15 }}>
              <Form.Item name="psd">
                <Input.TextArea rows={4} />
              </Form.Item>
            </Card>
          </Col>
          <Col span={24}>
            <Form.Item
              style={{
                marginTop: 20,
                textAlign: "right",
                marginBottom: 0,
              }}
            >
              <Button
                onClick={() => {
                  if (null === state.isPrompt ? false : !state.isPrompt) {
                    Modal.confirm({
                      title: "Cancel all unsaved changes?",
                      icon: <ExclamationCircleOutlined />,
                      content: "If Ok, you’ll delete any edits you made.",
                      onOk() {
                        history.push(`/${currentParam}/products`);
                      },
                      cancelText: "Continue",
                    });
                  } else {
                    history.push(`/${currentParam}/products`);
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className="ml-4"
                type="primary"
                icon={<LegacyIcon type={"save"} />}
                loading={state.loading}
                htmlType={"submit"}
                disabled={null === state.isPrompt ? true : state.isPrompt}
              >
                Publish Campaign
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export { ProductCampaignForm };
