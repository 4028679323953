import React from "react";
import { WrapperPage } from "../../../components/shared/WrapperPage";
import SuspenseComp from "../../../components/shared/SuspenseComp";
import { SkeletonPagePolaris } from "../../../components/shared/SkeletonPagePolaris";
import TiktokPushProducts from "../../../components/tiktok/push-products/TiktokPushProducts"

export default function TiktokPushProductsPage() {
	return (
		<WrapperPage>
			<SuspenseComp fallback={<SkeletonPagePolaris />}>
				<TiktokPushProducts />
			</SuspenseComp>
		</WrapperPage>
	);
}
