import { Form, Icon as LegacyIcon } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ApolloConsumer } from "@apollo/react-components";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Modal,
  notification,
  Radio,
  Row,
} from "antd";
import RadioGroup from "antd/es/radio/group";
import { gql } from "apollo-boost";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import { apolloClient } from "../../client";
import { fieldsCampaign } from "../../constants";
import { productBaseFragment } from "../../fragments";
import { GET_FILE_BY_ID, GET_PRODUCT_BY_TITLE } from "../../graphql/queries";
import {
  getUnique,
  handleCampaignFields,
  handleDeepEqual,
  handleError,
  objectInvalid,
  toSlug,
} from "../../helper";
import history from "../../history";
import {
  DEFAULT_PRODUCT_LIST_FILTER,
  LIST_SELLER_PRODUCTS_QUERY,
} from "../../pages/seller/Products";
import {
  AMZ_MESSAGE_ERROR,
  MAX_LENGTH,
  MAX_TITLE_CHARACTERS,
  TITLE_LENGTH_MESS,
  UPLOAD_FOLDER,
  WHICH_FIELD,
} from "../../variable";
import CustomizeButton from "../customize/CustomizeButton";
import { FULFILLED } from "../file/hooks";
import { genHelpText } from "../product/components/ShortTitle";
import ProductPrintFiles from "../product/ProductPrintFiles";
import { validateDimensions } from "../product/_utils";
import CollectionsAutoComplete from "../shared/CollectionsAutoComplete";
import PageTitle from "../shared/PageTitle";
import TagsAutoComplete from "../shared/TagsAutoComplete";
import MediaSelectorButton from "../supplier/MediaSelectorButton";
import { LOAD_TEMPLATES } from "../templates/Templates";
import Wysiwyg from "../Wysiwyg";
import CampaignProductBaseItems from "./CampaignProductBaseItems";
import PushProduct from "./PushProduct";
import JoditEditor from "../shared/JoditEditor";

const CREATE_TEMPLATE = gql`
  mutation createTemplate($input: NewTemplate!) {
    createTemplate(input: $input) {
      id
      name
      slug
      grouping
      data {
        personalized
        productBases {
          ...ProductBasse
        }
        ProductBaseIds
        fields {
          name
          title
          type
          extraFee
          configure
          sorting
        }
        products {
          title
          description
          productbaseId
          shortDescription
          tagIds
          tags {
            id
            name
          }
          variants {
            salePrice
            regularPrice
            productBaseVariantID
          }
          collectionIds
          collections {
            id
            name
          }
        }
      }
    }
  }
  ${productBaseFragment}
`;

class CampaignConfiguration extends Component {
  state = {
    selected: 0,
    loading: false,
    template: {
      name: "",
      slug: "",
      visible: false,
      loading: false,
    },
    defaultFieldsValue: null,
    handleBtnSave: null,
    showBtnSave: null,
    updatePrices: false,
    mainImgId: {},
  };

  handleCaching = (client, products = []) => {
    try {
      const cache = client.readQuery({
        query: LIST_SELLER_PRODUCTS_QUERY,
        variables: {
          filter: DEFAULT_PRODUCT_LIST_FILTER,
        },
      });
      client.writeQuery({
        query: LIST_SELLER_PRODUCTS_QUERY,
        variables: {
          filter: DEFAULT_PRODUCT_LIST_FILTER,
        },
        data: {
          ...cache,
          products: {
            ...cache.products,
            total: cache.products.total + products.length,
            nodes: [...products, ...cache.products.nodes],
          },
        },
      });
    } catch (_) {}
  };
  handleCreateSplitProduct = (client, values) => {
    let { currentParam } = this.props;
    let args = [];
    let mutations = [];
    let variables = {};
    let total = 0;
    for (let i = 0; i < values.products.length; i++) {
      total++;
      args.push(`$input__${i}: NewProduct!`);
      mutations.push(`
      createProduct__${i}: createProduct(input: $input__${i}) {
          ...product
        }
      `);
      const v = values.products[i];
      const productBaseId = v.productBase.id;
      v["sortBases"] = [{ productBaseId, position: 0 }];
      v.personalized = values.personalized;
      v.fields = values.fields;
      v.productBases = [productBaseId];
      v.mainImageId = values.mainImageId[`mockup__${i}`];
      delete v.productBase;
      ["collections", "tags"].forEach((name) => {
        if (v[name] && v[name].length) {
          v[name] = v[name].map((t) => t.id);
        }
      });
      v.variants = v.variants.map((v) => {
        ["__typename", "productBaseVariant"].forEach((name) => {
          if (typeof v[name] !== "undefined") {
            delete v[name];
          }
        });
        if (v.image) {
          v.image = v.image.id;
          v.amazonImages =
            v.amazonImages?.length > 0
              ? v.amazonImages.map((i) => i.id)
              : undefined;
        }
        return v;
      });
      v.mockup = [];
      if (values[`mockup__${i}`] && values[`mockup__${i}`].length) {
        v.mockup = values[`mockup__${i}`].map((m) => {
          return {
            productBaseId,
            fileId: m.id,
          };
        });
      }

      v.mockup = getUnique(v.mockup, "fileId");
      v.designPositions = values[`designPositions__${i}`];
      if (v.designPositions && v.designPositions.length) {
        v.designPositions = v.designPositions.map((v) => {
          ["designPosition", "__typename", "file"].forEach((name) => {
            if (typeof v[name] !== "undefined") {
              delete v[name];
            }
          });
          return v;
        });
      } else {
        notification.error({ message: "Design position is required" });
        return;
      }
      v.pushToStores = values.pushToStores
        ? values.pushToStores.map((s) => s.id)
        : [];
      v.psd = values[`psd__${i}`];

      let psdFileIds = [];
      let psdFiles = values[`psdFiles__${i}`];
      if (psdFiles && psdFiles.length > 0) {
        let inputPsdFiles = psdFiles;
        inputPsdFiles.forEach((v) => {
          if (v && v.id && v.id.length > 0) {
            psdFileIds.push(v.id);
          }
        });
      }

      if (psdFileIds.length > 0) {
        v.psdFiles = psdFileIds;
      }

      variables[`input__${i}`] = v;
    }
    const q = `
       mutation createProduct(${args.join(",")}){
          ${mutations.join("\n")}
       }
    `;
    const mutation = gql`
      fragment product on Product {
        id
        title
        sku
        description
        designStatus
        personalized
        status
        productBases {
          id
          title
        }
        images {
          id
          productBaseId
          file {
            id
            url
            thumbnailUrl
          }
        }
        tags {
          id
          name
        }
        collections {
          id
          name
        }
        fields {
          id
          title
          name
          type
          extraFee
          configure
          sorting
        }
      }
      ${q}
    `;
    client
      .mutate({
        mutation: mutation,
        variables: variables,
      })
      .then((res) => {
        notification.success({
          message: `${total} products has been saved.`,
        });
        let products = [];
        for (let i = 0; i < total; i++) {
          products.push(res.data[`createProduct__${i}`]);
        }
        this.handleCaching(client, products);
        history.push(`/${currentParam}/products`);
      })
      .catch((e) => {
        notification.error({ message: handleError(e.toString()) });
      });
  };

  handleCreateGroupProduct = async (client, values) => {
    let { productBases, currentParam } = this.props;
    const mutation = gql`
      mutation createProduct($input: NewProduct!) {
        createProduct(input: $input) {
          id
          title
          sku
          description
          designStatus
          personalized
          status
          productBases {
            id
            title
          }
          images {
            id
            productBaseId
            file {
              id
              url
              thumbnailUrl
            }
          }
          tags {
            id
            name
          }
          collections {
            id
            name
          }
          fields {
            id
            title
            name
            type
            extraFee
            configure
            sorting
          }
        }
      }
    `;

    let variants = [];
    let designPositions = [];
    let mockup = [];
    let sortBases = [];
    let disabledVariants = [];
    let enabledVariants = [];

    for (let i = 0; i < values.products.length; i++) {
      const product = values.products[i];
      let pBaseId = product && product.productBase && product.productBase.id;
      sortBases.push({ productBaseId: pBaseId, position: i });
      // product.variants = product.variants.sort(
      //   (a, b) => a.productBaseVariant.sorting - b.productBaseVariant.sorting
      // );

      for (let j = 0; j < product.variants.length; j++) {
        let v = product.variants[j];
        if (v.disabled) {
          disabledVariants.push(v);
        } else {
          enabledVariants.push(v);
        }

        delete v.productBaseVariant;
        delete v.__typename;
        if (v.image) {
          v.image = v.image.id;
          v.amazonImages =
            v.amazonImages?.length > 0
              ? v.amazonImages.map((i) => i.id)
              : undefined;
        }
        if (v.__typename) {
          delete v.__typename;
        }
        if (v.productBaseVariantID) {
          delete v.productBaseVariantID;
        }

        // variants.push(v);
      }
      for (let j = 0; j < values[`designPositions__${i}`].length; j++) {
        const dp = values[`designPositions__${i}`][j];
        ["designPosition", "__typename", "file"].forEach((name) => {
          if (typeof dp[name] !== "undefined") {
            delete dp[name];
          }
        });
        designPositions.push(dp);
      }

      const images = values[`mockup__${i}`];
      const productBaseId = product.productBase.id;

      if (images && images.length) {
        for (let j = 0; j < images.length; j++) {
          const fileId = images[j].id;
          mockup.push({
            productBaseId,
            fileId,
          });
        }
      }
    }

    variants = [...enabledVariants, ...disabledVariants];

    let psdFileIds = [];
    if (values.psdFiles && values.psdFiles.length > 0) {
      let inputPsdFiles = values.psdFiles;
      inputPsdFiles.forEach((v) => {
        if (v && v.id && v.id.length > 0) {
          psdFileIds.push(v.id);
        }
      });
    }

    let mainImageId = values.mainImageId;
    let imgIds = [];
    if (mainImageId) {
      for (let [, value] of Object.entries(mainImageId)) {
        if (value) {
          imgIds.push(value);
        }
      }
    }

    // Unique mockup
    mockup = getUnique(mockup, "fileId");
    const data = {
      tags: values.tags.map((t) => t.id),
      collections: values.collections.map((c) => c.id),
      mockup,
      title: values.title,
      shortTitle: values.shortTitle,
      amzTitle: values.amzTitle,
      description: values.description,
      // shortDescription: values.shortDescription,
      personalized: values.personalized,
      fields: values.fields,
      variants: variants,
      productBases: productBases.map((p) => p.id),
      designPositions: designPositions,
      pushToStores: values.pushToStores
        ? values.pushToStores.map((s) => s.id)
        : [],
      psd: values.psd,
      ...(psdFileIds.length > 0 ? { psdFiles: psdFileIds } : null),
      mainImageId: imgIds && imgIds.length ? imgIds[imgIds.length - 1] : null,
      sortBases,
    };

    if (!data.designPositions || data.designPositions.length === 0) {
      notification.error({ message: "Design position is required." });
      return;
    }

    if (mockup && mockup.length === 0) {
      notification.error({ message: "Please select at least one mockup" });
      this.setState({ loading: false });
      return;
    }

    // Check amazon title
    if (values.amzTitle) {
      const { data: dataAmzTitle } = await client.query({
        query: GET_PRODUCT_BY_TITLE,
        variables: {
          title: values.amzTitle,
          whichField: WHICH_FIELD.AmzTitle,
        },
      });

      if (dataAmzTitle?.getProductsByTitle?.total > 0) {
        notification.error({
          message: AMZ_MESSAGE_ERROR,
          duration: 3000,
        });
        this.setState({
          loading: false,
        });
        return;
      }
    }

    client
      .mutate({
        mutation: mutation,
        variables: {
          input: data,
        },
      })
      .then((res) => {
        const product = res.data.createProduct;
        notification.success({ message: `Product has been saved.` });
        this.handleCaching(client, [product]);
        history.push(`/${currentParam}/products`);
      })
      .catch((e) => {
        notification.error({ message: handleError(e.toString()) });
      });
  };

  componentDidMount() {
    const { productBases, template } = this.props;

    const grouping = template ? _.get(template, "grouping") : true;
    let mapProductByBaseId = {};
    if (
      template &&
      template.data &&
      template.data.products &&
      template.data.products.length
    ) {
      for (let i = 0; i < template.data.products.length; i++) {
        const p = template.data.products[i];
        if (p) {
          mapProductByBaseId[p.productbaseId] = p;
        }
      }
    }

    let fields = _.get(template, "data.fields", []);
    if (fields) {
      fields = fields.map((f) => {
        if (typeof f.__typename !== "undefined") {
          delete f.__typename;
        }
        if (typeof f.sorting !== "undefined") {
          delete f.sorting;
        }
        return f;
      });
    }

    const fieldValue = {
      personalized: _.get(template, "data.personalized", false),
      grouping: grouping ? 1 : 0,
      fields: fields ? fields : [],
      products: productBases.map((p) => {
        let product = mapProductByBaseId[p.id];
        let title = `${p.title} Product Title`;
        let shortTitle = p.shortTitle;
        let amzTitle = p.amzTitle;
        let collections = [];
        let tags = [];

        if (template) {
          title = _.get(product, `title`, "");
          shortTitle = _.get(product, "shortTitle", "");
          amzTitle = _.get(product, "amzTitle", "");
          collections = _.get(product, "collections", []);
          tags = _.get(product, `tags`, []);
        }

        const item = {
          productBase: p,
          title,
          shortTitle,
          amzTitle,
          description: template
            ? _.get(product, "description", "")
            : p.defaultContent,
          // shortDescription: template
          //   ? _.get(product, "shortDescription", "")
          //   : p.defaultShortDescription,
          collections: collections ? collections : [],
          tags: tags ? tags : [],
          variants: p.variants.map((variant, index) => {
            let v = {
              productBaseVariant: variant,
              productBaseVariantId: variant.id,
              image: null,
              regularPrice: variant.regularPrice,
              salePrice: variant.salePrice,
              disabled: variant.disabled,
            };
            if (template) {
              const pv = _.get(product, `variants[${index}]`);
              v.regularPrice = _.get(pv, "regularPrice", 0);
              v.salePrice = _.get(pv, "salePrice", 0);
              v.disabled = _.get(pv, "disabled", false);
            }
            return v;
          }),
        };
        return item;
      }),
    };
    if (grouping) {
      let tags = [];
      let collections = [];
      const p = _.get(template, "data.products[0]", null);
      if (template && p) {
        tags = _.get(p, "tags", []);
        collections = _.get(p, "collections", []);
      }
      fieldValue.title = _.get(p, "title", "");
      fieldValue.shortTitle = _.get(p, "shortTitle", "");
      fieldValue.amzTitle = _.get(p, "amzTitle", "");
      fieldValue.description = _.get(p, "description", "");
      // fieldValue.shortDescription = _.get(p, "shortDescription", "");
      fieldValue.tags = tags ? tags : [];
      fieldValue.collections = collections ? collections : [];
    }
    for (let i = 0; i < productBases.length; i++) {
      fieldValue[`designPositions__${i}`] = productBases[i].designPositions.map(
        (dp) => {
          return {
            designPosition: dp,
            designPositionId: dp.id,
            file: null,
          };
        },
      );
    }

    // Default mockups
    const defaultMockupIds = _.get(template, "defaultMockupIds", []);
    let self = this;
    if (defaultMockupIds?.length > 0) {
      const { client } = this.props;

      (async function () {
        const res = await Promise.allSettled(
          defaultMockupIds.map(async (id) => {
            const value = await client.query({
              query: GET_FILE_BY_ID,
              variables: { id },
            });
            return value;
          }),
        );

        let mockup = res
          .filter(
            (item) =>
              item.status === FULFILLED && item.value?.data?.fileById != null,
          )
          .map((item) => item.value.data.fileById);

        if (mockup?.length > 0) {
          const fields = {};
          for (let i = 0; i < productBases.length; i++) {
            fields[`mockup__${i}`] = mockup;
            if (grouping && !i) break;
          }

          self.props.form.setFieldsValue(fields);
          self.setState({ ...fields });
        }
      })();
    }

    this.props.form.setFieldsValue(fieldValue);

    for (let i = 0; i < productBases.length; i++) {
      fieldsCampaign.push(`designPositions__${i}`);
      fieldsCampaign.push(`mockup__${i}`);
    }
    let defaultFieldsValue = JSON.parse(
      JSON.stringify(this.props.form.getFieldsValue(fieldsCampaign)),
    );

    // Set selected by product base id
    let newSelected = productBases && productBases.length && productBases[0].id;
    this.setState({
      selected: newSelected,
      defaultFieldsValue: { ...defaultFieldsValue, title: "" },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    let { handleBtnSave } = this.state;
    if (handleBtnSave !== prevState.handleBtnSave) {
      this.handleShowBtnSave();
    }
    let { clickSave } = this.props;
    let client = apolloClient;
    if (clickSave !== prevProps.clickSave) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.setState({ loading: true }, () => {
            const grouped = values.grouping;
            if (!grouped) {
              this.handleCreateSplitProduct(
                client,
                JSON.parse(JSON.stringify(values)),
              );
            } else {
              this.handleCreateGroupProduct(
                client,
                JSON.parse(JSON.stringify(values)),
              );
            }
          });
        } else {
          this.handleError(err);
        }
      });
    }
    let { loading } = this.state;
    let { handleLoading } = this.props;
    if (loading !== prevState.loading) {
      if (handleLoading) {
        handleLoading(loading);
      }
    }
  }

  handleShowBtnSave = () => {
    let { productBases, form, handleBtnSave } = this.props;
    let defaultFieldsValue = this.state.defaultFieldsValue && {
      ...this.state.defaultFieldsValue,
    };
    let newFieldsValue = { ...form.getFieldsValue(fieldsCampaign) };
    newFieldsValue = JSON.parse(JSON.stringify(newFieldsValue));

    if (newFieldsValue && defaultFieldsValue) {
      let values = handleCampaignFields(
        newFieldsValue,
        defaultFieldsValue,
        productBases,
      );
      newFieldsValue = values.newFieldsValue;
      defaultFieldsValue = values.defaultFieldsValue;

      let title = form.getFieldValue("title");
      let btnName = "Publish Campaign";
      let aggregations = { title, btnName };

      if (false === this.state.onChangeValue) {
        this.setState({
          showBtnSave: false,
          isPrompt: false,
        });
        if (handleBtnSave) {
          handleBtnSave(false, aggregations);
        }
      } else {
        if (handleDeepEqual(newFieldsValue, defaultFieldsValue)) {
          this.setState({
            showBtnSave: true,
            isPrompt: true,
          });
          if (handleBtnSave) {
            handleBtnSave(true, aggregations);
          }
        } else {
          this.setState({
            showBtnSave: false,
            isPrompt: false,
          });
          if (handleBtnSave) {
            handleBtnSave(false, aggregations);
          }
        }
      }
    }
  };

  handleCreateNewTemplate = (client) => {
    const { productBases, form } = this.props;
    const { name, slug, defaultMockups } = this.state.template;
    if (!name || !slug) {
      notification.error({ message: "Template name and slug are required!" });
      return;
    }
    const values = form.getFieldsValue();
    let products = values.products.map((p) => {
      return {
        title: p.title,
        shortTitle: p.shortTitle,
        amzTitle: p.amzTitle,
        description: p.description,
        // shortDescription: p.shortDescription,
        productBaseId: p.productBase.id,
        tagIds: p.tags ? p.tags.map((t) => t.id) : [],
        collectionIds: p.collections ? p.collections.map((c) => c.id) : [],
        variants: p.variants.map((v) => {
          return {
            salePrice: v.salePrice,
            regularPrice: v.regularPrice,
            productBaseVariantId: v.productBaseVariantId,
            disabled: v && v.disabled ? v.disabled : false,
          };
        }),
      };
    });
    if (values.grouping) {
      products = products.map((p) => {
        p.title = values.title;
        p.shortTitle = values.shortTitle;
        p.amzTitle = values.amzTitle;
        p.description = values.description;
        // p.shortDescription = values.shortDescription;
        p.tags = values.tags ? values.tags : [];
        p.tagIds = values.tags ? values.tags.map((t) => t.id) : [];
        p.collections = values.collections ? values.collections : [];
        p.collectionIds = values.collections
          ? values.collections.map((c) => c.id)
          : [];
        return p;
      });
    }
    let data = {
      productBaseIds: productBases.map((p) => p.id),
      Personalized: values.personalized,
      fields: values.fields,
      products: products,
    };

    const defaultMockupIds = (defaultMockups || [])
      .map((val) => val.id)
      .filter(Boolean);

    let template = {
      name,
      slug,
      data,
      grouping: !!values.grouping,
      defaultMockupIds,
    };

    this.setState((prevState) => {
      return {
        template: {
          ...prevState.template,
          loading: true,
        },
      };
    });
    // this.setState({
    //   template: {
    //     ...this.state.template,
    //     loading: false
    //   }
    // })
    client
      .mutate({
        mutation: CREATE_TEMPLATE,
        variables: {
          input: template,
        },
      })
      .then((res) => {
        notification.success({
          message: `Template ${template.name} has been saved.`,
        });
        this.setState(
          {
            template: {
              ...this.state.template,
              name: "",
              slug: "",
              visible: false,
              loading: false,
            },
          },
          () => {
            try {
              const cache = client.readQuery({ query: LOAD_TEMPLATES });
              client.writeQuery({
                query: LOAD_TEMPLATES,
                data: {
                  ...cache,
                  templates: [res.createTemplate, ...cache.templates],
                },
              });
            } catch (_) {}
          },
        );
      })
      .catch((e) => {
        notification.error({ message: handleError(e.toString()) });
        this.setState((prevState) => {
          return {
            template: {
              ...prevState.template,
              loading: false,
            },
          };
        });
      });
  };

  handleError = (err) => {
    let uniqueMessage = {};
    _.each(err, (e) => {
      _.each(_.get(e, "errors", []), (er) => {
        const msg = _.trim(er.message.toLowerCase());
        if (typeof uniqueMessage[msg] === "undefined") {
          notification.error({ message: handleError(er.message) });
        }
        uniqueMessage[msg] = true;
      });
    });
  };

  validateTitle = (_rule, value, callback) => {
    let error = null;
    if (!value) {
      error = "Product title is required.";
    } else if (value.length > MAX_LENGTH) {
      error = TITLE_LENGTH_MESS;
    }
    error ? callback(error) : callback();
  };

  designPositionRefs = {};

  render() {
    const { loading, handleBtnSave } = this.state;
    let { productBases, template, currentParam } = this.props;
    const { getFieldDecorator } = this.props.form;
    const grouping = this.props.form.getFieldValue("grouping");
    let productsValue = this.props.form.getFieldValue("products");
    const personalized = this.props.form.getFieldValue("personalized");
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };

    let groupDescriptions = productBases.reduce((accum, current) => {
      let content = `<div><strong>${current.title}<strong></div>${current.defaultContent}`;
      accum += content;
      return accum;
    }, "");

    // Facebook title
    const fbTitleValue =
      this.props.form && this.props.form.getFieldValue("shortTitle");
    const fbTitleHelp = genHelpText(fbTitleValue, MAX_TITLE_CHARACTERS.fbTitle);

    // Amazon Title
    const aTitleValue =
      this.props.form && this.props.form.getFieldValue("amzTitle");
    const aTitleHelp = genHelpText(aTitleValue, MAX_TITLE_CHARACTERS.amzTitle);

    // Origin product title
    const titleValue =
      this.props.form && this.props.form.getFieldValue("title");
    const titleHelp = genHelpText(titleValue);

    const { designPositionRefs } = this;

    return (
      <React.Fragment>
        <Prompt
          when={false === this.state.isPrompt}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <ApolloConsumer>
          {(client) => (
            <Form
              {...layout}
              onSubmit={(e) => {
                e.preventDefault();
                this.setState({
                  updatePrices: !this.state.updatePrices,
                });
                this.props.form.validateFields((err, values) => {
                  if (!err) {
                    // Validate file design
                    if (!objectInvalid(designPositionRefs)) {
                      let result;
                      for (let item of Object.values(designPositionRefs)) {
                        const dimensions = item?.state?.dimensions || {};
                        const error = validateDimensions({ dimensions });
                        if (error != null) {
                          result = error;
                          break;
                        }
                      }

                      if (result != null) {
                        notification.error({ message: result.message });
                        return;
                      }
                    }
                    this.setState({ loading: true, isPrompt: true }, () => {
                      const grouped = values.grouping;
                      let { mainImgId } = this.state;
                      values["mainImageId"] = mainImgId;
                      if (!grouped) {
                        this.handleCreateSplitProduct(
                          client,
                          JSON.parse(JSON.stringify(values)),
                        );
                      } else {
                        this.handleCreateGroupProduct(
                          client,
                          JSON.parse(JSON.stringify(values)),
                        );
                      }
                    });
                  } else {
                    this.handleError(err);
                  }
                });
              }}
              scrollToFirstError
            >
              <PageTitle
                subTitle={"Products"}
                title={"Add Campaign"}
                link={`/${currentParam}/products`}
                // action={
                //   <div>
                //     <Button
                //       icon={loading ? <LoadingOutlined /> : null}
                //       htmlType="submit"
                //       type="primary"
                //     >
                //       Publish Campaign
                //     </Button>
                //   </div>
                // }
              />
              <Row gutter={16}>
                <Col span={17}>
                  <Card title={"Product Configuration"}>
                    <Form.Item wrapperCol={{ span: 24 }} label={"Grouping"}>
                      {getFieldDecorator("grouping", {
                        initialValue: grouping ? 1 : 0,
                      })(
                        <RadioGroup disabled={productBases.length === 1}>
                          <Radio value={0}>
                            Split products into multiple products
                          </Radio>
                          <Radio value={1}>Group product into 1 product</Radio>
                        </RadioGroup>,
                      )}
                    </Form.Item>
                    <div
                      style={{
                        display: `${grouping === 1 ? "block" : "none"}`,
                      }}
                    >
                      <Form.Item label={"Title"} help={titleHelp || undefined}>
                        {getFieldDecorator("title", {
                          rules: grouping
                            ? [
                                {
                                  required: true,
                                  validator: this.validateTitle,
                                },
                              ]
                            : [],
                        })(
                          <Input
                            placeholder="Product title"
                            onChange={() => {
                              this.setState({
                                handleBtnSave: !handleBtnSave,
                              });
                            }}
                          />,
                        )}
                      </Form.Item>
                      <Form.Item label="Facebook Title" help={fbTitleHelp}>
                        {getFieldDecorator("shortTitle")(
                          <Input
                            placeholder="Enter facebook title"
                            maxLength={MAX_TITLE_CHARACTERS.fbTitle}
                            onChange={() => {
                              this.setState({
                                handleBtnSave: !handleBtnSave,
                              });
                            }}
                          />,
                        )}
                      </Form.Item>
                      <Form.Item label="Amazon Title" help={aTitleHelp}>
                        {getFieldDecorator("amzTitle")(
                          <Input
                            placeholder="Enter amazon title"
                            maxLength={MAX_TITLE_CHARACTERS.amzTitle}
                            onChange={() => {
                              this.setState({
                                handleBtnSave: !handleBtnSave,
                              });
                            }}
                          />,
                        )}
                      </Form.Item>
                      <Form.Item label={"Description"}>
                        {getFieldDecorator("description", {
                          initialValue: groupDescriptions,
                        })(
                          // <Wysiwyg
                          //   onChange={() => {
                          //     this.setState({
                          //       handleBtnSave: !handleBtnSave,
                          //     });
                          //   }}
                          // />,
                          <JoditEditor
                            onChange={() => {
                              this.setState({
                                handleBtnSave: !handleBtnSave,
                              });
                            }}
                          />,
                        )}
                      </Form.Item>
                      {/* <Form.Item label={"Short Description"}>
                        {getFieldDecorator("shortDescription", {
                          initialValue: null,
                        })(
                          <Wysiwyg
                            onChange={() => {
                              this.setState({
                                handleBtnSave: !handleBtnSave,
                              });
                            }}
                          />
                        )}
                      </Form.Item> */}
                      {/* <Form.Item label={"Design Resources"}>
                      {getFieldDecorator("psd", {
                        initialValue: null,
                      })(<Input.TextArea rows={4} />)}
                    </Form.Item> */}
                      <Form.Item label={"Collections"}>
                        {getFieldDecorator("collections", {
                          initialValue: [],
                          rules: grouping
                            ? [
                                {
                                  required: true,
                                  message: "Collections is required",
                                },
                              ]
                            : [],
                        })(
                          <CollectionsAutoComplete
                            onChange={() => {
                              this.setState({
                                handleBtnSave: !handleBtnSave,
                              });
                            }}
                          />,
                        )}
                      </Form.Item>
                      <Form.Item label={"Tags"}>
                        {getFieldDecorator("tags", {
                          initialValue: [],
                          rules: grouping
                            ? [
                                {
                                  required: true,
                                  message: "Tags is required",
                                },
                              ]
                            : [],
                        })(
                          <TagsAutoComplete
                            onChange={() => {
                              this.setState({
                                handleBtnSave: !handleBtnSave,
                              });
                            }}
                          />,
                        )}
                      </Form.Item>
                    </div>
                    <Form.Item>
                      {getFieldDecorator("products", {
                        initialValue: [],
                      })(
                        <CampaignProductBaseItems
                          grouped={!!grouping}
                          template={template}
                          selectedIndex={this.state.selected}
                          updatePrices={this.state.updatePrices}
                          onSelected={(pbaseId) => {
                            this.setState({ selected: pbaseId });
                          }}
                          onDelete={(index) => {
                            if (productsValue[index]) {
                              productBases = productBases.filter(
                                (p) =>
                                  p.id !== productsValue[index].productBase.id,
                              );
                              if (this.props.onChange) {
                                this.props.onChange(productBases);
                              }
                              this.props.form.setFieldsValue({
                                products: productsValue.filter(
                                  (_, i) => i !== index,
                                ),
                              });
                            }
                          }}
                          onChangeValue={(v) => {
                            this.setState({
                              onChangeValue: v,
                              handleBtnSave: !handleBtnSave,
                            });
                          }}
                        />,
                      )}
                    </Form.Item>
                    <div
                      className={"add-more"}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        onClick={() => {
                          if (this.props.onAddMore) {
                            this.props.onAddMore();
                          }
                        }}
                        type="primary"
                      >
                        Add More Products
                      </Button>
                    </div>
                  </Card>
                  <Card style={{ marginTop: 20 }} title={"Personalized"}>
                    <Form.Item>
                      {getFieldDecorator("personalized", {
                        initialValue: false,
                        valuePropName: "checked",
                      })(
                        <Checkbox
                          onChange={() => {
                            this.setState({
                              handleBtnSave: !handleBtnSave,
                            });
                          }}
                        >
                          This is a personalized product
                        </Checkbox>,
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("fields", {
                        initialValue: [],
                        rules: [
                          {
                            required: !!personalized,
                            message: "Personalized is required",
                          },
                        ],
                      })(<CustomizeButton showPreview={!!personalized} />)}
                    </Form.Item>
                  </Card>
                  <Card
                    title={"Templates"}
                    className={"mt-5"}
                    // hidden={
                    //   !productBases ||
                    //   !productBases.length ||
                    //   productBases.length === 1
                    // }
                  >
                    <p>
                      Save these variants and settings as a template to be used
                      for future use.
                    </p>
                    <div className={"flex py-5"}>
                      <div style={{ flexGrow: 1 }}>
                        <Button
                          onClick={() => {
                            this.props.onViewTemplates();
                          }}
                          className={"p-0"}
                          type="link"
                        >
                          View All Templates
                        </Button>
                      </div>
                      <Button
                        onClick={() =>
                          this.setState({
                            template: {
                              ...this.state.template,
                              visible: true,
                            },
                            updatePrices: !this.state.updatePrices,
                          })
                        }
                      >
                        Create Template
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col span={7}>
                  <Card style={{ marginBottom: 15 }} title={"Push Product"}>
                    {getFieldDecorator("pushToStores", {
                      initialValue: [],
                    })(
                      <PushProduct
                        onChange={() => {
                          this.setState({
                            handleBtnSave: !handleBtnSave,
                          });
                        }}
                      />,
                    )}
                  </Card>
                  {productBases.map((p, index) => {
                    const pBaseId = p && p.id;
                    const currentBase =
                      productsValue && productsValue.length > 0
                        ? productsValue.find(
                            (i) =>
                              i &&
                              i.productBase &&
                              i.productBase.id === this.state.selected,
                          )
                        : null;
                    const defaultMockupFiles = this.state[`mockup__${index}`];
                    return (
                      <Card
                        style={{ marginBottom: 20 }}
                        hidden={pBaseId !== this.state.selected}
                        key={index}
                        title={
                          // productsValue && productsValue[index]
                          //   ? productsValue[index].title
                          //   : `${p.title} Product Title`
                          currentBase
                            ? currentBase.title
                            : `${p.title} Product Title`
                        }
                      >
                        <Card
                          style={{ marginBottom: 15 }}
                          title={"Print Files"}
                        >
                          {getFieldDecorator(`designPositions__${index}`, {
                            initialValue: [],
                          })(
                            <ProductPrintFiles
                              uploadFolder={"undefined"}
                              ref={(el) => {
                                designPositionRefs[index] = el;
                                return el;
                              }}
                              onChange={() => {
                                this.setState({
                                  handleBtnSave: !handleBtnSave,
                                });
                              }}
                            />,
                          )}
                        </Card>
                        <Card key={`mockup-${index}`} title={"Mockup"}>
                          <Form.Item>
                            {getFieldDecorator(`mockup__${index}`, {
                              initialValue: [],
                              rules: [
                                ...(!grouping
                                  ? [
                                      {
                                        required: true,
                                        message:
                                          "Please select at least one image",
                                      },
                                    ]
                                  : []),
                              ],
                            })(
                              <MediaSelectorButton
                                isMockup
                                prefix="mockups/"
                                folder={"undefined"}
                                multiple={true}
                                listType={"picture-card"}
                                buttonType="primary"
                                fileList={defaultMockupFiles || []}
                                hasDefaultMockup
                                onChange={() => {
                                  this.setState({
                                    handleBtnSave: !handleBtnSave,
                                  });
                                }}
                                onChangeMainImg={(value) =>
                                  this.setState((prevState) => {
                                    return {
                                      ...prevState,
                                      showBtnSave: false,
                                      mainImgId: {
                                        ...prevState.mainImgId,
                                        [`mockup__${index}`]: value,
                                      },
                                    };
                                  })
                                }
                              />,
                            )}
                          </Form.Item>
                        </Card>
                        {!grouping ? (
                          <>
                            <Card
                              title={"Design Resource Files"}
                              style={{ marginTop: 15 }}
                            >
                              <Form.Item>
                                {getFieldDecorator(`psdFiles__${index}`, {
                                  initialValue: [],
                                })(
                                  <MediaSelectorButton
                                    prefix={UPLOAD_FOLDER.PSD_RESOURCE + "/"}
                                    folder={UPLOAD_FOLDER.PSD_RESOURCE}
                                    multiple={true}
                                    listType={"picture-card"}
                                    buttonType="primary"
                                    accept={"image/*"}
                                    onChange={() => {
                                      this.setState({
                                        handleBtnSave: !handleBtnSave,
                                      });
                                    }}
                                  />,
                                )}
                              </Form.Item>
                            </Card>
                            <Card
                              title={"Design Resource Urls"}
                              style={{ marginTop: 15 }}
                            >
                              <Form.Item>
                                {getFieldDecorator(`psd__${index}`, {
                                  initialValue: null,
                                })(<Input.TextArea rows={4} />)}
                              </Form.Item>
                            </Card>
                          </>
                        ) : null}
                      </Card>
                    );
                  })}
                  {grouping ? (
                    <>
                      <Card
                        title={"Design Resource Files"}
                        style={{ marginTop: 15 }}
                      >
                        <Form.Item>
                          {getFieldDecorator(`psdFiles`, {
                            initialValue: [],
                          })(
                            <MediaSelectorButton
                              prefix={UPLOAD_FOLDER.PSD_RESOURCE + "/"}
                              folder={UPLOAD_FOLDER.PSD_RESOURCE}
                              multiple={true}
                              listType={"picture-card"}
                              buttonType="primary"
                              accept={"image/*"}
                              onChange={() => {
                                this.setState({
                                  handleBtnSave: !handleBtnSave,
                                });
                              }}
                            />,
                          )}
                        </Form.Item>
                      </Card>
                      <Card
                        title={"Design Resources Urls"}
                        style={{ marginTop: 15 }}
                      >
                        <Form.Item>
                          {getFieldDecorator(`psd`, {
                            initialValue: null,
                          })(<Input.TextArea rows={4} />)}
                        </Form.Item>
                      </Card>
                    </>
                  ) : null}
                </Col>
                <Col span={24}>
                  <Form.Item
                    style={{
                      marginTop: 20,
                      textAlign: "right",
                      marginBottom: 0,
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (
                          null === this.state.showBtnSave
                            ? false
                            : !this.state.showBtnSave
                        ) {
                          Modal.confirm({
                            title: "Cancel all unsaved changes?",
                            icon: <ExclamationCircleOutlined />,
                            content: "If Ok, you’ll delete any edits you made.",
                            onOk() {
                              history.push(`/${currentParam}/products`);
                            },
                            cancelText: "Continue",
                          });
                        } else {
                          history.push(`/${currentParam}/products`);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="ml-4"
                      type="primary"
                      icon={<LegacyIcon type={"save"} />}
                      loading={loading}
                      htmlType={"submit"}
                      disabled={
                        null === this.state.showBtnSave
                          ? true
                          : this.state.showBtnSave
                      }
                    >
                      Publish Campaign
                    </Button>
                  </Form.Item>
                </Col>
                <Modal
                  onOk={() => this.handleCreateNewTemplate(client)}
                  onCancel={() => {
                    this.setState({
                      template: {
                        ...this.state.template,
                        name: "",
                        slug: "",
                        visible: false,
                      },
                    });
                  }}
                  okText={"Save"}
                  title={"Create a New Template"}
                  visible={this.state.template.visible}
                  confirmLoading={this.state.template.loading}
                >
                  <Form.Item required={true} label={"Template Name"}>
                    <Input
                      onChange={(e) => {
                        this.setState({
                          template: {
                            ...this.state.template,
                            name: e.target.value,
                            slug: toSlug(e.target.value),
                          },
                        });
                      }}
                      value={this.state.template.name}
                    />
                  </Form.Item>
                  <Form.Item required={true} label={"Template Slug"}>
                    <Input
                      onChange={(e) => {
                        this.setState({
                          template: {
                            ...this.state.template,
                            slug: e.target.value,
                          },
                        });
                      }}
                      value={this.state.template.slug}
                    />
                  </Form.Item>
                  <Form.Item label="Default Mockup Files">
                    <MediaSelectorButton
                      prefix="mockups/"
                      folder={UPLOAD_FOLDER.MOCKUPS}
                      multiple={true}
                      listType={"picture-card"}
                      buttonType="primary"
                      accept={"image/*"}
                      onChange={(value) => {
                        this.setState({
                          template: {
                            ...this.state.template,
                            defaultMockups: value,
                          },
                        });
                      }}
                    />
                  </Form.Item>
                </Modal>
              </Row>
            </Form>
          )}
        </ApolloConsumer>
      </React.Fragment>
    );
  }
}

CampaignConfiguration.propTypes = {
  productBases: PropTypes.array,
  template: PropTypes.object,
  onChange: PropTypes.func,
  onAddMore: PropTypes.func,
  onViewTemplates: PropTypes.func,
};

export default Form.create({ name: "campaign-form" })(CampaignConfiguration);
