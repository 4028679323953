import {
	Page,
	Card,
	Form,
	FormLayout,
	TextField,
	Spinner,
	ChoiceList,
	ButtonGroup,
	Button,
	Stack,
} from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import { getParamByRole } from "../../../helper";
import { useHistory } from "react-router-dom";
import { Prompt } from "react-router-dom";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { useToastContext } from "../../shared/ToastContext";

const _ = require('lodash');

const CREATE_TIKTOK_PUSH = gql`
	mutation createTiktokPush($input: TiktokPushRecord!) {
		createTiktokPush(input: $input) {
			id
			status
		}
	}
`;

const StoreSelect = React.lazy(() =>
	import("../../store/components/StoreSelect")
);
const BaseSelect = React.lazy(() => import("../../base/components/BaseSelect"));
const TagSelect = React.lazy(() => import("../../tag/components/TagSelect"));
const CollectionSelect = React.lazy(() => import("../../collection/components/CollectionSelect"));
const ProductSelect = React.lazy(() => import("../../product/components/ProductSelectV2"));
const TiktokCrawlProductsSelect = React.lazy(() => import("./TiktokCrawlProductsSelect"));
const TiktokProductTemplatesSelect = React.lazy(() => import("./TiktokProductTemplatesSelect"));

const Container = styled.div`
	.short-desc {
		font-size: 13px;
		font-style: italic;
	}
	.btn-wrap {
		margin-top: 20px;
		display: flex;
		flex-direction: row-reverse;
	}
	.mt-15 {
		margin-top: 15px;
	}
`;

const ProductBaseFragment = `
	id
	title
	teamId
	variants {
		id
		sorting
		regularPrice
		salePrice
		attributes {
		name
		slug
		option
		}
	}
`;

export default function TiktokCreatePush() {
	const history = useHistory();
	const [fieldData, setFieldData] = useState({
		title: null,
		source: "tiktok_crawl_products", // product source to push to Tiktok come from crawler or MB products
		storeIDs: [],
		limit: null,
		order: "DESC",
		orderBy: "title",
		paused: "false",
		inclusionCollections: {
			ids: [],
			matchType: "MATCH_ONE",
		},
		exclusionCollections: {
			ids: [],
			matchType: "MATCH_ONE",
		},
		inclusionTags: {
			ids: [],
			matchType: "MATCH_ONE",
		},
		exclusionTags: {
			ids: [],
			matchType: "MATCH_ONE",
		},
		productBaseIDs: [],
		includedCrawlProductIDs: [],
		excludedCrawlProductIDs: [],
		includedMBProductIDs: [],
		excludedMBProductIDs: [],
		intervalMinutes: null,
		maxDailyPushes: null,
		tiktokProductTemplateID: null,
		processingMode: "USING_API",
		titlePrefix: "",
		titleSuffix: "",
		mainImageIndex: [1]
	});
	const [fieldError, setFielError] = useState({});

	const [isPrompt, setIsPrompt] = useState(false);
	const [mutationData, setMutationData] = useState({loading: false, response: null, error: null});

	const { currentUser } = useAppContext();
	const param = getParamByRole(currentUser);

	const pushesPagePath = `/${param}/tiktok-hub/push-products`;
	const { toggleToast, setNotify } = useToastContext();

	const handleFieldDataChanged = (field, value) => {
		setFieldData((prevFields) => {
			if (field.includes(".")) { // Set complex nested object value
				const keys = field.split(".");
				const [parentKey, childKey] = keys;

				return {
					...prevFields,
					[parentKey]: {
						...prevFields[parentKey],
						[childKey]: value,
					},
				};
			}

			return { // Set case simple key:value
				...prevFields,
				[field]: value,
			};
		});
		if (value) { // The value is valid so try to remove the field error.
			setFielError((prevState) => {
				const newState = { ...prevState };
				if (field in newState) {
					delete newState[field];
				}
				return newState;
			});
		}
		setIsPrompt(true);
	};

	const handleFieldChange = React.useCallback((value, id) => {
		handleFieldDataChanged(id, value);
	}, [])

	const handleFieldErrorChanged = (field, value) => {
		setFielError((prevFields) => ({
			...prevFields,
			[field]: value,
		}));
	}

	const isValidKey = (obj, key) => {
		let valid = _.has(obj, key) && obj[key] != null && obj[key].length > 0;
		return valid;
	};

	const [createTiktokPush] = useMutation(CREATE_TIKTOK_PUSH);

	const handleFormSubmit = async () => {
		let formData = {...fieldData};
		let hasError = false;
		if (!isValidKey(formData, 'title')) {
			handleFieldErrorChanged('title', 'Please enter a title');
			hasError = true;
		}
		if (!isValidKey(formData, 'storeIDs')) {
			handleFieldErrorChanged('storeIDs', 'Please choose at least one store');
			hasError = true;
		}

		// if ((formData.source == 'mb_products' || formData.processingMode == 'EXPORT_EXCEL') && !formData.tiktokProductTemplateID) {
		if (!formData.tiktokProductTemplateID) {
			handleFieldErrorChanged('tiktokProductTemplateID', 'Please choose a product template');
			hasError = true;
		}

		if (hasError) {
			return;
		}

		if (formData && formData.paused && 'string' == typeof formData.paused) {
			if ('true' === formData.paused) {
				formData['paused'] = true;
			} else {
				formData['paused'] = false;
			}
		}

		if (formData && formData.limit && formData.limit.length > 0 && 'number' != typeof formData.limit) {
			formData['limit'] = parseInt(formData.limit);
		}

		if (formData && formData.intervalMinutes && formData.intervalMinutes.length > 0 && 'number' != typeof formData.intervalMinutes) {
			formData['intervalMinutes'] = parseInt(formData.intervalMinutes);
		}

		if (formData && formData.maxDailyPushes && formData.maxDailyPushes.length > 0 && 'number' != typeof formData.maxDailyPushes) {
			formData['maxDailyPushes'] = parseInt(formData.maxDailyPushes);
		}

		if (formData?.mainImageIndex?.length > 0) {
			formData.mainImageIndex = formData.mainImageIndex[0]
		}

		// Submit data
		toggleToast(true);
		try {
			setMutationData((prev) => ({...prev, loading: true}));
			const res = await createTiktokPush({
				variables: {
					input: formData
				},
			});
			if (res && res.data && res.data.createTiktokPush) {
				setNotify({
                    msg: "The new push has been added successfully",
                    err: false,
                });
			}
			
		} catch (ex) {
			setNotify({
				msg: "Could not add new push",
				err: false,
			});
		} finally {
			setIsPrompt(false);
			setMutationData((prev) => ({...prev, loading: false}));
			setTimeout(function() {
				history.push(pushesPagePath);
			}, 3000);
		}
	}

	return (
		<Container>
			<Page
				title="New Push"
				breadcrumbs={[
					{
						content: "All Pushes",
						url: pushesPagePath,
					},
				]}
			>
				<React.Fragment>
					<Prompt
						when={isPrompt}
						message="You have unsaved changes, are you sure you want to leave?"
					/>
					<Container>
						<Form onSubmit={handleFormSubmit}>
							<Card sectioned>
								<FormLayout>
									<div>
										<ComponentLabelPolaris
											label="Push name"
											required
										/>
										<TextField
											value={fieldData?.title || null}
											placeholder="Enter push name"
											onChange={(val) =>
												handleFieldDataChanged(
													"title",
													val
												)
											}
											error={fieldError?.title || null}
										/>
									</div>

									<div>
										<ComponentLabelPolaris label="Processing mode" />
										<ChoiceList
											choices={[
												{
													value: "USING_API",
													label: "Using Tiktok Shop API",
												},
												{
													value: "EXPORT_EXCEL",
													label: "Exporting to Excel file",
												},
											]}
											selected={
												fieldData?.processingMode || 'USING_API'
											}
											onChange={(val) =>
												handleFieldDataChanged(
													"processingMode",
													val?.[0]
												)
											}
										/>
									</div>

									<div>
										<ComponentLabelPolaris label="Product source" />
										<ChoiceList
											choices={[
												{
													value: "tiktok_crawl_products",
													label: "Using Tiktok crawl products",
												},
												{
													value: "mb_products",
													label: "Using MerchBridge products",
												},
											]}
											selected={fieldData?.source || 'tiktok_crawl_products'}
											onChange={(val) =>
												{
													handleFieldDataChanged(
														"source",
														val?.[0]
													)
												}
											}
										/>
									</div>

									<div>
										<React.Suspense
											fallback={<Spinner size="small" />}
										>
											<ComponentLabelPolaris
												label="Target stores"
												required
											/>
											<StoreSelect
												allowMultiple={fieldData?.processingMode !== 'EXPORT_EXCEL'}
												labelHidden={true}
												value={fieldData?.storeIDs}
												onChange={(val) =>{
														let newVal = val;
														if (newVal && typeof newVal === 'string') {
															newVal = [newVal]
														}
														handleFieldDataChanged(
															"storeIDs",
															newVal
														)
													}
												}
												filter={{ platform: "tiktok" }} // Only allow select Tiktok store
												error={fieldError?.storeIDs || null}
											/>
										</React.Suspense>
									</div>

									<div>
										<React.Suspense
											fallback={<Spinner size="small" />}
										>
											<TiktokProductTemplatesSelect
												// required={ (fieldData.source == 'mb_products' || fieldData.processingMode == 'EXPORT_EXCEL') ? true : false }
												required
												label="Tiktok product template"
												onChange={(val) =>
													handleFieldDataChanged(
														"tiktokProductTemplateID",
														val
													)
												}
												filters={{
													must_has_excel_template_file: fieldData?.processingMode && fieldData.processingMode == 'EXPORT_EXCEL' ? true : false,
												}}
												value={fieldData?.tiktokProductTemplateID}
												error={fieldError?.tiktokProductTemplateID || null}
											/>
											<p className="short-desc">This template will be applied to MerchBridge products when pushing them to TikTok.</p>
										</React.Suspense>
									</div>

								</FormLayout>
							</Card>

							<Card sectioned>
								<FormLayout>
									<div>
										<React.Suspense
											fallback={<Spinner size="small" />}
										>
											<CollectionSelect
												multiple
												label="Inclusion Collections"
												onChange={(val) =>
													handleFieldDataChanged(
														"inclusionCollections.ids",
														val
													)
												}
												value={fieldData?.inclusionCollections?.ids}
											/>
										</React.Suspense>
									</div>
									<div>
										<ComponentLabelPolaris label="Matching type" />
										<ChoiceList
											choices={[
												{
													value: "MATCH_ONE",
													label: "Match One: only one value to match for the selected collections",
												},
												{
													value: "MATCH_ALL",
													label: "Match All: requires all values to match for the selected collections",
												},
											]}
											selected={
												fieldData?.inclusionCollections?.matchType || 'MATCH_ONE'
											}
											onChange={(val) =>
												handleFieldDataChanged(
													"inclusionCollections.matchType",
													val?.[0]
												)
											}
										/>
									</div>
								</FormLayout>
							</Card>

							<Card sectioned>
								<FormLayout>
									<div>
										<React.Suspense
											fallback={<Spinner size="small" />}
										>
											<CollectionSelect
												multiple
												label="Exclusion Collections"
												onChange={(val) =>
													handleFieldDataChanged(
														"exclusionCollections.ids",
														val
													)
												}
												value={fieldData?.exclusionCollections?.ids}
											/>
										</React.Suspense>
									</div>
									<div>
										<ComponentLabelPolaris label="Matching type" />
										<ChoiceList
											choices={[
												{
													value: "MATCH_ONE",
													label: "Match One: only one value to match for the selected collections",
												},
												{
													value: "MATCH_ALL",
													label: "Match All: requires all values to match for the selected collections",
												},
											]}
											selected={
												fieldData?.exclusionCollections?.matchType || 'MATCH_ONE'
											}
											onChange={(val) =>
												handleFieldDataChanged(
													"exclusionCollections.matchType",
													val?.[0]
												)
											}
										/>
									</div>
								</FormLayout>
							</Card>

							<Card sectioned>
								<FormLayout>
									<div>
										<React.Suspense
											fallback={<Spinner size="small" />}
										>
											<TagSelect
												multiple
												label="Inclusion tags"
												onChange={(val) =>
													handleFieldDataChanged(
														"inclusionTags.ids",
														val
													)
												}
												value={fieldData?.inclusionTags?.ids}
											/>
										</React.Suspense>
									</div>
									<div>
										<ComponentLabelPolaris label="Matching type" />
										<ChoiceList
											choices={[
												{
													value: "MATCH_ONE",
													label: "Match One: only one value to match for the selected collections",
												},
												{
													value: "MATCH_ALL",
													label: "Match All: requires all values to match for the selected collections",
												},
											]}
											selected={
												fieldData?.inclusionTags?.matchType || 'MATCH_ONE'
											}
											onChange={(val) =>
												handleFieldDataChanged(
													"inclusionTags.matchType",
													val?.[0]
												)
											}
										/>
									</div>
								</FormLayout>
							</Card>

							<Card sectioned>
								<FormLayout>
									<div>
										<React.Suspense
											fallback={<Spinner size="small" />}
										>
											<TagSelect
												multiple
												label="Exclusion tags"
												onChange={(val) =>
													handleFieldDataChanged(
														"exclusionTags.ids",
														val
													)
												}
												value={fieldData?.exclusionTags?.ids}
											/>
										</React.Suspense>
									</div>
									<div>
										<ComponentLabelPolaris label="Matching type" />
										<ChoiceList
											choices={[
												{
													value: "MATCH_ONE",
													label: "Match One: only one value to match for the selected collections",
												},
												{
													value: "MATCH_ALL",
													label: "Match All: requires all values to match for the selected collections",
												},
											]}
											selected={
												fieldData?.exclusionTags?.matchType || 'MATCH_ONE'
											}
											onChange={(val) =>
												handleFieldDataChanged(
													"exclusionTags.matchType",
													val?.[0]
												)
											}
										/>
									</div>
								</FormLayout>
							</Card>

							{fieldData.source == 'tiktok_crawl_products' ? (
								<Card sectioned>
									<FormLayout>
										<div>
											<React.Suspense fallback={<Spinner size="small" />}>
												<TiktokCrawlProductsSelect
													label="Inclusion Tiktok crawl products"
													value={fieldData?.includedCrawlProductIDs}
													allowMultiple
													onChange={(val) => handleFieldDataChanged("includedCrawlProductIDs",val)}
												/>
											</React.Suspense>
											<p className="short-desc">Only the selected products will be processed</p>
										</div>
										<div>
											<React.Suspense fallback={<Spinner size="small" />}>
												<TiktokCrawlProductsSelect
													label="Exclusion Tiktok crawl products"
													value={fieldData?.excludedCrawlProductIDs}
													allowMultiple
													onChange={(val) => handleFieldDataChanged("excludedCrawlProductIDs",val)}
												/>
											</React.Suspense>
											<p className="short-desc">Any products not included in the selected ones will be processed</p>
										</div>
										

									</FormLayout>
								</Card>
							) : (
								<Card sectioned>
									<FormLayout>
										<div>
											<React.Suspense fallback={<Spinner size="small" />}>
												<BaseSelect
													allowMultiple
													value={ fieldData?.productBaseIDs }
													onChange={ (val) => handleFieldDataChanged("productBaseIDs",val) }
													fragment={ ProductBaseFragment }
												/>
												<p className="short-desc">Products containing any of the selected values will be included in the push</p>
											</React.Suspense>
										</div>
										<div>
											<React.Suspense fallback={<Spinner size="small" />}>
												<ProductSelect
													label="Inclusion special products"
													value={fieldData?.includedMBProductIDs}
													allowMultiple
													onChange={(val) => handleFieldDataChanged("includedMBProductIDs",val)}
												/>
											</React.Suspense>
											<p className="short-desc">Only the selected products will be processed</p>
										</div>
										<div>
											<React.Suspense fallback={<Spinner size="small" />}>
												<ProductSelect
													label="Exclusion special products"
													value={fieldData?.excludedMBProductIDs}
													allowMultiple
													onChange={(val) => handleFieldDataChanged("excludedMBProductIDs",val)}
												/>
											</React.Suspense>
											<p className="short-desc">Any products not included in the selected ones will be processed</p>
										</div>
										

									</FormLayout>
								</Card>
							) }

							<Card sectioned>
								<FormLayout>
									<div>
										<ComponentLabelPolaris label="Limit products per store" />
										<TextField
											type="number"
											value={fieldData?.limit}
											onChange={(val) =>
												handleFieldDataChanged(
													"limit",
													val
												)
											}
											min={0}
											//error={errors.limit}
										/>
										<p className="short-desc">
											The maximum number of products you
											want to retrieve. Leave it blank to
											retrieve all products.
										</p>
									</div>

									<div>
										<ComponentLabelPolaris label="Order by" />
										<ChoiceList
											choices={[
												{
													value: "title",
													label: "Title",
												},
												{
													value: "created_at",
													label: "Created at",
												},
											]}
											selected={fieldData?.orderBy || 'title'}
											onChange={(val) =>
												handleFieldDataChanged(
													"orderBy",
													val?.[0]
												)
											}
										/>
									</div>

									<div>
										<ComponentLabelPolaris label="Order" />
										<ChoiceList
											choices={[
												{
													value: "DESC",
													label: "Descending",
												},
												{
													value: "ASC",
													label: "Ascending",
												},
											]}
											selected={fieldData?.order || 'DESC'}
											onChange={(val) =>
												handleFieldDataChanged(
													"order",
													val?.[0]
												)
											}
										/>
									</div>

									{fieldData?.processingMode && fieldData.processingMode == 'USING_API' ? (
										<>
											<div>
												<ComponentLabelPolaris label="Pause this push" />
												<ChoiceList
													choices={[
														{ value: "false", label: "No" },
														{ value: "true", label: "Yes" },
													]}
													selected={fieldData?.paused && (true === fieldData.paused || 'true' === fieldData.paused)  ? ['true'] : ['false']}
													onChange={(val) =>
														{
															handleFieldDataChanged(
																"paused",
																'true' == val?.[0] ? true : false
															)
														}
													}
												/>
												<p className="short-desc">
													This option ensures that your push will not process until it is resummed
												</p>
											</div>

											<div className="mt-15">
												<ComponentLabelPolaris label="Push Interval (Minutes)" />
												<TextField
													type="number"
													value={fieldData?.intervalMinutes}
													onChange={(val) =>
														handleFieldDataChanged(
															"intervalMinutes",
															val
														)
													}
													min={0}
													//error={errors.limit}
												/>
												<p className="short-desc">Set the minimum wait time in minutes between pushes</p>
											</div>

											<div className="mt-15">
												<ComponentLabelPolaris label="Maximum Daily Pushes" />
												<TextField
													type="number"
													value={fieldData?.maxDailyPushes}
													onChange={(val) =>
														handleFieldDataChanged(
															"maxDailyPushes",
															val
														)
													}
													min={0}
													//error={errors.limit}
												/>
												<p className="short-desc">Enter the maximum number of products that can be pushed each day</p>
											</div>
										</>
									) : null }

								</FormLayout>
							</Card>

							<Card sectioned title="Extra settings">
									<Stack vertical>
										<TextField
											label="Title Prefix"
											id="titlePrefix"
											onChange={handleFieldChange}
											placeholder="Enter title prefix"
											value={fieldData.titlePrefix || ""}
											helpText="If this string includes a semicolon(;), the System will split it and randomly select a value for Title"
										/>
										<TextField
											label="Title Suffix"
											id="titleSuffix"
											onChange={handleFieldChange}
											placeholder="Enter title suffix"
											value={fieldData.titleSuffix || ""}
											helpText="If this string includes a semicolon(;), the System will split it and randomly select a value for Title"
										/>

										<ChoiceList title="Which image index do you want to set as the main image?" name="mainImageIndex" choices={[
											{value: 1, label: "Main image"},
											{ value:2, label: "Image 2"},
											{ value:3, label: "Image 3"}
											]}
											selected={fieldData.mainImageIndex || []}
											onChange={handleFieldChange}
											/>
									</Stack>
							</Card>

							<div className="btn-wrap">
								<ButtonGroup>
									<Button onClick={() => history.push(pushesPagePath) }>
										Cancel
									</Button>

									<Button submit primary loading={mutationData.loading} disabled={!isPrompt || mutationData.loading}>
										Create new push
									</Button>
								</ButtonGroup>
							</div>
						</Form>
					</Container>
				</React.Fragment>
			</Page>
		</Container>
	);
}
