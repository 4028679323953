import { Heading } from "@shopify/polaris";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
} from "react";
import styled from "styled-components";

import { reducerFn } from "../../../helper";
import ImageTemplateSelect from "../../tiktok-hub/image-template/image-template-select";

function TiktokSizeChartImageTemplate({ value }, ref) {
  const [state, setState] = useReducer(reducerFn, {
    value: [],
  });

  useEffect(() => {
    if (!value) return;

    setState({ value: [value] });
  }, [value]);

  const handleValuesChange = useCallback((value) => {
    setState({ value: value ? [value] : [] });
  }, []);

  useImperativeHandle(ref, () => ({
    getId: () => state.value,
  }));

  return (
    <Wrapper>
      <Heading element="h3" children="Size chart image template" />
      <ImageTemplateSelect
        label={`Size char image template`}
        value={state.value}
        onChange={handleValuesChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default forwardRef(TiktokSizeChartImageTemplate);
