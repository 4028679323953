import { useMutation, useSubscription } from "@apollo/react-hooks";
import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Form,
  FormLayout,
  Modal,
  TextContainer,
  TextField,
} from "@shopify/polaris";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { get } from "lodash";
import authImage from "../../assets/images/tiktok-app-oauth.png";
import CopyBox from "../../components/shared/CopyBox";
import { useToastContext } from "../../components/shared/ToastContext";
import { TIKTOK_LINK_AUTH } from "../../config";
import { useAppContext } from "../../context";
import { CREATE_ACCOUNT_SALE_CHANNEL } from "../../graphql/mutations";
import { STORE_EVENTS } from "../../graphql/subscription";
import {
  getParamByRole,
  handleError,
  isPME_TEAM,
  reducerFn,
} from "../../helper";
import { PLATFORMS } from "../../variable";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import ExtraInfoTiktok from "../store/components/ExtraInfoTiktok";

export default function AddTiktokAccount() {
  const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
    name: "",
    email: "",
    "shop Code": "",
    emailLabel: "Store Email",
    useAPI: false,
    openModal: false,
    canRedirect: true,
  });
  const [extraInfo, setExtraInfo] = React.useReducer(reducerFn, {
    friendly: "",
    files: [],
  });
  const [errors, setErrors] = React.useReducer((p, s) => ({ ...p, ...s }), {});

  // Context
  const { setNotify, toggleToast } = useToastContext();
  const { currentUser } = useAppContext();
  const isPMETeam = isPME_TEAM(currentUser);
  const param = getParamByRole(currentUser);
  const history = useHistory();

  const redirectRef = React.useRef(null);
  const [createAccount, { loading }] = useMutation(
    CREATE_ACCOUNT_SALE_CHANNEL,
    {
      onCompleted: () => {
        setNotify({ msg: "Create Tiktok account success", err: false });

        if (state.useAPI) {
          setState({ openModal: true, canRedirect: true });
          return;
        }
        redirectFn();
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
        setState({ openModal: false, canRedirect: false });
      },
    },
  );

  const { data } = useSubscription(STORE_EVENTS);

  React.useEffect(() => {
    if (data?.storeEvents?.event === "store_tiktok_is_connected") {
      if (state["shop Code"] === data?.storeEvents?.store?.domain) {
        redirectFn();
      }
    }
  }, [data, state["shop Code"]]);

  const redirectFn = React.useCallback(() => {
    redirectRef.current && clearTimeout(redirectRef.current);
    redirectRef.current = setTimeout(() => {
      history.push(`/${param}/stores/tiktok`);
    }, 2000);
  }, [history, param]);

  const validateField = React.useCallback((value, id) => {
    let error = null;
    let label = id.charAt(0).toUpperCase() + id.slice(1);

    if (!value || !value.length) {
      error = `${label} is required.`;
    }
    setErrors({ [id]: error });
  }, []);

  const inputRef = React.useRef(null);
  const handleFieldChange = React.useCallback((value, id) => {
    setState({ [id]: value });

    inputRef.current && clearTimeout(inputRef.current);
    inputRef.current = setTimeout(() => {
      validateField(value, id);
    }, 350);
  }, []);

  const handleSubmit = React.useCallback(() => {
    let { email, name, useAPI } = state;

    validateField(name, "name");

    let tmp = email && name;
    const input = {
      name,
      platform: PLATFORMS.Tiktok,
      useAPI,
    };

    if (useAPI) {
      const domain = state["shop Code"];
      tmp = name && domain;
      validateField(domain, "shop Code");
      input.domain = domain;
      input.email = "";
    } else {
      validateField(email, "email");
      input.email = email;
    }

    if (extraInfo) {
      let ei = { friendly: extraInfo.friendly };
      if (extraInfo?.files?.length > 0) {
        const id = get(extraInfo, "files[0].id") || null;
        if (id) {
          ei["logo"] = id;
        }
      }

      input["extraInfo"] = ei;
    }

    if (tmp) {
      toggleToast && toggleToast();
      setNotify && setNotify({ msg: null, err: false });

      createAccount({
        variables: {
          input,
        },
      });
    }
  }, [state, extraInfo, createAccount, setNotify, toggleToast, validateField]);

  const handleUseAPIChange = React.useCallback((value) => {
    const s = { useAPI: value, emailLabel: "Store Email" };
    if (value) {
      s.emailLabel = "Shop Code";
    }

    setState(s);
  }, []);

  const onCloseModal = React.useCallback(() => {
    setState({ openModal: false });

    if (state.canRedirect) {
      redirectFn();
    }
  }, [state.canRedirect]);

  const handleExtraChange = React.useCallback((value, id) => {
    setExtraInfo((p) => ({
      ...p.extraInfo,
      [id]: value,
    }));
  }, []);

  React.useEffect(
    () => () => {
      inputRef.current && clearTimeout(inputRef.current);
    },
    [],
  );

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit} name="add_tiktok_account">
        <Card title="General" sectioned>
          <FormLayout>
            <Checkbox
              label="Use TikTok's API"
              checked={state.useAPI}
              onChange={handleUseAPIChange}
            />
            <div>
              <ComponentLabelPolaris label="Store name" required />
              <TextField
                value={state.name}
                id="name"
                onChange={handleFieldChange}
                error={errors["name"]}
                placeholder="Enter store name"
              />
            </div>
            <div>
              <ComponentLabelPolaris label={state.emailLabel} required />
              <TextField
                value={state.useAPI ? state["shop Code"] : state.email}
                id={state.useAPI ? "shop Code" : "email"}
                onChange={handleFieldChange}
                error={state.useAPI ? errors["shop Code"] : errors["email"]}
                placeholder={`Enter ${state.emailLabel}`}
              />
            </div>
          </FormLayout>
        </Card>
        {isPMETeam ? (
          <React.Suspense fallback={null}>
            <ExtraInfoTiktok values={extraInfo} onChange={handleExtraChange} />
          </React.Suspense>
        ) : null}

        <div className="btn-wrap">
          <ButtonGroup>
            <Button children="Cancel" url={`/${param}/stores/tiktok`} />
            <Button children="Add Account" primary loading={loading} submit />
          </ButtonGroup>
        </div>
      </Form>

      <ConnectTiktokModal open={state.openModal} onClose={onCloseModal} />
    </Wrapper>
  );
}

export function ConnectTiktokModal({ open, onClose }) {
  const [checked, setChecked] = React.useState(false);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Connect Your TikTok Account"
      sectioned
    >
      <ModalContainer>
        <TextContainer>
          <h2>
            Manage and fulfill your Tiktok orders from all your accounts at once
            with MerchBridge
          </h2>
          <div className={"image"}>
            <img src={authImage} alt={""} style={{ backgroundColor: "#fff" }} />
          </div>
          <div className={"warning-box"}>
            <p>
              Warning: To protect your account from being suspended by Tiktok,
              Please:
            </p>
            <ul>
              <li>Read and follow the documentation carefully</li>
              <li>
                Only use the Auth Tiktok Account URL from the PC or VPS of the
                Tiktok account that you want to connect
              </li>
            </ul>
          </div>
          <Checkbox
            label="I have read and agreed with the documentation."
            checked={checked}
            onChange={setChecked}
          />
          {checked && <CopyBox text={TIKTOK_LINK_AUTH} />}
        </TextContainer>
      </ModalContainer>
    </Modal>
  );
}

const ModalContainer = styled.div`
  .image {
    display: flex;
    justify-content: center;
    img {
      max-width: 200px;
      height: auto;
    }
  }
  .warning-box {
    background: rgb(233, 162, 173);
    padding: 10px;
  }
`;

const Wrapper = styled.div`
  a:hover {
    color: inherit;
  }

  .btn-wrap {
    margin-top: 2rem;
    justify-content: flex-end;
    display: flex;
  }
`;
