import React from "react";
import { WrapperPage } from "../../../components/shared/WrapperPage";
import SuspenseComp from "../../../components/shared/SuspenseComp";
import { SkeletonPagePolaris } from "../../../components/shared/SkeletonPagePolaris";
import TiktokCreateProductTemplate from "../../../components/tiktok/product-templates/TiktokCreateProductTemplate"

export default function TiktokCreateProductTemplatePage() {
	return (
		<WrapperPage>
			<SuspenseComp fallback={<SkeletonPagePolaris />}>
				<TiktokCreateProductTemplate />
			</SuspenseComp>
		</WrapperPage>
	);
}
