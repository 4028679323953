import React, { useState, useEffect } from "react";
import { Autocomplete, Tag } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { TagContainer } from "../../shared/TagContainer";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";

// GraphQL Query
const GET_TIKTOK_CATEGORIES = gql`
	fragment TiktokCat on TiktokCategory {
		id
		localName
		isLeaf
		parentID
	}
	query getFirstStoreTiktokCategories {
		getFirstStoreTiktokCategories {
			...TiktokCat
			children {
				...TiktokCat
				children {
					...TiktokCat
					children {
						...TiktokCat
						children {
							...TiktokCat
						}
					}
				}
			}
		}
	}
`;

// Helper function to flatten categories
const flattenCategories = (categories, parentPath = "") => {
	const flattened = [];

	categories.forEach((category) => {
		const currentPath = parentPath
			? `${parentPath} > ${category.localName}`
			: category.localName;

		flattened.push({
			id: category.id,
			path: currentPath,
			localName: category.localName,
			isLeaf: category.isLeaf,
		});

		if (category.children && category.children.length > 0) {
			flattened.push(
				...flattenCategories(category.children, currentPath)
			);
		}
	});

	return flattened;
};

export default function TiktokCategorySelector({ defaultValue, onSelect, label }) {
	const { loading, error, data } = useQuery(GET_TIKTOK_CATEGORIES);
	const [options, setOptions] = useState([]); // Full list of options
	const [displayOptions, setDisplayOptions] = useState([]); // Subset of options for display
	const [inputValue, setInputValue] = useState("");
	const [selected, setSelected] = useState(defaultValue || []); // Selected category IDs

	useEffect(() => {
		setSelected(defaultValue);
	}, [defaultValue])

	// Load and flatten categories
	useEffect(() => {
		if (data && data.getFirstStoreTiktokCategories) {
			const flattened = flattenCategories(
				data.getFirstStoreTiktokCategories
			);
			const mappedOptions = flattened.map((item) => ({
				label: item.path,
				value: item.id,
				disabled: !item.isLeaf, // Disable non-leaf categories
			}));
			setOptions(mappedOptions);
			setDisplayOptions(mappedOptions.slice(0, 100)); // Display first 100 options initially
		}
	}, [data]);

	// Update display options based on search input
	const updateText = (value) => {
		setInputValue(value);

		if (value) {
			// Filter options based on search text
			const lowerSearch = value.toLowerCase();
			const filtered = options.filter((option) =>
				option.label.toLowerCase().includes(lowerSearch)
			);
			setDisplayOptions(filtered.slice(0, 100)); // Limit to first 100 results
		} else {
			// Reset to the first 100 options when search is cleared
			setDisplayOptions(options.slice(0, 100));
		}
	};

	// Handle option selection
	const updateSelection = (selectedIds) => {
		setSelected(selectedIds); // Update selected state
		if (onSelect) onSelect(selectedIds);

		// Update displayOptions to highlight selected
		setDisplayOptions((prevDisplayOptions) =>
			prevDisplayOptions.map((option) => ({
				...option,
				selected: selectedIds.includes(option.value),
			}))
		);
	};

	// Get selected labels
	const selectedLabels = selected
		.map((id) => options.find((option) => option.value === id))
		.filter(Boolean)
		.map((option) => option.label);

	if (loading) return <p>Loading categories...</p>;
	if (error) return <p>Error loading categories</p>;

	return (
		<div>
			<ComponentLabelPolaris
				label={label ? label : "Tiktok category"}
				required
			/>
			<Autocomplete
				options={displayOptions}
				selected={selected}
				textField={
					<Autocomplete.TextField
						value={inputValue}
						onChange={updateText}
						placeholder="Type to search..."
					/>
				}
				onSelect={updateSelection}
			/>

			{/* Display selected tags below */}
			<TagContainer>
				{selectedLabels.map((label) => (
					<Tag
						key={label}
						onRemove={() =>
							updateSelection(
								selected.filter(
									(id) =>
										options.find(
											(option) => option.label === label
										).value !== id
								)
							)
						}
					>
						{label}
					</Tag>
				))}
			</TagContainer>
		</div>
	);
}