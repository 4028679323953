import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ApolloConsumer } from "@apollo/react-components";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Modal,
  notification,
  Row,
} from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import { handleError, toSlug } from "../../../helper";
import history from "../../../history";
import {
  MAX_LENGTH,
  TITLE_LENGTH_MESS,
  UPLOAD_FOLDER,
} from "../../../variable";
import CustomizeButton from "../../customize/CustomizeButton";
import CampaignProductBaseItems from "../../seller/CampaignProductBaseItems";
import CollectionsAutoComplete from "../../shared/CollectionsAutoComplete";
import PageTitle from "../../shared/PageTitle";
import TagsAutoComplete from "../../shared/TagsAutoComplete";
import MediaSelectorButton from "../../supplier/MediaSelectorButton";
import Wysiwyg from "../../Wysiwyg";
import JoditEditor from "../../shared/JoditEditor";

class AddForCampaignForm extends Component {
  state = {
    selected: 0,
    loading: false,
    defaultFieldsValue: null,
    showBtnSave: null,
  };

  componentDidMount() {
    const { productBases } = this.props;
    const fieldValue = {
      personalized: false,
      fields: [],
      products: productBases.map((p) => {
        let title = `${p.title} Product Title`;
        let collections = [];
        let tags = [];

        const item = {
          productBase: p,
          title,
          description: p.defaultContent,
          collections: collections ? collections : [],
          tags: tags ? tags : [],
          variants: p.variants.map((variant, index) => {
            let v = {
              productBaseVariant: variant,
              productBaseVariantId: variant.id,
              image: null,
              regularPrice: variant.regularPrice,
              salePrice: variant.salePrice,
              disabled: variant.disabled,
            };
            return v;
          }),
        };
        return item;
      }),
    };

    this.props.form.setFieldsValue(fieldValue);
  }
  onFinish = (values) => {
    const { productBases } = this.props;
    const {
      name,
      slug,
      defaultMockups,
      collections,
      fields,
      tags,
      description,
      personalized,
    } = values;
    let products = values.products.map((p) => {
      return {
        title: p.title,
        description: description,
        productBaseId: p.productBase.id,
        tags: tags ? tags : [],
        tagIds: tags ? tags.map((t) => t.id) : [],
        collections: collections ? collections : [],
        collectionIds: collections ? collections.map((c) => c.id) : [],
        variants: p.variants.map((v) => {
          return {
            salePrice: v.salePrice,
            regularPrice: v.regularPrice,
            productBaseVariantId: v.productBaseVariantId,
            disabled: v && v.disabled ? v.disabled : false,
          };
        }),
      };
    });

    let data = {
      productBaseIds: productBases.map((p) => p.id),
      Personalized: personalized,
      fields: fields,
      products: products,
    };

    const defaultMockupIds = (defaultMockups || [])
      .map((val) => val.id)
      .filter(Boolean);

    let template = {
      name,
      slug,
      data,
      grouping: true,
      defaultMockupIds,
    };
    if (this.props.onSubmit) {
      this.props.onSubmit(template);
    }
  };

  handleError = (err) => {
    let uniqueMessage = {};
    _.each(err, (e) => {
      _.each(_.get(e, "errors", []), (er) => {
        const msg = _.trim(er.message.toLowerCase());
        if (typeof uniqueMessage[msg] === "undefined") {
          notification.error({ message: handleError(er.message) });
        }
        uniqueMessage[msg] = true;
      });
    });
  };

  validateTitle = (_rule, value, callback) => {
    let error = null;
    if (!value) {
      error = "Template name is required.";
    } else if (value.length > MAX_LENGTH) {
      error = TITLE_LENGTH_MESS;
    }
    error ? callback(error) : callback();
  };

  handleTitleChange = (e) => {
    const { value } = e.target;
    const slug = toSlug(value);
    this.props.form.setFieldsValue({ slug });
  };

  render() {
    let { productBases, currentParam, loading } = this.props;
    const { getFieldDecorator } = this.props.form;
    let productsValue = this.props.form.getFieldValue("products");
    const personalized = this.props.form.getFieldValue("personalized");
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };

    let groupDescriptions = productBases.reduce((accum, current) => {
      let content = `<div><strong>${current.title}<strong></div>${current.defaultContent}`;
      accum += content;
      return accum;
    }, "");

    return (
      <React.Fragment>
        <Prompt
          when={false === this.state.isPrompt}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <ApolloConsumer>
          {(_client) => (
            <Form
              {...layout}
              onSubmit={(e) => {
                e.preventDefault();
                this.props.form.validateFields((err, values) => {
                  if (!err) {
                    this.onFinish(values);
                  } else {
                    this.handleError(err);
                  }
                });
              }}
              scrollToFirstError
              onChange={() => {
                this.setState({ showBtnSave: false });
              }}
            >
              <PageTitle
                subTitle={"Templates"}
                title={"Add Template for Campaign"}
                link={`/${currentParam}/templates`}
              />
              <Row gutter={16}>
                <Col span={24}>
                  <Card>
                    <Form.Item label={"Template name"}>
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            validator: this.validateTitle,
                          },
                        ],
                      })(
                        <Input
                          placeholder="Template name"
                          onChange={this.handleTitleChange}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label={"Template slug"}>
                      {getFieldDecorator("slug", {
                        rules: [
                          {
                            required: true,
                            message: "Template slug is required ",
                          },
                        ],
                      })(<Input placeholder="Template slug" />)}
                    </Form.Item>
                    <Form.Item label={"Description"}>
                      {getFieldDecorator("description", {
                        initialValue: groupDescriptions,
                      // })(<Wysiwyg />)}
                      })(<JoditEditor />)}
                    </Form.Item>
                    <Form.Item label={"Default Mockup Files"}>
                      {getFieldDecorator("defaultMockups", {
                        initialValue: [],
                      })(
                        <MediaSelectorButton
                          prefix="mockups/"
                          folder={UPLOAD_FOLDER.MOCKUPS}
                          multiple={true}
                          listType={"picture-card"}
                          buttonType="primary"
                          accept={"image/*"}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label={"Collections"}>
                      {getFieldDecorator("collections", {
                        initialValue: [],
                      })(<CollectionsAutoComplete />)}
                    </Form.Item>
                    <Form.Item label={"Tags"}>
                      {getFieldDecorator("tags", {
                        initialValue: [],
                      })(<TagsAutoComplete />)}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("products", {
                        initialValue: [],
                      })(
                        <CampaignProductBaseItems
                          grouped
                          noAction
                          selectedIndex={this.state.selected}
                          onSelected={(pbaseId) => {
                            this.setState({ selected: pbaseId });
                          }}
                          onDelete={(index) => {
                            if (productsValue[index]) {
                              productBases = productBases.filter(
                                (p) =>
                                  p.id !== productsValue[index].productBase.id
                              );
                              if (this.props.onChange) {
                                this.props.onChange(productBases);
                              }
                              this.props.form.setFieldsValue({
                                products: productsValue.filter(
                                  (_, i) => i !== index
                                ),
                              });
                            }
                          }}
                          onChangeValue={(v) => {
                            this.setState({
                              onChangeValue: v,
                            });
                          }}
                        />
                      )}
                    </Form.Item>
                    <div
                      className={"add-more"}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        onClick={() => {
                          if (this.props.onAddMore) {
                            this.props.onAddMore();
                          }
                        }}
                        type="primary"
                      >
                        Add More Products
                      </Button>
                    </div>
                  </Card>
                  <Card style={{ marginTop: 20 }} title={"Personalized"}>
                    <Form.Item>
                      {getFieldDecorator("personalized", {
                        initialValue: false,
                        valuePropName: "checked",
                      })(<Checkbox>This is a personalized product</Checkbox>)}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("fields", {
                        initialValue: [],
                        rules: [
                          {
                            required: !!personalized,
                            message: "Personalized is required",
                          },
                        ],
                      })(<CustomizeButton showPreview={!!personalized} />)}
                    </Form.Item>
                  </Card>
                  <Form.Item
                    style={{
                      marginTop: 20,
                      textAlign: "right",
                      marginBottom: 0,
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (
                          null === this.state.showBtnSave
                            ? false
                            : !this.state.showBtnSave
                        ) {
                          Modal.confirm({
                            title: "Cancel all unsaved changes?",
                            icon: <ExclamationCircleOutlined />,
                            content: "If Ok, you’ll delete any edits you made.",
                            onOk() {
                              history.push(`/${currentParam}/templates`);
                            },
                            cancelText: "Continue",
                          });
                        } else {
                          history.push(`/${currentParam}/templates`);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="ml-4"
                      type="primary"
                      loading={loading}
                      htmlType={"submit"}
                      disabled={
                        null === this.state.showBtnSave
                          ? true
                          : this.state.showBtnSave
                      }
                    >
                      Create template
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </ApolloConsumer>
      </React.Fragment>
    );
  }
}

AddForCampaignForm.propTypes = {
  productBases: PropTypes.array,
  onChange: PropTypes.func,
  onAddMore: PropTypes.func,
  onViewTemplates: PropTypes.func,
};

export default Form.create({ name: "campaign-form" })(AddForCampaignForm);
