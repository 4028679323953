import { ActionList, Button, Popover, Modal, TextContainer } from "@shopify/polaris";

import React, { useState, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useAppContext } from "../../../context";
import { getParamByRole } from "../../../helper";
import { useHistory } from "react-router-dom";
const _ = require('lodash');

const DELETE_SELECTED_TEMPLATE = gql`
	mutation deleteTiktokProductTemplate($id: ID!) {
		deleteTiktokProductTemplate(id: $id)
	}
`;

export default function ProductTemplateActions({item, onActionCompleted, disableAction}) {
	const history = useHistory();
	const [open, setOpen] = useState(false);
	const [actionInModal, setActionInModal] = useState({});
	const [modalActionLoading, setModalActionLoading] = useState(false);
	const [activeRow, setActiveRow] = useState(null);

	const { currentUser } = useAppContext();
	const param = getParamByRole(currentUser);

	const handleAction = (action, item) => {
		switch(action) {
			case 'edit':
				let itemDetailUrl = `/${param}/tiktok-hub/product-templates/${item?.id}`;
				history.push(itemDetailUrl);
				break
			case 'clone':
				let linkClone = `/${param}/tiktok-hub/product-templates/${item?.id}/clone`;
				history.push(linkClone);
				break
			case 'delete':
				setOpen(true);
				setActionInModal({
					action,
					item
				});
				break;
		}
		setActiveRow(null); // Close the popover
	};

	const renderActions = useCallback((item) => {
		let actions = [{
			content: 'Edit template',
			onAction: () => handleAction('edit', item),
		}];
		actions.push({
			content: 'Clone template',
			onAction: () => handleAction('clone', item),
		});

		actions.push({
			content: 'Delete template',
			onAction: () => handleAction('delete', item),
		});

		return (
			<Popover
				active={activeRow === item.id}
				activator={
					<Button disabled={disableAction} disclosure onClick={() => {
						setActiveRow(prevActiveRow => {
							let newActiveRow = (prevActiveRow === item.id ? null : item.id);
							return newActiveRow;
						});
					}}>Actions</Button>
				}
				onClose={() => setActiveRow(null)}
			>
				<ActionList items={actions} />
			</Popover>
		)
	}, [activeRow]);

	const handleModalChange = useCallback(() => setOpen((open) => !open), []);

	const [deleteTiktokProductTemplate, {}] = useMutation(DELETE_SELECTED_TEMPLATE);
	let validActions = {
		delete: deleteTiktokProductTemplate,
	}

	const modalActionLabels = {
		delete: {
			heading: 'Delete this template: #' + (item?.id || ''),
			label: 'Delete this template',
			confirmText: 'Are you sure to delete the selected template?',
		},
	}

	return (
		<>
			{renderActions(item)}

			<Modal
				title={modalActionLabels?.[actionInModal?.action]?.heading}
				open={open}
				onClose={handleModalChange}
				primaryAction={{
					content: modalActionLabels?.[actionInModal?.action]?.label,
					loading: modalActionLoading,
					onAction: () => {
						setModalActionLoading(true);

						let selectedItem = actionInModal?.item
						if (selectedItem) {
							switch (actionInModal?.action) {
								case 'delete':
									validActions[actionInModal.action]({
										variables: { id: selectedItem.id },
									})
									.then((res) => {
										setModalActionLoading(false);
										if (res && onActionCompleted) {
											onActionCompleted({
												type: 'success',
												message: _.startCase(actionInModal?.action) + ' processed successfully',
											});
										}
									}).catch((err) => {
										setModalActionLoading(false);
										if (onActionCompleted) {
											onActionCompleted({
												type: 'fail',
												message: _.startCase(actionInModal?.action) + ' failed to process',
											});
										}
									}).finally(() => {
										setOpen(false);
									});
									break;
							}
						}
					}
				}}
				secondaryActions={[
					{
						content: "Cancel",
						onAction: handleModalChange,
					},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<div dangerouslySetInnerHTML={{ __html: modalActionLabels?.[actionInModal.action]?.confirmText }} />
					</TextContainer>
				</Modal.Section>
			</Modal>
		</>
	);
}